export default function Write({ className }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-icon ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0601 4.09998H13.6001C13.8388 4.09998 14.0677 4.1948 14.2365 4.36358C14.4053 4.53236 14.5001 4.76128 14.5001 4.99998C14.5001 5.23867 14.4053 5.46759 14.2365 5.63637C14.0677 5.80515 13.8388 5.89998 13.6001 5.89998H10.1001C9.1001 5.89998 8.4101 5.89998 7.8701 5.93998C7.3501 5.98998 7.0501 6.06998 6.8201 6.17998C6.32891 6.42959 5.92971 6.82878 5.6801 7.31998C5.5701 7.54998 5.4801 7.84998 5.4401 8.36998C5.4001 8.90998 5.4001 9.60998 5.4001 10.6V14.4C5.4001 15.4 5.4001 16.09 5.4401 16.63C5.4901 17.15 5.5701 17.45 5.6801 17.68C5.9301 18.17 6.3301 18.57 6.8201 18.82C7.0501 18.93 7.3501 19.02 7.8701 19.06C8.4101 19.1 9.1101 19.1 10.1001 19.1H13.9001C14.9001 19.1 15.5901 19.1 16.1301 19.06C16.6501 19.01 16.9501 18.93 17.1801 18.82C17.6713 18.5704 18.0705 18.1712 18.3201 17.68C18.4301 17.45 18.5201 17.15 18.5601 16.63C18.6001 16.09 18.6001 15.39 18.6001 14.4V10.9C18.6001 10.6613 18.6949 10.4324 18.8637 10.2636C19.0325 10.0948 19.2614 9.99998 19.5001 9.99998C19.7388 9.99998 19.9677 10.0948 20.1365 10.2636C20.3053 10.4324 20.4001 10.6613 20.4001 10.9V14.44C20.4001 15.39 20.4001 16.15 20.3501 16.77C20.3236 17.3695 20.1774 17.9578 19.9201 18.5C19.4985 19.3264 18.8265 19.9984 18.0001 20.42C17.4579 20.6772 16.8697 20.8234 16.2701 20.85C15.6501 20.9 14.8901 20.9 13.9401 20.9H10.0601C9.1101 20.9 8.3501 20.9 7.7301 20.85C7.13053 20.8234 6.54232 20.6772 6.0001 20.42C5.17369 19.9984 4.50168 19.3264 4.0801 18.5C3.82309 17.9577 3.6769 17.3695 3.6501 16.77C3.6001 16.15 3.6001 15.39 3.6001 14.44V10.56C3.6001 9.60998 3.6001 8.84998 3.6501 8.22998C3.7001 7.58998 3.8101 7.02998 4.0801 6.49998C4.50168 5.67357 5.17369 5.00156 6.0001 4.57998C6.54241 4.32297 7.13057 4.17678 7.7301 4.14998C8.3501 4.09998 9.1101 4.09998 10.0601 4.09998Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6201 3.33C19.7207 3.22276 19.8419 3.1368 19.9764 3.0772C20.1108 3.0176 20.2559 2.98558 20.4029 2.98302C20.55 2.98046 20.6961 3.00741 20.8325 3.06229C20.969 3.11718 21.0931 3.19887 21.1974 3.30253C21.3017 3.4062 21.3842 3.52974 21.44 3.66585C21.4957 3.80195 21.5236 3.94785 21.522 4.09493C21.5204 4.242 21.4893 4.38726 21.4306 4.5221C21.3719 4.65695 21.2867 4.77865 21.1801 4.88L20.8301 5.23C20.7929 5.26779 20.7485 5.29781 20.6996 5.31829C20.6506 5.33878 20.5981 5.34933 20.5451 5.34933C20.492 5.34933 20.4395 5.33878 20.3906 5.31829C20.3417 5.29781 20.2973 5.26779 20.2601 5.23L19.2701 4.24C19.1968 4.16523 19.1557 4.0647 19.1557 3.96C19.1557 3.8553 19.1968 3.75477 19.2701 3.68L19.6201 3.33ZM19.0201 5.9L18.6001 5.48C18.1601 5.04 17.8801 5.06 17.4701 5.48L12.3401 10.6C10.3901 12.56 9.15008 14.49 9.58008 14.92C10.0101 15.35 11.9401 14.12 13.9001 12.16L19.0201 7.03C19.4601 6.59 19.4401 6.31 19.0201 5.9Z"
        fill="#1D1D1D"
      />
    </svg>
  );
}
