const LogoWithoutLine = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M17.7572 6.88296H14.6708C14.4663 6.87732 14.2636 6.92151 14.08 7.01174C13.8965 7.10196 13.7377 7.2355 13.6173 7.40081L11.4802 10.1361L10.0224 4.72504C9.95619 4.50621 9.8279 4.31128 9.6531 4.16383C9.4783 4.01638 9.22183 3.92275 8.99489 3.89427C8.51146 3.8195 8.10872 3.98592 7.80536 4.37154L2.85491 10.71C2.85491 10.71 2.8275 10.7405 2.80903 10.7494C2.79055 10.7582 2.74963 10.7606 2.74963 10.7606H0.172196C0.059233 10.1912 0 9.60252 0 9C0 4.02944 4.03116 0 9.00384 0C13.247 0 16.8047 2.93391 17.7572 6.88296Z" />
    <path d="M18 9.37527H15.3411C15.3226 9.37374 15.304 9.37724 15.2873 9.38541C15.2706 9.39357 15.2564 9.40609 15.2462 9.42165L11.9592 13.6205C11.8318 13.7882 11.664 13.9209 11.4714 14.0062C11.2788 14.0915 11.0678 14.1266 10.8579 14.1083C10.6481 14.0899 10.4463 14.0188 10.2714 13.9014C10.0965 13.784 9.95426 13.6243 9.85788 13.437C9.79149 13.2923 9.73975 13.1413 9.70343 12.9863C9.34142 11.694 8.32166 7.86271 8.32166 7.86271L4.4812 12.7669C4.3649 12.9212 4.21355 13.0456 4.03965 13.1298C3.86575 13.2141 3.67429 13.2558 3.48108 13.2515H1.06595C2.58431 16.0781 5.56953 18 9.00384 18C13.8508 18 17.8032 14.1718 18 9.37527Z" />
  </svg>
);

export default LogoWithoutLine;
