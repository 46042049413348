import React from "react";

const Hourly = ({ className }) => (
  <svg
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M4.46075 0C3.36 0.00323594 2.2984 0.408674 1.47572 1.14001L0.97584 0.640131C0.923395 0.587702 0.856583 0.551999 0.78385 0.537536C0.711117 0.523073 0.635729 0.530499 0.567216 0.558876C0.498703 0.587252 0.440141 0.635305 0.398934 0.696959C0.357727 0.758614 0.335725 0.831101 0.335709 0.905258V2.62502C0.335709 2.72448 0.375218 2.81986 0.445545 2.89019C0.515872 2.96052 0.611255 3.00003 0.710713 3.00003H2.43048C2.50464 3.00001 2.57712 2.97801 2.63878 2.9368C2.70043 2.8956 2.74848 2.83703 2.77686 2.76852C2.80524 2.70001 2.81266 2.62462 2.7982 2.55189C2.78374 2.47915 2.74803 2.41234 2.69561 2.3599L2.26998 1.93427C2.76667 1.50997 3.37582 1.23893 4.02353 1.15403C4.67124 1.06914 5.32967 1.17403 5.91893 1.456C6.50819 1.73797 7.00296 2.18488 7.34321 2.74252C7.68346 3.30016 7.85457 3.94456 7.83578 4.59754C7.81165 5.44125 7.47227 6.24529 6.88456 6.85111C6.29685 7.45693 5.50347 7.82055 4.66089 7.87026C3.8183 7.91996 2.98767 7.65215 2.33281 7.11964C1.67794 6.58713 1.24637 5.82856 1.12322 4.99355C1.10563 4.85778 1.03945 4.73297 0.93693 4.64224C0.834409 4.55151 0.702488 4.50099 0.565586 4.50004C0.486147 4.49902 0.407404 4.51495 0.334616 4.54679C0.261829 4.57863 0.196673 4.62563 0.143501 4.68466C0.0903289 4.74369 0.0503654 4.81338 0.0262797 4.88909C0.00219399 4.9648 -0.00545928 5.04477 0.00383112 5.12367C0.159912 6.22842 0.720233 7.236 1.57637 7.95145C2.43251 8.66689 3.52359 9.03934 4.6385 8.99671C5.76641 8.94237 6.83365 8.46989 7.63212 7.67142C8.4306 6.87294 8.90307 5.8057 8.95741 4.67779C8.98066 4.07251 8.8816 3.46875 8.66615 2.90263C8.45071 2.3365 8.12331 1.81964 7.70353 1.38295C7.28375 0.946266 6.78021 0.598719 6.22303 0.361098C5.66586 0.123477 5.06648 0.000663114 4.46075 0V0Z" />
    <path d="M4.27344 2.625C4.12426 2.625 3.98118 2.68426 3.87569 2.78975C3.7702 2.89524 3.71094 3.03832 3.71094 3.18751V4.79739C3.71098 4.99629 3.79003 5.18703 3.93069 5.32765L4.60307 6.00003C4.70916 6.1025 4.85125 6.15919 4.99874 6.15791C5.14622 6.15663 5.28731 6.09747 5.3916 5.99318C5.49589 5.88889 5.55505 5.7478 5.55633 5.60032C5.55762 5.45283 5.50092 5.31074 5.39845 5.20465L4.83595 4.64214V3.18751C4.83595 3.03832 4.77668 2.89524 4.67119 2.78975C4.5657 2.68426 4.42263 2.625 4.27344 2.625Z" />
  </svg>
);

export default Hourly;
