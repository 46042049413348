import React from "react";

const Daily = ({ className }) => (
  <svg
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M4.50019 9C5.60093 8.99676 6.66254 8.59133 7.48522 7.85999L7.9851 8.35987C8.03754 8.4123 8.10435 8.448 8.17709 8.46246C8.24982 8.47693 8.32521 8.4695 8.39372 8.44112C8.46223 8.41275 8.5208 8.36469 8.562 8.30304C8.60321 8.24139 8.62521 8.1689 8.62523 8.09474V6.37498C8.62523 6.27552 8.58572 6.18014 8.51539 6.10981C8.44507 6.03948 8.34968 5.99997 8.25022 5.99997H6.53046C6.4563 5.99999 6.38381 6.02199 6.32216 6.0632C6.26051 6.1044 6.21245 6.16297 6.18408 6.23148C6.1557 6.29999 6.14827 6.37538 6.16274 6.44811C6.1772 6.52085 6.2129 6.58766 6.26533 6.6401L6.69096 7.06573C6.19427 7.49003 5.58512 7.76107 4.93741 7.84597C4.2897 7.93086 3.63127 7.82597 3.04201 7.544C2.45275 7.26203 1.95798 6.81512 1.61772 6.25748C1.27747 5.69984 1.10637 5.05544 1.12516 4.40246C1.14928 3.55875 1.48866 2.75471 2.07638 2.14889C2.66409 1.54307 3.45746 1.17945 4.30005 1.12974C5.14264 1.08004 5.97326 1.34785 6.62813 1.88036C7.283 2.41287 7.71456 3.17144 7.83772 4.00645C7.85531 4.14222 7.92149 4.26703 8.02401 4.35776C8.12653 4.44849 8.25845 4.49901 8.39535 4.49996C8.47479 4.50098 8.55353 4.48505 8.62632 4.45321C8.69911 4.42137 8.76426 4.37437 8.81744 4.31534C8.87061 4.25631 8.91057 4.18662 8.93466 4.11091C8.95874 4.0352 8.9664 3.95523 8.95711 3.87633C8.80103 2.77158 8.2407 1.764 7.38457 1.04855C6.52843 0.333107 5.43734 -0.0393353 4.32244 0.00329304C3.19453 0.0576344 2.12729 0.530106 1.32881 1.32858C0.530337 2.12706 0.0578661 3.1943 0.00352478 4.32221C-0.0197201 4.92749 0.0793409 5.53125 0.294785 6.09737C0.510229 6.6635 0.83763 7.18036 1.25741 7.61705C1.67719 8.05373 2.18072 8.40128 2.7379 8.6389C3.29508 8.87652 3.89446 8.99934 4.50019 9V9Z" />
    <path d="M4.27344 2.625C4.12426 2.625 3.98118 2.68426 3.87569 2.78975C3.7702 2.89524 3.71094 3.03832 3.71094 3.18751V4.79739C3.71098 4.99629 3.79003 5.18703 3.93069 5.32765L4.60307 6.00003C4.70916 6.1025 4.85125 6.15919 4.99874 6.15791C5.14622 6.15663 5.28731 6.09747 5.3916 5.99318C5.49589 5.88889 5.55505 5.7478 5.55633 5.60032C5.55762 5.45283 5.50092 5.31074 5.39845 5.20465L4.83595 4.64214V3.18751C4.83595 3.03832 4.77668 2.89524 4.67119 2.78975C4.5657 2.68426 4.42263 2.625 4.27344 2.625Z" />
  </svg>
);

export default Daily;
