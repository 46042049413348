import React from "react";

const TelegramColored = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="url(#paint0_linear_16718_31023)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52635 9.89446C7.44156 8.62435 9.38548 7.78702 10.3581 7.38246C13.1352 6.22737 13.7123 6.02672 14.0884 6.02009C14.1711 6.01863 14.3561 6.03913 14.4759 6.13635C14.5771 6.21844 14.6049 6.32934 14.6182 6.40717C14.6316 6.485 14.6481 6.6623 14.635 6.80083C14.4845 8.38207 13.8333 12.2193 13.502 13.9903C13.3618 14.7397 13.0858 14.991 12.8186 15.0155C12.2379 15.069 11.7969 14.6318 11.2345 14.2631C10.3543 13.6861 9.85711 13.327 9.00279 12.764C8.01547 12.1134 8.65551 11.7558 9.21817 11.1714C9.36543 11.0184 11.9241 8.69115 11.9736 8.48002C11.9798 8.45362 11.9856 8.3552 11.9271 8.30323C11.8686 8.25125 11.7823 8.26903 11.72 8.28316C11.6318 8.3032 10.2257 9.23252 7.50198 11.0711C7.10288 11.3452 6.7414 11.4787 6.41752 11.4717C6.06046 11.464 5.37364 11.2698 4.86306 11.1039C4.23681 10.9003 3.73907 10.7927 3.78242 10.4469C3.80499 10.2669 4.05297 10.0827 4.52635 9.89446Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16718_31023"
        x1="10"
        y1="0"
        x2="10"
        y2="19.8517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AABEE" />
        <stop offset="1" stopColor="#229ED9" />
      </linearGradient>
    </defs>
  </svg>
);

export default TelegramColored;
