import Link from "next/link";
import { useRouter } from "next/router";
import Button from "~ui/button";
import AllIcons from "~ui/all-icons";

const locales = ["en", "ru", "ua"];

const DropdownItems = () => {
  const { asPath } = useRouter();
  return (
    <div className="flex flex-col">
      {locales.map((locale) => (
        <Link key={locale} href={asPath} locale={locale}>
          <a
            className={
              "px-5 py-3 hover:bg-gray-100 text-black  rounded-xl text-lg  font-medium"
            }
          >
            {locale.toUpperCase()}
          </a>
        </Link>
      ))}
    </div>
  );
};

const LocaleButton = ({ isDark = false, isFooter = false }) => {
  const { locale } = useRouter();

  return (
    <Button
      variant="text"
      dropdownItems={DropdownItems}
      dropdownContainerClasses={`!left-1/2 !-translate-x-1/2 ${
        isFooter && "md:-translate-y-[130%]"
      }  !w-auto !rounded-xl p-2`}
      className={`!font-medium text-lg ${
        isDark
          ? "!text-white hover:bg-white hover:!text-black"
          : "!text-black hover:bg-gray-100 "
      }  px-4 py-2 rounded-xl`}
    >
      {locale.toUpperCase()}
      <AllIcons name="ChevronDown" className="ml-2 w-5 h-5" />
    </Button>
  );
};

export default LocaleButton;
