import React, { memo } from "react";
import useStyleRewriter from "../../../zui/functions/use-style-rewriter";

const H1 = memo(({ children = "", className = "" }) => {
  const srClasses = useStyleRewriter(baseClasses, className);

  return <h1 className={srClasses}>{children}</h1>;
});

export default H1;

const baseClasses = `
@ftf font-montserrat
@ftw font-semibold
@fts text-2xl md:text-26px
@tta text-left
@vla align-top
@leh leading-30px
@ttc text-zinc-900`;
