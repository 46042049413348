const PaidMessage = ({ className }) => (
  <svg
    className={`stroke-current ${className}`}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3V3C16 1.89543 15.1046 1 14 1H3C1.89543 1 1 1.89543 1 3V10C1 11.1046 1.89543 12 3 12H8.5H11.5"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M1 3.00008L8.57992 8L16 3"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M18.0487 9.25525C18.0319 9.61384 18.3089 9.91818 18.6675 9.935C19.0261 9.95182 19.3304 9.67477 19.3473 9.31618L18.0487 9.25525ZM13.702 8.88235L14.3517 8.90212L14.3518 8.9002L13.702 8.88235ZM18.6223 13.1176L17.9726 13.1374L17.9726 13.138L18.6223 13.1176ZM14.352 12.7143C14.352 12.3553 14.061 12.0643 13.702 12.0643C13.3431 12.0643 13.052 12.3553 13.052 12.7143H14.352ZM19.3473 9.31618C19.3946 8.30794 19.0066 7.53357 18.3747 7.02963C17.7682 6.54596 16.9887 6.35 16.2757 6.35V7.65C16.7739 7.65 17.2433 7.79017 17.5641 8.046C17.8595 8.28156 18.0771 8.65004 18.0487 9.25525L19.3473 9.31618ZM16.2757 6.35C14.4324 6.35 13.0871 7.59501 13.0523 8.86451L14.3518 8.9002C14.3623 8.51592 14.9398 7.65 16.2757 7.65V6.35ZM13.0523 8.86258C13.0252 9.75564 13.4716 10.3635 14.0531 10.7745C14.5925 11.1558 15.2959 11.4022 15.8878 11.6125C16.5263 11.8393 17.0583 12.0312 17.446 12.2942C17.7989 12.5336 17.9618 12.7822 17.9726 13.1374L19.272 13.0979C19.245 12.2094 18.7644 11.6177 18.1758 11.2184C17.622 10.8427 16.9145 10.5976 16.323 10.3875C15.6848 10.1608 15.1676 9.97027 14.8034 9.71289C14.4813 9.48521 14.3411 9.25276 14.3517 8.90212L13.0523 8.86258ZM17.9726 13.138C17.9849 13.531 17.8359 13.8016 17.5814 13.9947C17.3046 14.2046 16.8602 14.35 16.2757 14.35V15.65C17.0537 15.65 17.8015 15.4593 18.3669 15.0305C18.9545 14.5849 19.2975 13.9144 19.272 13.0973L17.9726 13.138ZM16.2757 14.35C15.4165 14.35 14.9692 14.1505 14.732 13.9202C14.4992 13.6943 14.352 13.3251 14.352 12.7143H13.052C13.052 13.5152 13.2455 14.2889 13.8265 14.8529C14.403 15.4126 15.2426 15.65 16.2757 15.65V14.35Z"
      fill="#999999"
    />
    <line
      x1="16.0512"
      y1="6.35"
      x2="16.0512"
      y2="5.65"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <line
      x1="16.0512"
      y1="16.35"
      x2="16.0512"
      y2="15.65"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default PaidMessage;
