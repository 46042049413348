import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccountAddress,
  selectAccountId,
  selectJwt,
} from "~store/authSlice/selectors";
import { useGetMeQuery, useGetProfileByIdQuery } from ".";
import { useGetSubscriptionLevelsQuery } from "../subscription-levels";
import { logout as appLogout, startSigning } from "~store/authSlice";
import useWeb3Auth from "src/hooks/web3/use-web3-auth";
import { authTokensManager } from "~utils/localStorage/authTokensManager";

import { backWsUrl } from "~configs";
import { chatsApi } from "~services/backend/chats";
import { transformResponseItem } from "~api";

const useProfile = () => {
  const dispatch = useDispatch();
  const { logout: web3logout } = useWeb3Auth();

  const jwtToken = useSelector(selectJwt);
  const accountId = useSelector(selectAccountId);
  const accountAddress = useSelector(selectAccountAddress);

  const {
    data: myProfileById,
    error,
    // refetch: refetchProfileById,
  } = useGetProfileByIdQuery(accountId, {
    skip: !accountId,
  });

  useWsListener({ jwtToken, accountId });

  useEffect(() => {
    if (!myProfileById) {
      // if (typeof refetchProfileById === "function") {
      //   refetchProfileById();
      // }
    }
  }, [jwtToken]);

  const { data: myProfileByMe, error: byMeError } = useGetMeQuery(undefined, {
    skip: !jwtToken || accountId,
  });

  useEffect(() => {
    if (byMeError) {
      console.error("Error when fetch me", byMeError);
      dispatch(startSigning());

      authTokensManager.removeJwt();
    }
    if (!error) return;

    if (error.status !== 403 && error.status !== 401) {
      setTimeout(() => {
        // refetchProfileById();
      }, 2000);
    } else {
      dispatch(startSigning());

      authTokensManager.removeJwt();
    }
  }, [error, byMeError]);

  const { data: subscriptionLevels = [] } = useGetSubscriptionLevelsQuery(
    accountAddress?.toLowerCase(),
  );

  const logout = () => {
    dispatch(appLogout());
    web3logout({ forced: true });
  };

  return useMemo(() => {
    let profile = null;

    if (accountId) {
      profile = myProfileById || myProfileByMe;
    }

    if (subscriptionLevels && profile) {
      profile = { ...profile, subscriptionLevels };
    }

    return {
      profile,
      logout,
    };
  }, [accountId, myProfileById, myProfileByMe, subscriptionLevels]);
};

export default useProfile;

let socket;
let interval;
const useWsListener = ({ jwtToken, accountId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!jwtToken) return;

    if (!socket) {
      socket = new WebSocket(`${backWsUrl}/api`);
    }

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          type: "AUTHENTICATE",
          payload: {
            token: jwtToken,
          },
        }),
      );
    };

    socket.onmessage = ({ data: message }) => {
      if (message === "") return;

      const { type, payload } = JSON.parse(message);

      if (type === "MESSAGE" && payload.chat) {
        const transformedMessage = transformResponseItem(payload);
        dispatch(
          chatsApi.util.updateQueryData(
            "getMessagesByChat",
            { chatId: +payload.chat },
            (draft) => {
              draft.push(transformedMessage);
            },
          ),
        );
        dispatch(
          chatsApi.util.updateQueryData("getChats", accountId, (draft) => {
            let chat = draft.find((chat) => chat.id === payload.chat);

            if (!chat) {
              draft.push({
                id: payload.chat,
                user: transformedMessage.user,
              });
              chat = draft.find((chat) => chat.id === payload.chat);
            }
            chat.lastMessage = transformedMessage;
            chat.unreadCount = chat.unreadCount ? chat.unreadCount + 1 : 1;
          }),
        );
      }
    };

    window.socket = socket;

    if (!interval) {
      interval = setInterval(() => {
        if (socket.OPEN) {
          socket.send("");
        } else {
          clearInterval(interval);
        }
      }, 30000);
    }
  }, [jwtToken]);
};
