export default function ChevronDown({ className }) {
  return (
    <svg
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path d="M14.5 1.5L7.75092 8.24908L1.00184 1.5" strokeWidth="2" />
    </svg>
  );
}
