import { memo, useCallback } from "react";
import AllIcons from "~components/ui/all-icons";
import P from "~components/ui/typography/p";

const wallet = typeof window !== "undefined" ? window?.ethereum : null;
const isMetaMaskInstalled = wallet?.isMetaMask;

const WalletButton = memo(
  ({
    title,
    icon,
    connectorId,
    uid,
    background,
    isLoading = false,
    onClick,
    setLoadingWalletId,
  }) => {
    const style = { background };

    const handleClick = useCallback(() => {
      if (uid === "metamask" && !isMetaMaskInstalled) {
        window.location.href = `https://metamask.app.link/dapp/subclub.me${window.location.pathname}`;
        return;
      }

      setLoadingWalletId(uid);
      onClick(connectorId, uid);
    }, [onClick, connectorId, uid]);

    return (
      <button
        className={
          "flex justify-center items-center w-full rounded-full py-3 duration-200 shadow-base md:hover:scale-105 md:hover:rotate-2 md:hover:odd:-rotate-2"
        }
        style={style}
        onClick={handleClick}
      >
        <P className="@ftf font-clash @ttc text-white @ftw font-semibold leading-[23px] mr-[10px]">
          {isLoading ? "Connecting..." : title}
        </P>
        <AllIcons
          className={isLoading ? "animate-spin" : "w-5 h-5"}
          name={isLoading ? "SmallLoader" : icon}
        />
      </button>
    );
  },
);

export default WalletButton;
