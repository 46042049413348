import { api } from "~utils/api/fetch";
import {
  transformPageBlock,
  getMainQuery,
  mainTransformers,
  layoutTransformers,
} from "./transformers";

// TODO to env
const backBaseUrl = "https://website.subclub.space";

const getMainPageProps = async ({ locale }) => {
  const res = await api
    .get(`${backBaseUrl}/api/main-page?${getMainQuery(locale)}`)
    .catch((err) => console.log("getMainPageProps", err.message));

  const pageBlocks =
    res?.page_blocks?.map((block) =>
      transformPageBlock(block, mainTransformers),
    ) || {};

  return {
    props: {
      data: pageBlocks || null,
      header: layoutTransformers.header(res?.header?.[0]),
      footer: layoutTransformers.footer(res?.footer?.[0]),
      locale,
      isLanding: true,
    },
    revalidate: 300,
  };
};

export default getMainPageProps;
