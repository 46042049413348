import React from "react";

const Swap = ({ className }) => {
  return (
    <svg
      width="13"
      className={`fill-current ${className}`}
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.32222 11.6C3.00313 11.8485 2.55044 11.7813 2.31111 11.45L0.144449 8.44999C-0.0196861 8.22273 -0.0460878 7.91867 0.0762519 7.66459C0.19859 7.4105 0.448668 7.25 0.722226 7.25H12.2778C12.6766 7.25 13 7.58578 13 7.99999C13 8.4142 12.6766 8.74999 12.2778 8.74999H2.16667L3.46667 10.55C3.70599 10.8813 3.64132 11.3514 3.32222 11.6Z" />
      <path
        opacity="0.7"
        d="M9.67778 0.40003C9.99687 0.151504 10.4496 0.21866 10.6889 0.550029L12.8556 3.55001C13.0197 3.77727 13.0461 4.08133 12.9237 4.33541C12.8014 4.5895 12.5513 4.75 12.2778 4.75H0.722225C0.323354 4.75 3.8147e-06 4.41422 3.8147e-06 4.00001C3.8147e-06 3.5858 0.323354 3.25001 0.722225 3.25001H10.8333L9.53333 1.45002C9.29401 1.11866 9.35868 0.648557 9.67778 0.40003Z"
      />
    </svg>
  );
};

export default Swap;
