import React from "react";

const Iphone = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.2023 38.8969C25.2023 39.4998 25.6925 39.9897 26.295 39.9897C26.8976 39.9897 27.3878 39.4995 27.3878 38.8969C27.3878 38.2944 26.8973 37.8042 26.295 37.8042C25.6928 37.8042 25.2023 38.2944 25.2023 38.8969Z" />
      <path d="M29.1941 11.0954H20.8987C19.9027 11.0954 19.0957 11.9027 19.0957 12.8987V15.2286C19.0397 15.2549 19 15.3109 19 15.3766V15.9725C19 16.0382 19.0393 16.0945 19.0957 16.1202V16.8135C19.0397 16.8395 19 16.8952 19 16.9609V17.5571C19 17.6228 19.0393 17.6788 19.0957 17.7048V39.1963C19.0957 40.193 19.9027 41 20.8987 41H31.4729C32.4689 41 33.2762 40.193 33.2762 39.1963V12.8987C33.2762 11.9027 32.4689 11.0954 31.4729 11.0954H31.0608C31.0351 11.0397 30.9791 11 30.913 11H29.3418C29.2761 11 29.2194 11.0397 29.1941 11.0954ZM27.7162 38.8969C27.7162 39.6802 27.0786 40.3178 26.295 40.3178C25.5114 40.3178 24.8745 39.6802 24.8745 38.8969C24.8745 38.1133 25.5117 37.4757 26.295 37.4757C27.0783 37.4757 27.7162 38.1133 27.7162 38.8969ZM32.2519 15.1939C32.3426 15.1939 32.4159 15.2672 32.4159 15.3579V36.7928C32.4159 36.8832 32.3426 36.9562 32.2519 36.9562H20.1611C20.0707 36.9562 19.9977 36.8828 19.9977 36.7928V15.3579C19.9977 15.2672 20.0707 15.1939 20.1611 15.1939H32.2519ZM27.2243 13.5409C27.3147 13.5409 27.3881 13.6143 27.3881 13.7047C27.3881 13.795 27.3144 13.8691 27.2243 13.8691H25.1749C25.0846 13.8691 25.0109 13.7954 25.0109 13.7047C25.0109 13.614 25.0846 13.5409 25.1749 13.5409H27.2243ZM26.4047 12.5296C26.4047 12.6806 26.2823 12.803 26.1316 12.803C25.9806 12.803 25.8582 12.6806 25.8582 12.5296C25.8582 12.3788 25.9806 12.2565 26.1316 12.2565C26.2823 12.2565 26.4047 12.3788 26.4047 12.5296Z" />
    </svg>
  );
};

export default Iphone;
