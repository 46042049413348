import React from "react";

const Uniswap = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M16.6233 9.10233C16.1898 9.04041 16.1692 9.01977 16.3756 8.99913C16.7677 8.93721 17.6759 9.01977 18.3157 9.16425C19.8018 9.51513 21.1434 10.4233 22.5675 12.0126L22.939 12.446L23.4757 12.3634C25.7667 11.9919 28.1196 12.2809 30.0804 13.189C30.6171 13.4367 31.4633 13.9321 31.5665 14.0559C31.6078 14.0972 31.6697 14.3655 31.711 14.6338C31.8555 15.6039 31.7935 16.3263 31.4839 16.8836C31.3188 17.1932 31.3188 17.2757 31.422 17.544C31.5046 17.7504 31.7523 17.8949 31.9793 17.8949C32.4746 17.8949 32.9906 17.1106 33.2383 16.0167L33.3415 15.5832L33.5273 15.7896C34.5799 16.9661 35.4055 18.5967 35.5293 19.7525L35.5706 20.0621L35.3849 19.7938C35.0753 19.3191 34.7863 19.0095 34.3942 18.7411C33.6924 18.2664 32.9494 18.1219 30.9886 18.0187C29.2136 17.9155 28.2022 17.7711 27.2115 17.4408C25.519 16.8836 24.6521 16.1612 22.6501 13.4986C21.7626 12.3221 21.2053 11.6823 20.648 11.1457C19.4303 9.96921 18.2125 9.35001 16.6233 9.10233Z"
          fill="#FF007A"
        />
        <path
          d="M32 11.7234C32.0412 10.9391 32.1444 10.4231 32.3715 9.94841C32.454 9.76265 32.5366 9.59753 32.5572 9.59753C32.5779 9.59753 32.5366 9.74201 32.4747 9.90713C32.3096 10.3612 32.2889 11.001 32.3921 11.7234C32.5366 12.6522 32.5985 12.7761 33.5892 13.7874C34.0433 14.2621 34.5799 14.8607 34.7863 15.1084L35.1372 15.5624L34.7863 15.2322C34.3529 14.8194 33.3622 14.0351 33.1352 13.9319C32.9907 13.8493 32.97 13.8493 32.8668 13.9525C32.7843 14.0351 32.7636 14.1589 32.7636 14.7575C32.743 15.6863 32.6192 16.2642 32.3096 16.8628C32.1444 17.1724 32.1238 17.1104 32.2683 16.7596C32.3715 16.4912 32.3921 16.3674 32.3921 15.4799C32.3921 13.6842 32.1857 13.2508 30.9267 12.5284C30.6171 12.3426 30.0804 12.0743 29.7708 11.9298C29.4406 11.7854 29.1929 11.6615 29.2136 11.6615C29.2549 11.6202 30.4726 11.9711 30.9473 12.1775C31.6697 12.4665 31.7936 12.4871 31.8761 12.4665C31.938 12.4046 31.9793 12.2394 32 11.7234Z"
          fill="#FF007A"
        />
        <path
          d="M17.4695 14.7987C16.6026 13.6016 16.0453 11.744 16.1692 10.3611L16.2104 9.92767L16.4168 9.96895C16.7883 10.0309 17.4282 10.2786 17.7378 10.4643C18.5634 10.9597 18.9349 11.6408 19.2858 13.3332C19.389 13.8286 19.5334 14.4065 19.5954 14.5923C19.6986 14.9019 20.0907 15.6243 20.421 16.0783C20.648 16.4086 20.5035 16.5737 19.9875 16.5324C19.2032 16.4499 18.1506 15.7275 17.4695 14.7987Z"
          fill="#FF007A"
        />
        <path
          d="M30.9472 23.7771C26.8605 22.1259 25.4157 20.7018 25.4157 18.2869C25.4157 17.936 25.4364 17.6471 25.4364 17.6471C25.457 17.6471 25.6015 17.7709 25.7873 17.9154C26.6129 18.5759 27.5416 18.8648 30.1216 19.2363C31.6283 19.4634 32.4952 19.6285 33.2795 19.8968C35.7769 20.7224 37.3249 22.4149 37.6964 24.7059C37.7996 25.3664 37.7377 26.6254 37.5726 27.2859C37.4281 27.8019 37.0153 28.7513 36.9121 28.7719C36.8915 28.7719 36.8502 28.6687 36.8502 28.5036C36.8089 27.6367 36.3755 26.8112 35.6531 26.1713C34.7862 25.4283 33.6717 24.871 30.9472 23.7771Z"
          fill="#FF007A"
        />
        <path
          d="M28.0577 24.4584C28.0164 24.1488 27.9132 23.7566 27.8513 23.5915L27.7481 23.2819L27.9338 23.5089C28.2021 23.8185 28.4085 24.19 28.5943 24.706C28.7388 25.0982 28.7388 25.222 28.7388 25.8619C28.7388 26.4811 28.7181 26.6255 28.5943 26.9764C28.3879 27.5337 28.1402 27.9259 27.7274 28.3593C26.9844 29.123 26.0143 29.5358 24.6315 29.7215C24.3838 29.7422 23.682 29.8041 23.0628 29.8454C21.5148 29.9279 20.4829 30.093 19.5541 30.4233C19.4302 30.4646 19.3064 30.5058 19.2857 30.4852C19.2445 30.4439 19.8843 30.0724 20.4003 29.8247C21.1227 29.4738 21.8657 29.2881 23.4963 28.9991C24.3012 28.8753 25.1268 28.7102 25.3332 28.6276C27.3766 27.9878 28.3879 26.3985 28.0577 24.4584Z"
          fill="#FF007A"
        />
        <path
          d="M29.9359 27.7813C29.3992 26.6048 29.2754 25.4903 29.5644 24.4377C29.6056 24.3345 29.6469 24.2313 29.6882 24.2313C29.7295 24.2313 29.8533 24.2932 29.9772 24.3757C30.2248 24.5409 30.7408 24.8298 32.0618 25.5522C33.7336 26.4604 34.683 27.1621 35.3435 27.9671C35.9214 28.6688 36.2723 29.4738 36.4374 30.4645C36.5406 31.0218 36.4787 32.3634 36.3342 32.9206C35.8802 34.675 34.8482 36.0785 33.3415 36.8835C33.1144 37.0073 32.9287 37.0899 32.908 37.0899C32.8874 37.0899 32.9699 36.8835 33.0938 36.6358C33.5891 35.5832 33.651 34.5718 33.2795 33.4366C33.0525 32.7349 32.5778 31.8886 31.6283 30.4645C30.4932 28.8133 30.2248 28.3799 29.9359 27.7813Z"
          fill="#FF007A"
        />
        <path
          d="M14.5594 34.0971C16.0867 32.8174 17.9649 31.9092 19.6987 31.6203C20.4417 31.4964 21.6801 31.5377 22.3612 31.7235C23.4551 32.0124 24.4458 32.6316 24.9618 33.3953C25.4572 34.1383 25.6842 34.7782 25.9113 36.2023C25.9938 36.7596 26.097 37.3375 26.1177 37.4614C26.2828 38.2044 26.613 38.7823 27.0258 39.0919C27.6656 39.5666 28.7802 39.5873 29.8741 39.1745C30.0599 39.1125 30.225 39.0506 30.225 39.0713C30.2663 39.1125 29.709 39.4841 29.3375 39.6698C28.8215 39.9381 28.4087 40.0207 27.8514 40.0207C26.8607 40.0207 26.0145 39.5047 25.3333 38.4727C25.1889 38.2663 24.8999 37.6678 24.6522 37.1105C23.9298 35.4387 23.5583 34.9433 22.7121 34.386C21.9691 33.9113 21.0196 33.8081 20.2972 34.159C19.3478 34.6131 19.1001 35.8308 19.7606 36.5738C20.0289 36.8834 20.5243 37.1311 20.9371 37.193C21.7007 37.2962 22.3612 36.6977 22.3612 35.934C22.3612 35.4387 22.1755 35.1497 21.6801 34.9227C21.0196 34.6337 20.2972 34.9639 20.3179 35.6038C20.3179 35.8721 20.4417 36.0372 20.71 36.161C20.8751 36.2436 20.8751 36.2436 20.7513 36.223C20.1527 36.0991 20.0083 35.3561 20.483 34.8814C21.0609 34.3035 22.2787 34.5511 22.6914 35.3561C22.8566 35.6863 22.8772 36.3468 22.7327 36.7596C22.3818 37.6678 21.3911 38.1425 20.3798 37.8742C19.6987 37.6884 19.4097 37.5026 18.5841 36.6564C17.1393 35.1703 16.5821 34.8814 14.5181 34.5718L14.1259 34.5099L14.5594 34.0971Z"
          fill="#FF007A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.70267 0.887513C8.51175 6.72859 15.9215 15.8101 16.293 16.3055C16.6026 16.7183 16.4787 17.1104 15.9627 17.3994C15.6738 17.5645 15.0752 17.7296 14.7863 17.7296C14.456 17.7296 14.0639 17.5645 13.7956 17.2962C13.6098 17.1104 12.8048 15.934 10.9885 13.1063C9.60567 10.9391 8.42919 9.14345 8.40856 9.12281C8.326 9.08153 8.326 9.08153 10.8441 13.581C12.4333 16.4087 12.9493 17.42 12.9493 17.5439C12.9493 17.8122 12.8668 17.9567 12.5365 18.3282C11.9792 18.9474 11.7316 19.6491 11.5458 21.1146C11.3394 22.7451 10.7821 23.9009 9.19287 25.8617C8.26408 27.0175 8.1196 27.2239 7.89256 27.6987C7.6036 28.2766 7.52104 28.6068 7.47976 29.3498C7.43848 30.1342 7.52104 30.6295 7.74808 31.3725C7.95448 32.033 8.18152 32.4665 8.73879 33.3127C9.21351 34.0557 9.50247 34.613 9.50247 34.8194C9.50247 34.9845 9.54375 34.9845 10.2868 34.8194C12.0618 34.4066 13.5272 33.7049 14.3322 32.838C14.8275 32.3013 14.9514 32.0124 14.9514 31.2694C14.9514 30.7946 14.9307 30.6914 14.8069 30.4025C14.6005 29.9484 14.2083 29.5769 13.3621 28.999C12.2476 28.2353 11.7728 27.6161 11.649 26.7905C11.5458 26.0888 11.6696 25.614 12.2888 24.3137C12.9287 22.9721 13.0938 22.4149 13.197 21.0526C13.2589 20.1858 13.3621 19.8349 13.6098 19.5666C13.8781 19.2776 14.1051 19.1744 14.745 19.0918C15.7976 18.9474 16.4787 18.6791 17.0154 18.1631C17.4901 17.7296 17.6965 17.2962 17.7171 16.6563L17.7378 16.1816L17.4694 15.8927C16.4994 14.7781 3.06284 0 3.00092 0C2.98028 0 3.31052 0.392157 3.70267 0.887513ZM10.0391 30.2374C10.2661 29.8452 10.1423 29.3498 9.77078 29.1022C9.41991 28.8751 8.88327 28.9783 8.88327 29.2879C8.88327 29.3705 8.92455 29.453 9.04839 29.4943C9.23415 29.5975 9.25479 29.7007 9.11031 29.9278C8.96583 30.1548 8.96583 30.3612 9.15159 30.5057C9.44055 30.7327 9.8327 30.6089 10.0391 30.2374Z"
          fill="#FF007A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3983 19.3807C17.903 19.5251 17.4282 20.0618 17.2838 20.5984C17.2012 20.9286 17.2425 21.5272 17.387 21.713C17.614 22.0019 17.8204 22.0845 18.3983 22.0845C19.5335 22.0845 20.5036 21.5891 20.6068 20.9906C20.71 20.4952 20.2765 19.8141 19.678 19.5045C19.3684 19.3394 18.7285 19.2775 18.3983 19.3807ZM19.7193 20.4126C19.8844 20.165 19.8225 19.8966 19.5129 19.7109C18.9556 19.36 18.1094 19.649 18.1094 20.1856C18.1094 20.4539 18.5428 20.7429 18.9556 20.7429C19.2239 20.7429 19.5954 20.5778 19.7193 20.4126Z"
          fill="#FF007A"
        />
      </g>
      <defs></defs>
    </svg>
  );
};

export default Uniswap;
