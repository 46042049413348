import React from "react";

const Ethereum = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.9975 0.666504L13.8186 1.2742V18.9066L13.9975 19.0851L22.1821 14.2471L13.9975 0.666504Z"
          fill="#343434"
        />
        <path
          d="M13.9976 0.666504L5.81274 14.2471L13.9976 19.0851V10.5268V0.666504Z"
          fill="#8C8C8C"
        />
        <path
          d="M13.9976 20.6348L13.8967 20.7578V27.0387L13.9976 27.333L22.1872 15.7993L13.9976 20.6348Z"
          fill="#3C3C3B"
        />
        <path
          d="M13.9976 27.333V20.6348L5.81274 15.7993L13.9976 27.333Z"
          fill="#8C8C8C"
        />
        <path
          d="M13.9976 19.0846L22.1822 14.2467L13.9976 10.5264V19.0846Z"
          fill="#141414"
        />
        <path
          d="M5.81274 14.2467L13.9976 19.0846V10.5264L5.81274 14.2467Z"
          fill="#393939"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="26.6667"
            height="26.6667"
            fill="white"
            transform="translate(0.666626 0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ethereum;
