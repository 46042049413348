import { useEffect, useState } from "react";
import AllIcons from "~components/zui/components/all-icons";
import { Transition } from "transition-component";
import useStyleRewriter from "../../../zui/functions/use-style-rewriter";

const ModalComponent = ({
  show = false, //required
  setShow = () => {}, //required
  RenderCard = () => <></>, //required
  CloseButton = DefaultCloseButton,
  popupWindowClasses = "",
  closeButtonClasses = "",
  layoutClasses = "",
  containerClasses = "",
  removeFromDom = true,
  transitionDuration: duration = 300,
  renderCardProps = {},
  hideDocumentOverflow = true,
  hideCloseButton = false,
}) => {
  const [showLayout, setShowLayout] = useState(show);
  const srLayoutClasses = useStyleRewriter(baseLayoutClasses, layoutClasses);
  const srPopupWindowClasses = useStyleRewriter(
    "@pre pointer-events-auto @tnp transition @tm transform z-40 @pn relative",
    popupWindowClasses,
  );
  const srContainerClasses = useStyleRewriter(
    baseContainerClasses,
    containerClasses,
  );

  const changePopup = (bool) => {
    if (bool) {
      if (hideDocumentOverflow) {
        document.body.style.overflow = "hidden";
      }
      setShowLayout(true);
    } else {
      if (hideDocumentOverflow) {
        document.body.style.overflow = "auto";
      }

      setTimeout(() => {
        setShowLayout(false);
      }, duration - 50);
    }
  };

  useEffect(() => {
    if (showLayout || show) changePopup(show);
  }, [show]);

  // close popup on 'escape' key
  useEffect(() => {
    const changePopupListener = (e) => {
      if (e.code === "Escape") {
        setShow(false);
        changePopup(false);
      }
    };
    window.addEventListener("keydown", changePopupListener);
    return () => {
      window.removeEventListener("keydown", changePopupListener);
      document.body.style.overflow = null;
    };
  }, []);

  const TransitionElement = removeFromDom ? TransitionComponent : PlainDiv;

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${
          showLayout ? "z-50 block" : "-z-1"
        } ${srContainerClasses} `}
      >
        <TransitionElement
          baseClasses={`fixed inset-0 pointer-events-auto duration-${duration} transition z-10`}
          show={show}
          transitionProps={layoutTransitionProps}
        >
          <div className="transition fixed inset-0">
            <div onClick={() => setShow(false)} className={srLayoutClasses} />
          </div>
        </TransitionElement>

        <TransitionElement
          baseClasses={`pointer-events-auto transition duration-${duration} transform z-40 relative`}
          show={show}
          id={"modal"}
          changePopup={setShow}
          transitionProps={contentTransitionProps}
        >
          <div className={srPopupWindowClasses}>
            <RenderCard {...renderCardProps} show={show} setShow={setShow} />
          </div>
        </TransitionElement>

        {!hideCloseButton && show ? (
          <CloseButton
            setShow={(bool) => setShow(bool)}
            closeButtonClasses={closeButtonClasses}
          />
        ) : null}
      </div>
    </>
  );
};

export default ModalComponent;

const baseCloseButtonClasses =
  "@pn absolute @oe outline-none focus:outline-none @zi z-80 rounded-full right-[10px] top-[10px]";

const DefaultCloseButton = ({ setShow, closeButtonClasses }) => {
  return (
    <button
      onClick={() => setShow(false)}
      type="button"
      className={`${baseCloseButtonClasses} ${closeButtonClasses}`}
    >
      <AllIcons name="Close" className="w-12 h-12 text-white" />
    </button>
  );
};

const baseLayoutClasses =
  "@pn fixed @it inset-0 @pre pointer-events-auto @zi z-10 @bdc bg-black @bdo bg-opacity-60";

const baseContainerClasses =
  "@pre pointer-events-none @dy flex @pn fixed @ow overflow-hidden @it bottom-0 inset-0 @pg px-5 py-10 @ani items-center @jyc justify-center";
const layoutTransitionProps = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

const contentTransitionProps = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale -95",
  enterTo: "opacity-100 translate-y-0 sm:scale-100",
  leave: "ease-in duration-300",
  leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
  leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
};

const TransitionComponent = ({ children, show, transitionProps }) => (
  <Transition show={show} {...transitionProps}>
    {children}
  </Transition>
);

const PlainDiv = ({
  children,
  show,
  transitionProps,
  baseClasses,
  changePopup = () => {},
  id,
}) => (
  <div
    id={id}
    onClick={(e) => (e.target.id === id ? changePopup(false) : null)}
    className={`${baseClasses} ${
      show ? transitionProps.enterTo : transitionProps.leaveTo
    }`}
  >
    {children}
  </div>
);
