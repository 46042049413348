export default function Write({ className }) {
  return (
    <svg
      className={`stroke-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17566 13.299C8.10359 13.371 8.01121 13.4193 7.91091 13.4374L4.57476 14.0396C4.24256 14.0995 3.94763 13.8198 3.98989 13.4849L4.4335 9.9695C4.4474 9.85938 4.49753 9.75702 4.57601 9.67854L12.9006 1.35397C13.0958 1.15871 13.4124 1.15871 13.6077 1.35397L16.5106 4.25689C16.7059 4.45215 16.7059 4.76874 16.5106 4.964L8.17566 13.299Z"
        strokeWidth="2"
      />
      <line x1="2" y1="18" x2="18" y2="18" strokeWidth="2" />
    </svg>
  );
}
