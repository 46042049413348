import { forwardRef } from "react";

import AllIcons from "~components/zui/components/all-icons";
import InputHeading from "../input-heading";
import InputError from "../input-error";
import Button from "~components/ui/button";
import useStyleRewriter from "../../zui/functions/use-style-rewriter";
import Input from "../../zui/components/input";

const TextInput = forwardRef((props, ref) => {
  const {
    label,
    error,
    value,
    id,
    onChange,
    placeholder,
    type,
    blocked,
    changeType = () => {},
    enableTypeChanging = false,
    inputContainerClasses = "",
    UnderInput,
    headingProps,
    inputsClassName = "@ttc text-zinc-900 @ani items-stretch @ht h-12 @bdc bg-white @brc border-neutral-300 hover:border-purple-600 focus:border-purple-600 @bxsw hover:drop-shadow-sm focus:drop-shadow-sm",
    Icon,
    incrementButtonVariant = "pink",
  } = props;
  const srClasses = useStyleRewriter(
    baseInputContainerClasses,
    inputContainerClasses,
  );

  return (
    <>
      <div className={srClasses}>
        <div className="flex justify-between">
          {label && (
            <InputHeading
              title={label}
              className={error ? "@wh w-fit" : ""}
              buttonTitle={error && headingProps?.buttonTitle}
              onButtonClick={
                headingProps?.onButtonClick
                  ? () => headingProps.onButtonClick(props)
                  : undefined
              }
            />
          )}
          {error && <InputError error={error} />}
        </div>
        <div className="relative flex flex-col">
          <Input
            ref={ref}
            value={value}
            id={id}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            blocked={blocked}
            Icon={Icon}
            className={inputsClassName}
          />
          {type === "number" && !blocked ? (
            <ChangeNumberButtons
              incrementButtonVariant={incrementButtonVariant}
              value={value}
              onChange={onChange}
            />
          ) : null}
          {enableTypeChanging ? (
            <Button
              variant="text"
              className="@pn absolute @it right-3 top-3 group"
              onClick={(e) => changeType(e, { field: id })}
            >
              <AllIcons
                name={type === "password" ? "Hide" : "Unhide"}
                className="@wh w-6 @ttc text-gray-primary group-hover:text-blue-primary-dark @tndn duration-200"
              />
            </Button>
          ) : null}
          {UnderInput ? <UnderInput {...props} /> : null}
        </div>
      </div>
    </>
  );
});

export default TextInput;

const baseInputContainerClasses = "@mn mb-4 @pn relative";

export const ChangeNumberButtons = ({
  value,
  onChange,
  incrementButtonVariant,
  stepValue = 1,
  className,
}) => (
  <div
    className={`flex flex-col items-center absolute right-0 bottom-0 top-[3px] my-auto w-[27px] pr-[4px] ${className}`}
  >
    <ChangeNumberButton
      item={{ icon: "Plus" }}
      onClick={() => onChange({ target: { value: +value + stepValue } })}
      incrementButtonVariant={incrementButtonVariant}
    />
    <ChangeNumberButton
      item={{ icon: "Minus" }}
      onClick={() =>
        onChange({
          target: { value: value > stepValue ? +value - stepValue : 0 },
        })
      }
      incrementButtonVariant={incrementButtonVariant}
    />
  </div>
);

const ChangeNumberButton = ({
  item = { icon: "Plus" },
  incrementButtonVariant = "pink",
  onClick = () => {},
}) => (
  <button
    type="button"
    onClick={onClick}
    className={`rounded-md flex items-center justify-center h-[18px] w-[24px] my-[1px] duration-200 ${
      incrementButtonVariant === "pink"
        ? "bg-white text-white hover:text-pink-base bg-opacity-20 hover:bg-opacity-100"
        : "bg-stone-base hover:bg-black text-black hover:text-white"
    }`}
  >
    <AllIcons name={item.icon} className="@wh w-2 stroke-1.5" />
  </button>
);
