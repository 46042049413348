import React from "react";

const InfoItalic = ({ className }) => (
  <svg
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M4.61154 2.22224C5.10246 2.22224 5.50043 1.82427 5.50043 1.33335C5.50043 0.842427 5.10246 0.444458 4.61154 0.444458C4.12063 0.444458 3.72266 0.842427 3.72266 1.33335C3.72266 1.82427 4.12063 2.22224 4.61154 2.22224Z" />
    <path d="M5.52042 5.82893C5.49586 5.81241 5.46825 5.80097 5.43921 5.79526C5.41017 5.78956 5.38028 5.78971 5.35131 5.79571C5.32233 5.80172 5.29484 5.81345 5.27045 5.83022C5.24607 5.84699 5.22528 5.86846 5.20931 5.89338C4.99448 6.21152 4.73131 6.49418 4.42931 6.73115C4.35597 6.78671 4.08264 6.99782 3.96708 6.95338C3.88708 6.92893 3.93375 6.77115 3.94931 6.70449L4.06708 6.3556C4.11597 6.21338 4.96708 3.68893 5.06042 3.40004C5.19819 2.97782 5.13819 2.56227 4.50931 2.66227C4.33819 2.68004 2.60264 2.90449 2.57153 2.90671C2.54235 2.90861 2.51382 2.91623 2.48759 2.92915C2.46135 2.94207 2.43792 2.96003 2.41862 2.98201C2.39933 3.00399 2.38455 3.02955 2.37514 3.05724C2.36572 3.08492 2.36185 3.11419 2.36375 3.14338C2.36565 3.17256 2.37327 3.20108 2.38619 3.22732C2.39911 3.25355 2.41707 3.27699 2.43905 3.29628C2.46103 3.31557 2.48659 3.33035 2.51428 3.33977C2.54196 3.34918 2.57123 3.35305 2.60042 3.35115C2.60042 3.35115 3.26708 3.26449 3.34042 3.25782C3.37798 3.25415 3.4158 3.26143 3.44932 3.27878C3.48283 3.29613 3.51061 3.32281 3.52931 3.3556C3.56957 3.48007 3.56405 3.61483 3.51375 3.7356C3.45597 3.95782 2.54264 6.53115 2.51375 6.68004C2.48277 6.80464 2.49137 6.93579 2.53836 7.05527C2.58536 7.17474 2.66841 7.27661 2.77597 7.34671C2.97786 7.48127 3.21825 7.54605 3.46042 7.53115C3.69588 7.52828 3.92874 7.48155 4.14708 7.39338C4.70042 7.17115 5.27819 6.58004 5.58264 6.1156C5.60885 6.06841 5.617 6.01329 5.60555 5.96054C5.5941 5.90779 5.56384 5.86101 5.52042 5.82893Z" />
  </svg>
);

export default InfoItalic;
