export default function Cog({ className }) {
  return (
    <svg
      width="57"
      height="43"
      viewBox="0 0 57 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04878 21.1939C9.04878 21.6118 9.09238 30.8405 9.09238 30.8405C9.09238 35.9041 5.77762 40.261 1 42.2863C7.23982 42.1832 13.0604 40.548 18.0218 37.7817C22.0154 40.6071 27.0485 42.2966 32.5244 42.2966C45.4896 42.2966 56 32.8483 56 21.1933C56 9.53829 45.4896 1.29657 32.5244 1.29657C19.5591 1.29657 9.04878 9.5389 9.04878 21.1939Z"
        fill="#DDDDDD"
        stroke="#DDDDDD"
      />
    </svg>
  );
}
