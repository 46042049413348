import React from "react";

const Instagram = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2 1.8H13.2C16.0715 1.8 18.4 4.12854 18.4 7V13C18.4 15.8715 16.0715 18.2 13.2 18.2H7.2C4.32854 18.2 2 15.8715 2 13V7C2 4.12854 4.32854 1.8 7.2 1.8ZM13.2 16.3C15.0195 16.3 16.5 14.8195 16.5 13V7C16.5 5.18046 15.0195 3.7 13.2 3.7H7.2C5.38046 3.7 3.9 5.18046 3.9 7V13C3.9 14.8195 5.38046 16.3 7.2 16.3H13.2ZM6 10C6 7.68054 7.88054 5.8 10.2 5.8C12.5195 5.8 14.4 7.68054 14.4 10C14.4 12.3195 12.5195 14.2 10.2 14.2C7.88054 14.2 6 12.3195 6 10ZM7.9 10C7.9 11.2675 8.93246 12.3 10.2 12.3C11.4675 12.3 12.5 11.2675 12.5 10C12.5 8.73151 11.4676 7.7 10.2 7.7C8.9324 7.7 7.9 8.73151 7.9 10ZM15.233 5.70001C15.233 6.10483 14.9048 6.433 14.5 6.433C14.0952 6.433 13.767 6.10483 13.767 5.70001C13.767 5.29518 14.0952 4.96701 14.5 4.96701C14.9048 4.96701 15.233 5.29518 15.233 5.70001Z"
      />
    </svg>
  );
};

export default Instagram;
