export default function LogoCircle({ className }) {
  return (
    <svg
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6251 20.1713C12.8871 20.1713 16.3422 16.7162 16.3422 12.4542C16.3422 8.19216 12.8871 4.7371 8.6251 4.7371C4.36306 4.7371 0.907998 8.19216 0.907998 12.4542C0.907998 16.7162 4.36306 20.1713 8.6251 20.1713ZM8.6251 20.7766C13.2214 20.7766 16.9475 17.0505 16.9475 12.4542C16.9475 7.85789 13.2214 4.13184 8.6251 4.13184C4.02879 4.13184 0.302734 7.85789 0.302734 12.4542C0.302734 17.0505 4.02879 20.7766 8.6251 20.7766Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43207 20.2442C12.4499 20.2442 15.7251 16.8366 15.7251 12.6103C15.7251 8.38407 12.4499 4.97648 8.43207 4.97648C4.41425 4.97648 1.13908 8.38407 1.13908 12.6103C1.13908 16.8366 4.41425 20.2442 8.43207 20.2442ZM8.43207 20.4863C12.5936 20.4863 15.9672 16.9601 15.9672 12.6103C15.9672 8.26057 12.5936 4.73438 8.43207 4.73438C4.27055 4.73438 0.896973 8.26057 0.896973 12.6103C0.896973 16.9601 4.27055 20.4863 8.43207 20.4863Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96562 19.8381C11.7613 19.8381 14.8527 16.6102 14.8527 12.6102C14.8527 8.61017 11.7613 5.38226 7.96562 5.38226C4.16996 5.38226 1.07855 8.61017 1.07855 12.6102C1.07855 16.6102 4.16996 19.8381 7.96562 19.8381ZM7.96562 20.0197C11.8695 20.0197 15.0343 16.7024 15.0343 12.6102C15.0343 8.51804 11.8695 5.20068 7.96562 5.20068C4.06171 5.20068 0.896973 8.51804 0.896973 12.6102C0.896973 16.7024 4.06171 20.0197 7.96562 20.0197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76827 19.3603C11.4963 19.3603 14.5185 16.3381 14.5185 12.6101C14.5185 8.88201 11.4963 5.85982 7.76827 5.85982C4.04021 5.85982 1.01803 8.88201 1.01803 12.6101C1.01803 16.3381 4.04021 19.3603 7.76827 19.3603ZM7.76827 19.4814C11.5632 19.4814 14.6396 16.405 14.6396 12.6101C14.6396 8.81515 11.5632 5.73877 7.76827 5.73877C3.97336 5.73877 0.896973 8.81515 0.896973 12.6101C0.896973 16.405 3.97336 19.4814 7.76827 19.4814Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53504 19.3491C11.1663 19.3491 14.1126 16.3333 14.1126 12.6101C14.1126 8.88685 11.1663 5.87107 7.53504 5.87107C3.90373 5.87107 0.957499 8.88685 0.957499 12.6101C0.957499 16.3333 3.90373 19.3491 7.53504 19.3491ZM7.53504 19.4096C11.2011 19.4096 14.1731 16.3654 14.1731 12.6101C14.1731 8.8548 11.2011 5.81055 7.53504 5.81055C3.86894 5.81055 0.896973 8.8548 0.896973 12.6101C0.896973 16.3654 3.86894 19.4096 7.53504 19.4096Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5ZM9.07905 21.079C13.9261 21.079 17.8554 17.1497 17.8554 12.3027C17.8554 7.45566 13.9261 3.52637 9.07905 3.52637C4.23202 3.52637 0.302734 7.45566 0.302734 12.3027C0.302734 17.1497 4.23202 21.079 9.07905 21.079Z"
      />
    </svg>
  );
}
