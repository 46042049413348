export default function Telegram({ className }) {
  return (
    <svg
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M19.6664 1.08469L16.5822 16.3133C16.5822 16.3133 16.151 17.3692 14.9646 16.8623L7.81546 11.5035C8.77638 10.6576 16.231 4.09401 16.557 3.79636C17.0609 3.33571 16.7482 3.06156 16.1624 3.40994L5.15133 10.2581L0.903186 8.85751C0.903186 8.85751 0.234391 8.62513 0.170025 8.11823C0.105279 7.61134 0.924895 7.33681 0.924895 7.33681L18.2431 0.683353C18.2431 0.683353 19.6664 0.070898 19.6664 1.08469Z" />
    </svg>
  );
}
