import React from "react";

const Book = ({ className }) => (
  <svg
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M3.49171 1.48753C2.81433 1.16412 1.86753 1.00569 0.599951 1.00007C0.480405 0.998444 0.363205 1.0333 0.263979 1.1C0.182534 1.15505 0.115864 1.22927 0.0698331 1.31613C0.0238027 1.403 -0.000177803 1.49984 9.92516e-07 1.59814V6.96207C9.92516e-07 7.32466 0.257979 7.5982 0.599951 7.5982C1.9324 7.5982 3.26898 7.72269 4.06954 8.47938C4.08049 8.48978 4.09425 8.49673 4.10912 8.49937C4.12399 8.50201 4.13931 8.50022 4.15317 8.49423C4.16703 8.48824 4.17883 8.47831 4.18709 8.46567C4.19535 8.45303 4.19972 8.43824 4.19965 8.42313V2.10285C4.19968 2.06021 4.19056 2.01806 4.1729 1.97924C4.15524 1.94043 4.12945 1.90586 4.09728 1.87787C3.91391 1.7211 3.71021 1.5898 3.49171 1.48753ZM8.73527 1.09944C8.636 1.03291 8.51879 0.998243 8.3993 1.00007C7.13172 1.00569 6.18492 1.16337 5.50754 1.48753C5.28905 1.58962 5.0853 1.72066 4.90178 1.87712C4.86968 1.90516 4.84396 1.93974 4.82633 1.97855C4.80871 2.01735 4.79959 2.05948 4.7996 2.1021V8.42276C4.79959 8.43727 4.80387 8.45146 4.81189 8.46355C4.81992 8.47564 4.83134 8.4851 4.84471 8.49072C4.85809 8.49635 4.87283 8.4979 4.88708 8.49518C4.90134 8.49246 4.91447 8.4856 4.92484 8.47544C5.40611 7.99736 6.25073 7.59764 8.40005 7.59783C8.55917 7.59783 8.71177 7.53462 8.82428 7.42211C8.93679 7.3096 9 7.157 9 6.99788V1.59833C9.00022 1.49983 8.97618 1.40279 8.93001 1.31578C8.88384 1.22877 8.81696 1.15447 8.73527 1.09944Z" />
  </svg>
);

export default Book;
