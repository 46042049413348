import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "~components/ui/button";
import { chatsApi, useCreateChatMutation } from "~services/backend/chats";
import { useGetDonationsQuery } from "~services/backend/donations";
import { useCreateFollowingMutation } from "~services/backend/followings";
import { setDonateModal, setSubscribeModal } from "~store/modals";
import { formatAddress } from "~utils/transformers";

const PermissionConfigPreview = ({ user, author }) => {
  const dispatch = useDispatch();

  const { replace } = useRouter();
  const [createFollowing] = useCreateFollowingMutation();

  const { data: donations } = useGetDonationsQuery(
    {
      userId: user?.id,
      authorId: author?.id,
    },
    {
      skip: !user || !author,
    },
  );

  const [createChat, createChatResult] = useCreateChatMutation();

  useEffect(() => {
    if (createChatResult.isLoading || createChatResult.isSuccess) return;
    if (author.chat) {
      replace(`/chats/${author.chat.id}`);
      return;
    }

    (async () => {
      const { data: createdChat } = await createChat({
        users: [user.id, author.id],
      });

      if (createdChat?.id) {
        dispatch(
          chatsApi.util.updateQueryData("getChats", user?.id, (draft) => {
            const newChat = { ...createdChat };
            newChat.user = author;

            draft.push(newChat);
          }),
        );

        replace(`/chats/${createdChat.id}`);
      }
    })();
  }, [
    donations?.length,
    user,
    user.followings,
    author?.id,
    author?.following?.id,
    author?.followers?.length,
  ]);

  const createFollowingCb = () =>
    createFollowing({
      authorId: author.id,
    });
  const handlers = {
    following: createFollowingCb,
    levels: () => dispatch(setSubscribeModal({ profile: author, show: true })),
    subscription: () =>
      dispatch(setSubscribeModal({ profile: author, show: true })),
    price: (price) =>
      author?.address &&
      dispatch(
        setDonateModal({ show: true, address: author.address, amount: price }),
      ),
  };

  const conditionState = {
    following: !!author.following,
    levels: author.following?.subscriptionLevel?.index,
    subscription: !!author.following && !!author.following?.subscriptionLevel,
    followedByUser: !!author.followingByUser,
    price: donations?.length ? donations[0].amount : 0,
  };

  return (
    <div className="w-full min-h-20 border-t pt-2.5 px-3 xl:px-6 pb-3.5 xl:mb-3">
      <div className="text-12px mb-4 ">
        Sending messages is limited. Complete one of the conditions above to
        start a conversation
      </div>
      <div className="flex flex-col gap-2 items-start w-full overflow-hidden">
        {author.permissionConfig?.chat?.length
          ? author.permissionConfig.chat.map((config) => {
              const configKeys = Object.keys(config).filter((key) => {
                const conditionConfig = config[key];

                return (
                  (Array.isArray(conditionConfig)
                    ? conditionConfig.length
                    : !!conditionConfig) && !!permissionsButtons[key]
                );
              });

              return (
                <Fragment key={`config-${config.id}`}>
                  <span className="text-zinc-base text-sm">Condition:</span>
                  <div className="w-full flex items-center overflow-x-scroll gap-2">
                    {configKeys.map((key, index) => {
                      const Button = permissionsButtons[key];

                      const isLast = index === configKeys.length - 1;
                      const isActive = conditionState[key];

                      return (
                        <Fragment key={`${config.id}-${key}-${index}`}>
                          <Button
                            data={config[key]}
                            handler={isActive ? undefined : handlers[key]}
                            active={isActive}
                            author={author}
                          />
                          {isLast ? null : <span className="">{"&"}</span>}
                        </Fragment>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PermissionConfigPreview;

export const permissionsButtons = {
  following: ({ active, handler }) => (
    <Button
      variant={active ? "lime" : "gray"}
      onClick={handler}
      className="@wh w-auto lg:w-28 @mn mb-2 @cr cursor-pointer"
    >
      {active ? "Following" : "Follow"}
    </Button>
  ),
  levels: ({ data, handler, active }) =>
    data?.length
      ? [...data]
          .sort((a, b) => a.index - b.index)
          .map((level, index) => {
            const isActive = active >= level.index;
            return (
              <Fragment key={level.id || index}>
                <Button
                  onClick={handler}
                  variant={isActive ? "lime" : "gray"}
                  className="@wh w-auto lg:w-44 @mn mb-2 @cr cursor-pointer @fxs shrink-0"
                >
                  <span className="text-pink-base pr-1">{`Tier ${
                    level.index + 1
                  }`}</span>
                  {level.index === active ? "subscribed" : "subscription"}
                </Button>

                {!!data[index + 1] && <span className="px-1">or</span>}
              </Fragment>
            );
          })
      : null,
  subscription: ({ active, handler }) => (
    <Button
      variant={active ? "lime" : "gray"}
      onClick={handler}
      className="@wh w-auto lg:w-44 @mn mb-2 @cr cursor-pointer"
    >
      {active ? "Subscribed" : "Subscription"}
    </Button>
  ),
  followedByUser: ({ active, author }) => (
    <Button
      variant={active ? "lime" : "gray"}
      className="@wh w-auto lg:w-60 @fxs shrink-0 @mn mb-2 @cr cursor-pointer"
    >
      Followed by {author ? `@${formatAddress(author.username)}` : "me"}
    </Button>
  ),
  price: ({ active, handler, data }) => {
    const isActive = typeof active === "number" ? active >= data : active;
    return (
      <Button
        variant={isActive ? "lime" : "gray"}
        onClick={() => handler && handler(data)}
        className={`@wh w-auto lg:w-44 @fxs shrink-0 @mn mb-2 ${
          !isActive && "@cr cursor-pointer"
        }`}
      >
        {isActive ? "Purchased" : "Purchase"} {data} $
      </Button>
    );
  },
};
