const SuccessCircle = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.99994C0 4.48594 4.486 0 10.0001 0C15.5141 0 20 4.48594 20 9.99994C20 15.5139 15.5141 19.9999 10.0001 19.9999C4.486 19.9999 0 15.5139 0 9.99994ZM15.8388 5.80843C15.3234 5.29306 14.4878 5.29301 13.9725 5.80836L8.38862 11.3923L6.02753 9.03119C5.51215 8.51591 4.6766 8.51598 4.16122 9.03126C3.64594 9.54664 3.64598 10.3822 4.16126 10.8975L7.45554 14.1918C7.71306 14.4492 8.0516 14.5783 8.38862 14.5783C8.72564 14.5783 9.06425 14.4492 9.32178 14.1917L15.8388 7.6747C16.3541 7.15932 16.3541 6.32381 15.8388 5.80843Z"
      fill="#34C228"
    />
  </svg>
);

export default SuccessCircle;
