import { backBaseUrl } from "src/configs";
import { authTokensManager } from "~utils/localStorage/authTokensManager";

export const getSocialImagePath = (image, format) => {
  if (typeof image === "string") return image;

  const imageObj = image?.data || image;
  if (!imageObj?.url) return null;

  const baseUrl = image.provider === "aws-s3" ? "" : backBaseUrl;

  const token =
    image.provider === "aws-s3" ? "" : `?token=${authTokensManager.getJwt()}`;

  if (format && imageObj.formats?.[format]) {
    return `${baseUrl}${imageObj.formats[format].url}${token}`;
  }

  return `${baseUrl}${imageObj.url}${token}`;
};

export const getSocialFilePath = (file) => {
  if (!file?.url) return null;
  const token =
    file.provider === "aws-s3" ? "" : `?token=${authTokensManager.getJwt()}`;

  const baseUrl = file.provider === "aws-s3" ? "" : backBaseUrl;

  return `${baseUrl}${file.url}${token}`;
};
