import React from "react";

const LogoWide = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 100 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2808 8.53044H16.7558C16.5223 8.52386 16.2907 8.57541 16.0811 8.68066C15.8715 8.78591 15.6901 8.94167 15.5526 9.13451L13.1118 12.3252L11.4468 6.01322C11.3712 5.75796 11.2246 5.53057 11.025 5.35857C10.8254 5.18658 10.5324 5.07735 10.2733 5.04413C9.72111 4.95691 9.26114 5.15105 8.91466 5.60087L3.26065 12.9947C3.26065 12.9947 3.22935 13.0303 3.20825 13.0406C3.18714 13.051 3.14041 13.0537 3.14041 13.0537H0.196668C0.0676512 12.3895 0 11.7028 0 11C0 5.2018 4.60407 0.501465 10.2835 0.501465C15.1297 0.501465 19.193 3.92388 20.2808 8.53044Z"
        fill="#0065FF"
      />
      <path
        d="M20.5582 11.4377H17.5214C17.5002 11.4359 17.479 11.44 17.4599 11.4495C17.4408 11.4591 17.4246 11.4737 17.413 11.4918L13.6589 16.3898C13.5134 16.5854 13.3217 16.7402 13.1018 16.8397C12.8818 16.9392 12.6407 16.9801 12.4011 16.9588C12.1614 16.9374 11.9309 16.8544 11.7312 16.7174C11.5315 16.5805 11.369 16.3942 11.2589 16.1758C11.1831 16.007 11.124 15.8309 11.0825 15.65C10.669 14.1426 9.50434 9.67331 9.50434 9.67331L5.11807 15.394C4.98524 15.574 4.81238 15.7191 4.61376 15.8174C4.41515 15.9157 4.19648 15.9644 3.97581 15.9593H1.21744C2.95159 19.2565 6.36108 21.4985 10.2835 21.4985C15.8192 21.4985 20.3334 17.0329 20.5582 11.4377Z"
        fill="#0065FF"
      />
      <path
        d="M29.5727 17.2388C29.4481 17.2388 29.3421 17.1942 29.2549 17.1051C29.1676 17.016 29.124 16.9078 29.124 16.7805V15.673C29.124 15.5584 29.1427 15.4565 29.1801 15.3674C29.23 15.2783 29.2985 15.1701 29.3858 15.0428L34.1532 9.33339H29.7223C29.5977 9.33339 29.4917 9.28884 29.4045 9.19973C29.3172 9.09789 29.2736 8.98332 29.2736 8.85602V7.7676C29.2736 7.6403 29.3172 7.53209 29.4045 7.44298C29.4917 7.35387 29.5977 7.30931 29.7223 7.30931H36.8455C36.9701 7.30931 37.0761 7.35387 37.1633 7.44298C37.2506 7.53209 37.2942 7.6403 37.2942 7.7676V8.95149C37.2942 9.05333 37.263 9.15517 37.2007 9.25701C37.1508 9.35885 37.0948 9.45433 37.0324 9.54344L32.3584 15.2147H37.2194C37.344 15.2147 37.45 15.2592 37.5372 15.3483C37.6245 15.4375 37.6681 15.552 37.6681 15.6921V16.7805C37.6681 16.9078 37.6245 17.016 37.5372 17.1051C37.4624 17.1942 37.3565 17.2388 37.2194 17.2388H29.5727Z"
        fill="#1D1D1D"
      />
      <path
        d="M43.6577 17.4297C42.2617 17.4297 41.1524 17.0223 40.3298 16.2076C39.5196 15.3929 39.0896 14.2345 39.0398 12.7323L39.0211 12.2549C39.0211 10.6637 39.4261 9.40977 40.2363 8.49321C41.0589 7.57665 42.1932 7.11836 43.639 7.11836C45.1222 7.11836 46.2626 7.57665 47.0603 8.49321C47.8705 9.40977 48.2756 10.6319 48.2756 12.1595V12.5605C48.2756 12.6878 48.232 12.796 48.1447 12.8851C48.0575 12.9742 47.9453 13.0187 47.8082 13.0187H41.6011V13.1715C41.626 13.8844 41.813 14.4763 42.162 14.9473C42.5235 15.4056 43.0158 15.6348 43.639 15.6348C44.3494 15.6348 44.929 15.342 45.3777 14.7564C45.4899 14.6164 45.5771 14.5336 45.6395 14.5082C45.7018 14.47 45.8077 14.4509 45.9573 14.4509H47.6025C47.7147 14.4509 47.8082 14.4827 47.883 14.5463C47.9702 14.61 48.0138 14.6991 48.0138 14.8137C48.0138 15.1192 47.8331 15.482 47.4717 15.9021C47.1227 16.3095 46.6179 16.6659 45.9573 16.9714C45.2967 17.2769 44.5302 17.4297 43.6577 17.4297ZM45.6955 11.3957V11.3575C45.6955 10.6064 45.5086 10.0081 45.1347 9.56253C44.7732 9.10425 44.2746 8.87511 43.639 8.87511C43.0033 8.87511 42.5048 9.10425 42.1433 9.56253C41.7818 10.0081 41.6011 10.6064 41.6011 11.3575V11.3957H45.6955Z"
        fill="#1D1D1D"
      />
      <path
        d="M50.7084 17.2388C50.5838 17.2388 50.4778 17.1942 50.3906 17.1051C50.3033 17.016 50.2597 16.9078 50.2597 16.7805V7.7676C50.2597 7.62757 50.3033 7.51936 50.3906 7.44298C50.4778 7.35387 50.5838 7.30931 50.7084 7.30931H52.2602C52.3973 7.30931 52.5032 7.35387 52.578 7.44298C52.6652 7.51936 52.7089 7.62757 52.7089 7.7676V8.5505C53.4692 7.59574 54.5099 7.11836 55.8311 7.11836C56.9778 7.11836 57.8877 7.50027 58.5607 8.26407C59.2338 9.02787 59.5703 10.0781 59.5703 11.4148V16.7805C59.5703 16.9078 59.5267 17.016 59.4394 17.1051C59.3646 17.1942 59.2587 17.2388 59.1216 17.2388H57.439C57.3143 17.2388 57.2084 17.1942 57.1211 17.1051C57.0339 17.016 56.9902 16.9078 56.9902 16.7805V11.5293C56.9902 10.791 56.8095 10.2181 56.4481 9.81077C56.0991 9.39068 55.5943 9.18063 54.9337 9.18063C54.298 9.18063 53.787 9.39068 53.4006 9.81077C53.0142 10.2309 52.821 10.8037 52.821 11.5293V16.7805C52.821 16.9078 52.7774 17.016 52.6902 17.1051C52.6154 17.1942 52.5094 17.2388 52.3723 17.2388H50.7084Z"
        fill="#1D1D1D"
      />
      <path
        d="M63.3126 17.2388C63.1879 17.2388 63.082 17.1942 62.9947 17.1051C62.9075 17.016 62.8639 16.9078 62.8639 16.7805V9.35249H61.3495C61.2248 9.35249 61.1189 9.30793 61.0317 9.21882C60.9444 9.12971 60.9008 9.02151 60.9008 8.89421V7.7676C60.9008 7.6403 60.9444 7.53209 61.0317 7.44298C61.1189 7.35387 61.2248 7.30931 61.3495 7.30931H62.8639V6.54551C62.8639 5.38708 63.1692 4.5278 63.78 3.96768C64.3907 3.39482 65.313 3.1084 66.547 3.1084H67.837C67.9616 3.1084 68.0676 3.15295 68.1548 3.24206C68.2421 3.33117 68.2857 3.43938 68.2857 3.56668V4.69329C68.2857 4.82059 68.2421 4.9288 68.1548 5.01791C68.0676 5.10702 67.9616 5.15157 67.837 5.15157H66.6404C66.1544 5.15157 65.8116 5.27251 65.6122 5.51438C65.4127 5.75625 65.313 6.13179 65.313 6.64099V7.30931H67.65C67.7747 7.30931 67.8806 7.35387 67.9679 7.44298C68.0551 7.53209 68.0987 7.6403 68.0987 7.7676V8.89421C68.0987 9.02151 68.0551 9.12971 67.9679 9.21882C67.8806 9.30793 67.7747 9.35249 67.65 9.35249H65.313V16.7805C65.313 16.9078 65.2694 17.016 65.1822 17.1051C65.1074 17.1942 65.0014 17.2388 64.8643 17.2388H63.3126Z"
        fill="#1D1D1D"
      />
      <path
        d="M73.1848 17.4297C72.0381 17.4297 71.1344 17.0478 70.4739 16.284C69.8133 15.5202 69.483 14.47 69.483 13.1333V7.7676C69.483 7.62757 69.5266 7.51936 69.6138 7.44298C69.7011 7.35387 69.807 7.30931 69.9317 7.30931H71.6143C71.7389 7.30931 71.8449 7.35387 71.9321 7.44298C72.0194 7.53209 72.063 7.6403 72.063 7.7676V13.0187C72.063 14.5845 72.7298 15.3674 74.0635 15.3674C74.7116 15.3674 75.2226 15.1574 75.5966 14.7373C75.9829 14.3172 76.1761 13.7444 76.1761 13.0187V7.7676C76.1761 7.62757 76.2198 7.51936 76.307 7.44298C76.3943 7.35387 76.5002 7.30931 76.6248 7.30931H78.2888C78.4259 7.30931 78.5318 7.35387 78.6066 7.44298C78.6939 7.51936 78.7375 7.62757 78.7375 7.7676V16.7805C78.7375 16.9078 78.6939 17.016 78.6066 17.1051C78.5318 17.1942 78.4259 17.2388 78.2888 17.2388H76.737C76.6124 17.2388 76.5064 17.1942 76.4192 17.1051C76.3319 17.016 76.2883 16.9078 76.2883 16.7805V15.9976C75.6028 16.9523 74.5683 17.4297 73.1848 17.4297Z"
        fill="#1D1D1D"
      />
      <path
        d="M84.9085 17.4297C83.9612 17.4297 83.1697 17.2833 82.5341 16.9905C81.8984 16.6977 81.4248 16.3604 81.1132 15.9785C80.8141 15.5838 80.6645 15.2465 80.6645 14.9664C80.6645 14.8391 80.7019 14.7373 80.7767 14.6609C80.8639 14.5845 80.9636 14.5463 81.0758 14.5463H82.6089C82.7086 14.5463 82.7958 14.5973 82.8706 14.6991C82.9329 14.75 83.07 14.871 83.2819 15.0619C83.5063 15.2529 83.7556 15.4056 84.0298 15.5202C84.3164 15.622 84.628 15.673 84.9646 15.673C85.4631 15.673 85.8682 15.5775 86.1798 15.3865C86.4914 15.1829 86.6472 14.8964 86.6472 14.5273C86.6472 14.2727 86.5724 14.069 86.4229 13.9162C86.2857 13.7507 86.0302 13.6043 85.6563 13.477C85.2949 13.337 84.7464 13.1842 84.0111 13.0187C82.9516 12.7896 82.1664 12.4395 81.6554 11.9685C81.1568 11.4975 80.9075 10.8737 80.9075 10.0972C80.9075 9.58799 81.0571 9.11062 81.3562 8.66506C81.6554 8.20678 82.0978 7.83761 82.6837 7.55755C83.2819 7.26476 83.9924 7.11836 84.815 7.11836C85.6626 7.11836 86.3917 7.25839 87.0024 7.53846C87.6132 7.80579 88.0743 8.1304 88.3859 8.5123C88.71 8.89421 88.872 9.23155 88.872 9.52434C88.872 9.63891 88.8284 9.74075 88.7412 9.82986C88.6664 9.90624 88.5729 9.94444 88.4607 9.94444H87.0585C86.9214 9.94444 86.8155 9.89352 86.7407 9.79167C86.6534 9.72802 86.5101 9.61345 86.3107 9.44796C86.1237 9.26974 85.9118 9.12971 85.675 9.02787C85.4382 8.92603 85.1515 8.87511 84.815 8.87511C84.3538 8.87511 83.9986 8.97695 83.7493 9.18063C83.5 9.38431 83.3754 9.65164 83.3754 9.98263C83.3754 10.2118 83.4315 10.4027 83.5437 10.5555C83.6683 10.7082 83.9114 10.8546 84.2728 10.9947C84.6343 11.122 85.1765 11.2556 85.8994 11.3957C88.1429 11.8412 89.2646 12.8405 89.2646 14.3936C89.2646 15.2974 88.8783 16.0294 88.1055 16.5895C87.3452 17.1496 86.2795 17.4297 84.9085 17.4297Z"
        fill="#1D1D1D"
      />
      <path
        d="M95.382 17.4297C93.9861 17.4297 92.8768 17.0223 92.0541 16.2076C91.244 15.3929 90.814 14.2345 90.7641 12.7323L90.7454 12.2549C90.7454 10.6637 91.1505 9.40977 91.9607 8.49321C92.7833 7.57665 93.9175 7.11836 95.3633 7.11836C96.8465 7.11836 97.987 7.57665 98.7847 8.49321C99.5949 9.40977 99.9999 10.6319 99.9999 12.1595V12.5605C99.9999 12.6878 99.9563 12.796 99.8691 12.8851C99.7818 12.9742 99.6696 13.0187 99.5325 13.0187H93.3255V13.1715C93.3504 13.8844 93.5374 14.4763 93.8863 14.9473C94.2478 15.4056 94.7401 15.6348 95.3633 15.6348C96.0738 15.6348 96.6534 15.342 97.1021 14.7564C97.2142 14.6164 97.3015 14.5336 97.3638 14.5082C97.4261 14.47 97.5321 14.4509 97.6816 14.4509H99.3269C99.4391 14.4509 99.5325 14.4827 99.6073 14.5463C99.6946 14.61 99.7382 14.6991 99.7382 14.8137C99.7382 15.1192 99.5575 15.482 99.196 15.9021C98.847 16.3095 98.3422 16.6659 97.6816 16.9714C97.021 17.2769 96.2545 17.4297 95.382 17.4297ZM97.4199 11.3957V11.3575C97.4199 10.6064 97.2329 10.0081 96.859 9.56253C96.4976 9.10425 95.999 8.87511 95.3633 8.87511C94.7277 8.87511 94.2291 9.10425 93.8677 9.56253C93.5062 10.0081 93.3255 10.6064 93.3255 11.3575V11.3957H97.4199Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default LogoWide;
