import React from "react";

const ThickPlus = ({ className }) => (
  <svg
    viewBox="0 0 7 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} fill-current`}
  >
    <path d="M2.61511 6.61511C2.61511 7.10382 3.01129 7.5 3.5 7.5C3.98871 7.5 4.38489 7.10382 4.38489 6.61511V4.88489H6.11511C6.60382 4.88489 7 4.48871 7 4C7 3.51129 6.60382 3.11511 6.11511 3.11511H4.38489V1.38489C4.38489 0.896177 3.98871 0.5 3.5 0.5C3.01129 0.5 2.61511 0.896178 2.61511 1.38489V3.11511H0.884887C0.396177 3.11511 0 3.51129 0 4C0 4.48871 0.396178 4.88489 0.884887 4.88489H2.61511V6.61511Z" />
  </svg>
);

export default ThickPlus;
