import React from "react";

const Bell = ({ className }) => (
  <svg
    width="20" // Need for rendering
    height="20" // Need for rendering
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73169 3.1757C5.39901 4.06702 3.80995 6.2688 3.80995 8.77419L3.81056 12.1474C2.79679 12.2251 2 13.0498 2 14.0519C2 15.1066 2.88115 15.9624 3.9698 15.9624H7.1444C7.29745 17.3624 8.5166 18.4545 10 18.4545C11.4834 18.4545 12.7025 17.3624 12.8556 15.9624H16.0302C17.1182 15.9624 18 15.1056 18 14.0519C18 13.0495 17.2019 12.2252 16.19 12.1469V8.77416C16.19 6.26929 14.6009 4.06719 12.2683 3.17576C12.2537 1.9724 11.2446 1 10 1C8.75671 1 7.74639 1.97288 7.73169 3.1757ZM10.7481 3.20231C10.7481 2.80008 10.4125 2.47546 10 2.47546C9.58855 2.47546 9.25189 2.80085 9.25189 3.20231V4.25785L8.70267 4.41181C6.72148 4.96721 5.33032 6.73702 5.33032 8.77416V12.2922C5.33032 13.0221 4.72069 13.6168 3.96745 13.6168C3.72203 13.6168 3.52036 13.8115 3.52036 14.0519C3.52036 14.2924 3.72152 14.4869 3.9698 14.4869H16.0302C16.2777 14.4869 16.4796 14.2916 16.4796 14.0519C16.4796 13.8119 16.2772 13.6168 16.0326 13.6168C15.278 13.6168 14.6697 13.024 14.6697 12.2922V8.77416C14.6697 6.73753 13.2784 4.96729 11.2973 4.41183L10.7481 4.25785V3.20231ZM8.68339 15.9624C8.8222 16.5455 9.35979 16.9791 10 16.9791C10.6402 16.9791 11.1778 16.5455 11.3166 15.9624H8.68339Z"
    />
  </svg>
);

export default Bell;
