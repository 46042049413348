import React from "react";
import Button from "~components/ui/button";
import P from "~components/ui/typography/p";
import getFormattedPrice from "~utils/vanilla/formatters/get-formatted-price";

const SubscriptionLevelCard = ({
  isLoading = false,
  index,
  title,
  price,
  description,
  image,
  availableSupply,
  onClick = () => {},
  isActive = false,
  isAlreadySubscribed = false,
  isDisabled = false,
  toEdit = false,
  isPreview = false,
  limited = false,
  discount = 0,
  noChosenCard = true,
}) => {
  if (isLoading) return <LevelCardPlaceHolder />;

  return (
    <div
      onClick={() => !isAlreadySubscribed && !isDisabled && onClick(index)}
      className={`relative p-4 md:p-5 bg-white flex flex-col items-center justify-between rounded-[18px] shadow-base transition-all whitespace-pre-line group w-full border-[2px] border-gray-100 cursor-pointer hover:opacity-100
      ${isActive ? "shadow-custom cursor-pointer !border-lime-base" : ""} 
      ${isPreview ? "cursor-default !shadow-none" : ""}
      ${!noChosenCard && !isActive ? "opacity-50" : ""}
      ${
        isDisabled || isAlreadySubscribed
          ? "cursor-default opacity-50 hover:opacity-50"
          : "hover:shadow-custom"
      }
      `}
    >
      <div className="flex flex-col w-full h-[86%]">
        <P className="@ftf font-medium @fts text-[18px] leading-[22px] mb-4 md:mb-5 break-words whitespace-pre-wrap text-center w-full">
          {title || `Level ${index + 1}`}
        </P>
        {image ? (
          <div className="mx-auto w-[100px] pb-[100px] overflow-hidden relative mb-1">
            <img
              src={image}
              className="absolute inset-0 object-cover rounded"
            />
          </div>
        ) : null}
        <div className="flex flex-col items-center">
          {limited ? (
            <P className="@ftf font-clash @ftw font-medium @ttc text-pink-base @fts text-12px @leh leading-15px">
              {availableSupply} slot{availableSupply % 10 !== 1 && "s"} left
            </P>
          ) : (
            <div className="h-[15px]" />
          )}
          {discount ? (
            <div className="flex items-start relative pr-5">
              <P className="@ftf font-clash @ftw font-normal @fts text-12px @ttc text-zinc-900 line-through mt-1 mr-1">
                ${getFormattedPrice(price)}
              </P>
              <P className="@ftf font-clash @ftw font-semibold @fts text-[20px] @ttc text-zinc-900 leading-[27px]">
                ${getFormattedPrice(price - (price * discount) / 100)}
              </P>
              <P className="@ftf font-clash @ftw font-bold @fts text-[10px] @ttc text-white @pg py-[2px] px-1 rounded-full absolute left-[80%] top-0 @bdc bg-pink-base">
                -{discount}%
              </P>
            </div>
          ) : (
            <P className="@ftf font-clash @ftw font-semibold @fts text-[20px] @ttc text-black leading-[27px]">
              ${getFormattedPrice(price)}
            </P>
          )}

          <P className="@fts text-[14px] @mn mb-4 md:mb-6 @oy opacity-20">
            month
          </P>
        </div>
        <div className="max-h-[200px] overflow-y-hidden pb-6">
          <P className="@mxh max-h-[200px] @wh w-full @tta text-left @ftf font-normal @fts text-[14px] @leh leading-[20px] @ow overflow-x-hidden overflow-y-scroll ">
            {description}
          </P>
        </div>
      </div>

      {isPreview || isDisabled ? null : toEdit ? (
        <Button onClick={() => onClick(index)} variant="rounded">
          <P>Edit</P>
        </Button>
      ) : (
        <Button
          onClick={() => {}}
          className={`@wh w-full lg:w-fit @mn mr-0 @bdc ${
            isAlreadySubscribed
              ? "bg-zinc-300 @ttc text-black"
              : "opacity-0 group-hover:opacity-100 bg-lime-base @ttc text-zinc-900"
          } @pg px-4 py-3 md:px-8 @tta text-center ${
            isActive ? "group-hover:opacity-0" : ""
          }`}
          variant="rounded"
          disabled={isAlreadySubscribed}
        >
          {isAlreadySubscribed ? "Subscribed" : "Select"}
        </Button>
      )}
    </div>
  );
};

export default SubscriptionLevelCard;

const bgColor = "bg-zinc-light animate-pulse";
const LevelCardPlaceHolder = () => (
  <div
    className={`relative p-4 md:p-5 bg-white flex flex-col items-center justify-between rounded-[18px] shadow-base transition-all whitespace-pre-line group w-full border-[2px] 
border-gray-100 hover:shadow-xl cursor-pointer`}
  >
    <div className="flex flex-col w-full">
      <div
        className={`mx-auto w-[200px] h-[14px] shrink-0 ${bgColor} rounded-full mb-4 md:mb-5 `}
      />
      <div
        className={`mx-auto w-[100px] pb-[100px] relative mb-1 rounded-[18px] ${bgColor}`}
      ></div>
      <div className="flex flex-col items-center">
        <div className="h-[15px]" />
        <div className={`h-[20px] leading-[27px] ${bgColor}`}></div>
      </div>
      <div className={"mx-auto h-[100px] overflow-y-hidden pb-6 "}>
        <div
          className={`w-[200px] h-[14px] shrink-0 ${bgColor} rounded-full mb-1 `}
        />
        <div
          className={`w-[175px] h-[14px] shrink-0 ${bgColor} rounded-full mb-1 `}
        />
        <div
          className={`w-[200px] h-[14px] shrink-0 ${bgColor} rounded-full mb-1 `}
        />
        <div
          className={`w-[175px] h-[14px] shrink-0 ${bgColor} rounded-full mb-1 `}
        />
        <div
          className={`w-[200px] h-[14px] shrink-0 ${bgColor} rounded-full mb-1 `}
        />
      </div>
    </div>
  </div>
);
