const Metamask = ({ className = "w-5 h-5" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8574 1.0874L11.1692 6.7948L12.6034 3.43267L18.8574 1.0874Z"
        fill="#E17726"
      />
      <path
        d="M11.1696 6.88892C11.1545 6.89482 11.1377 6.89482 11.1226 6.88892C11.114 6.87693 11.1094 6.86254 11.1094 6.84778C11.1094 6.83301 11.114 6.81862 11.1226 6.80663L12.5627 3.43862C12.5535 3.42841 12.5475 3.41574 12.5454 3.40217C12.5433 3.38859 12.5452 3.3747 12.5509 3.36221L18.8109 1.01106C18.8273 1.00491 18.8455 1.00511 18.8618 1.01164C18.8781 1.01816 18.8914 1.03053 18.899 1.04633C18.9106 1.05926 18.917 1.076 18.917 1.09335C18.917 1.1107 18.9106 1.12744 18.899 1.14038L11.1696 6.85365C11.1737 6.86506 11.1737 6.87751 11.1696 6.88892ZM12.6626 3.50327L11.3107 6.57739L18.3994 1.33434L12.6626 3.50327Z"
        fill="#E17726"
      />
      <path
        d="M1.05273 1.0874L8.69395 6.8477L7.33616 3.43267L1.05273 1.0874Z"
        fill="#E27625"
      />
      <path
        d="M8.70672 6.92415C8.69161 6.93005 8.67482 6.93005 8.6597 6.92415L1.00673 1.14621C0.995164 1.13328 0.98877 1.11654 0.98877 1.09919C0.98877 1.08184 0.995164 1.0651 1.00673 1.05217C1.01277 1.03884 1.0228 1.02772 1.03544 1.02035C1.04808 1.01298 1.06269 1.00972 1.07726 1.01102L7.36069 3.36217C7.38117 3.36917 7.39806 3.38395 7.40772 3.40331L8.77138 6.82422C8.78094 6.83581 8.78616 6.85035 8.78616 6.86537C8.78616 6.88039 8.78094 6.89493 8.77138 6.90651C8.76324 6.91508 8.75291 6.92125 8.74152 6.92436C8.73012 6.92747 8.71808 6.9274 8.70672 6.92415ZM1.50047 1.32843L8.5539 6.6185L7.2784 3.4856L1.50047 1.32843Z"
        fill="#E27625"
      />
      <path
        d="M16.078 14.3303L14.0266 17.4691L18.4232 18.6799L19.687 14.4009L16.078 14.3303Z"
        fill="#E27625"
      />
      <path
        d="M18.4229 18.7506L14.0263 17.5398C14.0146 17.5363 14.0037 17.5304 13.9945 17.5222C13.9854 17.5141 13.9782 17.504 13.9734 17.4927C13.969 17.4825 13.9668 17.4715 13.9668 17.4604C13.9668 17.4493 13.969 17.4383 13.9734 17.4281L16.0307 14.2893C16.0366 14.2775 16.0459 14.2679 16.0575 14.2616C16.069 14.2553 16.0822 14.2526 16.0953 14.254L19.6984 14.3246C19.7101 14.3239 19.7217 14.3261 19.7322 14.331C19.7428 14.3359 19.752 14.3433 19.7589 14.3527C19.7659 14.362 19.7705 14.3729 19.7723 14.3844C19.774 14.3959 19.7729 14.4076 19.769 14.4186L18.5052 18.6977C18.5039 18.7069 18.5007 18.7157 18.4956 18.7235C18.4906 18.7313 18.484 18.7379 18.4762 18.743C18.4684 18.748 18.4596 18.7513 18.4504 18.7526C18.4412 18.7539 18.4319 18.7532 18.4229 18.7506ZM14.1439 17.4222L18.3759 18.5978L19.5868 14.4833L16.1188 14.4186L14.1439 17.4222Z"
        fill="#E27625"
      />
      <path
        d="M0.237305 14.4009L1.48929 18.6799L5.88005 17.4691L3.83455 14.3303L0.237305 14.4009Z"
        fill="#E27625"
      />
      <path
        d="M1.48938 18.7505C1.47312 18.7522 1.45679 18.7477 1.4437 18.7379C1.43061 18.7281 1.42173 18.7137 1.41885 18.6976L0.166865 14.4185C0.161191 14.4087 0.158203 14.3975 0.158203 14.3862C0.158203 14.3748 0.161191 14.3637 0.166865 14.3538C0.173542 14.3445 0.182382 14.337 0.192623 14.3319C0.202864 14.3268 0.214197 14.3242 0.225644 14.3244L3.81702 14.2539C3.82888 14.2548 3.84038 14.2585 3.85058 14.2646C3.86079 14.2707 3.86942 14.2791 3.87579 14.2892L5.92129 17.428C5.92696 17.4378 5.92995 17.4489 5.92995 17.4603C5.92995 17.4716 5.92696 17.4828 5.92129 17.4926C5.91845 17.504 5.91257 17.5143 5.90429 17.5226C5.89601 17.5309 5.88563 17.5368 5.87427 17.5396L1.50702 18.7505H1.48938ZM0.313812 14.4714L1.51877 18.5859L5.74495 17.4103L3.7935 14.4067L0.313812 14.4714Z"
        fill="#E27625"
      />
      <path
        d="M5.64447 9.02864L4.42188 10.8743L8.77149 11.0683L8.63042 6.36597L5.64447 9.02864Z"
        fill="#E27625"
      />
      <path
        d="M8.77202 11.1447L4.41653 10.9449C4.40394 10.9439 4.39185 10.9396 4.38151 10.9323C4.37117 10.9251 4.36297 10.9152 4.35775 10.9037C4.35208 10.8928 4.34912 10.8807 4.34912 10.8685C4.34912 10.8562 4.35208 10.8441 4.35775 10.8332L5.58034 8.98755L8.56629 6.34251C8.57791 6.33581 8.59109 6.33228 8.6045 6.33228C8.61791 6.33228 8.63109 6.33581 8.6427 6.34251C8.65616 6.34727 8.66786 6.35599 8.67626 6.36753C8.68466 6.37908 8.68935 6.3929 8.68973 6.40717L8.83667 11.1095C8.84209 11.1267 8.84209 11.1451 8.83667 11.1624C8.82531 11.1656 8.81328 11.1657 8.80188 11.1626C8.79048 11.1595 8.78015 11.1533 8.77202 11.1447ZM4.55172 10.8038L8.66622 10.9919L8.53103 6.54236L5.6979 9.07572L4.55172 10.8038Z"
        fill="#E27625"
      />
      <path
        d="M14.2727 9.0285L11.2397 6.33057L11.1692 11.0681L15.5188 10.8742L14.2727 9.0285Z"
        fill="#E27625"
      />
      <path
        d="M11.1702 11.1442C11.1532 11.1511 11.1342 11.1511 11.1173 11.1442C11.111 11.1272 11.111 11.1084 11.1173 11.0913L11.1702 6.33026C11.1705 6.316 11.1752 6.30217 11.1836 6.29063C11.192 6.27909 11.2037 6.27036 11.2172 6.26561C11.2288 6.2589 11.242 6.25537 11.2554 6.25537C11.2688 6.25537 11.282 6.2589 11.2936 6.26561L14.3266 8.96354L15.5492 10.8092C15.5548 10.8201 15.5578 10.8322 15.5578 10.8445C15.5578 10.8567 15.5548 10.8688 15.5492 10.8797C15.5422 10.891 15.5327 10.9006 15.5216 10.9077C15.5104 10.9148 15.4977 10.9193 15.4845 10.9209L11.1349 11.1207L11.1702 11.1442ZM11.3406 6.49484L11.2172 11.0032L15.3611 10.8151L14.2149 9.08698L11.3406 6.49484Z"
        fill="#E27625"
      />
      <path
        d="M5.87915 17.4688L8.51243 16.1933L6.24358 14.4299L5.87915 17.4688Z"
        fill="#E27625"
      />
      <path
        d="M5.88018 17.5393C5.87377 17.5424 5.86673 17.544 5.8596 17.544C5.85248 17.544 5.84544 17.5424 5.83903 17.5393C5.82772 17.5314 5.81856 17.5208 5.8124 17.5085C5.80624 17.4962 5.80327 17.4825 5.80376 17.4687L6.17407 14.4299C6.17504 14.4173 6.17937 14.4052 6.18661 14.3949C6.19385 14.3845 6.20372 14.3763 6.21521 14.3711C6.2266 14.3638 6.23987 14.3599 6.25342 14.3599C6.26697 14.3599 6.28023 14.3638 6.29163 14.3711L8.56048 16.1345C8.5668 16.1441 8.57016 16.1553 8.57016 16.1668C8.57016 16.1783 8.5668 16.1895 8.56048 16.1991C8.5595 16.2117 8.55517 16.2238 8.54794 16.2341C8.5407 16.2445 8.53082 16.2527 8.51933 16.2579L5.88605 17.5275L5.88018 17.5393ZM6.30338 14.5651L5.96834 17.3453L8.37238 16.1697L6.30338 14.5651Z"
        fill="#E27625"
      />
      <path
        d="M11.3989 16.1933L14.0263 17.4688L13.6678 14.4299L11.3989 16.1933Z"
        fill="#E27625"
      />
      <path
        d="M14.0267 17.5396H13.9973L11.3699 16.2935C11.3584 16.2883 11.3486 16.2801 11.3413 16.2698C11.3341 16.2594 11.3298 16.2473 11.3288 16.2347C11.3225 16.2251 11.3191 16.2139 11.3191 16.2024C11.3191 16.1909 11.3225 16.1797 11.3288 16.1701L13.5976 14.4067C13.6093 14.4 13.6224 14.3965 13.6358 14.3965C13.6493 14.3965 13.6624 14.4 13.674 14.4067C13.6855 14.4119 13.6954 14.4202 13.7027 14.4305C13.7099 14.4408 13.7142 14.4529 13.7152 14.4655L14.109 17.4691C14.1122 17.4824 14.1111 17.4965 14.1059 17.5092C14.1006 17.5218 14.0914 17.5325 14.0796 17.5396C14.0718 17.5449 14.0626 17.5478 14.0532 17.5478C14.0437 17.5478 14.0345 17.5449 14.0267 17.5396ZM11.5404 16.1818L13.9386 17.3574L13.6094 14.5772L11.5404 16.1818Z"
        fill="#E27625"
      />
      <path
        d="M14.0263 17.4689L11.3989 16.1934L11.6105 17.9038V18.6268L14.0263 17.4689Z"
        fill="#D5BFB2"
      />
      <path
        d="M11.5928 18.6975C11.5864 18.7006 11.5794 18.7022 11.5722 18.7022C11.5651 18.7022 11.5581 18.7006 11.5517 18.6975C11.5399 18.6916 11.5302 18.6822 11.5239 18.6707C11.5176 18.6591 11.515 18.6459 11.5164 18.6328V17.9099L11.3048 16.217C11.3016 16.2037 11.3027 16.1896 11.308 16.177C11.3132 16.1643 11.3224 16.1536 11.3342 16.1465C11.3456 16.1392 11.3588 16.1353 11.3724 16.1353C11.3859 16.1353 11.3992 16.1392 11.4106 16.1465L14.0321 17.422C14.0447 17.4275 14.0554 17.4365 14.0629 17.448C14.0705 17.4595 14.0745 17.4729 14.0745 17.4866C14.0745 17.5004 14.0705 17.5138 14.0629 17.5253C14.0554 17.5368 14.0447 17.5458 14.0321 17.5513L11.5987 18.7269L11.5928 18.6975ZM11.487 16.3463L11.6869 17.9157V18.5329L13.8558 17.469L11.487 16.3463Z"
        fill="#D5BFB2"
      />
      <path
        d="M5.87915 17.469L8.32434 18.6446V17.9216L8.53007 16.2112L5.87915 17.469Z"
        fill="#D5BFB2"
      />
      <path
        d="M8.32529 18.6976H8.29003L5.84484 17.522C5.83225 17.5165 5.82154 17.5075 5.81402 17.496C5.8065 17.4845 5.80249 17.4711 5.80249 17.4574C5.80249 17.4436 5.8065 17.4302 5.81402 17.4187C5.82154 17.4072 5.83225 17.3982 5.84484 17.3927L8.484 16.1172C8.49417 16.1096 8.50654 16.1055 8.51926 16.1055C8.53198 16.1055 8.54436 16.1096 8.55453 16.1172C8.56136 16.1277 8.56499 16.14 8.56499 16.1525C8.56499 16.165 8.56136 16.1773 8.55453 16.1878L8.3488 17.8923V18.6036C8.3502 18.6166 8.34758 18.6298 8.34128 18.6414C8.33497 18.653 8.3253 18.6623 8.31354 18.6682C8.31734 18.6721 8.32034 18.6767 8.32235 18.6817C8.32437 18.6868 8.32537 18.6922 8.32529 18.6976ZM6.05056 17.4691L8.23125 18.5095V17.9217L8.42522 16.3406L6.05056 17.4691Z"
        fill="#D5BFB2"
      />
      <path
        d="M8.3661 13.3545L6.17954 12.7138C6.16498 12.7094 6.15221 12.7005 6.14313 12.6883C6.13405 12.6762 6.12915 12.6614 6.12915 12.6462C6.12915 12.631 6.13405 12.6162 6.14313 12.604C6.15221 12.5919 6.16498 12.5829 6.17954 12.5786L7.71954 11.8674C7.72802 11.8634 7.73719 11.8612 7.74653 11.8609C7.75587 11.8605 7.76518 11.862 7.77393 11.8653C7.78268 11.8686 7.7907 11.8736 7.7975 11.88C7.80431 11.8864 7.80977 11.8941 7.81358 11.9026L8.46015 13.2545C8.46685 13.2662 8.47038 13.2793 8.47038 13.2928C8.47038 13.3062 8.46685 13.3193 8.46015 13.331C8.44898 13.3443 8.43385 13.3538 8.41695 13.358C8.40005 13.3623 8.38225 13.361 8.3661 13.3545ZM6.39114 12.6197L8.23091 13.1605L7.69015 12.0261L6.39114 12.6197Z"
        fill="#161616"
      />
      <path
        d="M11.5458 13.3548C11.5345 13.3542 11.5235 13.3512 11.5134 13.3462C11.5033 13.3411 11.4943 13.334 11.487 13.3254C11.4803 13.3138 11.4768 13.3006 11.4768 13.2872C11.4768 13.2738 11.4803 13.2606 11.487 13.249L12.1336 11.8971C12.1424 11.8808 12.1567 11.8683 12.174 11.8618C12.1913 11.8553 12.2104 11.8553 12.2277 11.8618L13.7794 12.573C13.7927 12.579 13.8039 12.5891 13.8112 12.6017C13.8186 12.6144 13.8219 12.629 13.8206 12.6435C13.8198 12.6586 13.8142 12.6729 13.8047 12.6845C13.7952 12.6962 13.7822 12.7045 13.7677 12.7082L11.5635 13.3548H11.5458ZM12.2218 12.0264L11.681 13.1608L13.5208 12.6494L12.2218 12.0264Z"
        fill="#161616"
      />
      <path
        d="M5.87972 17.4691L6.26178 14.3303L3.83423 14.4009L5.87972 17.4691Z"
        fill="#CC6228"
      />
      <path
        d="M5.87921 17.5396C5.85464 17.5387 5.83143 17.5281 5.81455 17.5102L3.76906 14.442C3.76172 14.4306 3.75781 14.4173 3.75781 14.4038C3.75781 14.3902 3.76172 14.377 3.76906 14.3656C3.77428 14.3541 3.78248 14.3442 3.79282 14.337C3.80316 14.3297 3.81525 14.3254 3.82783 14.3244L6.25539 14.2539C6.25539 14.2539 6.30241 14.2539 6.31417 14.2539C6.3183 14.2621 6.32046 14.2712 6.32046 14.2804C6.32046 14.2895 6.3183 14.2986 6.31417 14.3068L5.94974 17.4691C5.95 17.4844 5.94482 17.4993 5.93513 17.5111C5.92545 17.523 5.91188 17.531 5.89684 17.5338L5.87921 17.5396ZM3.9689 14.4655L5.82631 17.2634L6.17898 14.4067L3.9689 14.4655Z"
        fill="#CC6228"
      />
      <path
        d="M13.6499 14.3303L14.0261 17.4691L16.0775 14.4009L13.6499 14.3303Z"
        fill="#CC6228"
      />
      <path
        d="M14.0262 17.5396C14.0116 17.5359 13.9987 17.5276 13.9891 17.516C13.9796 17.5043 13.9741 17.49 13.9733 17.475L13.5971 14.3362C13.593 14.328 13.5908 14.3189 13.5908 14.3097C13.5908 14.3005 13.593 14.2915 13.5971 14.2833C13.6063 14.279 13.6164 14.2769 13.6265 14.2769C13.6366 14.2769 13.6467 14.279 13.6559 14.2833L16.0834 14.3538C16.0966 14.3554 16.1093 14.3599 16.1205 14.367C16.1317 14.3741 16.1411 14.3837 16.1481 14.3949C16.1538 14.4069 16.1568 14.4199 16.1568 14.4332C16.1568 14.4464 16.1538 14.4594 16.1481 14.4714L14.1085 17.5102C14.1001 17.5237 14.0874 17.5339 14.0724 17.5392C14.0575 17.5445 14.0412 17.5447 14.0262 17.5396ZM13.7323 14.4067L14.0732 17.2633L15.9482 14.4655L13.7323 14.4067Z"
        fill="#CC6228"
      />
      <path
        d="M15.4905 10.874L11.1409 11.068L11.5464 13.3016L12.1871 11.9555L13.7389 12.6609L15.4905 10.874Z"
        fill="#CC6228"
      />
      <path
        d="M11.5456 13.3545C11.5298 13.3534 11.5148 13.347 11.5031 13.3364C11.4914 13.3257 11.4836 13.3114 11.481 13.2958L11.0813 11.068C11.0766 11.0589 11.0742 11.0489 11.0742 11.0387C11.0742 11.0284 11.0766 11.0184 11.0813 11.0093C11.0874 11.0007 11.0953 10.9937 11.1044 10.9886C11.1136 10.9835 11.1237 10.9805 11.1342 10.9799L15.4897 10.78C15.5044 10.7813 15.5186 10.7863 15.531 10.7945C15.5433 10.8027 15.5533 10.8139 15.5602 10.8271C15.5683 10.8381 15.5727 10.8515 15.5727 10.8653C15.5727 10.879 15.5683 10.8924 15.5602 10.9035L13.7969 12.6962C13.7843 12.7062 13.7688 12.7117 13.7528 12.7117C13.7367 12.7117 13.7212 12.7062 13.7087 12.6962L12.2275 12.0203L11.6397 13.3016C11.6378 13.3117 11.6337 13.3213 11.6277 13.3296C11.6217 13.338 11.6139 13.3449 11.605 13.35C11.596 13.355 11.5861 13.358 11.5758 13.3588C11.5656 13.3596 11.5553 13.3581 11.5456 13.3545ZM11.2282 11.1151L11.575 13.043L12.1217 11.8674C12.1304 11.8512 12.1448 11.8387 12.1621 11.8322C12.1794 11.8257 12.1984 11.8257 12.2157 11.8322L13.7204 12.5199L15.2839 10.9564L11.2282 11.1151Z"
        fill="#CC6228"
      />
      <path
        d="M6.18523 12.6609L7.72523 11.9555L8.36592 13.3016L8.77149 11.068L4.42188 10.874L6.18523 12.6609Z"
        fill="#CC6228"
      />
      <path
        d="M8.36605 13.3545C8.35199 13.3534 8.3385 13.3484 8.32709 13.3401C8.31567 13.3318 8.30678 13.3205 8.3014 13.3075L7.71361 12.0261L6.23827 12.7021C6.22669 12.7116 6.21214 12.7169 6.19713 12.7169C6.18211 12.7169 6.16756 12.7116 6.15598 12.7021L4.39262 10.9093C4.3845 10.8982 4.38013 10.8849 4.38013 10.8711C4.38013 10.8574 4.3845 10.844 4.39262 10.8329C4.39988 10.8201 4.41004 10.8092 4.42229 10.801C4.43454 10.7928 4.44854 10.7877 4.46316 10.7859L8.81865 10.9857C8.81939 10.9916 8.81939 10.9975 8.81865 11.0034C8.82329 11.0125 8.82572 11.0225 8.82572 11.0328C8.82572 11.043 8.82329 11.053 8.81865 11.0621L8.41896 13.2899C8.41635 13.3055 8.40856 13.3198 8.39683 13.3305C8.3851 13.3411 8.37011 13.3475 8.3543 13.3486L8.36605 13.3545ZM7.72537 11.8557C7.73896 11.8556 7.7523 11.8594 7.76377 11.8667C7.77524 11.874 7.78436 11.8844 7.79002 11.8968L8.33667 13.0724L8.68346 11.1444L4.60423 10.9622L6.19713 12.5786L7.69598 11.8909L7.72537 11.8557Z"
        fill="#CC6228"
      />
      <path
        d="M4.42188 10.874L6.24401 14.4007L6.18523 12.6374L4.42188 10.874Z"
        fill="#E27525"
      />
      <path
        d="M6.24448 14.5301C6.23089 14.5302 6.21755 14.5264 6.20608 14.5191C6.19461 14.5118 6.18549 14.5014 6.17983 14.489L4.35769 10.9329C4.34654 10.9209 4.34033 10.9052 4.34033 10.8888C4.34033 10.8724 4.34654 10.8567 4.35769 10.8447C4.36424 10.8381 4.37203 10.8329 4.3806 10.8294C4.38918 10.8258 4.39837 10.824 4.40765 10.824C4.41694 10.824 4.42613 10.8258 4.4347 10.8294C4.44327 10.8329 4.45106 10.8381 4.45761 10.8447L6.22097 12.6081C6.22603 12.6253 6.22603 12.6437 6.22097 12.661L6.28563 14.4243C6.28511 14.4416 6.27915 14.4583 6.2686 14.472C6.25805 14.4858 6.24344 14.4958 6.22685 14.5007L6.24448 14.5301ZM4.70448 11.2679L6.16219 14.1011L6.11517 12.6904L4.70448 11.2679Z"
        fill="#E27525"
      />
      <path
        d="M13.7383 12.6608L13.6677 14.4242L15.4899 10.8975L13.7383 12.6608Z"
        fill="#E27525"
      />
      <path
        d="M13.6679 14.5302C13.6513 14.5252 13.6367 14.5152 13.6262 14.5015C13.6156 14.4878 13.6096 14.4711 13.6091 14.4538L13.6797 12.6904C13.6752 12.6731 13.6752 12.6549 13.6797 12.6375L15.443 10.8742C15.4577 10.8641 15.4751 10.8586 15.493 10.8586C15.5108 10.8586 15.5283 10.8641 15.543 10.8742C15.5526 10.888 15.5577 10.9044 15.5577 10.9212C15.5577 10.938 15.5526 10.9544 15.543 10.9682L13.7149 14.5243C13.7006 14.5321 13.6838 14.5342 13.6679 14.5302ZM13.809 12.7198L13.7561 14.1305L15.2079 11.2974L13.809 12.7198Z"
        fill="#E27525"
      />
      <path
        d="M8.77178 11.0681L8.36621 13.3017L8.87758 15.935L8.99514 12.4612L8.77178 11.0681Z"
        fill="#E27525"
      />
      <path
        d="M8.87781 16.0111C8.86097 16.0113 8.8446 16.0055 8.83166 15.9947C8.81873 15.9839 8.81008 15.9689 8.80727 15.9523L8.2959 13.3131L8.70147 11.0795C8.70427 11.0629 8.71293 11.0479 8.72586 11.0371C8.7388 11.0263 8.75517 11.0205 8.772 11.0208C8.7893 11.0213 8.806 11.0272 8.81972 11.0378C8.83344 11.0483 8.84348 11.0629 8.84842 11.0795L9.0659 12.4726L8.95422 15.964C8.95286 15.9823 8.94499 15.9995 8.93205 16.0124C8.9191 16.0253 8.90194 16.0332 8.88368 16.0346L8.87781 16.0111ZM8.44284 13.3014L8.81903 15.2822L8.90719 12.4608L8.75437 11.5086L8.44284 13.3014Z"
        fill="#E27525"
      />
      <path
        d="M11.1699 11.0681L10.9583 12.4553L11.0641 15.935L11.5754 13.3017L11.1699 11.0681Z"
        fill="#E27525"
      />
      <path
        d="M11.0345 16.0112C11.0162 16.0098 10.9991 16.002 10.9861 15.989C10.9732 15.9761 10.9653 15.9589 10.964 15.9407L10.8582 12.461L11.0756 11.0562C11.0776 11.0461 11.0817 11.0365 11.0876 11.0282C11.0936 11.0198 11.1014 11.0129 11.1104 11.0078C11.1193 11.0028 11.1293 10.9998 11.1395 10.999C11.1498 10.9982 11.1601 10.9997 11.1697 11.0033C11.1865 11.003 11.2029 11.0088 11.2158 11.0196C11.2288 11.0304 11.2374 11.0454 11.2402 11.062L11.6458 13.2956L11.1344 15.9348C11.1368 15.9476 11.1356 15.9608 11.1309 15.973C11.1262 15.9851 11.1183 15.9957 11.1079 16.0037C11.0976 16.0116 11.0852 16.0165 11.0723 16.0178C11.0593 16.0191 11.0462 16.0168 11.0345 16.0112ZM11.1697 11.5146L11.0227 12.4668L11.1109 15.2765L11.493 13.3015L11.1697 11.5146Z"
        fill="#E27525"
      />
      <path
        d="M11.546 13.3016L11.0347 15.9348L11.3991 16.1935L13.6679 14.4301L13.7385 12.6667L11.546 13.3016Z"
        fill="#F5841F"
      />
      <path
        d="M11.3994 16.2935H11.3582L10.9879 16.0349C10.9763 16.0263 10.9673 16.0145 10.9621 16.001C10.9569 15.9874 10.9557 15.9727 10.9585 15.9585L11.4758 13.3252C11.4765 13.3117 11.4814 13.2988 11.4899 13.2883C11.4983 13.2777 11.5098 13.2701 11.5228 13.2664L13.7152 12.6258C13.7255 12.6214 13.7365 12.6191 13.7476 12.6191C13.7587 12.6191 13.7697 12.6214 13.7799 12.6258C13.7875 12.6466 13.7875 12.6695 13.7799 12.6904L13.7094 14.4538C13.7132 14.4631 13.7152 14.4731 13.7152 14.4832C13.7152 14.4932 13.7132 14.5032 13.7094 14.5125L11.4405 16.2759C11.4293 16.2865 11.4147 16.2928 11.3994 16.2935ZM11.1172 15.9291L11.3994 16.129L13.5977 14.4185L13.6565 12.7845L11.611 13.3722L11.1172 15.9291Z"
        fill="#F5841F"
      />
      <path
        d="M6.18555 12.6606L6.24433 14.424L8.51318 16.1874L8.8776 15.9287L8.36623 13.2955L6.18555 12.6606Z"
        fill="#F5841F"
      />
      <path
        d="M8.51273 16.2935C8.49744 16.2984 8.48099 16.2984 8.4657 16.2935L6.19685 14.5301C6.1913 14.5109 6.1913 14.4905 6.19685 14.4713L6.1322 12.708C6.12455 12.6871 6.12455 12.6642 6.1322 12.6433C6.14241 12.639 6.15341 12.6367 6.16452 12.6367C6.17564 12.6367 6.18663 12.639 6.19685 12.6433L8.38341 13.284C8.39675 13.2881 8.40873 13.2957 8.41806 13.306C8.42738 13.3164 8.4337 13.3291 8.43631 13.3428L8.94769 15.9761C8.95054 15.9903 8.94929 16.005 8.94409 16.0186C8.93889 16.0321 8.92994 16.0439 8.9183 16.0525L8.54799 16.3111L8.51273 16.2935ZM6.31441 14.4184L8.51273 16.1289L8.80074 15.9291L8.307 13.3546L6.26151 12.7668L6.31441 14.4184Z"
        fill="#F5841F"
      />
      <path
        d="M11.5924 18.6446V17.9216L11.3985 17.7511H8.45953L8.27144 17.9216V18.6446L5.87915 17.469L6.73144 18.1685L8.4948 19.344H11.469L13.2323 18.1685L14.0259 17.469L11.5924 18.6446Z"
        fill="#C0AC9D"
      />
      <path
        d="M11.4406 19.438H8.46643H8.42528L6.69131 18.2624L5.83315 17.5571C5.82158 17.5441 5.81519 17.5274 5.81519 17.51C5.81519 17.4927 5.82158 17.476 5.83315 17.463C5.84355 17.4486 5.85833 17.438 5.87529 17.4327C5.89224 17.4274 5.91044 17.4277 5.92719 17.4336L8.27834 18.5446V17.9568C8.27893 17.9455 8.28185 17.9344 8.28691 17.9243C8.29197 17.9142 8.29905 17.9052 8.30773 17.898L8.49582 17.7334C8.51094 17.7275 8.52772 17.7275 8.54284 17.7334H11.4818C11.4969 17.7275 11.5137 17.7275 11.5288 17.7334L11.7286 17.898C11.7354 17.917 11.7354 17.9377 11.7286 17.9568V18.5446L14.0798 17.4336C14.0966 17.4286 14.1145 17.4287 14.1313 17.434C14.148 17.4392 14.1629 17.4493 14.1738 17.463C14.1854 17.476 14.1918 17.4927 14.1918 17.51C14.1918 17.5274 14.1854 17.5441 14.1738 17.5571L13.3274 18.2624L11.5641 19.438H11.4406ZM8.50169 19.291H11.4406L13.1628 18.1155L13.5508 17.7922L11.6463 18.6974C11.6362 18.705 11.6238 18.7091 11.6111 18.7091C11.5984 18.7091 11.586 18.705 11.5758 18.6974C11.5641 18.6915 11.5544 18.6821 11.5481 18.6706C11.5418 18.659 11.5391 18.6458 11.5405 18.6327V17.945L11.3877 17.8216H8.52521L8.38414 17.945V18.6327C8.38554 18.6458 8.38291 18.659 8.37661 18.6706C8.37031 18.6821 8.36063 18.6915 8.34887 18.6974C8.3387 18.705 8.32632 18.7091 8.3136 18.7091C8.30089 18.7091 8.28851 18.705 8.27834 18.6974L6.37979 17.7981L6.76773 18.1155L8.50169 19.291Z"
        fill="#C0AC9D"
      />
      <path
        d="M8.36623 13.3016L6.18555 12.6609L7.72555 11.9556L8.36623 13.3016Z"
        fill="#161616"
      />
      <path
        d="M11.5458 13.3016L12.1865 11.9556L13.7382 12.6609L11.5458 13.3016Z"
        fill="#161616"
      />
      <path
        d="M11.3988 16.1935L11.0344 15.9349H8.8772L8.51277 16.1935L8.30705 17.9039L8.49514 17.7335H11.4341L11.628 17.9039L11.3988 16.1935Z"
        fill="#161616"
      />
      <path
        d="M8.30755 17.9744H8.27229C8.26008 17.966 8.25035 17.9544 8.24415 17.941C8.23794 17.9275 8.23548 17.9127 8.23702 17.8979L8.44274 16.1934C8.44296 16.1828 8.44575 16.1725 8.45087 16.1633C8.45599 16.154 8.46329 16.1462 8.47213 16.1405L8.83656 15.8818H8.87771H11.0349H11.076L11.4404 16.1405C11.4493 16.1462 11.4566 16.154 11.4617 16.1633C11.4668 16.1725 11.4696 16.1828 11.4698 16.1934L11.6873 17.8979C11.6876 17.9131 11.684 17.9282 11.6768 17.9416C11.6696 17.9549 11.659 17.9662 11.6462 17.9744C11.6342 17.983 11.6198 17.9876 11.605 17.9876C11.5903 17.9876 11.5759 17.983 11.5639 17.9744L11.3876 17.8274H8.52503L8.35458 17.9744C8.33946 17.9803 8.32267 17.9803 8.30755 17.9744ZM8.49565 17.6628H11.4346C11.4497 17.6569 11.4665 17.6569 11.4816 17.6628L11.5345 17.704L11.3464 16.2169L11.029 15.9935H8.90122L8.58381 16.2169L8.4016 17.704L8.44862 17.6628C8.46374 17.6569 8.48053 17.6569 8.49565 17.6628Z"
        fill="#161616"
      />
      <path
        d="M19.1871 7.17127L19.8395 4.01486L18.8579 1.07593L11.3989 6.60699L14.2732 9.02867L18.323 10.2042L19.2165 9.15798L18.8109 8.88172L19.3987 8.29394L18.9285 7.92363L19.5162 7.4534L19.1871 7.17127Z"
        fill="#763E1A"
      />
      <path
        d="M18.3228 10.2804L14.267 9.10484L11.3516 6.66553C11.3444 6.6466 11.3444 6.62567 11.3516 6.60675C11.3514 6.5953 11.3539 6.58397 11.3591 6.57373C11.3642 6.56349 11.3717 6.55465 11.381 6.54797L18.8106 1.01103C18.8199 1.00388 18.8313 1 18.843 1C18.8547 1 18.866 1.00388 18.8753 1.01103C18.8866 1.01387 18.897 1.01975 18.9053 1.02803C18.9136 1.03631 18.9195 1.04669 18.9223 1.05805L19.898 3.99698C19.9008 4.00857 19.9008 4.02065 19.898 4.03225L19.2573 7.14163L19.6276 7.42377C19.6373 7.4302 19.6453 7.43894 19.6508 7.4492C19.6563 7.45945 19.6592 7.47091 19.6592 7.48255C19.6592 7.49419 19.6563 7.50565 19.6508 7.5159C19.6453 7.52616 19.6373 7.53489 19.6276 7.54133L19.081 7.95278L19.4807 8.2643C19.4895 8.27006 19.4968 8.27788 19.502 8.2871C19.5071 8.29632 19.5099 8.30666 19.5101 8.31721C19.5156 8.3364 19.5156 8.35678 19.5101 8.37598L18.9576 8.8756L19.275 9.10484C19.2813 9.12191 19.2813 9.14067 19.275 9.15774C19.2808 9.17489 19.2808 9.19349 19.275 9.21064L18.3815 10.2569C18.374 10.2648 18.3648 10.271 18.3547 10.275C18.3446 10.2791 18.3337 10.2809 18.3228 10.2804ZM14.3082 8.96377L18.2992 10.1393L19.1104 9.19301L18.8106 8.94614C18.8019 8.93888 18.7949 8.92992 18.7898 8.91981C18.7847 8.90969 18.7818 8.89865 18.7812 8.88736C18.7761 8.87842 18.7734 8.86829 18.7734 8.85797C18.7734 8.84765 18.7761 8.83751 18.7812 8.82858L19.3338 8.32308L18.9282 8.01156C18.9189 8.00488 18.9113 7.99604 18.9062 7.9858C18.9011 7.97556 18.8986 7.96423 18.8988 7.95278C18.8994 7.94149 18.9023 7.93044 18.9074 7.92033C18.9124 7.91021 18.9195 7.90125 18.9282 7.894L19.4748 7.48255L19.1457 7.2298C19.139 7.21819 19.1354 7.20501 19.1354 7.1916C19.1354 7.17818 19.139 7.16501 19.1457 7.15339L19.7922 4.02049L18.8106 1.205L11.5162 6.61263L14.3082 8.96377Z"
        fill="#763E1A"
      />
      <path
        d="M0.0771484 4.01384L0.729591 7.17025L0.312263 7.4759L0.935316 7.94613L0.465087 8.31644L1.08226 8.90422L0.694323 9.18636L1.58776 10.2091L5.64348 9.03354L8.51187 6.61186L1.05287 1.08667L0.0771484 4.01384Z"
        fill="#763E1A"
      />
      <path
        d="M1.58908 10.2809C1.57977 10.2848 1.56978 10.2868 1.55969 10.2868C1.54961 10.2868 1.53962 10.2848 1.53031 10.2809L0.636871 9.24052C0.631049 9.22336 0.631049 9.20477 0.636871 9.18762C0.641612 9.16761 0.651782 9.14931 0.66626 9.13472L0.983665 8.90548L0.413512 8.37647C0.407961 8.35727 0.407961 8.33689 0.413512 8.31769C0.409783 8.30937 0.407855 8.30036 0.407855 8.29124C0.407855 8.28213 0.409783 8.27311 0.413512 8.26479L0.813207 7.95327L0.260688 7.54182C0.250989 7.53538 0.243032 7.52665 0.237528 7.51639C0.232024 7.50614 0.229143 7.49468 0.229143 7.48304C0.229143 7.4714 0.232024 7.45994 0.237528 7.44968C0.243032 7.43943 0.250989 7.43069 0.260688 7.42426L0.642749 7.14212L0.00206248 4.03273C-0.000687492 4.02114 -0.000687492 4.00906 0.00206248 3.99747L0.977787 1.05854C0.981079 1.04641 0.987836 1.0355 0.997231 1.02715C1.00663 1.01879 1.01825 1.01336 1.03069 1.01152C1.03995 1.00437 1.05132 1.00049 1.06302 1.00049C1.07471 1.00049 1.08608 1.00437 1.09534 1.01152L8.55434 6.54846C8.56439 6.55483 8.57281 6.56347 8.57894 6.57367C8.58506 6.58388 8.58872 6.59537 8.58961 6.60724C8.59589 6.62633 8.59589 6.64692 8.58961 6.66601L5.69183 9.08769L1.63611 10.2633L1.58908 10.2809ZM0.801451 9.18174L1.6126 10.1281L5.60954 8.9525L8.39564 6.60136L1.08947 1.20549L0.149009 4.02098L0.801451 7.15388C0.808791 7.16527 0.812695 7.17853 0.812695 7.19208C0.812695 7.20563 0.808791 7.2189 0.801451 7.23029L0.437024 7.47716L0.983665 7.88861C0.992339 7.89586 0.999425 7.90482 1.00448 7.91494C1.00954 7.92505 1.01246 7.9361 1.01305 7.94739C1.01329 7.95883 1.01074 7.97017 1.00562 7.98041C1.0005 7.99065 0.992966 7.99949 0.983665 8.00617L0.589848 8.32357L1.14237 8.82907C1.14753 8.838 1.15024 8.84814 1.15024 8.85846C1.15024 8.86877 1.14753 8.87891 1.14237 8.88784C1.14177 8.89914 1.13885 8.91018 1.13379 8.9203C1.12874 8.93041 1.12165 8.93937 1.11298 8.94662L0.801451 9.18174Z"
        fill="#763E1A"
      />
      <path
        d="M18.323 10.2102L14.2731 9.03467L15.4899 10.8803L13.6677 14.407H16.0776H19.6866L18.323 10.2102Z"
        fill="#F5841F"
      />
      <path
        d="M13.668 14.5301C13.6552 14.5297 13.6427 14.5263 13.6315 14.5201C13.6203 14.514 13.6106 14.5054 13.6034 14.4949C13.5977 14.484 13.5947 14.4719 13.5947 14.4596C13.5947 14.4473 13.5977 14.4352 13.6034 14.4243L15.4079 10.8976L14.2323 9.09312C14.2237 9.08113 14.219 9.06674 14.219 9.05197C14.219 9.03721 14.2237 9.02282 14.2323 9.01083C14.2413 8.9981 14.2541 8.98847 14.2688 8.98321C14.2835 8.97796 14.2995 8.97734 14.3146 8.98144L18.3703 10.157C18.3817 10.1599 18.392 10.1657 18.4003 10.174C18.4086 10.1823 18.4145 10.1927 18.4173 10.204L19.781 14.3949C19.7849 14.4059 19.786 14.4177 19.7843 14.4292C19.7825 14.4407 19.7779 14.4516 19.7709 14.4609C19.764 14.4702 19.7548 14.4776 19.7442 14.4825C19.7337 14.4875 19.7221 14.4897 19.7104 14.489H16.0779L13.668 14.5184V14.5301ZM14.4439 9.18716L15.5548 10.8624C15.5605 10.8732 15.5634 10.8853 15.5634 10.8976C15.5634 10.9099 15.5605 10.922 15.5548 10.9329L13.7914 14.3832L16.0779 14.3538H19.6046L18.2939 10.2981L14.4439 9.18716Z"
        fill="#F5841F"
      />
      <path
        d="M5.64494 9.02856L1.58921 10.2041L0.237305 14.4009H3.83455H6.24448L4.42234 10.8742L5.64494 9.02856Z"
        fill="#F5841F"
      />
      <path
        d="M6.2438 14.53L3.83388 14.5007H0.23663C0.227409 14.5049 0.217385 14.5071 0.207241 14.5071C0.197097 14.5071 0.187073 14.5049 0.177852 14.5007C0.172177 14.4908 0.169189 14.4797 0.169189 14.4683C0.169189 14.457 0.172177 14.4458 0.177852 14.436L1.52976 10.2451C1.5326 10.2337 1.53848 10.2233 1.54676 10.2151C1.55504 10.2068 1.56542 10.2009 1.57678 10.1981L5.6325 9.02249C5.64753 9.01743 5.66382 9.01758 5.67875 9.02291C5.69368 9.02824 5.70638 9.03845 5.7148 9.05188C5.72341 9.06387 5.72804 9.07826 5.72804 9.09303C5.72804 9.10779 5.72341 9.12218 5.7148 9.13417L4.53922 10.9387L6.34373 14.4654C6.34939 14.4763 6.35235 14.4884 6.35235 14.5007C6.35235 14.5129 6.34939 14.525 6.34373 14.5359C6.33724 14.543 6.32926 14.5485 6.32038 14.5521C6.3115 14.5557 6.30193 14.5572 6.29237 14.5567C6.28281 14.5561 6.27349 14.5534 6.26509 14.5488C6.25669 14.5442 6.24942 14.5378 6.2438 14.53ZM0.365943 14.3537H3.83388L6.12625 14.3831L4.36289 10.9328C4.35722 10.9219 4.35426 10.9098 4.35426 10.8975C4.35426 10.8852 4.35722 10.8732 4.36289 10.8623L5.46792 9.15768L1.64732 10.2686L0.365943 14.3537Z"
        fill="#F5841F"
      />
      <path
        d="M11.17 11.0682L11.4286 6.6069L12.6042 3.43286H7.33765L8.51322 6.6069L8.77184 11.0682L8.87177 12.473V15.9351H11.0289V12.473L11.17 11.0682Z"
        fill="#F5841F"
      />
      <path
        d="M11.0342 16.0112H8.877C8.86722 16.0112 8.85754 16.0092 8.84858 16.0053C8.83961 16.0014 8.83156 15.9957 8.82492 15.9885C8.81829 15.9813 8.81323 15.9728 8.81005 15.9636C8.80687 15.9544 8.80565 15.9445 8.80646 15.9348V12.4727L8.70654 11.0738L8.44792 6.62427L7.27234 3.45611C7.26887 3.43511 7.27366 3.41359 7.28572 3.39605C7.29778 3.37852 7.31615 3.36634 7.337 3.36206H12.5742C12.5856 3.36342 12.5967 3.36718 12.6066 3.37308C12.6165 3.37898 12.625 3.3869 12.6317 3.39632C12.6383 3.40574 12.6429 3.41646 12.6452 3.42777C12.6474 3.43908 12.6473 3.45074 12.6447 3.46198L11.4691 6.63015L11.2105 11.0797L11.1106 12.4845V15.9407C11.1106 15.9505 11.1086 15.9601 11.1047 15.9691C11.1008 15.9781 11.0951 15.9861 11.0879 15.9927C11.0807 15.9994 11.0722 16.0044 11.063 16.0076C11.0537 16.0108 11.0439 16.012 11.0342 16.0112ZM8.95341 15.8643H10.9578V12.4727L11.0518 11.0679L11.3163 6.60076L12.4566 3.52076H7.4428L8.58311 6.58313L8.84173 11.0503L8.94165 12.4492L8.95341 15.8643Z"
        fill="#F5841F"
      />
    </svg>
  );
};

export default Metamask;
