import React, { useEffect, useState } from "react";
import useToken from "src/hooks/web3/use-token";
import P from "~components/ui/typography/p";
import Button from "~components/ui/button";
import AllIcons from "~components/ui/all-icons";

import PaidModalBase from "./paid-modal-base";
import DonateForm from "./donate-form";
import { useSendDonationMutation } from "~services/backend/donations";
import { useDispatch, useSelector } from "react-redux";

import createEventNotification from "~utils/create-event-notification";
import { selectAccountId } from "~store/authSlice/selectors";
import { profilesApi } from "~services/backend/profiles";

const DonateCard = ({ setShow, address, amount = 5 }) => {
  const dispatch = useDispatch();

  const accountId = useSelector(selectAccountId);
  const [tokensValue, setTokensValue] = useState(amount);
  const [message, setMessage] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [sended, setSended] = useState(false);

  const [sendDonation, sendDonationResult] = useSendDonationMutation();

  const { balance, transfer } = useToken();

  const handleReset = () => {
    setTokensValue(0);
    setMessage("");
    setWaiting(false);
    setSended(false);
  };

  const handleClose = () => {
    setShow(false);
    handleReset();
  };

  const handleDonate = async () => {
    try {
      setWaiting(true);

      const onConfirm = (tx) => {
        sendDonation({
          data: {
            tx_hash: tx.hash,
            message,
          },
        });
      };

      const tx = await transfer({
        address,
        amount: tokensValue,
        onConfirm,
      });

      if (tx.status) {
        setSended(true);

        createEventNotification({
          event: "success",
          title: "Donation sent successfully",
        });

        sendDonation({
          data: {
            tx_hash: tx.transactionHash,
            message,
          },
        });
      }

      setWaiting(false);
    } catch (e) {
      setWaiting(false);
    }
  };

  useEffect(() => {
    if (sendDonationResult.isSuccess) {
      dispatch(
        profilesApi.util.updateQueryData(
          "getProfileById",
          accountId,
          (draft) => {
            if (!draft.donations) {
              draft.donations = [];
            }
            draft.donations.push(sendDonationResult.data);
          },
        ),
      );
      setShow(false);
    }
  }, [sendDonationResult.isSuccess]);

  const handleMessageChange = (e) => setMessage(e.target.value);

  const iconPath = sended && "/assets/images/heart.png";
  const title = sended ? "Donation sent" : "Donate";
  const subtitle = sended
    ? "Your donation has been successfully sent to the author"
    : "Support user with money.";
  const disabled = waiting;

  const buttonsSection = (
    <>
      {!sended && (
        <div className="w-[140px]">
          <Button
            onClick={handleClose}
            className="@wh w-auto @jyc flex items-center justify-center @bxsw shadow-none py-3 @ht h-12 @brc border border-black @bdc bg-white @ttc text-black hover:bg-black hover:text-white"
            variant="rounded"
          >
            <P variant="button" className="@ftf font-family-inter-medium">
              Cancel
            </P>
          </Button>
        </div>
      )}

      <div className="w-[140px]">
        <Button
          onClick={sended ? handleClose : handleDonate}
          className="@wh w-auto @jyc @bxsw shadow-none hover:opacity-70 transition duration-30 text-white disabled:text-gray-450 py-3 flex justify-center items-center @ht h-12"
          variant="rounded"
          disabled={disabled || !tokensValue}
        >
          {waiting ? (
            <AllIcons
              name="SmallLoader"
              className="h-5 w-5 animate-spin flex justify-center items-center"
            />
          ) : (
            <P variant="button" className="@ftf font-family-inter-medium @ttc">
              {sended ? "Okay" : "Donate"}
            </P>
          )}
        </Button>
      </div>
    </>
  );

  return (
    <PaidModalBase
      iconPath={iconPath}
      title={title}
      subtitle={subtitle}
      buttons={buttonsSection}
    >
      {sended ? null : (
        <DonateForm
          setShow={setShow}
          balance={balance}
          tokensValue={tokensValue}
          setTokensValue={setTokensValue}
          message={message}
          disabled={disabled}
          handleMessageChange={handleMessageChange}
        />
      )}
    </PaidModalBase>
  );
};

export default DonateCard;
