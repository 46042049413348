export default function File({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8824 18H6C4.89543 18 4 17.1046 4 16V4C4 2.89479 4.8946 2 5.99854 2H11.5V5.5C11.5 6.88071 12.6193 8 14 8H17.8824V16.0019C17.8824 17.1057 16.9878 18 15.8824 18ZM17.4224 7L12.5 2.37251V5.5C12.5 6.32843 13.1716 7 14 7H17.4224ZM3 4C3 2.34315 4.34168 1 5.99854 1H12.1038C12.3584 1 12.6032 1.09706 12.7887 1.27141L18.5673 6.70382C18.7683 6.89282 18.8824 7.15608 18.8824 7.43201V16.0019C18.8824 17.6588 17.5392 19 15.8824 19H6C4.34315 19 3 17.6569 3 16V4Z"
      />
    </svg>
  );
}
