import { createNotification } from "~components/notifications";

const createEventNotification = ({ event, message, title, ...props }) => {
  const eventProps = notificationPropsObject[event];

  const formattedMessage = message ? (
    <div className="text-left text-[13px]">{message}</div>
  ) : null;

  const titleClasses = "text-black !text-[16px]";

  return createNotification({
    ...eventProps,
    ...props,
    title,
    message: formattedMessage,
    titleClasses,
  });
};

export default createEventNotification;

const notificationPropsObject = {
  success: {
    iconName: "NotificationSuccess",
    titleClasses: "text-18px",
  },
  warning: {
    iconName: "NotificationWarning",
    iconClassName: "w-3 h-3",
    titleClasses: "text-amber-550 text-18px",
  },
  error: {
    iconName: "NotificationError",
    iconClassName: "w-3 h-3",
    titleClasses: "text-red-550 text-18px",
  },
};

export const handleApiError = (error) => {
  let title =
    (error && error.data?.error.message) ||
    error.message ||
    error.error ||
    error.status;

  if (!title) {
    try {
      title = JSON.stringify(error);
    } catch (error) {
      title = "Something went wrong :(";
    }
  }

  return {
    event: "error",
    title,
  };
};
