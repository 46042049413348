import React from "react";

const TurkeyFlag = ({ className }) => (
  <svg
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.7827 16.9231C24.7827 18.6223 23.5887 20 22.116 20H3.44938C1.97671 20 0.782715 18.6223 0.782715 16.9231V3.07692C0.782715 1.37769 1.97671 0 3.44938 0H22.116C23.5887 0 24.7827 1.37769 24.7827 3.07692V16.9231Z"
      fill="#E30917"
    />
    <path
      d="M11.0306 14.5385C8.68008 14.5385 6.77495 12.6533 6.77495 10.3258C6.77495 7.99895 8.68008 6.11305 11.0306 6.11305C11.9598 6.11305 12.818 6.41146 13.5181 6.91207C12.562 5.84204 11.1689 5.16309 9.61207 5.16309C6.73168 5.16309 4.39673 7.47447 4.39673 10.3258C4.39673 13.1778 6.73168 15.4892 9.61207 15.4892C11.1689 15.4892 12.5627 14.8102 13.5181 13.7395C12.8187 14.2401 11.9612 14.5385 11.0306 14.5385ZM13.8061 10.4873L15.5367 10.8819L15.6927 12.6322L16.6063 11.1255L18.3369 11.5194L17.1709 10.1938L18.0837 8.68633L16.4503 9.3744L15.2842 8.0481L15.4402 9.79919L13.8061 10.4873Z"
      fill="#EEEEEE"
    />
  </svg>
);

export default TurkeyFlag;
