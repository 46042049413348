import React from "react";

const Menu = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.40002C3 3.62681 3.62681 3 4.40002 3C5.17323 3 5.80003 3.62681 5.80003 4.40002C5.80003 5.17323 5.17323 5.80003 4.40002 5.80003C3.62681 5.80003 3 5.17323 3 4.40002ZM3.00011 10.0001C3.00011 9.22688 3.62692 8.60007 4.40013 8.60007C5.17334 8.60007 5.80015 9.22688 5.80015 10.0001C5.80015 10.7733 5.17334 11.4001 4.40013 11.4001C3.62692 11.4001 3.00011 10.7733 3.00011 10.0001ZM10.0002 3C9.22699 3 8.60018 3.62681 8.60018 4.40002C8.60018 5.17323 9.22699 5.80003 10.0002 5.80003C10.7734 5.80003 11.4002 5.17323 11.4002 4.40002C11.4002 3.62681 10.7734 3 10.0002 3ZM8.60018 10.0001C8.60018 9.22688 9.22699 8.60007 10.0002 8.60007C10.7734 8.60007 11.4002 9.22688 11.4002 10.0001C11.4002 10.7733 10.7734 11.4001 10.0002 11.4001C9.22699 11.4001 8.60018 10.7733 8.60018 10.0001ZM15.6002 3C14.8269 3 14.2001 3.62681 14.2001 4.40002C14.2001 5.17323 14.8269 5.80003 15.6002 5.80003C16.3734 5.80003 17.0002 5.17323 17.0002 4.40002C17.0002 3.62681 16.3734 3 15.6002 3ZM14.2001 10.0001C14.2001 9.22688 14.8269 8.60007 15.6002 8.60007C16.3734 8.60007 17.0002 9.22688 17.0002 10.0001C17.0002 10.7733 16.3734 11.4001 15.6002 11.4001C14.8269 11.4001 14.2001 10.7733 14.2001 10.0001ZM4.40013 14.2001C3.62692 14.2001 3.00011 14.8269 3.00011 15.6002C3.00011 16.3734 3.62692 17.0002 4.40013 17.0002C5.17334 17.0002 5.80015 16.3734 5.80015 15.6002C5.80015 14.8269 5.17334 14.2001 4.40013 14.2001ZM8.60018 15.6002C8.60018 14.8269 9.22699 14.2001 10.0002 14.2001C10.7734 14.2001 11.4002 14.8269 11.4002 15.6002C11.4002 16.3734 10.7734 17.0002 10.0002 17.0002C9.22699 17.0002 8.60018 16.3734 8.60018 15.6002ZM15.6002 14.2001C14.8269 14.2001 14.2001 14.8269 14.2001 15.6002C14.2001 16.3734 14.8269 17.0002 15.6002 17.0002C16.3734 17.0002 17.0002 16.3734 17.0002 15.6002C17.0002 14.8269 16.3734 14.2001 15.6002 14.2001Z"
      />
    </svg>
  );
};

export default Menu;
