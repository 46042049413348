import React from "react";

const ProfileInCircle = ({ className }) => (
  <svg
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M2.33953 12.8653C2.75714 12.2737 3.2466 11.7741 3.79893 11.3578C4.8003 10.604 5.75228 10.3323 5.97231 10.2666C6.42136 10.1307 6.83897 10.0562 7.19821 10.0124C7.28802 10.0036 7.297 9.88094 7.21168 9.85465C6.75814 9.71441 6.11152 9.41642 5.5996 8.77223C5.09667 8.14118 4.84072 7.32608 4.944 6.48469C5.13709 4.8764 6.52913 3.70633 8.14569 3.7151C9.77573 3.72386 11.1004 4.91145 11.3025 6.41895C11.527 8.06668 10.4224 9.4953 8.96745 9.87656C8.88213 9.89847 8.89111 10.0212 8.97643 10.0343C8.99888 10.0387 9.01684 10.0387 9.0393 10.0431C10.9657 10.3498 12.5014 11.2833 13.642 12.8346C13.642 12.8346 13.642 12.8346 13.6465 12.839C13.7543 12.988 13.9743 13.0011 14.0956 12.8653C15.1733 11.6295 15.8648 10.065 15.9815 8.34277C16.3049 3.68004 12.3353 -0.24647 7.55295 0.0120837C3.34539 0.235579 0 3.6406 0 7.80375C0 9.74947 0.727455 11.5243 1.93539 12.8916C2.04316 13.0186 2.24074 13.0055 2.33953 12.8653Z" />
  </svg>
);

export default ProfileInCircle;
