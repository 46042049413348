import React from "react";

const Linkedin = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.38266 7.15907H3.46535C3.33587 7.15907 3.23096 7.26402 3.23096 7.39345V16.7655C3.23096 16.895 3.33587 16.9999 3.46535 16.9999H6.38266C6.51213 16.9999 6.61704 16.895 6.61704 16.7655V7.39345C6.61704 7.26402 6.51213 7.15907 6.38266 7.15907Z" />
      <path d="M4.92506 2.5C3.86357 2.5 3 3.36263 3 4.42295C3 5.48373 3.86357 6.34669 4.92506 6.34669C5.9857 6.34669 6.84857 5.48369 6.84857 4.42295C6.84862 3.36263 5.9857 2.5 4.92506 2.5Z" />
      <path d="M13.803 6.92617C12.6313 6.92617 11.7652 7.42986 11.2398 8.00219V7.39348C11.2398 7.26406 11.1349 7.1591 11.0055 7.1591H8.21163C8.08215 7.1591 7.97724 7.26406 7.97724 7.39348V16.7656C7.97724 16.895 8.08215 17 8.21163 17H11.1226C11.252 17 11.3569 16.895 11.3569 16.7656V12.1286C11.3569 10.566 11.7814 9.95725 12.8706 9.95725C14.0569 9.95725 14.1512 10.9331 14.1512 12.209V16.7656C14.1512 16.8951 14.2561 17 14.3855 17H17.2976C17.427 17 17.5319 16.8951 17.5319 16.7656V11.6249C17.5319 9.30139 17.0889 6.92617 13.803 6.92617Z" />
    </svg>
  );
};

export default Linkedin;
