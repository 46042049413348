import { forwardRef } from "react";

import InputHeading from "../input-heading";
import InputError from "../input-error";
import useStyleRewriter from "../../zui/functions/use-style-rewriter";

const TextArea = forwardRef((props, ref) => {
  const {
    label,
    error,
    value,
    id,
    onChange,
    placeholder,
    inputContainerClasses = "",
    headingProps,
    inputsClassName,
    maxLength,
  } = props;
  const srClasses = useStyleRewriter(
    baseInputContainerClasses,
    inputContainerClasses,
  );

  const inputSrClasses = useStyleRewriter(baseInputClasses, inputsClassName);

  return (
    <>
      <div className="flex justify-between mb-1">
        {label && (
          <InputHeading
            title={label}
            className={error ? "@wh w-fit" : ""}
            buttonTitle={error && headingProps?.buttonTitle}
            onButtonClick={
              headingProps?.onButtonClick
                ? () => headingProps.onButtonClick(props)
                : undefined
            }
          />
        )}
        {error && <InputError error={error} />}
      </div>
      <div className={srClasses}>
        <textarea
          ref={ref}
          value={value}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          className={inputSrClasses}
          maxLength={maxLength}
        />
      </div>
    </>
  );
});

export default TextArea;

const baseInputContainerClasses = "@mn mb-4 @pn relative @wh w-full";

const baseInputClasses = `
    @wh w-full
    @ht h-100px max-h-200px min-h-100px
    @ftf font-montserrat
    @ftw font-medium
    @tndn duration-200
    @oe outline-none focus:outline-none hover:outline-none
    @brr rounded-8px
    @bro hover:border-opacity-70
    @fts text-sm md:text-base
    @dy flex
    @pg pt-3
    @pn relative
    @brc border-neutral-300 hover:border-purple-600 focus:border-purple-600
    @ttc text-zinc-900 placeholder-zinc-base
    @oe outline-none focus:outline-none hover:outline-none
`;
