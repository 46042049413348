import { allowedImageExtensions } from "~configs";

// const urlRegex =
//   /\b(?<![@.,%&#-])(?<protocol>\w{2,10}:\/\/)?((?:\w|\&\#\d{1,5};)[.-]?)+(\.([a-z]{2,15})|((protocol)(?:\:\d{1,6})|(?!)))\b(?![@])(\/)?(?:([\w\d\?\-=#:%@&.;])+(?:\/(?:([\w\d\?\--=#:%@&;.])+))*)?(?<![.,?!-])/gim;

export const shortenAddress = (address, symbols = [6, 4]) => {
  const firstPart = address.slice(0, symbols[0]);
  const secondPart = address.slice(-symbols[1]);

  return `${firstPart}...${secondPart}`;
};

export const addProtocolToUrl = (url) => {
  const splittedString = url.split("://");
  let urlWithProtocol = "";
  if (splittedString.length > 1) {
    urlWithProtocol =
      splittedString[0][-1] === "s" ? url : `https://${splittedString[1]}`;
  } else {
    urlWithProtocol = `https://${url}`;
  }
  return urlWithProtocol;
};

export const parseMimeType = (mime) => {
  if (!mime) return {};
  const splittedMime = mime?.split("/");

  const type = splittedMime[0];
  const ext = splittedMime[1];
  let renderType = type;

  if (type === "image" && !allowedImageExtensions.includes(ext)) {
    renderType = "file";
  }

  if (type !== "image" && type !== "video") {
    renderType = "file";
  }

  return { type, ext, renderType };
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .search(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
