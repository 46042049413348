import createEventNotification from "~utils/create-event-notification";
import axios from "axios";
import qs from "qs";
import {
  isObject,
  isArray,
  flatItemAttributes,
  combineHeaders,
  transformEntriesInObj,
} from "./utils";

import { backBaseUrl } from "~configs";

export const transformResponseItem = (resItem) => {
  if (isArray(resItem)) {
    return resItem.map((item) => transformResponseItem(item));
  }

  if (isObject(resItem)) {
    if (isArray(resItem.data)) {
      resItem = [...resItem.data];
    } else if (isObject(resItem.data)) {
      resItem = transformEntriesInObj(flatItemAttributes(resItem.data));
    } else if (resItem.data === null) {
      resItem = null;
    } else {
      resItem = transformEntriesInObj(flatItemAttributes(resItem));
    }

    for (const key in resItem) {
      resItem[key] = transformResponseItem(resItem[key]);
    }

    return resItem;
  }

  return resItem;
};

class Api {
  constructor(url) {
    this.baseUrl = url || backBaseUrl;
  }

  _transformResponseItem(resItem) {
    if (isArray(resItem)) {
      return resItem.map((item) => this._transformResponseItem(item));
    }

    if (isObject(resItem)) {
      if (isArray(resItem.data)) {
        resItem = [...resItem.data];
      } else if (isObject(resItem.data)) {
        resItem = transformEntriesInObj(flatItemAttributes(resItem.data));
      } else if (resItem.data === null) {
        resItem = null;
      } else {
        resItem = transformEntriesInObj(flatItemAttributes(resItem));
      }

      for (const key in resItem) {
        resItem[key] = this._transformResponseItem(resItem[key]);
      }

      return resItem;
    }

    return resItem;
  }

  _stringifyQueryObject(object) {
    return qs.stringify(object, {
      encodeValuesOnly: true,
    });
  }

  request({
    model,
    query,
    method = "GET",
    data,
    withAuth,
    headers,
    id,
    notifyError = true,
  }) {
    const stringifiedQuery = this._stringifyQueryObject(query);
    const passHeaders = { ...headers, ...combineHeaders({ withAuth }) };

    return axios({
      url: `${this.baseUrl}/api/${model}${
        id ? `/${id}` : ""
      }?${stringifiedQuery}`,
      headers: passHeaders,
      method,
      data,
    })
      .then((res) => this._transformResponseItem(res.data))
      .catch((error) => {
        const message = error?.response?.data?.error?.message || error?.message;

        notifyError &&
          createEventNotification({
            event: "error",
            title: message,
          });
      });
  }
}

export const apiClient = new Api();
