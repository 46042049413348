import React from "react";

const Twitter = ({ className }) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M19.9996 2.33705C19.2893 2.6486 18.5325 2.85511 17.7435 2.95537C18.5552 2.47074 19.1747 1.70918 19.466 0.791255C18.7092 1.24246 17.8736 1.56117 16.9832 1.73903C16.2646 0.973886 15.2404 0.5 14.1231 0.5C11.9554 0.5 10.2103 2.25947 10.2103 4.41642C10.2103 4.72678 10.2365 5.02519 10.301 5.30928C7.04588 5.15053 4.16556 3.5904 2.23062 1.21381C1.89282 1.7999 1.69467 2.47074 1.69467 3.19291C1.69467 4.54892 2.39296 5.75094 3.43384 6.44685C2.80478 6.43491 2.18765 6.25228 1.66483 5.96461C1.66483 5.97654 1.66483 5.99206 1.66483 6.00758C1.66483 7.91029 3.02203 9.4907 4.80178 9.85477C4.48307 9.94191 4.13572 9.98368 3.77523 9.98368C3.52456 9.98368 3.2715 9.96936 3.03396 9.91684C3.54127 11.4674 4.98083 12.6074 6.69255 12.6444C5.36042 13.6864 3.66899 14.3143 1.83791 14.3143C1.51681 14.3143 1.20884 14.3 0.900879 14.2606C2.63528 15.3791 4.69077 16.0177 6.90741 16.0177C14.1124 16.0177 18.0515 10.0493 18.0515 4.87598C18.0515 4.7029 18.0455 4.53579 18.0372 4.36987C18.8142 3.81839 19.4672 3.12965 19.9996 2.33705Z" />
  </svg>
);

export default Twitter;
