import React from "react";

const Chart = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M2.25012 1.5C2.25012 1.08579 1.91434 0.75 1.50012 0.75C1.08591 0.75 0.750123 1.08579 0.750123 1.5L2.25012 1.5ZM1.50012 16.5H0.750122C0.750122 16.9142 1.08591 17.25 1.50012 17.25L1.50012 16.5ZM16.2858 17.25C16.7 17.25 17.0358 16.9142 17.0358 16.5C17.0358 16.0858 16.7 15.75 16.2858 15.75V17.25ZM3.47408 10.5272C3.17299 10.8117 3.15952 11.2863 3.44398 11.5874C3.72844 11.8885 4.20313 11.902 4.50421 11.6175L3.47408 10.5272ZM8.32496 6.9759L8.83761 6.42846C8.54827 6.15751 8.09803 6.1585 7.80989 6.43074L8.32496 6.9759ZM11.3369 9.79641L10.8242 10.3439C11.1126 10.6139 11.5611 10.6139 11.8495 10.3439L11.3369 9.79641ZM17.0128 5.50869C17.3151 5.22556 17.3307 4.75094 17.0476 4.4486C16.7644 4.14626 16.2898 4.13068 15.9875 4.41381L17.0128 5.50869ZM0.750123 1.5L0.750122 16.5H2.25012L2.25012 1.5L0.750123 1.5ZM1.50012 17.25L16.2858 17.25V15.75L1.50012 15.75L1.50012 17.25ZM4.50421 11.6175L8.84003 7.52107L7.80989 6.43074L3.47408 10.5272L4.50421 11.6175ZM7.81231 7.52334L10.8242 10.3439L11.8495 9.24898L8.83761 6.42846L7.81231 7.52334ZM11.8495 10.3439L17.0128 5.50869L15.9875 4.41381L10.8242 9.24898L11.8495 10.3439Z" />
  </svg>
);

export default Chart;
