import P from "~components/ui/typography/p";

const InputError = ({ error, className = baseClasses }) => (
  <P className={`${baseClasses} ${className}`}>{error?.message}</P>
);

export default InputError;

const baseClasses =
  "@ttc text-red-500 @fts text-12px @ttt normal-case @wh w-fit @leh leading-none text-right";
