import React, { useMemo, useState } from "react";
import {
  getSocialFilePath,
  getSocialImagePath,
} from "~utils/vanilla/formatters/get-social-image-path";
import { isMobile } from "react-device-detect";

import ReactSpringGallery from "./ReactSpringGallery";

import BluredContent from "~components/feed/feed-card/post-content/BluredContent";
import PostFooter from "~components/feed/feed-card/post-footer";
import VideoPreview from "~components/media/VideoPreview";
import { useDispatch, useSelector } from "react-redux";
import { selectMediaGalleryModalState } from "~store/modals/selectors";
import { setMediaGalleryModal, setSubscribeModal } from "~store/modals";

const MediaGallery = ({ postLikes, post, setPostLikes }) => {
  const dispatch = useDispatch();
  const { media: stateMedia, mediaIndex } = useSelector(
    selectMediaGalleryModalState,
  );

  const setShowSubscribeModal = () =>
    post?.user &&
    dispatch(
      setSubscribeModal({
        profile: post.user,
        show: true,
      }),
    );

  const setShow = () =>
    dispatch(setMediaGalleryModal({ show: false, media: stateMedia }));

  const setActiveSlideIndex = (index) =>
    dispatch(
      setMediaGalleryModal({
        show: true,
        media: stateMedia,
        mediaIndex: index,
      }),
    );

  const mediaHash = useMemo(
    () => stateMedia?.map((mediaObj) => mediaObj.hash).join(""),
    [stateMedia],
  );

  const media = useMemo(() => stateMedia, [mediaHash]);

  if (!media) return null;

  return (
    <div className="flex flex-row items-center md:items-start justify-center relative h-full">
      <div
        className={`${
          media.length > 1 ? "md:block" : "md:hidden"
        } hidden h-full `}
      >
        <div
          style={{
            width: "106px",
            minHeight: "90px",
          }}
          className=" md:max-h-full bg-black flex flex-col items-center py-1 rounded-10px overflow-hidden mr-5"
        >
          <div className="h-full overflow-y-scroll">
            {media.map((mediaObj, index) => (
              <div
                key={`${mediaObj.id}-${index}`}
                style={{
                  width: "90px",
                }}
                className="cursor-pointer flex relative"
                onClick={() => setActiveSlideIndex(index)}
              >
                {mediaObj.renderType === "image" || mediaObj.isHidden ? (
                  <img
                    className={`w-full self-center mx-auto h-auto object-contain max-h-[90px] my-1 rounded ${
                      mediaIndex === index ? "brightness-100" : "brightness-50"
                    } `}
                    src={
                      getSocialImagePath(mediaObj, "thumbnail") ||
                      "/assets/images/blur.jpg"
                    }
                  />
                ) : (
                  <div className="w-full h-auto pb-full relative overflow-hidden cursor-pointer">
                    <VideoPreview
                      url={getSocialFilePath(mediaObj)}
                      mime={mediaObj.mime}
                      className="absolute object-cover block w-full h-full max-w-full max-h-full"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div onClick={() => setShow(false)} className="h-full"></div>
      </div>
      <ReactSpringGallery
        activeSlide={mediaIndex}
        setActiveSlide={setActiveSlideIndex}
        media={media}
        setShow={setShow}
      >
        {(mediaObj) =>
          mediaObj.isHidden ? (
            <BluredContent setShowSubscribeModal={setShowSubscribeModal} />
          ) : null
        }
      </ReactSpringGallery>
      {post && postLikes ? (
        <div className="absolute right-0 bottom-3 md:relative md:h-full  self-end flex flex-col items-end justify-end md:w-[5.75rem]">
          {!isMobile && (
            <div onClick={() => setShow(false)} className="h-full w-full"></div>
          )}
          <div className="w-10 z-40">
            <PostFooter
              setPostLikes={setPostLikes}
              {...postLikes}
              post={post}
              isGallery={true}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MediaGallery;
