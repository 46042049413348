import React from "react";

const Bithumb = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.8953 9.52704H12.5415L8.1514 20.3553C8.1514 20.3553 5.85646 20.3175 5.15474 18.2954C4.95818 17.695 4.94167 17.0494 5.10729 16.4395L6.8953 9.52704Z"
          fill="#D53127"
        />
        <path
          d="M28.7933 9.75895L21.6138 9.63304L23.2295 4.2592C23.3783 3.76727 23.411 3.24704 23.3248 2.74012C23.2385 2.2332 23.0359 1.75365 22.733 1.33983C22.4302 0.926007 22.0355 0.589392 21.5805 0.356905C21.1256 0.124418 20.623 0.00251001 20.113 0.000930786L13.5603 0.000930786L6.06863 27.1345C6.06863 27.1345 3.38412 38.7384 13.5603 39.7885C13.5603 39.7885 29.6473 43.1049 34.8091 23.5033C34.8091 23.5033 37.5335 11.6476 28.7933 9.75895ZM23.8862 23.2162C23.8862 23.2162 23.5991 28.2224 19.8532 29.1012C19.3002 29.2415 18.7158 29.1808 18.2026 28.93C17.6157 28.6278 17.0089 28.0008 17.0239 26.6863C17.0337 26.3063 17.0942 25.9294 17.2037 25.5657L18.5872 20.3555H22.3629C22.3629 20.3555 24.0735 20.9171 23.8862 23.2162Z"
          fill="#F47320"
        />
        <path
          d="M35.1536 18.0388C34.4669 10.0737 28.7932 9.75895 28.7932 9.75895L21.6137 9.63304L18.5297 20.4185H22.2955C22.9872 20.4563 24.2483 21.6071 23.8387 23.6443"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="33.8351"
          y1="28.6933"
          x2="22.3868"
          y2="11.2719"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.41" stopColor="#F47320" />
          <stop offset="0.5" stopColor="#F16D21" />
          <stop offset="0.62" stopColor="#E95C22" />
          <stop offset="0.75" stopColor="#DC4125" />
          <stop offset="0.81" stopColor="#D53127" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Bithumb;
