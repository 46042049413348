import AllIcons from "~ui/all-icons";
import Button from "~ui/button";

const ButtonWithIcon = ({
  variant = "primary",
  text,
  icon,
  disabled,
  onClick,
  iconClassName = "",
  buttonClassName = "",
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    className={`@dy flex @ani items-center ${buttonClassName}`}
  >
    {icon ? (
      <AllIcons name={icon} className={`mr-2 w-5 ${iconClassName}`} />
    ) : null}
    {text || null}
  </Button>
);

export default ButtonWithIcon;
