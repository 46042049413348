import React from "react";
import useStyleRewriter from "../../../zui/functions/use-style-rewriter";

const H2 = ({ children = "", className = "" }) => {
  const srClasses = useStyleRewriter(baseClasses, className);

  return <h2 className={srClasses}>{children}</h2>;
};

export default H2;

const baseClasses = `
    @leh leading-10 sm:leading-none
    @ftf font-montserrat
    @ftw font-extrabold
    @ttc text-gray-900
    @fts text-4xl sm:text-5xl md:text-6xl`;
