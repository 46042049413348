export default function Cog({ className }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.1475 7.40321C8.05236 8.20957 6.10115 1.38066 6.10115 1.38066C6.10115 1.38066 6.69367 8.4246 1.25889 9.28472C6.69361 8.42461 8.30521 15.3073 8.30521 15.3073C8.30521 15.3073 8.05236 8.20957 13.1475 7.40321Z"
        stroke="url(#paint0_linear_234_402)"
        strokeWidth="1.37561"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_234_402"
          x1="4.55578"
          y1="10.266"
          x2="12.6507"
          y2="8.85784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13D97A" />
          <stop offset="0.34375" stopColor="#26ADE6" />
          <stop offset="0.65625" stopColor="#FB38FF" />
          <stop offset="1" stopColor="#FF971D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
