import React from "react";

const Coinbase = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="#1652F0" />
      <path
        d="M22.2554 35C16.0621 35 10 30.5435 10 20.4836C10 10.391 16.0621 6 22.2554 6C25.3028 6 27.6949 6.78644 29.3989 7.90056L27.5311 11.9966C26.3842 11.1774 24.6802 10.6531 22.9763 10.6531C19.2407 10.6531 15.8328 13.6023 15.8328 20.4181C15.8328 27.2339 19.339 30.2486 22.9763 30.2486C24.6802 30.2486 26.3842 29.7243 27.5311 28.9051L29.3989 33.0994C27.6294 34.2791 25.3028 35 22.2554 35Z"
        fill="white"
      />
    </svg>
  );
};

export default Coinbase;
