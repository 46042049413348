import { isMobile } from "react-device-detect";
import H1 from "~components/ui/typography/h1";
import P from "~components/ui/typography/p";
import CardBox from "~components/ui/card-box";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useLazyLoginWithEmailQuery,
  useSendEmailCodeMutation,
  useUpdateUserMutation,
} from "~services/backend/profiles";
import createEventNotification, {
  handleApiError,
} from "~utils/create-event-notification";
import OtpInput from "~components/input/otp";
import AllIcons from "~components/ui/all-icons";
import Button from "~components/ui/button";

const EmailLoginCard = ({ onLogin = () => {}, email, profile }) => {
  const [emailCode, setEmailCode] = useState(null);
  const [counter, setCounter] = useState(59);

  const [loginWithEmail, loginWithEmailResult] = useLazyLoginWithEmailQuery();
  const [resend] = useSendEmailCodeMutation();
  const [updateUser] = useUpdateUserMutation();

  const disabled = `${emailCode}`.length !== 6;

  const handleSetEmailCode = (e) => {
    setEmailCode(e.target.value);
  };

  const handleSubmitCode = async () => {
    if (profile?.id) {
      const { error, data } = await updateUser({
        id: profile.id,
        data: {
          data: {
            email,
            confirmationToken: emailCode,
          },
        },
      });

      if (error) {
        console.error("handleSubmitCode error", error);
        if (error?.data?.error?.message === "token.invalid") {
          createEventNotification({ event: "error", title: "Invalid code" });
        } else {
          createEventNotification(handleApiError(error));
        }
        return;
      }

      if (data?.id) {
        onLogin();
        createEventNotification({
          event: "success",
          title: "Success!",
        });
      }
    } else {
      loginWithEmail({ confirmation: emailCode, email: email.toLowerCase() });
    }
  };

  const handleResend = useCallback(() => {
    if (counter) return;

    resend({ email })
      .unwrap()
      .then(() => {
        createEventNotification({ event: "success", message: "Code sended" });
        setCounter(60);
      })
      .catch((error) => {
        createEventNotification(handleApiError(error));
      });
  }, [counter]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (loginWithEmailResult.isError) {
      const { error } = loginWithEmailResult;
      const message = error?.data?.error.message || "Something went wrong";
      createEventNotification({
        event: "error",
        title: "Send code error",
        message,
      });
    }
    if (loginWithEmailResult.isSuccess) {
      onLogin();
      createEventNotification({
        event: "success",
        title: "Success!",
      });
    }
  }, [loginWithEmailResult]);

  return (
    <CardBox
      className={`@pg p-3 md:p-10 pb-5 @wh min-w-[320px] w-full md:w-[480px] ${
        isMobile ? "@brs border-none pt-0" : ""
      }`}
    >
      <H1 className="@ftf font-clash mb-3.5 text-center">
        Verify your idenity
      </H1>
      <P className="text-center mb-10 w-4/5 mx-auto whitespace-pre-line break-words">
        Enter 6-digit code we sent to the email address <strong>{email}</strong>
      </P>

      <div className="mb-8">
        <OtpInput onSubmit={handleSubmitCode} onChange={handleSetEmailCode} />
      </div>
      <Button
        className="font-clash font-semibold text-md text-white px-10 py-3 bg-zinc-900 hover:bg-pink-base duration-200 rounded-full cursor-pointer flex items-center justify-center mx-auto w-fit h-11.5"
        onClick={handleSubmitCode}
        disabled={disabled}
      >
        {loginWithEmailResult.isFetching ? (
          <AllIcons name="SmallLoader" className="h-5 w-5 animate-spin" />
        ) : (
          <span className="text-center">Submit</span>
        )}
      </Button>

      <div className="flex mt-4 w-full justify-center">
        <P className="@fts text-14px mr-1 leading-20px">
          You can send the code again
        </P>
        <Button
          className="@ftw font-semibold @fts text-14px text-zinc-900 leading-20px"
          variant="text"
          onClick={handleResend}
        >
          {counter ? `${counter} sec` : "Resend"}
        </Button>
      </div>
    </CardBox>
  );
};

export default EmailLoginCard;
