import React from "react";

const Bitmex = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7665 7H28.8953C28.8861 7.2001 28.8169 7.39282 28.6968 7.55312C28.1847 8.43145 27.7237 9.34563 27.2205 10.224C26.5087 11.4851 25.8429 12.7693 25.1195 14.0254C24.6611 14.9293 24.1438 15.8051 23.6547 16.6936C20.715 16.6936 17.7753 16.6936 14.8356 16.6936C14.6819 16.6936 14.5296 16.7013 14.3759 16.7103C14.2479 17.0483 14.0353 17.3441 13.8638 17.6616C13.6397 18.0252 13.4579 18.4132 13.2364 18.7768C13.0021 19.2659 12.6782 19.7038 12.4426 20.1916C11.956 21.0174 11.5015 21.8561 11.0252 22.6909C10.5284 23.5444 10.0427 24.41 9.56816 25.2874C9.36731 25.5984 9.18228 25.9194 9.01376 26.249C8.01892 27.98 7.05609 29.7265 6.05996 31.4562C5.97547 31.6422 5.86418 31.8147 5.72963 31.9684C4.02674 31.9568 2.32386 31.9684 0.620977 31.9684C0.41124 31.9724 0.202747 32.0016 0 32.0554C0 31.5843 0.31497 31.2066 0.52751 30.8109C0.783583 30.2796 1.12544 29.7866 1.38279 29.254C3.42369 25.6024 5.44283 21.938 7.47733 18.2838C7.93826 17.49 8.33389 16.6795 8.79738 15.9011C9.37611 14.8768 9.91514 13.8295 10.5182 12.8167C10.7102 12.3929 10.9689 12.0049 11.1801 11.5914C11.9304 10.256 12.6666 8.91286 13.4144 7.57744C13.5031 7.36889 13.6217 7.17437 13.7665 7V7Z"
        fill="#EB4A60"
      />
      <path
        d="M31.2972 12.3768C32.3049 10.6163 33.278 8.83656 34.2997 7.08374H39.7643C39.8439 7.08777 39.9229 7.10021 39.9999 7.12087C39.8181 7.39615 39.6657 7.68807 39.5057 7.97487C38.1877 10.3401 36.8737 12.7067 35.5634 15.0745C35.0129 16.0386 34.5007 17.0245 33.9425 17.9835L33.8874 18.0027L33.8772 18.123C31.3079 22.7323 28.7472 27.3451 26.195 31.9612C21.1785 31.9663 16.1616 31.9663 11.1443 31.9612C11.4298 31.3646 11.7679 30.7948 12.0854 30.2148C13.5143 27.5875 14.9803 24.9832 16.3887 22.3559C19.471 22.3508 22.5532 22.3508 25.6355 22.3559C27.5394 19.0398 29.4023 15.698 31.2921 12.3768H31.2972Z"
        fill="#595FB5"
      />
    </svg>
  );
};

export default Bitmex;
