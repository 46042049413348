import React from "react";

const Trade = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 20C8.35786 20 5 16.6421 5 12.5C5 8.35786 8.35786 5 12.5 5C16.6421 5 20 8.35786 20 12.5C20 16.6421 16.6421 20 12.5 20ZM13.3088 14.1365V10.8638C13.3088 9.96003 14.0859 9.2274 15.0444 9.2274C16.0029 9.2274 16.7799 9.96003 16.7799 10.8638C16.7799 11.7675 16.0029 12.5001 15.0444 12.5001V13.3183C16.4821 13.3183 17.6477 12.2194 17.6477 10.8638C17.6477 9.50816 16.4821 8.40922 15.0444 8.40922C13.6066 8.40922 12.4411 9.50816 12.4411 10.8638V14.1365C12.4411 15.0402 11.664 15.7729 10.7055 15.7729C9.74701 15.7729 8.96998 15.0402 8.96998 14.1365C8.96998 13.2328 9.74701 12.5001 10.7055 12.5001V11.6819C9.26775 11.6819 8.10221 12.7809 8.10221 14.1365C8.10221 15.4921 9.26775 16.591 10.7055 16.591C12.1433 16.591 13.3088 15.4921 13.3088 14.1365Z"
      />
    </svg>
  );
};

export default Trade;
