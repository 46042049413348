import React from "react";

const Filter = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18152 8.15699C8.76643 9.42364 7.57467 10.3385 6.16924 10.3385C4.41892 10.3385 3 8.91956 3 7.16924C3 5.41892 4.41892 4 6.16924 4C7.72587 4 9.02039 5.12226 9.28782 6.60182H16.6129C17.0435 6.60182 17.3925 6.95088 17.3925 7.38146C17.3925 7.81204 17.0435 8.16109 16.6129 8.16109H9.26202C9.23484 8.16109 9.20798 8.1597 9.18152 8.15699ZM8.05238 7.15784C8.05238 8.20422 7.20411 9.05249 6.15773 9.05249C5.11134 9.05249 4.26307 8.20422 4.26307 7.15784C4.26307 6.11145 5.11134 5.26318 6.15773 5.26318C7.20411 5.26318 8.05238 6.11145 8.05238 7.15784Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3059 14.8437C11.7284 16.0973 12.9137 17 14.3098 17C16.0601 17 17.479 15.5811 17.479 13.8307C17.479 12.0804 16.0601 10.6615 14.3098 10.6615C12.7457 10.6615 11.4463 11.7944 11.1874 13.2844H4.00243C3.57185 13.2844 3.22279 13.6335 3.22279 14.064C3.22279 14.4946 3.57185 14.8437 4.00243 14.8437H11.3059ZM12.4266 13.8192C12.4266 14.8656 13.2749 15.7139 14.3213 15.7139C15.3677 15.7139 16.2159 14.8656 16.2159 13.8192C16.2159 12.7728 15.3677 11.9246 14.3213 11.9246C13.2749 11.9246 12.4266 12.7728 12.4266 13.8192Z"
      />
    </svg>
  );
};

export default Filter;
