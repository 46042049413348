import React from "react";

const Change = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.07827 9.46926C5.31927 9.24035 5.55408 9.00524 5.79508 8.77633C6.02372 8.55978 6.3574 8.53504 6.60458 8.70827C6.85176 8.87532 6.9568 9.19704 6.8394 9.46926C6.80232 9.56207 6.74053 9.65487 6.66637 9.72293C6.08551 10.2983 5.49847 10.8675 4.91143 11.4367C4.59628 11.746 4.21934 11.7399 3.90419 11.4181C3.34187 10.8428 2.77336 10.2612 2.21104 9.6858C1.93915 9.40121 1.92679 8.99906 2.1925 8.7392C2.46439 8.47317 2.86605 8.49173 3.15648 8.7887C3.37276 9.01143 3.59522 9.23416 3.81768 9.45689C3.82386 9.46307 3.83622 9.46307 3.86093 9.47545C3.87947 9.34552 3.89801 9.2156 3.91655 9.08567C4.27495 6.57378 6.20292 4.5321 8.71175 4.1052C11.0661 3.70305 13.0126 4.45785 14.5327 6.30156C14.7428 6.55522 14.749 6.83982 14.5698 7.06255C14.335 7.35952 13.9024 7.37189 13.6614 7.06874C13.2783 6.58616 12.8458 6.17163 12.3205 5.8561C9.4162 4.1052 5.71475 5.76948 5.09063 9.10423C5.07209 9.22178 5.05973 9.33315 5.0412 9.4507C5.05355 9.46307 5.06591 9.46926 5.07827 9.46926Z" />
      <path d="M14.4776 10.5458C14.2798 10.7376 14.113 10.8923 13.9461 11.0532C13.8782 11.1212 13.8102 11.1955 13.736 11.2573C13.458 11.5048 13.0687 11.4924 12.8215 11.2326C12.5743 10.9727 12.5805 10.5829 12.8462 10.3169C13.3653 9.80958 13.8843 9.30844 14.4034 8.80111C14.4899 8.72068 14.5702 8.63406 14.6568 8.55363C14.9348 8.29997 15.3118 8.30615 15.5713 8.56601C16.1645 9.17232 16.7577 9.77864 17.351 10.385C17.5981 10.6386 17.5858 11.0408 17.3386 11.2883C17.0914 11.5357 16.6898 11.5419 16.4364 11.2883C16.2263 11.0779 16.0162 10.8614 15.8123 10.651C15.7752 10.6139 15.732 10.5768 15.6702 10.5149C15.6578 10.5829 15.6455 10.6263 15.6455 10.6696C15.3488 13.4908 13.1304 15.7243 10.3188 15.9718C8.14368 16.1636 6.35166 15.3778 4.97984 13.6702C4.76356 13.398 4.80064 13.0453 5.06017 12.835C5.31353 12.6308 5.67193 12.6803 5.89439 12.9587C6.94488 14.2704 8.31053 14.9138 9.98514 14.8334C12.2653 14.7282 14.1377 13.002 14.4652 10.7438C14.4714 10.7005 14.4714 10.6448 14.4776 10.5458Z" />
    </svg>
  );
};

export default Change;
