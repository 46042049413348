import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useSendEmailCodeMutation } from "~services/backend/profiles";
import TextInput from "~components/input/text-input";
import AllIcons from "~components/ui/all-icons";
import Button from "~components/ui/button";
import CardBox from "~components/ui/card-box";
import H1 from "~components/ui/typography/h1";
import P from "~components/ui/typography/p";
import createEventNotification from "~utils/create-event-notification";
import { validateEmail } from "~utils/vanilla";
import walletConfigs from "~web3/configs/wallets";
import WalletButton from "./WalletButton";

const LoginCard = ({
  loginMethod = "both",
  onMessageSended = () => {},
  onSelectWallet = () => {},
  email,
  setEmail,
  loadingWalletId,
  setLoadingWalletId,
}) => {
  const [sendEmailCode, sendEmailCodeResult] = useSendEmailCodeMutation();

  const handleSendEmailCode = (e) => {
    e.preventDefault();
    sendEmailCode({ email })
      .unwrap()
      .then(onMessageSended)
      .catch((error) =>
        createEventNotification({
          event: "error",
          message: error?.data?.error.message || "Something went wrong",
        }),
      );
  };

  const handleChangeEmail = (e) => setEmail(e.target.value);

  const emailError = useMemo(
    () => (email.length ? validateEmail(email) : false),
    [email],
  );

  const state = useMemo(() => modalVariants[loginMethod], [loginMethod]);

  return (
    <CardBox
      className={`@pg p-10 lg:w-[480px] ${
        isMobile ? "@brs border-none pt-0" : ""
      }`}
    >
      <H1 className="@ftf font-clash mb-5 text-center">{state.title}</H1>
      <P className="text-center mb-10">{state.description}</P>

      {state.emailForm && (
        <form onSubmit={handleSendEmailCode}>
          <TextInput
            placeholder="Enter email address"
            value={email}
            onChange={handleChangeEmail}
            inputsClassName={`@ftw font-medium @ttc text-zinc-900 placeholder-zinc-base @ani items-stretch @bdc bg-white @brc border-[#DCDCDC] hover:border-zinc-900 focus:border-zinc-900 text-16px @brr rounded-[10px] transition-all 
            ${!!emailError && "@brc border-[#FF0302]"}
            `}
          />
          <Button
            variant="rounded"
            className="h-11 flex justify-center items-center disabled:bg-stone-base disabled:zinc-base @ttc text-white @bdc bg-zinc-900 hover:bg-pink-base md:hover:scale-105 md:hover:rotate-2"
            disabled={!email.length || emailError}
            onClick={handleSendEmailCode}
          >
            {sendEmailCodeResult.isFetching ? (
              <AllIcons name="SmallLoader" className="h-5 w-5 animate-spin" />
            ) : (
              state.buttonText
            )}
          </Button>
        </form>
      )}

      {state.separator && (
        <div className="flex justify-center items-center py-8 text-zinc-base text-14px leading-[17px] ">
          <hr className="border-[#DCDCDC] w-full" />
          <span className="px-3">Or</span>
          <hr className="border-[#DCDCDC] w-full" />
        </div>
      )}

      {state.walletButtons && (
        <div className="flex flex-col gap-5">
          {walletConfigs.map((wallet) => (
            <WalletButton
              title={wallet.title}
              icon={wallet.icon}
              background={wallet.background}
              key={wallet.uid}
              connectorId={wallet.connectorId}
              uid={wallet.uid}
              onClick={onSelectWallet}
              isLoading={loadingWalletId === wallet.uid}
              setLoadingWalletId={setLoadingWalletId}
            />
          ))}

          <Button
            variant="text"
            href="https://www.youtube.com/watch?v=GNPz-Dv5BjM"
            className="mt-3 flex justify-center"
          >
            How to get a MetaMask wallet?
          </Button>
        </div>
      )}
    </CardBox>
  );
};

export default LoginCard;

const modalVariants = {
  email: {
    title: "Connect your email",
    description:
      "Connect your mail for a convenient quick login to the platform.",
    emailForm: true,
    walletButtons: false,
    separator: false,
    buttonText: "Connect",
  },
  wallet: {
    title: "Wallet is not connected",
    description: "Connect your wallet to take full advantage of the platform.",
    emailForm: false,
    walletButtons: true,
    separator: false,
    buttonText: "Continue with email",
  },
  both: {
    title: "Log in",
    description: (
      <>
        By creating an account you agree <br /> to our{" "}
        <strong>Terms of Service</strong> and <strong>Privacy Policy</strong>.
      </>
    ),
    emailForm: true,
    walletButtons: true,
    separator: true,
    buttonText: "Continue with email",
  },
};
