const Chevron = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="6 0 12 24"
    fill="none"
    stroke="currentColor"
    className={className}
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

export default Chevron;
