import { localStorage, readJson } from "./common";

const authTokensStorageKey = "authTokensStorage";

export const authTokensManager = {
  getStorage() {
    return readJson(localStorage[authTokensStorageKey], {});
  },

  setAccountStorage(address, accountStorage) {
    const storage = this.getStorage() || {};

    storage[address] = accountStorage;

    localStorage.setItem(authTokensStorageKey, JSON.stringify(storage));
  },

  getAccountStorage(address) {
    const accountStorage = this.getStorage()[address] || {};

    return {
      jwt: accountStorage.jwt,
      signature: accountStorage.signature,
    };
  },

  setToken(address, tokenKey, token) {
    const accountStorage = this.getAccountStorage(address.toLowerCase());

    accountStorage[tokenKey] = token;

    this.setAccountStorage(address.toLowerCase(), accountStorage);
  },

  getToken(address, tokenKey) {
    return this.getAccountStorage(address.toLowerCase())[tokenKey];
  },

  getJwt(address) {
    if (!address) {
      address = this.getCurrentAccount();
    }
    return address
      ? this.getToken(address, "jwt")
      : this.getToken("default", "jwt");
  },

  getCurrentAccount() {
    return localStorage.getItem("currentWeb3Account");
  },

  setJwt(address, jwt) {
    if (!address) {
      address = this.getCurrentAccount();
    }
    return this.setToken(address, "jwt", jwt);
  },

  setCurrentAccount(address) {
    address
      ? localStorage.setItem("currentWeb3Account", address.toLowerCase())
      : localStorage.removeItem("currentWeb3Account");
  },

  clearCurrentAccount() {
    localStorage.removeItem("currentWeb3Account");
    this.setAccountStorage("default", undefined);
  },

  removeJwt(address) {
    if (!address) {
      address = this.getCurrentAccount();
    }
    console.log("removeJwt");
    return this.setToken(address, "jwt", null);
  },
};
