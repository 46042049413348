import React from "react";

const Back = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09055 14.9266C7.12941 14.8468 7.18207 14.7721 7.2485 14.7059L11.7312 10.2393C12.0514 9.92023 12.5706 9.92023 12.8908 10.2393C13.211 10.5584 13.211 11.0757 12.8908 11.3947L9.58141 14.6922H21.3847C21.7706 14.6922 22.0835 15.0051 22.0835 15.3911C22.0835 15.7771 21.7707 16.0899 21.3847 16.0899H9.77209L12.9221 19.2287C13.2424 19.5478 13.2424 20.0651 12.9221 20.3841C12.6019 20.7032 12.0828 20.7032 11.7625 20.3841L7.24016 15.878C6.98174 15.6205 6.93187 15.2339 7.09055 14.9266Z"
      />
    </svg>
  );
};

export default Back;
