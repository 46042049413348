import React from "react";

const Github = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M10.1479 3C6.20088 3 3 6.21339 3 10.1774C3 13.3479 5.04814 16.0377 7.88979 16.9876C8.24723 17.0537 8.3777 16.8321 8.3777 16.6417C8.3777 16.4711 8.37144 16.0198 8.36787 15.4211C6.3796 15.8545 5.95961 14.4587 5.95961 14.4587C5.63523 13.6294 5.16609 13.4087 5.16609 13.4087C4.51644 12.9637 5.21435 12.9726 5.21435 12.9726C5.93191 13.0235 6.30901 13.7125 6.30901 13.7125C6.94704 14.809 7.98272 14.4926 8.39021 14.3085C8.45455 13.8448 8.63952 13.5284 8.84416 13.3488C7.25712 13.1674 5.58787 12.5517 5.58787 9.8021C5.58787 9.01841 5.86668 8.3777 6.3242 7.87549C6.25003 7.69409 6.00518 6.96402 6.3939 5.97659C6.3939 5.97659 6.9944 5.78357 8.35983 6.71202C8.92994 6.55296 9.54117 6.47343 10.1497 6.47075C10.7565 6.47432 11.3686 6.55296 11.9396 6.71292C13.3041 5.78446 13.9037 5.97748 13.9037 5.97748C14.2933 6.96581 14.0485 7.69499 13.9752 7.87639C14.4336 8.37859 14.7098 9.0193 14.7098 9.80299C14.7098 12.5598 13.0387 13.1665 11.4463 13.3443C11.7028 13.5659 11.9316 14.0038 11.9316 14.6731C11.9316 15.6329 11.9226 16.4067 11.9226 16.6417C11.9226 16.8339 12.0513 17.0573 12.4141 16.9867C15.2513 16.0359 17.2976 13.347 17.2976 10.1774C17.2976 6.21339 14.0967 3 10.1479 3Z" />
    </svg>
  );
};

export default Github;
