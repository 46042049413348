import React from "react";

const YoutubeColored = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11427_260430)">
      <path
        d="M19.5818 5.18581C19.4688 4.76306 19.2459 4.37724 18.9356 4.06695C18.6253 3.75666 18.2384 3.53279 17.8136 3.41774C16.25 3 10 3 10 3C10 3 3.75 3 2.18636 3.41774C1.76161 3.53279 1.37472 3.75666 1.06441 4.06695C0.754097 4.37724 0.531255 4.76306 0.418182 5.18581C4.96705e-08 6.74613 0 10 0 10C0 10 4.96705e-08 13.2539 0.418182 14.8142C0.531255 15.2369 0.754097 15.6228 1.06441 15.933C1.37472 16.2433 1.76161 16.4672 2.18636 16.5823C3.75 17 10 17 10 17C10 17 16.25 17 17.8136 16.5823C18.2384 16.4672 18.6253 16.2433 18.9356 15.933C19.2459 15.6228 19.4688 15.2369 19.5818 14.8142C20 13.2539 20 10 20 10C20 10 20 6.74613 19.5818 5.18581Z"
        fill="#FF0302"
      />
      <path
        d="M7.97437 12.9738V7.02612L13.2016 9.99999L7.97437 12.9738Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_11427_260430">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default YoutubeColored;
