import React from "react";

const Kucoin = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.40011 3.37741C7.27624 3.37741 8.80023 4.9014 8.80023 6.77752V33.1991C8.80023 35.0752 7.27624 36.5992 5.40011 36.5992C3.52399 36.5992 2 35.0752 2 33.1991V6.77752C2.00577 4.9014 3.52399 3.37741 5.40011 3.37741V3.37741Z"
          fill="#3F97E2"
        />
        <path
          d="M27.3534 1.32226C28.6754 2.65575 28.6638 4.80896 27.3303 6.12513L8.54598 24.7132C7.21249 26.0351 5.05928 26.0236 3.74311 24.6901C2.42116 23.3566 2.4327 21.2034 3.7662 19.8872L22.5505 1.30494C23.884 -0.0227819 26.0372 -0.0112364 27.3534 1.32226V1.32226Z"
          fill="#3F97E2"
        />
        <path
          d="M27.3534 38.6313C28.6754 37.2978 28.6638 35.1446 27.3303 33.8284L8.54598 15.2461C7.21249 13.9242 5.05928 13.9357 3.74311 15.2692C2.42116 16.6027 2.4327 18.7559 3.7662 20.0721L22.5505 38.6544C23.884 39.9821 26.0372 39.9705 27.3534 38.6313V38.6313Z"
          fill="#3F97E2"
        />
        <path
          d="M37.6002 24.3668C38.9221 25.7003 38.9164 27.8535 37.5886 29.1755L27.7058 39.0122C26.3723 40.3341 24.2191 40.3283 22.8971 39.0006C21.5752 37.6671 21.581 35.5139 22.9087 34.192L32.7915 24.3553C34.1192 23.0333 36.2725 23.0391 37.6002 24.3668V24.3668Z"
          fill="#3F97E2"
        />
        <path
          d="M37.6002 15.633C38.9221 14.2995 38.9164 12.1463 37.5886 10.8243L27.7058 0.987679C26.3723 -0.334267 24.2191 -0.328494 22.8971 0.999224C21.5752 2.33272 21.581 4.48593 22.9087 5.80788L32.7915 15.6445C34.125 16.9665 36.2782 16.9665 37.6002 15.633Z"
          fill="#3F97E2"
        />
        <path
          d="M25.3274 23.4321C27.2212 23.4321 28.7564 21.8969 28.7564 20.003C28.7564 18.1092 27.2212 16.5739 25.3274 16.5739C23.4337 16.5739 21.8985 18.1092 21.8985 20.003C21.8985 21.8969 23.4337 23.4321 25.3274 23.4321Z"
          fill="#3F97E2"
        />
      </g>
      <defs></defs>
    </svg>
  );
};

export default Kucoin;
