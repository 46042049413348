import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import Button from "~components/ui/button";
import Modal from "~components/popups/modal";
import {
  selectJwt,
  selectSignModalOpened,
  selectIsAccountBindingInProgress,
} from "~store/authSlice/selectors";
import useWeb3Auth from "src/hooks/web3/use-web3-auth";
import {
  logout,
  finishSigning,
  startBinding,
  finishBinding,
  startSigning,
  web3AccountUpdated,
} from "~store/authSlice";
import SignModal from "./SignModal";
import {
  useLazyLoginWithWeb3Query,
  useUpdateUserMutation,
} from "~services/backend/profiles";
import createEventNotification, {
  handleApiError,
} from "~utils/create-event-notification";
import { signMessage } from "~web3/utils/web3-react";
import { useWeb3React } from "@web3-react/core";
import { authTokensManager } from "~utils/localStorage/authTokensManager";
import EmailLoginCard from "~components/auth/EmailLoginCard";
import useProfile from "~services/backend/profiles/useProfile";
import LoginCard from "~components/auth/LoginCard";

// TODO refucktor + optimization
const ConnectWalletButton = ({
  title = "Login",
  loginMethod = "wallet",
  autoOpenSign = true,
}) => {
  const { library } = useWeb3React();
  const { login: web3login, logout: web3logout, account } = useWeb3Auth();
  const { profile } = useProfile();

  const dispatch = useDispatch();
  const storedJwt = useSelector(selectJwt);
  const isBinding = useSelector(selectIsAccountBindingInProgress);

  const [loginWithSignature] = useLazyLoginWithWeb3Query();
  const [updateUser] = useUpdateUserMutation();

  const [email, setEmail] = useState("");
  const [loadingWalletId, setLoadingWalletId] = useState(null);

  const [showLogin, setShowLogin] = useState(false);
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const signModalOpened = useSelector(selectSignModalOpened);

  const onMessageSended = () => {
    setShowLogin(false);
    setShowEmailLogin(true);

    createEventNotification({
      event: "success",
      title: "Confirmation code sended!",
    });
  };

  const onSelectWallet = async (...props) => {
    try {
      // Binding - процесс логина в веб3 с емейл-акка
      // Единственный кейс, когда эта функция
      // вызывается юзером с profile !== null это биндинг
      if (profile?.id) dispatch(startBinding());
      await web3login(...props);
      setLoadingWalletId(null);
      setShowLogin(false);
    } catch (err) {
      dispatch(finishBinding());
      dispatch(finishSigning());
      setLoadingWalletId(null);
    }
  };

  useEffect(() => {
    if (!isBinding || !account || !profile) return;

    // Процесс биндинга, точно есть профиль и аккаунт в веб3
    if (profile.address) {
      if (account.toLowerCase() === profile.address) {
        authTokensManager.setJwt(account, storedJwt);
        authTokensManager.setCurrentAccount(account);
        dispatch(web3AccountUpdated({ account }));
        dispatch(finishBinding());
      } else {
        createEventNotification({
          event: "error",
          title: "This Subclub account has another web3 address",
        });

        web3logout();
        dispatch(finishBinding());
      }
    } else {
      dispatch(startSigning());
    }
  }, [account]);

  // TODO: вынести в хук отдельный
  const initLoginWithSignature = async () => {
    try {
      if (!account) {
        dispatch(finishBinding());
        dispatch(finishSigning());
        web3logout();
        return;
      }

      const expires = Date.now() + 15 * 60 * 1000; // 15 minutes

      const signature = await signMessage({
        provider: library,
        account,
        expires,
      });

      // CASE1: Adding wallet to email account
      if (profile && !profile.address && account && signature) {
        const { error } = await updateUser({
          id: profile?.id,
          data: {
            data: {
              address: account,
              web3Signature: signature,
              expires: expires,
            },
          },
        });

        if (error) {
          createEventNotification({
            event: "error",
            title: "Can't login in your web3 account.",
            message: error.message || error.data?.error?.message,
          });

          dispatch(finishBinding());
          dispatch(finishSigning());
          web3logout();
          return;
        }

        createEventNotification({
          event: "success",
          title: "Wallet succesfully linked.",
        });

        authTokensManager.setJwt(account, storedJwt);
        authTokensManager.setCurrentAccount(account);
        dispatch(web3AccountUpdated({ account }));
        dispatch(finishBinding());
        dispatch(finishSigning());

        return;
      }

      // CASE2: Adding wallet to email+wallet account
      if (profile?.address && account) {
        if (profile.address !== account.toLowerCase()) {
          throw new Error("This Subclub account has another web3 address");
        } else {
          /* Будет обработано в useEffect выше, без подписи */
        }

        return;
      }

      // CASE3: Register by web3
      const { data: { jwt } = {} } = await loginWithSignature({
        signature,
        expires,
        address: account,
      });

      authTokensManager.setJwt(account, jwt);
      dispatch(finishBinding());
      dispatch(finishSigning());
    } catch (err) {
      createEventNotification({
        event: "error",
        title: "Can't login in your web3 account",
        message: err.message,
      });
      console.error(err);

      dispatch(finishSigning());
      dispatch(finishBinding());
      web3logout();
    }
  };

  return (
    <>
      <Modal
        show={showLogin}
        setShow={setShowLogin}
        RenderCard={LoginCard}
        renderCardProps={{
          loginMethod, // methods[`wallet`],
          onMessageSended,
          onSelectWallet,
          email,
          setEmail,
          loadingWalletId,
          setLoadingWalletId,
        }}
        containerClasses={"@zi z-90"}
        renderCardContainerClasses={isMobile && "@ow overflow-scroll pb-8"}
      />

      <Modal
        show={showEmailLogin}
        setShow={setShowEmailLogin}
        RenderCard={EmailLoginCard}
        renderCardProps={{
          onLogin: () => setShowEmailLogin(false),
          email,
          profile,
        }}
        renderCardContainerClasses={isMobile && "@ow overflow-scroll pb-8"}
      />

      <Modal
        show={signModalOpened && autoOpenSign}
        setShow={() => {
          if (!signModalOpened) return;
          web3logout();
          dispatch(finishBinding());
          dispatch(finishSigning());
        }}
        RenderCard={SignModal}
        renderCardProps={{
          login: initLoginWithSignature,
          logout: () => {
            // TODO: Hack, на мобиле вылетает экшен логаута при запуске
            if (!signModalOpened) return;
            dispatch(logout());
            web3logout();
          },
          isWC: library?.provider?.wc,
        }}
        renderCardContainerClasses="@ow overflow-scroll pb-8"
        containerClasses={autoOpenSign && "@zi z-90"}
      />

      <Button
        className="flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          setShowLogin(true);
        }}
        variant="rounded"
      >
        {title}
      </Button>
    </>
  );
};

export default ConnectWalletButton;
