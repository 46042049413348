import { useEffect } from "react";
import { useRouter } from "next/router";
import MainLayout from "~components/layout/main";
import { GTMPageView, loadScripts } from "~utils/vanilla/html";
import { MetaBlock } from "~utils/seo";
import "../styles/tailwind.css";
import "../styles/fonts.css";
import "../styles/select-emoji-table.css";
import AcceptCookiesMessage from "~components/ui/accept-cookies-message";
import { referralManager } from "~utils/localStorage/referralManager";
import wrapper from "src/store";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "~web3/utils/web3-react";

function App({ Component, pageProps }) {
  const router = useRouter();
  const { events, query } = router;

  useEffect(() => {
    Promise.all([
      loadScripts({
        u: "/js/gtm.js",
        id: process.env.NEXT_PUBLIC_GTM_KEY || "GTM-XXXXXXX",
      }),
    ]).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url);
    const referrer = query["utm_referrer"] || query["utm_referer"];
    events.on("routeChangeComplete", handleRouteChange);

    if (window && referrer) {
      window.utmReferer = referrer;
    }

    if (query.ar) {
      const { ar, others } = query;
      referralManager.setReferralToStorage("admin", ar);

      router.replace({
        pathname: router.pathname,
        query: others,
      });
    }

    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <MetaBlock />
      {pageProps.isLanding ? (
        <Component {...pageProps} />
      ) : (
        <Web3ReactProvider getLibrary={getLibrary}>
          <MainLayout>
            <Component {...pageProps} />
          </MainLayout>
        </Web3ReactProvider>
      )}
      <AcceptCookiesMessage containerClasses="fixed bottom-[20px] left-[90px]" />
      <div id="modal" />
      <div id="notification" />
    </>
  );
}

export default wrapper.withRedux(App);
