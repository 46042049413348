import React from "react";

const Candles = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 7C10.2985 7 10 7.29848 10 7.66666V10.4173C10.2131 10.3625 10.4365 10.3333 10.6667 10.3333C10.8969 10.3333 11.1202 10.3625 11.3333 10.4173V7.66666C11.3333 7.29848 11.0349 7 10.6667 7ZM11.3333 19.5826C11.1202 19.6375 10.8969 19.6666 10.6667 19.6666C10.4365 19.6666 10.2131 19.6375 10 19.5826V22.3333C10 22.7015 10.2985 22.9999 10.6667 22.9999C11.0349 22.9999 11.3333 22.7015 11.3333 22.3333V19.5826Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 9C9.1939 9 8 10.1939 8 11.6667V17C8 18.4727 9.1939 19.6666 10.6667 19.6666C12.1394 19.6666 13.3333 18.4727 13.3333 17V11.6667C13.3333 10.1939 12.1394 9 10.6667 9ZM10.6666 10.5238C9.93023 10.5238 9.33327 11.1207 9.33327 11.8571V16.8095C9.33327 17.5459 9.93023 18.1428 10.6666 18.1428C11.403 18.1428 11.9999 17.5459 11.9999 16.8095V11.8571C11.9999 11.1207 11.403 10.5238 10.6666 10.5238Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3337 7C18.9655 7 18.667 7.29848 18.667 7.66666V13.084C18.8801 13.0292 19.1034 13 19.3336 13C19.5638 13 19.7872 13.0292 20.0003 13.084V7.66666C20.0003 7.29848 19.7018 7 19.3337 7ZM20.0003 20.9159C19.7872 20.9708 19.5638 21 19.3336 21C19.1034 21 18.8801 20.9708 18.667 20.916V22.3333C18.667 22.7015 18.9655 22.9999 19.3337 22.9999C19.7018 22.9999 20.0003 22.7015 20.0003 22.3333V20.9159Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3336 13C17.8609 13 16.667 14.1939 16.667 15.6667V18.3333C16.667 19.8061 17.8609 21 19.3336 21C20.8064 21 22.0003 19.8061 22.0003 18.3333V15.6667C22.0003 14.1939 20.8064 13 19.3336 13ZM19.3335 14.3333C18.5972 14.3333 18.0002 14.9303 18.0002 15.6666V18.3333C18.0002 19.0697 18.5972 19.6666 19.3335 19.6666C20.0699 19.6666 20.6669 19.0697 20.6669 18.3333V15.6666C20.6669 14.9303 20.0699 14.3333 19.3335 14.3333Z"
      />
    </svg>
  );
};

export default Candles;
