import { hexValue } from "ethers/lib/utils";
import { DEFAULT } from "~web3/configs/constants";
import networkConfigs from "~web3/configs/networks";
import createEventNotification from "~utils/create-event-notification";
const BASE_URL = "";

export const setupNetwork = async (provider, chainId = DEFAULT) => {
  // const provider = window.ethereum;

  try {
    const hexChainId = hexValue(chainId);

    try {
      // check if the chain to connect to is installed
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChainId }], // chainId must be in hexadecimal numbers
      });

      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [networkConfigs[chainId]],
        });

        return true;
      } catch (addError) {
        // createEventNotification({
        //   event: `error`,
        //   title: `Error when add chain`,
        //   message: addError.message + ` ` + addError.code,
        // });

        console.error("wallet_addEthereumChain ", addError);
      }

      console.error("wallet_switchEthereumChain", error);
    }
  } catch (error) {
    createEventNotification({
      event: "error",
      title: "Failed to setup the network in Metamask:",
      message: error,
    });

    console.error("Failed to setup the network in Metamask:", error);
    return false;
  }

  //   if (provider && window.localStorage.connectorId === `injected`) {} else {
  //   return true;
  // }
};

export const registerToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
) => {
  const tokenAdded = await window.ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  });

  return tokenAdded;
};
