import { authTokensManager } from "~utils/localStorage/authTokensManager";

export const isObject = (data) => data && typeof data === "object";

export const isArray = (data) => data && Array.isArray(data);

export const flatItemAttributes = (data) => {
  if (!data?.attributes) return data;

  return {
    id: data?.id,
    ...data.attributes,
  };
};

export const combineHeaders = ({ withAuth }) => {
  const headers = {};

  if (withAuth) {
    const token = authTokensManager.getJwt();
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const snakeToCamel = (str) => {
  return str.replace(/([-_][a-z])/gi, (char) => {
    return char.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const transformEntriesInObj = (item) => {
  if (isObject(item) && !isArray(item)) {
    const entries = Object.entries(item).map((entry) => {
      const key = snakeToCamel(entry[0]);

      let value = entry[1];

      if (isObject(value)) {
        value = transformEntriesInObj(value);
      } else if (isArray(value)) {
        value = value.map((elem) => transformEntriesInObj({ item: elem }));
      }

      return [key, value];
    });

    return Object.fromEntries(entries);
  }

  return item;
};

export const populatePost = [
  "comments",
  "comments.likes",
  "comments.likes.user",
  "comments.likes.user.image",
  "comments.user",
  "comments.user.image",
  "user",
  "user.image",
  "media",
];

export const postsPopulate = {
  media: {
    populate: "*",
  },
  user: {
    populate: ["image"],
  },
  subscription_level: "*",
};

export const profilePopulate = {
  image: "*",
  cover: "*",
  followings: {
    populate: {
      author: { populate: ["image"] },
      subscription_level: "*",
    },
  },
  followers: {
    populate: {
      user: {
        populate: ["image"],
      },
      subscription_level: "*",
    },
  },
  subscription_levels: "*",
};

export const populateComment = ["user", "user.image", "media"];
