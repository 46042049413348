import { getCountAfterDot, hasZeroAfterDot } from "../utils";

const getFormattedPrice = (price) => {
  if (price === null || price === undefined) return 0;

  if (hasZeroAfterDot(price) && price < 0) {
    const countAfterDot = getCountAfterDot(price);

    return (
      new Intl.NumberFormat("en-US", {
        minimumFractionDigits: countAfterDot,
        maximumFractionDigits: countAfterDot,
      }).format(price) || 0
    );
  }

  const options = minFraction;
  const formattedPrice = new Intl.NumberFormat("en-US", options).format(price);
  return formattedPrice || 0;
};

export default getFormattedPrice;

const minFraction = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
