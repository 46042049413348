import React from "react";

const ZenfuseTitle = ({ className }) => (
  <svg
    viewBox="0 0 89 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M0.674774 18.1314C0.519143 18.1314 0.386858 18.0769 0.277916 17.9679C0.168974 17.8589 0.114502 17.7266 0.114502 17.5709V16.2163C0.114502 16.0762 0.137849 15.9516 0.184538 15.8426C0.24679 15.7336 0.332386 15.6013 0.441328 15.4456L6.39421 8.46265H0.86153C0.705899 8.46265 0.573614 8.40816 0.464672 8.29917C0.35573 8.17461 0.301258 8.03449 0.301258 7.87879V6.54759C0.301258 6.39189 0.35573 6.25955 0.464672 6.15057C0.573614 6.04158 0.705899 5.98709 0.86153 5.98709H9.75584C9.91147 5.98709 10.0438 6.04158 10.1527 6.15057C10.2616 6.25955 10.3161 6.39189 10.3161 6.54759V7.99556C10.3161 8.12012 10.2772 8.24468 10.1994 8.36923C10.1371 8.49379 10.0671 8.61056 9.98928 8.71955L4.15312 15.6558H10.2227C10.3784 15.6558 10.5106 15.7103 10.6196 15.8193C10.7285 15.9283 10.783 16.0684 10.783 16.2397V17.5709C10.783 17.7266 10.7285 17.8589 10.6196 17.9679C10.5262 18.0769 10.3939 18.1314 10.2227 18.1314H0.674774Z" />
    <path d="M18.2619 18.3649C16.5188 18.3649 15.1337 17.8667 14.1065 16.8702C13.0949 15.8738 12.558 14.4569 12.4957 12.6197L12.4724 12.0359C12.4724 10.0897 12.9782 8.55607 13.9898 7.43506C15.0169 6.31405 16.4332 5.75354 18.2385 5.75354C20.0905 5.75354 21.5145 6.31405 22.5106 7.43506C23.5222 8.55607 24.028 10.0507 24.028 11.9191V12.4095C24.028 12.5652 23.9735 12.6976 23.8646 12.8066C23.7556 12.9156 23.6156 12.97 23.4444 12.97H15.6939V13.1569C15.7251 14.0288 15.9585 14.7528 16.3943 15.3288C16.8456 15.8893 17.4604 16.1696 18.2385 16.1696C19.1256 16.1696 19.8493 15.8115 20.4096 15.0953C20.5496 14.924 20.6586 14.8228 20.7364 14.7917C20.8142 14.745 20.9465 14.7216 21.1332 14.7216H23.1876C23.3276 14.7216 23.4444 14.7605 23.5377 14.8384C23.6467 14.9162 23.7011 15.0252 23.7011 15.1654C23.7011 15.539 23.4755 15.9828 23.0242 16.4966C22.5884 16.9948 21.9581 17.4307 21.1332 17.8044C20.3084 18.1781 19.3513 18.3649 18.2619 18.3649ZM20.8064 10.9849V10.9382C20.8064 10.0196 20.573 9.28784 20.1061 8.7429C19.6548 8.1824 19.0322 7.90215 18.2385 7.90215C17.4448 7.90215 16.8223 8.1824 16.3709 8.7429C15.9196 9.28784 15.6939 10.0196 15.6939 10.9382V10.9849H20.8064Z" />
    <path d="M27.0657 18.1314C26.9101 18.1314 26.7778 18.0769 26.6688 17.9679C26.5599 17.8589 26.5054 17.7266 26.5054 17.5709V6.54759C26.5054 6.37633 26.5599 6.24398 26.6688 6.15057C26.7778 6.04158 26.9101 5.98709 27.0657 5.98709H29.0033C29.1745 5.98709 29.3068 6.04158 29.4002 6.15057C29.5091 6.24398 29.5636 6.37633 29.5636 6.54759V7.50512C30.5129 6.3374 31.8124 5.75354 33.4621 5.75354C34.8939 5.75354 36.03 6.22063 36.8704 7.1548C37.7108 8.08898 38.1311 9.37347 38.1311 11.0083V17.5709C38.1311 17.7266 38.0766 17.8589 37.9676 17.9679C37.8743 18.0769 37.742 18.1314 37.5708 18.1314H35.4698C35.3141 18.1314 35.1818 18.0769 35.0729 17.9679C34.964 17.8589 34.9095 17.7266 34.9095 17.5709V11.1484C34.9095 10.2454 34.6838 9.54474 34.2325 9.04651C33.7967 8.53271 33.1664 8.27582 32.3416 8.27582C31.5479 8.27582 30.9098 8.53271 30.4273 9.04651C29.9449 9.56031 29.7036 10.2609 29.7036 11.1484V17.5709C29.7036 17.7266 29.6492 17.8589 29.5402 17.9679C29.4468 18.0769 29.3146 18.1314 29.1434 18.1314H27.0657Z" />
    <path d="M42.8038 18.1314C42.6482 18.1314 42.5159 18.0769 42.4069 17.9679C42.298 17.8589 42.2435 17.7266 42.2435 17.5709V8.48601H40.3526C40.197 8.48601 40.0647 8.43151 39.9558 8.32252C39.8468 8.21354 39.7923 8.0812 39.7923 7.9255V6.54759C39.7923 6.39189 39.8468 6.25955 39.9558 6.15057C40.0647 6.04158 40.197 5.98709 40.3526 5.98709H42.2435V5.05291C42.2435 3.63608 42.6248 2.58513 43.3874 1.90007C44.15 1.19944 45.3017 0.849121 46.8424 0.849121H48.4532C48.6088 0.849121 48.7411 0.903614 48.8501 1.0126C48.959 1.12159 49.0135 1.25393 49.0135 1.40963V2.78754C49.0135 2.94323 48.959 3.07557 48.8501 3.18456C48.7411 3.29355 48.6088 3.34804 48.4532 3.34804H46.9592C46.3522 3.34804 45.9242 3.49595 45.6752 3.79177C45.4262 4.0876 45.3017 4.5469 45.3017 5.16968V5.98709H48.2198C48.3754 5.98709 48.5077 6.04158 48.6166 6.15057C48.7256 6.25955 48.78 6.39189 48.78 6.54759V7.9255C48.78 8.0812 48.7256 8.21354 48.6166 8.32252C48.5077 8.43151 48.3754 8.48601 48.2198 8.48601H45.3017V17.5709C45.3017 17.7266 45.2472 17.8589 45.1383 17.9679C45.0449 18.0769 44.9126 18.1314 44.7414 18.1314H42.8038Z" />
    <path d="M55.1307 18.3649C53.6989 18.3649 52.5705 17.8978 51.7457 16.9636C50.9209 16.0295 50.5084 14.745 50.5084 13.1102V6.54759C50.5084 6.37633 50.5629 6.24398 50.6719 6.15057C50.7808 6.04158 50.9131 5.98709 51.0687 5.98709H53.1697C53.3254 5.98709 53.4576 6.04158 53.5666 6.15057C53.6755 6.25955 53.73 6.39189 53.73 6.54759V12.97C53.73 14.8851 54.5626 15.8426 56.2279 15.8426C57.0372 15.8426 57.6752 15.5857 58.1421 15.0719C58.6246 14.5581 58.8658 13.8575 58.8658 12.97V6.54759C58.8658 6.37633 58.9203 6.24398 59.0292 6.15057C59.1382 6.04158 59.2705 5.98709 59.4261 5.98709H61.5038C61.675 5.98709 61.8072 6.04158 61.9006 6.15057C62.0096 6.24398 62.064 6.37633 62.064 6.54759V17.5709C62.064 17.7266 62.0096 17.8589 61.9006 17.9679C61.8072 18.0769 61.675 18.1314 61.5038 18.1314H59.5662C59.4105 18.1314 59.2782 18.0769 59.1693 17.9679C59.0604 17.8589 59.0059 17.7266 59.0059 17.5709V16.6133C58.1499 17.781 56.8582 18.3649 55.1307 18.3649Z" />
    <path d="M69.7694 18.3649C68.5866 18.3649 67.5984 18.1859 66.8046 17.8278C66.0109 17.4697 65.4195 17.0571 65.0304 16.59C64.6569 16.1073 64.4702 15.6947 64.4702 15.3522C64.4702 15.1965 64.5169 15.0719 64.6102 14.9785C64.7192 14.8851 64.8437 14.8384 64.9838 14.8384H66.898C67.0225 14.8384 67.1315 14.9007 67.2248 15.0252C67.3027 15.0875 67.4738 15.2354 67.7384 15.469C68.0186 15.7025 68.3298 15.8893 68.6722 16.0295C69.0302 16.154 69.4192 16.2163 69.8394 16.2163C70.462 16.2163 70.9678 16.0995 71.3568 15.866C71.7459 15.6169 71.9405 15.2666 71.9405 14.815C71.9405 14.5037 71.8471 14.2545 71.6603 14.0677C71.4891 13.8653 71.1701 13.6862 70.7032 13.5306C70.2519 13.3593 69.5671 13.1725 68.6489 12.97C67.326 12.6898 66.3455 12.2616 65.7074 11.6856C65.0849 11.1095 64.7737 10.3466 64.7737 9.39682C64.7737 8.77404 64.9604 8.19018 65.3339 7.64525C65.7074 7.08474 66.2599 6.63322 66.9914 6.29069C67.7384 5.93259 68.6255 5.75354 69.6527 5.75354C70.711 5.75354 71.6214 5.92481 72.384 6.26734C73.1466 6.5943 73.7224 6.99132 74.1115 7.45841C74.5161 7.9255 74.7185 8.33809 74.7185 8.69619C74.7185 8.83632 74.664 8.96088 74.555 9.06986C74.4617 9.16328 74.345 9.20999 74.2049 9.20999H72.454C72.2828 9.20999 72.1506 9.14771 72.0572 9.02316C71.9482 8.94531 71.7693 8.80518 71.5202 8.60278C71.2868 8.3848 71.0222 8.21354 70.7265 8.08898C70.4308 7.96442 70.0729 7.90215 69.6527 7.90215C69.0768 7.90215 68.6333 8.0267 68.322 8.27582C68.0108 8.52493 67.8551 8.85189 67.8551 9.2567C67.8551 9.53695 67.9252 9.7705 68.0652 9.95733C68.2209 10.1442 68.5244 10.3232 68.9757 10.4945C69.427 10.6502 70.104 10.8137 71.0067 10.9849C73.808 11.5299 75.2087 12.7521 75.2087 14.6516C75.2087 15.757 74.7262 16.6523 73.7613 17.3373C72.812 18.0224 71.4813 18.3649 69.7694 18.3649Z" />
    <path d="M82.8471 18.3649C81.1041 18.3649 79.719 17.8667 78.6918 16.8702C77.6802 15.8738 77.1433 14.4569 77.081 12.6197L77.0577 12.0359C77.0577 10.0897 77.5635 8.55607 78.5751 7.43506C79.6022 6.31405 81.0185 5.75354 82.8238 5.75354C84.6758 5.75354 86.0998 6.31405 87.0959 7.43506C88.1075 8.55607 88.6133 10.0507 88.6133 11.9191V12.4095C88.6133 12.5652 88.5588 12.6976 88.4499 12.8066C88.3409 12.9156 88.2008 12.97 88.0296 12.97H80.2792V13.1569C80.3104 14.0288 80.5438 14.7528 80.9796 15.3288C81.4309 15.8893 82.0456 16.1696 82.8238 16.1696C83.7109 16.1696 84.4346 15.8115 84.9949 15.0953C85.1349 14.924 85.2439 14.8228 85.3217 14.7917C85.3995 14.745 85.5318 14.7216 85.7185 14.7216H87.7729C87.9129 14.7216 88.0297 14.7605 88.123 14.8384C88.232 14.9162 88.2864 15.0252 88.2864 15.1654C88.2864 15.539 88.0608 15.9828 87.6094 16.4966C87.1737 16.9948 86.5434 17.4307 85.7185 17.8044C84.8937 18.1781 83.9366 18.3649 82.8471 18.3649ZM85.3917 10.9849V10.9382C85.3917 10.0196 85.1583 9.28784 84.6914 8.7429C84.24 8.1824 83.6175 7.90215 82.8238 7.90215C82.0301 7.90215 81.4076 8.1824 80.9562 8.7429C80.5049 9.28784 80.2792 10.0196 80.2792 10.9382V10.9849H85.3917Z" />
  </svg>
);

export default ZenfuseTitle;
