import { transformResponseItem } from "~api";
import { backendApi } from "..";

export const permissionConfigsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createPermissionConfig: build.mutation({
      query: (data) => ({
        url: "permission-configs",
        method: "POST",
        body: data,
        params: {
          populate: ["chat", "chat.levels"],
        },
      }),

      transformResponse: transformResponseItem,
    }),

    updatePermissionConfig: build.mutation({
      query: ({ id, data }) => ({
        url: `permission-configs/${id}`,
        method: "PUT",
        body: data,
        params: {
          populate: ["chat", "chat.levels"],
        },
      }),

      transformResponse: transformResponseItem,
    }),

    getPermissionConfig: build.query({
      query: ({ userId }) => ({
        url: "permission-configs",
        params: {
          filters: {
            user: userId,
          },
          populate: ["chat", "chat.levels"],
        },
      }),

      transformResponse: (res) =>
        res?.data?.length ? transformResponseItem(res.data[0]) : null,
    }),
  }),
});

export const {
  useCreatePermissionConfigMutation,
  useUpdatePermissionConfigMutation,
  useGetPermissionConfigQuery,
} = permissionConfigsApi;
