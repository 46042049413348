import React from "react";

const Referral = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.5355 6.47142C21.3875 6.32165 21.2111 6.20284 21.0166 6.12191C20.8222 6.04097 20.6136 5.99953 20.403 6H16.0128C15.7248 6.00054 15.4486 6.11462 15.2442 6.3175L6.46767 15.0923C6.16819 15.3924 6 15.799 6 16.223C6 16.6469 6.16819 17.0536 6.46767 17.3537L10.6461 21.5321C10.9463 21.8317 11.3531 22 11.7772 22C12.2013 22 12.6081 21.8317 12.9082 21.5321L21.6812 12.7609C21.8845 12.5568 21.999 12.2808 21.9998 11.9927V7.59996C22.0011 7.39047 21.9607 7.18281 21.881 6.98906C21.8013 6.79531 21.6839 6.61936 21.5355 6.47142V6.47142ZM18.5713 10.5713C18.3453 10.5713 18.1243 10.5043 17.9364 10.3787C17.7484 10.2531 17.602 10.0747 17.5155 9.86583C17.429 9.65701 17.4063 9.42722 17.4504 9.20554C17.4945 8.98385 17.6034 8.78022 17.7632 8.62039C17.923 8.46056 18.1267 8.35172 18.3483 8.30762C18.57 8.26352 18.7998 8.28616 19.0086 8.37265C19.2175 8.45915 19.396 8.60563 19.5215 8.79357C19.6471 8.98151 19.7141 9.20246 19.7141 9.42849C19.7141 9.73159 19.5937 10.0223 19.3794 10.2366C19.1651 10.4509 18.8744 10.5713 18.5713 10.5713Z" />
    </svg>
  );
};

export default Referral;
