import { transformResponseItem } from "~api";
import { nullAddress } from "~web3/configs/constants";
import { backendApi } from "..";

export const subscriptionLevelsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionLevels: build.query({
      query: (address) => ({
        url: "subscription-levels",
        params: {
          filters: {
            user: {
              address: address || nullAddress,
            },
          },
          populate: "*",
          sort: {
            index: "asc",
          },
        },
      }),

      transformResponse: transformResponseItem,
    }),

    createSubscriptionLevel: build.mutation({
      query: ({ formData }) => ({
        url: "subscription-levels",
        method: "POST",
        body: formData,
        params: { populate: "*" },
      }),

      transformResponse: transformResponseItem,
    }),

    updateSubscriptionLevel: build.mutation({
      query: ({ id, formData }) => ({
        url: `subscription-levels/${id}`,
        method: "PUT",
        body: formData,
        params: { populate: "*" },
      }),

      transformResponse: transformResponseItem,
    }),
  }),
});

export const {
  useGetSubscriptionLevelsQuery,

  useCreateSubscriptionLevelMutation,
  useUpdateSubscriptionLevelMutation,
} = subscriptionLevelsApi;
