import Link from "next/link";
import AllIcons from "~components/ui/all-icons";
import P from "~components/ui/typography/p";
import Button from "~components/ui/button";
import LocaleButton from "../navbar/locale-button";

const Footer = ({ data = {}, className, forPlatform = false }) => {
  const {
    description,
    copyright,
    socials,
    policies,
    platform_items,
    help_items,
    resources_items,
  } = data;

  return (
    <footer
      className={`w-full relative py-8 ${
        forPlatform ? "" : "bg-black text-white placeholder:text-white"
      } ${className}`}
    >
      <div className="landing-container">
        <div className="mb-16 flex flex-col gap-16 md:mb-4 md:flex-row md:gap-4">
          <div className="flex flex-1 flex-col items-center md:items-start">
            <AllIcons name="LogoFull" className="fill-icon mb-2" />
            <P className="@ftf font-clash @ttc text-zinc-base @fts text-[14px] @mn mb-4">
              {description}
            </P>
            <div className="flex flex-row gap-3 items-center">
              {socials?.map((social, index) =>
                social.href ? (
                  <Link key={social?.name || index} href={social.href} passHref>
                    <a target={"_blank"}>
                      <AllIcons
                        name={social?.name}
                        className="w-5 hover:text-pink-base"
                      />
                    </a>
                  </Link>
                ) : null,
              )}

              <LocaleButton isDark={!forPlatform} isFooter={true} />
            </div>
          </div>
          {[platform_items, help_items, resources_items].map((item, index) => (
            <div
              key={item?.title || index}
              className="flex flex-1 flex-col items-center md:items-start"
            >
              <P className="@ftf font-clash @fts text-base @ftw font-semibold @mn mb-5">
                {item?.title}
              </P>
              <div className="flex flex-row md:flex-col md:items-start">
                {item?.links?.map((link, index) => (
                  <Button
                    key={link.name || index}
                    href={link.href}
                    className="@ftf font-clash @fts text-[14px] @ttc text-zinc-base hover:text-pink-base @tndn duration-200 @cr cursor-pointer @mn mx-3.5 md:mb-4 md:mx-0"
                  >
                    {link.name}
                  </Button>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center md:items-start">
          <P className="@ftf font-clash @ttc text-zinc-base @fts text-[14px] @mn mb-2">
            © {new Date().getFullYear()} {copyright}
          </P>
          <div className="flex gap-4">
            {policies?.map((policy, index) => (
              <Button
                key={policy.name || index}
                href={policy.href}
                className="@ftf font-clash @fts text-[14px] @ttc text-zinc-base hover:text-pink-base @tndn duration-200 @cr cursor-pointer"
              >
                {policy.name}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
