export default function Cog({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path d="M13 9.13397C13.6667 9.51888 13.6667 10.4811 13 10.866L9.25 13.0311C8.58333 13.416 7.75 12.9349 7.75 12.1651L7.75 7.83494C7.75 7.06514 8.58333 6.58401 9.25 6.96891L13 9.13397Z" />
      <circle cx="10" cy="10" r="8.5" />
    </svg>
  );
}
