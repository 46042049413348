import AllIcons from "../all-icons";
import Button from "../button";

export default function LogoButton({ href = "/home", className }) {
  return (
    <Button
      href={href}
      className={`text-zinc-900 hover:text-pink-base duration-200 ${className}`}
    >
      <div className="flex items-center">
        <AllIcons name="LogoCircle" className="w-1/6 mr-2" />
        <AllIcons name="LogoText" className="w-5/6" />
      </div>
    </Button>
  );
}
