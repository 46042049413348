import React from "react";

const CircleLogo = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#0065FF" />
      <mask
        id="mask0"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <circle cx="15" cy="15" r="15" fill="#0065FF" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M-1.5 22.0883V17.9344H-1.38462C0.639231 17.9344 2.66385 17.9344 4.68923 17.9344C4.72328 17.937 4.7574 17.9305 4.78818 17.9158C4.81896 17.901 4.84531 17.8784 4.86461 17.8502C7.09538 14.954 9.32615 12.059 11.5569 9.1652C12.0392 8.53982 12.5158 7.90982 13.005 7.28905C13.5104 6.64635 14.1796 6.36597 14.985 6.49058C15.3631 6.53806 15.7193 6.69411 16.0105 6.93986C16.3017 7.1856 16.5154 7.51049 16.6258 7.8752C16.8023 8.39559 16.9292 8.93212 17.0769 9.46174C17.5385 11.1064 17.9973 12.7506 18.4535 14.3944C18.6842 15.2098 18.9123 16.0256 19.1377 16.8417C19.1377 16.8533 19.1446 16.866 19.1538 16.8937C19.1827 16.8602 19.2046 16.8348 19.2242 16.8094C20.3781 15.3179 21.5319 13.8264 22.6858 12.3348C22.8863 12.0593 23.1508 11.8367 23.4566 11.6864C23.7624 11.536 24.1002 11.4623 24.4408 11.4717C26.8208 11.4717 29.2012 11.4717 31.5819 11.4717H31.71V15.6256H25.4838C25.453 15.623 25.422 15.6289 25.3942 15.6425C25.3663 15.6561 25.3427 15.677 25.3258 15.7029C23.5258 18.036 21.725 20.3687 19.9235 22.701C19.7112 22.9804 19.4316 23.2016 19.1108 23.3437C18.7899 23.4859 18.4383 23.5444 18.0887 23.5139C17.7391 23.4834 17.403 23.3648 17.1116 23.1691C16.8203 22.9735 16.5833 22.7072 16.4227 22.3952C16.3121 22.154 16.2259 21.9024 16.1654 21.644C15.5623 19.4902 14.9612 17.3383 14.3619 15.1883C14.1742 14.5137 13.9858 13.839 13.7965 13.1644C13.7965 13.1448 13.7815 13.1252 13.77 13.094L13.1931 13.8383L8.55577 19.8648C8.19231 20.3367 7.83346 20.811 7.46538 21.2783C7.27163 21.5354 7.01949 21.7428 6.72978 21.8832C6.44007 22.0236 6.12111 22.0931 5.79923 22.086C3.40615 22.086 1.01308 22.086 -1.38 22.086L-1.5 22.0883Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CircleLogo;
