import React from "react";

const UserIcon = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.6206 10.0283C12.2853 10.0283 13.6348 8.67883 13.6348 7.01416C13.6348 5.34948 12.2853 4 10.6206 4C8.95593 4 7.60645 5.34948 7.60645 7.01416C7.60645 8.67883 8.95593 10.0283 10.6206 10.0283Z" />
      <path d="M15.3706 14.3116C15.51 14.2564 15.6351 14.1704 15.7366 14.0601C15.8381 13.9497 15.9135 13.818 15.957 13.6745C16.0006 13.531 16.0112 13.3796 15.9881 13.2315C15.9651 13.0833 15.9089 12.9423 15.8238 12.8189C15.228 11.9581 14.4323 11.2547 13.5051 10.7688C12.5778 10.2829 11.5466 10.0291 10.4997 10.0291C9.45286 10.0291 8.42161 10.2829 7.49435 10.7688C6.56709 11.2547 5.77146 11.9581 5.17566 12.8189C5.09068 12.9425 5.03464 13.0837 5.01172 13.232C4.9888 13.3802 4.99958 13.5317 5.04327 13.6752C5.08695 13.8188 5.16241 13.9506 5.26404 14.0609C5.36567 14.1713 5.49086 14.2573 5.63031 14.3126C7.2 14.9382 8.87534 15.2562 10.5651 15.2492C12.2114 15.2397 13.8414 14.9217 15.3706 14.3116Z" />
    </svg>
  );
};

export default UserIcon;
