import { ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";

export const BIG_ZERO = ethers.utils.parseEther("0");
export const BIG_ONE = ethers.utils.parseEther("1");
export const BIG_NINE = ethers.utils.parseEther("9");
export const BIG_TEN = ethers.utils.parseEther("10");

export const formatEtherValue = (value, precision = 6) =>
  +value ? +(+formatEther(value)).toFixed(precision) : 0;
