export default function Heart({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M9.96829 4.75721C10.383 4.42687 10.7485 4.07544 11.1631 3.80133C12.2104 3.11957 13.3631 2.8806 14.6001 3.05631C16.048 3.26014 17.2147 3.96299 18.0511 5.15783C18.9929 6.50027 19.2459 7.96923 18.761 9.55064C18.4095 10.6963 17.7489 11.6662 16.9687 12.5659C15.6262 14.1192 14.0378 15.3913 12.3369 16.5299C11.6762 16.9727 10.9874 17.3663 10.3127 17.788C10.1018 17.9216 9.90503 17.9146 9.69418 17.788C7.54345 16.537 5.5333 15.1032 3.77617 13.332C2.91869 12.4745 2.16664 11.5397 1.61842 10.4433C0.732826 8.67911 0.782026 6.9501 1.86442 5.29137C2.6727 4.0403 3.85349 3.30231 5.32947 3.07037C6.50323 2.88762 7.62077 3.07037 8.64693 3.67482C9.02647 3.89973 9.35681 4.20195 9.70121 4.48309C9.81366 4.56744 9.89801 4.67989 9.96829 4.75721Z" />
    </svg>
  );
}
