import { configureStore } from "@reduxjs/toolkit";

import { createLogger } from "redux-logger";
import { createWrapper } from "next-redux-wrapper";

import { isProd } from "~configs";

import auth from "./authSlice";
import modals from "./modals";
import { backendApi } from "~services/index";

const middlewares = [backendApi.middleware];

if (
  !isProd &&
  typeof window !== "undefined" &&
  process.env.NEXT_LOGGER_ENABLED
) {
  const logger = createLogger({
    collapsed: true,
    duration: true,
    timestamp: true,
  });

  middlewares.push(logger);
}

export const makeStore = () =>
  configureStore({
    reducer: {
      auth,
      modals,
      [backendApi.reducerPath]: backendApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
  });

export default createWrapper(makeStore);
