import React from "react";

const Union = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M20.2754 12.1675L15.9968 15.9707C15.2729 16.6142 14.9109 16.936 14.6035 16.9475C14.3364 16.9575 14.0799 16.8423 13.9099 16.636C13.7143 16.3986 13.7143 15.9143 13.7143 14.9457V13.7139H12C4.91282 13.7139 3.61324 16.6077 3.44878 17.0782C3.44544 17.0877 3.42942 17.0883 3.42969 17.0782C3.45075 16.2925 3.86467 8.57106 12 8.57106H13.7143V7.27825C13.7143 6.32902 13.7143 5.85441 13.906 5.62173C14.0726 5.41958 14.3239 5.30671 14.5857 5.31651C14.887 5.32778 15.2417 5.6431 15.9512 6.27372L20.2754 10.1175C20.6742 10.472 20.8736 10.6493 20.9471 10.8586C21.0117 11.0424 21.0117 11.2427 20.9471 11.4265C20.8736 11.6358 20.6742 11.813 20.2754 12.1675Z" />
  </svg>
);

export default Union;
