import React from "react";

const Chain = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13205_273481)" />
      <path
        d="M17.1877 19.5578C16.7896 19.9666 16.4196 20.3519 16.0495 20.7373C15.5337 21.2745 15.0123 21.8175 14.4965 22.3547C13.0219 23.8904 10.8073 23.8261 9.41119 22.2146C8.20014 20.8074 8.245 18.6937 9.52893 17.3332C10.4821 16.323 11.4408 15.3246 12.4108 14.3378C13.8124 12.9014 15.971 12.9481 17.3278 14.4312C17.4736 14.5947 17.597 14.7757 17.7371 14.9509C17.7876 15.0151 17.782 15.0618 17.7259 15.1202C17.5409 15.3071 17.3615 15.4997 17.1821 15.6866C16.8457 16.0311 16.3747 16.0428 16.0271 15.7099C15.7131 15.4122 15.3599 15.2195 14.9282 15.1961C14.4124 15.1669 13.9806 15.3596 13.6218 15.7391C12.9714 16.4223 12.3155 17.0996 11.6651 17.7769C11.396 18.0572 11.1268 18.3316 10.8633 18.6119C10.0391 19.4994 10.2802 20.9008 11.3455 21.4146C11.9398 21.7007 12.6631 21.5956 13.1396 21.1168C13.7227 20.5329 14.2834 19.9315 14.8553 19.3418C14.9114 19.2834 14.9562 19.2484 15.0459 19.2834C15.6795 19.5403 16.3411 19.6396 17.0195 19.5637C17.0587 19.552 17.1036 19.5578 17.1877 19.5578Z"
        fill="white"
      />
      <path
        d="M14.2273 12.4464C14.2834 12.388 14.3058 12.353 14.3339 12.3238C15.2085 11.4129 16.0831 10.502 16.9578 9.59699C17.6642 8.86128 18.5221 8.51094 19.5201 8.5343C21.0955 8.57517 22.5701 9.9123 22.8168 11.5297C23.0018 12.7617 22.6766 13.8244 21.83 14.7119C20.8937 15.6929 19.9518 16.6738 19.0098 17.6489C17.7932 18.9043 15.9542 19.062 14.5862 18.0343C14.1993 17.7424 13.8797 17.392 13.6106 16.9541C13.8629 16.703 14.0984 16.4286 14.3731 16.2067C14.6703 15.9615 15.0908 16.0082 15.3879 16.2885C15.7243 16.6096 16.1056 16.8081 16.5653 16.8081C17.0251 16.814 17.4231 16.6446 17.7483 16.306C18.1801 15.8622 18.6062 15.4126 19.0379 14.9688C19.5369 14.4492 20.0471 13.9295 20.5405 13.404C21.2806 12.6157 21.2021 11.4071 20.3667 10.7648C19.7499 10.286 18.8529 10.3152 18.2922 10.8699C17.7091 11.4479 17.1428 12.0494 16.5765 12.6449C16.498 12.7267 16.4364 12.7442 16.3354 12.7033C15.7187 12.4523 15.0739 12.3647 14.4124 12.4347C14.3619 12.4406 14.3114 12.4406 14.2273 12.4464Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13205_273481"
          x1="1.22799e-07"
          y1="35.2"
          x2="32.001"
          y2="0.000895969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91E00F" />
          <stop offset="1" stopColor="#D4F028" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Chain;
