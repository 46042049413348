export default function Disconnect({ className }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.2105 3.78906C17.504 5.42025 19 8.09953 19 11.1282C19 16.0988 14.9706 20.1282 10 20.1282C5.02944 20.1282 1 16.0988 1 11.1282C1 8.09953 2.49605 5.42025 4.78947 3.78906"
        strokeWidth="2"
        strokeLinecap="round"
        className="stroke-inherit"
      />
      <line
        x1="10.5263"
        y1="1"
        x2="10.5263"
        y2="6.57895"
        strokeWidth="2"
        strokeLinecap="round"
        className="stroke-inherit"
      />
    </svg>
  );
}
