import React from "react";

const Lightning = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42996 0.338793C9.65325 0.393413 9.84832 0.502017 9.98679 0.648808C10.1253 0.7956 10.1999 0.972925 10.1999 1.15499L9.10005 5.43278H14.5996C14.8008 5.43271 14.9982 5.47556 15.1703 5.55668C15.3423 5.6378 15.4825 5.75407 15.5755 5.89284C15.6685 6.03162 15.7108 6.18757 15.6977 6.34374C15.6847 6.49991 15.6169 6.65031 15.5016 6.77856L7.80206 15.3341C7.66806 15.4835 7.4763 15.5956 7.25467 15.6543C7.03305 15.713 6.79312 15.7152 6.56981 15.6605C6.34649 15.6058 6.15143 15.4971 6.01302 15.3503C5.8746 15.2034 5.80004 15.026 5.80019 14.8439L6.90005 10.5661H1.40047C1.19927 10.5662 1.0019 10.5233 0.829844 10.4422C0.657785 10.3611 0.517626 10.2448 0.424621 10.106C0.331616 9.96727 0.289328 9.81131 0.302358 9.65515C0.315389 9.49898 0.38324 9.34858 0.498526 9.22032L8.19804 0.66476C8.33224 0.51571 8.52404 0.403826 8.74558 0.34536C8.96712 0.286894 9.20686 0.284038 9.42996 0.338793Z"
    />
  </svg>
);

export default Lightning;
