const SimpleArrow = ({ className }) => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M15.5671 0.600098C15.9944 0.624497 16.3118 1.01488 16.1836 1.42356C16.0493 1.85054 15.8601 2.26532 15.6892 2.6801C13.9128 7.017 12.1364 11.3539 10.36 15.6908C10.2623 15.9348 10.1219 16.1239 9.84718 16.1849C9.48701 16.2581 9.15127 16.0629 9.07801 15.6969C8.95592 15.093 8.85215 14.483 8.74226 13.8731C8.45535 12.2933 8.16844 10.7134 7.88153 9.1275C7.86322 9.01161 7.82049 8.95671 7.6984 8.93841C5.99524 8.63342 4.29209 8.32234 2.58894 8.01735C2.125 7.93195 1.66106 7.85266 1.19712 7.76726C0.849163 7.70017 0.641611 7.49278 0.604984 7.18169C0.568357 6.8828 0.739282 6.62052 1.06282 6.48632C5.7938 4.55271 10.5187 2.6191 15.2496 0.691593C15.3473 0.648895 15.4633 0.630596 15.5671 0.600098Z" />
  </svg>
);

export default SimpleArrow;
