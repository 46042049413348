import SubscriptionLevelsModal from "~components/page-blocks/profile-header/profile-info/SubscriptionLevelsModal";
import Modal from "~components/popups/modal";
import MediaGallery from "~components/media/media-gallery";

import { useDispatch, useSelector } from "react-redux";
import {
  setDonateModal,
  setMediaGalleryModal,
  setPurchaseItemModal,
  setSubscribeModal,
  setUnlockAccessModal,
} from "~store/modals";
import {
  selectDonateModalState,
  selectMediaGalleryModalState,
  selectPurchaseItemModalState,
  selectSubscribeModalState,
  selectUnlockAccessModalState,
} from "~store/modals/selectors";
import DonateCard from "~components/web3/donate-modal";
import PermissionsCard from "~components/cards/PermissionsCard";
import PurchaseItemCard from "~components/web3/PurchaseItemCard";

const ModalsWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const {
    profile,
    show: showSubscribeModal,
    level,
  } = useSelector(selectSubscribeModalState);
  const {
    show: showMediaGalleryModal,
    media,
    mediaIndex,
  } = useSelector(selectMediaGalleryModalState);

  const {
    show: showDonateModal,
    address,
    amount,
  } = useSelector(selectDonateModalState);

  const {
    show: showUnlockAccessModal,
    author,
    permissions,
    itemId,
    isLocked,
  } = useSelector(selectUnlockAccessModalState);

  const {
    show: showPurchaseItemModal,
    price,
    type,
  } = useSelector(selectPurchaseItemModalState);

  // selectPurchaseItemModalState
  return (
    <>
      {children}
      <Modal
        show={showMediaGalleryModal}
        setShow={() => dispatch(setMediaGalleryModal({ show: false, media }))}
        RenderCard={MediaGallery}
        popupWindowClasses="@zi z-80 @pn @ht h-full max-h-[85vh] md:max-h-[95vh] @fx flex justify-center items-center"
        containerClasses="z-80 !px-0"
        closeButtonClasses="right-auto left-0 md:left-auto md:!right-[10px] !top-0 md:top-[15px]"
        useMobileModal={false}
        renderCardProps={{
          media,
          mediaIndex,
          // postLikes,
          // post,
          // setPostLikes,
          setShowSubscribeModal: () =>
            dispatch(
              setSubscribeModal({
                profile,
                show: true,
              }),
            ),
        }}
      />
      <Modal
        show={showDonateModal}
        setShow={() => dispatch(setDonateModal({ show: false, address }))}
        RenderCard={DonateCard}
        renderCardProps={{ address, amount }}
        childContainerClasses={"@wh w-full lg:w-fit"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />
      <Modal
        show={showUnlockAccessModal}
        setShow={() =>
          dispatch(
            setUnlockAccessModal({
              show: false,
              permissions,
              author,
              itemId,
              isLocked,
            }),
          )
        }
        RenderCard={PermissionsCard}
        renderCardProps={{
          permissions,
          author,
          itemId,
          isLocked,
        }}
        childContainerClasses={"@wh w-full lg:w-fit @mn ml-auto"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />
      <Modal
        show={showPurchaseItemModal}
        setShow={() =>
          dispatch(setPurchaseItemModal({ show: false, price, type }))
        }
        RenderCard={PurchaseItemCard}
        renderCardProps={{ price, type, itemId }}
        childContainerClasses={"@wh w-full lg:w-fit"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />

      <Modal
        show={showSubscribeModal}
        setShow={() => dispatch(setSubscribeModal({ show: false, profile }))}
        RenderCard={SubscriptionLevelsModal}
        renderCardProps={{
          profile,
          level,
        }}
        childContainerClasses={"@wh w-full lg:w-fit"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />
    </>
  );
};

export default ModalsWrapper;
