const Moon = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98378 0.611766C6.08934 0.796184 6.07503 1.02445 5.94723 1.19468C5.41961 1.89751 5.16572 2.76345 5.23173 3.63502C5.29774 4.50658 5.67927 5.32587 6.30692 5.94387C6.93458 6.56187 7.76667 6.93753 8.65185 7.00252C9.53703 7.06752 10.4165 6.81753 11.1303 6.29803C11.3032 6.1722 11.535 6.15811 11.7223 6.26204C11.9096 6.36597 12.0175 6.56855 11.9977 6.77934C11.8926 7.89884 11.4659 8.96572 10.7675 9.85516C10.069 10.7446 9.1278 11.4198 8.05388 11.8017C6.97995 12.1837 5.81775 12.2566 4.70328 12.0119C3.58881 11.7672 2.56816 11.2151 1.76076 10.4201C0.953353 9.62511 0.392594 8.62017 0.144093 7.52284C-0.104407 6.42552 -0.030371 5.2812 0.35754 4.22379C0.74545 3.16639 1.43119 2.23962 2.33452 1.55195C3.23785 0.864271 4.32141 0.444123 5.4584 0.340667C5.67249 0.321187 5.87822 0.427348 5.98378 0.611766ZM4.46305 1.63585C3.93806 1.80723 3.44359 2.06538 3.00179 2.40171C2.26238 2.96459 1.70108 3.72318 1.38357 4.5887C1.06605 5.45422 1.00545 6.39088 1.20885 7.28908C1.41226 8.18727 1.87126 9.00986 2.53214 9.66057C3.19303 10.3113 4.02847 10.7632 4.9407 10.9635C5.85293 11.1638 6.80422 11.1041 7.68327 10.7915C8.56231 10.4788 9.33275 9.92618 9.90443 9.19815C10.246 8.76315 10.5082 8.27629 10.6823 7.75938C10.0162 8.01713 9.29495 8.12685 8.57073 8.07368C7.42498 7.98955 6.34795 7.50331 5.53553 6.70339C4.72312 5.90347 4.22928 4.84302 4.14384 3.7149C4.08983 3.00181 4.20127 2.29163 4.46305 1.63585Z"
      />
    </svg>
  );
};

export default Moon;
