export default function Plane({ className }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M19.2015 3C19.664 3.02643 20.0076 3.44935 19.8689 3.89208C19.7235 4.35464 19.5187 4.80399 19.3336 5.25334C17.4107 9.95164 15.4878 14.6499 13.5649 19.3483C13.4591 19.6126 13.3071 19.8174 13.0098 19.8835C12.6199 19.9628 12.2565 19.7513 12.1772 19.3549C12.045 18.7007 11.9327 18.0399 11.8137 17.3791C11.5032 15.6676 11.1926 13.9561 10.882 12.238C10.8622 12.1125 10.8159 12.053 10.6838 12.0332C8.84014 11.7028 6.99652 11.3658 5.15289 11.0354C4.65068 10.9428 4.14847 10.8569 3.64626 10.7644C3.26961 10.6917 3.04494 10.4671 3.00529 10.1301C2.96564 9.80627 3.15066 9.52212 3.50089 9.37674C8.62208 7.282 13.7367 5.18726 18.8579 3.09912C18.9636 3.05286 19.0891 3.03304 19.2015 3Z" />
    </svg>
  );
}
