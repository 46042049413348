import React from "react";

const WalletConnect = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.09442 3.26611C7.35599 0.0727565 12.644 0.0727565 15.9056 3.26611L16.2981 3.65043C16.4612 3.8101 16.4612 4.06897 16.2981 4.22864L14.9554 5.54334C14.8738 5.62317 14.7416 5.62317 14.6601 5.54334L14.1199 5.01446C11.8445 2.7867 8.15547 2.7867 5.88012 5.01446L5.30164 5.58084C5.2201 5.66068 5.0879 5.66068 5.00636 5.58084L3.66357 4.26614C3.50049 4.10648 3.50049 3.8476 3.66357 3.68794L4.09442 3.26611ZM18.6826 5.98504L19.8777 7.15513C20.0408 7.31479 20.0408 7.57367 19.8777 7.73333L14.489 13.0094C14.3259 13.1691 14.0615 13.1691 13.8984 13.0094C13.8984 13.0094 13.8984 13.0094 13.8984 13.0094L10.0739 9.26482C10.0331 9.22491 9.96699 9.22491 9.92622 9.26482C9.92622 9.26483 9.92622 9.26483 9.92622 9.26483L6.10172 13.0094C5.93865 13.1691 5.67424 13.1691 5.51116 13.0094C5.51116 13.0094 5.51116 13.0094 5.51116 13.0094L0.122309 7.73327C-0.0407696 7.5736 -0.0407696 7.31473 0.122309 7.15506L1.31739 5.98497C1.48047 5.82531 1.74487 5.82531 1.90795 5.98497L5.73258 9.72961C5.77335 9.76952 5.83945 9.76952 5.88022 9.72961C5.88022 9.72961 5.88022 9.72961 5.88022 9.7296L9.70466 5.98497C9.86773 5.8253 10.1321 5.8253 10.2952 5.98496C10.2952 5.98496 10.2952 5.98496 10.2952 5.98497L14.1198 9.7296C14.1606 9.76952 14.2267 9.76952 14.2675 9.7296L18.0921 5.98504C18.2551 5.82537 18.5195 5.82537 18.6826 5.98504Z"
        fill="#3B99FC"
      />
    </svg>
  );
};

export default WalletConnect;
