export default function Lock({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6 7.33347C9.60844 5.8641 10.8589 4.59306 12.5026 4.60003L12.5094 4.60004C14.1543 4.60004 15.4 5.87913 15.4 7.34854V9.49998H9.6V7.33347ZM8 9.56502V7.32943C8.01085 4.92729 10.0297 2.98953 12.5094 3.00004C14.989 3.00004 17 4.94736 17 7.34854V9.56502C18.7107 9.87174 20 11.227 20 12.8535V16.6465C20 18.4992 18.3271 20 16.264 20H8.73596C6.67291 20 5 18.4992 5 16.6465V12.8535C5 11.227 6.2893 9.87174 8 9.56502ZM16.264 18.4H8.73596C7.38937 18.4 6.6 17.4572 6.6 16.6465V12.8535C6.6 12.0428 7.38937 11.1 8.73596 11.1H16.264C17.6106 11.1 18.4 12.0428 18.4 12.8535V16.6465C18.4 17.4572 17.6106 18.4 16.264 18.4Z"
      />
    </svg>
  );
}
