import React from "react";

const Kraken = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7435 4.0402C22.2055 3.81982 25.7327 4.50851 28.8504 6.03283C33.4386 8.25502 37.096 12.277 38.8271 17.0244C39.5902 19.118 39.9997 21.3402 39.9997 23.5624V31.8268C39.9997 32.1665 40.009 32.5063 39.9252 32.8369C39.7391 33.6725 39.1342 34.4071 38.3431 34.756C37.794 35.0131 37.1612 35.0407 36.5656 34.9489C35.2906 34.7193 34.2948 33.5347 34.2854 32.2584C34.2761 29.6964 34.2854 27.1345 34.2854 24.5633C34.3041 23.8379 34.3134 23.0574 33.9132 22.4146C33.2617 21.23 31.6982 20.6699 30.4325 21.1749C29.3623 21.5514 28.5898 22.6166 28.5805 23.7369C28.5712 26.3448 28.5805 28.9434 28.5805 31.5421C28.5805 32.0288 28.6084 32.5247 28.4595 32.993C28.1896 33.9112 27.4172 34.655 26.4865 34.903C25.2674 35.2335 23.8621 34.7009 23.2478 33.5898C22.829 32.9195 22.857 32.1114 22.8663 31.3585C22.857 28.7782 22.8756 26.1887 22.857 23.6083C22.829 22.2952 21.7402 21.1107 20.4186 20.9454C19.5624 20.8168 18.6597 21.1015 18.0362 21.6984C17.4778 22.2218 17.152 22.9747 17.152 23.7369V31.2666C17.1427 31.9002 17.2172 32.5522 16.9752 33.1491C16.5936 34.2234 15.4955 35.0131 14.3321 34.9856C13.1409 35.0499 11.9869 34.2418 11.6053 33.1399C11.3913 32.5981 11.4378 32.0104 11.4378 31.4411C11.4378 28.8424 11.4471 26.2437 11.4378 23.6451C11.4285 22.3595 10.4141 21.1841 9.13906 20.9546C7.97574 20.7066 6.69143 21.2851 6.11442 22.3136C5.86315 22.7452 5.73285 23.241 5.73285 23.7277V32.1665C5.74216 32.9838 5.36059 33.801 4.71844 34.3244C3.75055 35.1601 2.18705 35.206 1.17263 34.4346C0.437409 33.9388 0 33.0848 0 32.2033V23.6543C0.00930658 20.422 0.837592 17.1989 2.4011 14.3523C3.6761 12.0291 5.43504 9.96298 7.53833 8.31011C10.7026 5.79408 14.6765 4.27895 18.7435 4.0402Z"
        fill="#4DA7F8"
      />
    </svg>
  );
};

export default Kraken;
