export default function Cog({ className }) {
  return (
    <svg
      width="43"
      height="26"
      viewBox="0 0 43 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.4989 5.27581C43.4982 8.20736 42.9983 0.389893 42.9983 0.389893V11.308C42.9983 19.04 36.7303 25.308 28.9983 25.308H0C0 25.308 27.4989 27 27.4989 5.27581Z"
        fill="url(#paint0_linear_234_403)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_234_403"
          x1="13.8327"
          y1="25.3084"
          x2="43.1284"
          y2="0.685722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13D97A" />
          <stop offset="0.34375" stopColor="#26ADE6" />
          <stop offset="0.65625" stopColor="#FB38FF" />
          <stop offset="1" stopColor="#FF971D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
