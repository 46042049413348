import { useEffect, useMemo, useState } from "react";
import ModalArray from "~components/popups/modal/modal-array";
import dynamic from "next/dynamic";
import { isMobile } from "react-device-detect";
import useStyleRewriter from "../../zui/functions/use-style-rewriter";

const ModalPortal = dynamic(() => import("./modal-portal"), { ssr: false });
const MobileModalPortal = dynamic(
  () => import("../mobile-modal/mobile-modal-portal"),
  { ssr: false },
);

const Modal = ({
  children,
  showEffect = () => {},
  useMobileModal = isMobile,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const srChildContainerClasses = useStyleRewriter(
    "@cr cursor-pointer @wh w-full @ht h-full",
    props.childContainerClasses,
  );
  const modalComponentId = useMemo(() => {
    const name = props.RenderCard.name;

    if (!name || name === "anonymous") {
      return `component${Math.round(Math.random() * 10e5)}`;
    }
    return name;
  }, []);

  useEffect(() => {
    showEffect({ show, setShow });
  }, [children ? show : props.show, props]);

  const Component = useMobileModal ? MobileModalPortal : ModalPortal;

  return (
    <>
      <Component
        modalComponentId={
          props.modalComponentId ? props.modalComponentId : modalComponentId
        }
        setShow={children ? setShow : props.setShow}
        show={children ? show : props.show}
        {...props}
      />
      {children ? (
        <div className={srChildContainerClasses} onClick={() => setShow(true)}>
          {children}
        </div>
      ) : null}
    </>
  );
};

export { ModalArray };
export default Modal;
