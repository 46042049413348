import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authTokensManager } from "~utils/localStorage/authTokensManager";
import { logout, startSigning, web3AccountUpdated } from "~store/authSlice";
import {
  selectAccountId,
  selectIsAccountBindingInProgress,
  selectIsAuthenticated,
  selectIsConnectedWithWeb3,
} from "~store/authSlice/selectors";

/**
 * @description хук отвечает за логику авторизации, с момента получения данных
 * об адрессе пользователе от провайдера, заканчивая получением jwt.
 * Логика получения данных о профиле с бэкенда находится в хуке useProfile
 */
const useAuthorization = () => {
  const { account, library } = useWeb3React();

  const dispatch = useDispatch();
  const accountId = useSelector(selectAccountId);
  const isConnectedWithWeb3 = useSelector(selectIsConnectedWithWeb3);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAccountBindingInProgress = useSelector(
    selectIsAccountBindingInProgress,
  );

  /**
   * Если нет JWT в authTokenManager открываем модалку, которая его генернет
   */
  useEffect(() => {
    if (isAccountBindingInProgress) return;

    if (account && library) {
      if (isConnectedWithWeb3) dispatch(logout());
      authTokensManager.setCurrentAccount(account);
      dispatch(web3AccountUpdated({ account }));
    }

    // TODO: HMR (любое изм. кода) вызывает логаут здесь.
    // Это происходит из-за того что редакс провайдер выше, чем веб3 провайдер
    // вообще, эта логика делает логаут, если юзер отключил кошелек в ui кошеля
    if (isConnectedWithWeb3 && !account) dispatch(logout());
  }, [account]);

  /**
   * Если есть кошель в провайдере, но нет JWT в authTokenManager
   * открываем модалку, которая его генернет
   */
  useEffect(() => {
    if (isConnectedWithWeb3) {
      // означает отсутствие jwt => акк законнекчен, но не подписан
      if (!accountId) {
        dispatch(startSigning());
        return;
      }

      // если accountId есть в сторе, то вызовется квери на получение данных в
      // хуkе useProfile
    }
  }, [isConnectedWithWeb3, isAuthenticated, accountId]);
};

export default useAuthorization;
