import React from "react";
import P from "~components/ui/typography/p";

const SettingsLabel = ({ label, description, className }) => {
  return (
    <div className={`flex flex-col w-full ${className} mb-10`}>
      <P className="@ftf font-clash @ftw font-semibold @fts text-[20px] @ttc text-zinc-900 leading-[24px] mb-3">
        {label}
      </P>
      <P className="@ftf font-montserrat @ftw font-normal @fts text-[16px] @ttc text-zinc-900 leading-[20px]">
        {description}
      </P>
    </div>
  );
};

export default SettingsLabel;
