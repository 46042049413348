import React from "react";

const SmallLoader = ({ className }) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.2"
        d="M18.9991 11.0001C18.9991 15.9701 14.9701 19.9991 10.0001 19.9991C5.03 19.9991 1.00098 15.9701 1.00098 11.0001C1.00098 6.03 5.03 2.00098 10.0001 2.00098C14.9701 2.00098 18.9991 6.03 18.9991 11.0001Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M9.92164 20.1666C14.9839 20.1666 19.0877 16.0628 19.0877 11.0005C19.0877 5.93826 14.9839 1.83447 9.92164 1.83447C8.11072 1.83447 6.42247 2.35963 5.00085 3.26595"
        stroke="url(#paint0_linear_3865_57025)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3865_57025"
          x1="16.7708"
          y1="16.8759"
          x2="8.8856"
          y2="0.79103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.01" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SmallLoader;
