import React from "react";

const Reddit = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M18.3061 9.46157C18.3061 8.27372 17.3401 7.30773 16.1523 7.30773C15.6773 7.30773 15.2261 7.4628 14.8578 7.74388C13.656 6.90927 12.0815 6.41173 10.4069 6.28573L11.3104 4.16636L13.9392 4.7802C14.0232 5.59327 14.7038 6.23081 15.5384 6.23081C16.429 6.23081 17.1538 5.50604 17.1538 4.61543C17.1538 3.72482 16.429 3.00005 15.5384 3.00005C14.9741 3.00005 14.4776 3.2919 14.189 3.73236L11.1166 3.01405C10.856 2.95482 10.6007 3.08728 10.4973 3.32744L9.24812 6.25666C7.43998 6.31912 5.71691 6.8285 4.41599 7.71588C4.06168 7.45311 3.6223 7.30773 3.15384 7.30773C1.966 7.30773 1 8.27372 1 9.46157C1 10.2488 1.42969 10.9606 2.08877 11.3257C2.08015 11.4205 2.07692 11.5174 2.07692 11.6154C2.07692 14.5845 5.45845 17 9.61535 17C13.7712 17 17.1538 14.5845 17.1538 11.6154C17.1538 11.5314 17.1506 11.4485 17.1452 11.3656C17.8473 11.008 18.3061 10.2789 18.3061 9.46157ZM15.5384 4.07697C15.8346 4.07697 16.0769 4.3182 16.0769 4.61543C16.0769 4.91266 15.8346 5.15389 15.5384 5.15389C15.2423 5.15389 14.9999 4.91266 14.9999 4.61543C14.9999 4.3182 15.2423 4.07697 15.5384 4.07697ZM5.84614 11.0769C5.84614 10.4836 6.32967 10 6.92306 10C7.51644 10 7.99997 10.4836 7.99997 11.0769C7.99997 11.6714 7.51644 12.1539 6.92306 12.1539C6.32967 12.1539 5.84614 11.6714 5.84614 11.0769ZM12.102 14.6491C11.3589 15.1865 10.4866 15.4557 9.61535 15.4557C8.74413 15.4557 7.87182 15.1865 7.12875 14.6491C6.88752 14.4746 6.83367 14.1375 7.00813 13.8974C7.18259 13.6572 7.51967 13.6034 7.75982 13.7768C8.87012 14.5791 10.3606 14.5812 11.4709 13.7768C11.711 13.6034 12.047 13.6551 12.2226 13.8974C12.397 14.1386 12.3421 14.4746 12.102 14.6491ZM12.3077 12.1539C11.7132 12.1539 11.2307 11.6714 11.2307 11.0769C11.2307 10.4836 11.7132 10 12.3077 10C12.9021 10 13.3846 10.4836 13.3846 11.0769C13.3846 11.6714 12.9021 12.1539 12.3077 12.1539Z" />
    </svg>
  );
};

export default Reddit;
