import { useState, useEffect } from "react";
import ButtonWithIcon from "~components/ui/button-with-icon";
// import CommentsDropdown from "../comments-dropdown";
import useProfile from "~services/backend/profiles/useProfile";
import {
  useCreateLikeMutation,
  useDeleteLikeMutation,
} from "~services/backend/likes";
import Link from "next/link";
import Avatar from "~components/ui/avatar";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";

const PostFooter = ({
  post,
  isGallery = false,
  userLike,
  setPostLikes,
  likesCount,
}) => {
  const [deleteLike] = useDeleteLikeMutation();
  const [createLike] = useCreateLikeMutation();

  // const [openCommentsDropdown, setOpenCommentsDropdown] = useState(false);
  const [activeLikeRequest, setActiveLikeRequest] = useState(false);

  const { profile } = useProfile();

  const togglePostLike = async () => {
    if (activeLikeRequest) return;
    setActiveLikeRequest(true);

    if (userLike) {
      await deleteLike(userLike)
        .unwrap()
        .then(() => {
          setPostLikes((prev) => ({
            likesCount: prev.likesCount - 1,
            userLike: false,
          }));
        })
        .catch(() => setActiveLikeRequest(false));
    } else {
      await createLike(post.id)
        .unwrap()
        .then((createdLike) => {
          setPostLikes((prev) => ({
            likesCount: prev.likesCount + 1,
            userLike: createdLike?.id,
          }));
        })
        .catch(() => setActiveLikeRequest(false));
    }

    setActiveLikeRequest(false);
  };

  return (
    <div className={`flex flex-col ${!isGallery && "gap-4"} `}>
      <div
        className={`flex select-none items-center justify-start ${
          isGallery ? "flex-col" : "gap-5"
        }`}
      >
        <ButtonWithIcon
          text={`${likesCount || 0}`}
          icon="Heart"
          variant="text"
          iconClassName={`${
            userLike
              ? "text-pink-base"
              : "text-zinc-light md:hover:text-pink-base duration-200"
          } ${
            isGallery &&
            `!mr-0 !w-full stroke-[0.5px] stroke-pink-base ${
              userLike ? "text-pink-base" : "text-white"
            }`
          }`}
          onClick={togglePostLike}
          buttonClassName={`@ftf font-family-inter-medium @fts text-14px  @leh leading-20px ${
            activeLikeRequest ? "pointer-events-none" : ""
          } ${
            isGallery
              ? "flex-col w-full @ttc text-white"
              : "@ttc text-zinc-base"
          }`}
          disabled={!profile}
        />
        {isGallery && post?.user ? (
          <Link href={`/${post.user.username}`} passHref={true}>
            <a className="mt-4">
              <Avatar
                className="!w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px] border-2 border-white"
                src={
                  getSocialImagePath(post.user.image) ||
                  "/assets/images/avatar.jpg"
                }
              />
            </a>
          </Link>
        ) : null}
        {/* <ButtonWithIcon
          text={`${post?.comments?.length || 0}`}
          icon="Message"
          variant="text"
          iconClassName="text-zinc-light"
          onClick={() => setOpenCommentsDropdown(!openCommentsDropdown)}
          buttonClassName="@ftf font-family-inter-medium @fts text-14px @ttc text-zinc-base @leh leading-20px"
          disabled={!post?.comments?.length && !isOwner && !isSubscribed}
        /> */}
      </div>
      {/* {openCommentsDropdown ? (
        <CommentsDropdown
          comments={post?.comments}
          postId={post.id}
          post={post}
          isOpen={openCommentsDropdown}
          isOwner={isOwner}
          isSubscribed={isSubscribed}
        />
      ) : null} */}
    </div>
  );
};

export default PostFooter;
