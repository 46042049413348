import React from "react";

const QuestionCircle = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current fill-current ${className}`}
  >
    <circle cx="8" cy="8" r="7.5" fill="none" />
    <path
      d="M7.34849 9.71969H8.60985V9.6212C8.61743 8.81817 8.87879 8.45075 9.49622 8.07196C10.1932 7.65529 10.6288 7.08711 10.6288 6.21969C10.6288 4.93938 9.63258 4.13635 8.18561 4.13635C6.85985 4.13635 5.78409 4.8712 5.74622 6.3106H7.0947C7.12879 5.59847 7.64394 5.25756 8.17803 5.25756C8.75758 5.25756 9.22349 5.64393 9.22349 6.23863C9.22349 6.76893 8.87122 7.13256 8.41288 7.42044C7.74243 7.83711 7.35606 8.25756 7.34849 9.6212V9.71969ZM8.01137 12.0833C8.45834 12.0833 8.84091 11.7121 8.8447 11.25C8.84091 10.7954 8.45834 10.4242 8.01137 10.4242C7.54925 10.4242 7.17425 10.7954 7.17803 11.25C7.17425 11.7121 7.54925 12.0833 8.01137 12.0833Z"
      stroke="none"
    />
  </svg>
);

export default QuestionCircle;
