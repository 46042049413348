export default function Lightning({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      className={`stroke-current ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 10V3L4 14h7v7l9-11h-7z"
      />
    </svg>
  );
}
