export const localStorage =
  typeof window === "undefined"
    ? { setItem: () => {}, getItem: () => {} }
    : window.localStorage;

export const readJson = (json, defaultValue = null) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultValue;
  }
};
