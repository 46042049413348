import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { PermissionsList } from "~components/settings/messages";
import CardBox from "~components/ui/card-box";
import H2 from "~components/ui/typography/h2";
import P from "~components/ui/typography/p";
import { useCreateFollowingMutation } from "~services/backend/followings";
import { useGetPurchasesQuery } from "~services/backend/profiles";
import useProfile from "~services/backend/profiles/useProfile";
import { setPurchaseItemModal, setSubscribeModal } from "~store/modals";

const PermissionsCard = ({
  permissions,
  author,
  title = "Content hidden",
  subtitle = "Author set conditions for open content:",
  itemId,
  isLocked,
}) => {
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const [createFollowing] = useCreateFollowingMutation();

  const contentType = pathname === "/chats/[id]" ? "message" : "post";

  const { data: purchases } = useGetPurchasesQuery(
    {
      userId: profile?.id,
      filters: {
        [contentType]: itemId,
      },
    },
    {
      skip: !profile?.id || !itemId || !isLocked,
    },
  );

  const createFollowingCb = () =>
    author &&
    isLocked &&
    createFollowing({
      authorId: author.id,
    });

  const handlers = {
    following: createFollowingCb,
    levels: () =>
      isLocked &&
      author &&
      dispatch(setSubscribeModal({ profile: author, show: true })),
    subscription: () =>
      isLocked &&
      author &&
      dispatch(setSubscribeModal({ profile: author, show: true })),
    price: (price) =>
      isLocked &&
      author?.address &&
      dispatch(setPurchaseItemModal({ show: true, price, type: contentType })),
  };

  const followingForAuthor = useMemo(
    () => profile?.followings.find((f) => f.author.id === author?.id),
    [profile?.followings?.length],
  );

  const followingByAuthor = useMemo(
    () => profile?.followers.find((f) => f.user.id === author?.id),
    [profile?.followers?.length],
  );

  const conditionState = {
    following: !!followingForAuthor,
    levels: followingForAuthor?.subscriptionLevel?.index,
    subscription:
      !!followingForAuthor && !!followingForAuthor?.subscriptionLevel,
    followedByUser: !!followingByAuthor,
    price: !isLocked || !!purchases?.length,
  };

  return (
    <CardBox className="@ht max-h-[91vh] @dy flex @ani flex-col  @ow overflow-y-scroll @brw border-0 @wh min-w-fit w-full lg:max-w-[480px]">
      <div className="flex flex-col mb-6">
        <H2 className="@ftf font-clash @ftw font-semibold @fts text-[26px] @tta text-center @mn mb-3">
          {title}
        </H2>
        <P className="@ftf font-montserrat @mn mx-auto @fts text-14px @leh leading-20px @ttc text-zinc-700">
          {subtitle}
        </P>
      </div>
      <div className="flex items-center justify-center">
        <PermissionsList
          containerClassName={"justify-center"}
          config={permissions}
          handlers={handlers}
          author={author}
          conditionState={conditionState}
        />
      </div>
    </CardBox>
  );
};

export default PermissionsCard;
