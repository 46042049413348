import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "~components/ui/button";
import CardBox from "~components/ui/card-box";
import H1 from "~components/ui/typography/h1";
import P from "~components/ui/typography/p";

const SignModal = ({ login, logout, isWC = false }) => {
  const [clicked, setClicked] = useState(false);
  const { account } = useWeb3React();

  useEffect(() => {
    if (!account) logout();
  }, [account]);

  const handleSign = () => {
    setClicked(true);
    login();
  };

  return (
    <CardBox className="flex flex-col justify-center items-center @pg p-10 @brw border-0 md:border-px">
      <H1 className="mb-6 w-[320px] font-bold text-center">
        Sign the message in your wallet to continue
      </H1>
      <P className="mb-10 w-[320px] text-center">
        Subclub uses this signature to verify that you’re the owner of this
        address.
        {isWC && isMobile ? (
          <>
            <br />
            <br />
            <span className="font-medium">
              Wallet Connect can request a signature twice, it's ok
            </span>
          </>
        ) : null}
      </P>

      <Button
        className="w-[240px] text-center mb-3"
        onClick={handleSign}
        variant="rounded"
      >
        {clicked ? "Try again" : "Continue"}
      </Button>
      <Button className="" onClick={logout} variant="text">
        Disconnect
      </Button>
    </CardBox>
  );
};

export default SignModal;
