import React from "react";

const TikTokColored = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11427_260439)">
      <path
        d="M14.3135 7.21964C15.5996 8.14228 17.1752 8.68515 18.8769 8.68515V5.39892C18.5548 5.39899 18.2336 5.36529 17.9186 5.2983V7.88502C16.217 7.88502 14.6416 7.34216 13.3552 6.41958V13.1258C13.3552 16.4806 10.6452 19.2 7.30257 19.2C6.05534 19.2 4.89609 18.8216 3.93311 18.1726C5.0322 19.3004 6.56497 20.0001 8.26071 20.0001C11.6036 20.0001 14.3137 17.2807 14.3137 13.9257V7.21964H14.3135ZM15.4957 3.90424C14.8385 3.1836 14.4069 2.25231 14.3135 1.22273V0.800049H13.4054C13.6339 2.10865 14.4137 3.22664 15.4957 3.90424ZM6.04735 15.5983C5.68012 15.115 5.48167 14.5239 5.48255 13.9161C5.48255 12.3818 6.72206 11.1377 8.25127 11.1377C8.53627 11.1376 8.81955 11.1814 9.09115 11.2679V7.90822C8.77375 7.86457 8.45342 7.84604 8.13322 7.85283V10.4678C7.86142 10.3814 7.578 10.3374 7.29293 10.3377C5.76372 10.3377 4.52428 11.5816 4.52428 13.1162C4.52428 14.2012 5.14383 15.1406 6.04735 15.5983Z"
        fill="#FF004F"
      />
      <path
        d="M13.3554 6.41952C14.6419 7.34209 16.2172 7.88496 17.9188 7.88496V5.29823C16.969 5.0952 16.1281 4.59708 15.4959 3.90424C14.4138 3.22657 13.6342 2.10858 13.4056 0.800049H11.0201V13.9256C11.0147 15.4558 9.77728 16.6948 8.25135 16.6948C7.35214 16.6948 6.55329 16.2646 6.04735 15.5983C5.14391 15.1406 4.52436 14.2012 4.52436 13.1163C4.52436 11.5818 5.7638 10.3378 7.29301 10.3378C7.586 10.3378 7.8684 10.3835 8.13329 10.4679V7.8529C4.84934 7.92099 2.20825 10.6138 2.20825 13.9257C2.20825 15.5789 2.86594 17.0776 3.93339 18.1727C4.89637 18.8216 6.05562 19.2001 7.30285 19.2001C10.6456 19.2001 13.3555 16.4805 13.3555 13.1258V6.41952H13.3554Z"
        fill="#1D1D1D"
      />
      <path
        d="M17.9188 5.29817V4.59874C17.0623 4.60004 16.2226 4.35932 15.496 3.90411C16.1392 4.61089 16.9863 5.09822 17.9188 5.29817ZM13.4056 0.799986C13.3838 0.674926 13.367 0.549042 13.3554 0.422678V0H10.0617V13.1257C10.0564 14.6556 8.8191 15.8946 7.29303 15.8946C6.845 15.8946 6.42199 15.7879 6.04737 15.5983C6.55331 16.2645 7.35216 16.6946 8.25137 16.6946C9.77717 16.6946 11.0148 15.4558 11.0201 13.9256V0.799986H13.4056ZM8.13345 7.85284V7.10824C7.85823 7.07049 7.58076 7.05155 7.30294 7.05169C3.95993 7.05162 1.25 9.77116 1.25 13.1257C1.25 15.2288 2.31505 17.0822 3.93347 18.1725C2.86603 17.0775 2.20834 15.5787 2.20834 13.9255C2.20834 10.6138 4.84936 7.92093 8.13345 7.85284Z"
        fill="#00F2EA"
      />
    </g>
    <defs>
      <clipPath id="clip0_11427_260439">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TikTokColored;
