import React from "react";

const Macbook = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.27054 34.8627V16.8453C9.27054 15.8277 10.0935 15 11.1052 15H41.8953C42.907 15 43.7299 15.8277 43.7299 16.8453V34.8627H42.4256V16.3763H10.5754V34.8627H9.27054ZM29.3962 35.3517C29.3468 35.595 29.1339 35.7778 28.8782 35.7778H24.1223C23.8666 35.7778 23.6536 35.595 23.6043 35.3517H7V35.7945C7 36.4604 7.53645 37 8.19812 37H44.8019C45.464 37 46 36.4604 46 35.7945V35.3517H29.3962Z" />
    </svg>
  );
};

export default Macbook;
