export default function Search({ className }) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path
        d="M6.33336 11.6667C9.2789 11.6667 11.6667 9.2789 11.6667 6.33336C11.6667 3.38783 9.2789 1 6.33336 1C3.38783 1 1 3.38783 1 6.33336C1 9.2789 3.38783 11.6667 6.33336 11.6667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.0001L10.1 10.1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
