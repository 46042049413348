import React from "react";

const TrustWallet = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 20C15.5228 20 20 15.5228 20 9.99999C20 4.47715 15.5228 0 9.99999 0C4.47715 0 0 4.47715 0 9.99999C0 15.5228 4.47715 20 9.99999 20Z"
        fill="#3375BB"
      />
      <path
        d="M10.0004 4.76862L10.2739 4.41264C10.1952 4.35212 10.0987 4.31934 9.99937 4.31934C9.90007 4.31934 9.80356 4.35212 9.72482 4.41264L10.0004 4.76862ZM14.7027 6.15036H15.1512C15.152 6.09087 15.141 6.03181 15.1189 5.97656C15.0967 5.92134 15.0638 5.87105 15.0221 5.8286C14.9804 5.78617 14.9307 5.7524 14.8759 5.72926C14.8211 5.70611 14.7622 5.69407 14.7027 5.6938V6.15036ZM10.0004 15.7562L9.75098 16.1302C9.82447 16.1795 9.91092 16.2057 9.99937 16.2057C10.0878 16.2057 10.1743 16.1795 10.2478 16.1302L10.0004 15.7562ZM5.30205 6.15036V5.69984C5.24256 5.70011 5.18369 5.71215 5.12886 5.7353C5.07404 5.75842 5.02435 5.79219 4.98265 5.83464C4.94094 5.8771 4.90807 5.92738 4.88591 5.98261C4.86374 6.03783 4.85275 6.09691 4.85356 6.1564L5.30205 6.15036ZM9.72684 5.12261C11.7622 6.69945 14.0933 6.59888 14.7047 6.59888V5.69984C14.0712 5.69984 12.0418 5.78029 10.2799 4.41264L9.72684 5.12261ZM14.2562 6.14232C14.222 8.22598 14.1335 9.69623 13.9646 10.7843C13.7956 11.8724 13.5623 12.508 13.2546 12.9625C12.9469 13.4171 12.5527 13.7127 11.9754 14.0566C11.3982 14.4006 10.6601 14.7827 9.75098 15.3841L10.2578 16.1302C11.1186 15.557 11.8346 15.191 12.4441 14.827C13.0581 14.4925 13.591 14.027 14.0048 13.4633C14.4071 12.8599 14.6826 12.0554 14.8596 10.9211C15.0366 9.78672 15.1271 8.26019 15.1613 6.1564L14.2562 6.14232ZM10.2578 15.3841C9.35475 14.7807 8.61864 14.4066 8.04542 14.0586C7.47221 13.7107 7.078 13.4331 6.76828 12.9625C6.45854 12.4919 6.20713 11.8664 6.03417 10.7843C5.86118 9.70225 5.78476 8.22598 5.75057 6.14232L4.85356 6.1564C4.88774 8.26019 4.98025 9.79076 5.15524 10.9211C5.33022 12.0514 5.59771 12.8559 6.008 13.4633C6.41982 14.0273 6.95147 14.493 7.56472 14.827C8.16812 15.191 8.89015 15.557 9.75098 16.1302L10.2578 15.3841ZM5.30205 6.59888C5.90543 6.59888 8.23851 6.69945 10.2739 5.12261L9.72482 4.41264C7.95893 5.78029 5.92957 5.69984 5.30005 5.69984L5.30205 6.59888Z"
        fill="white"
      />
    </svg>
  );
};

export default TrustWallet;
