import React from "react";

const BitStamp = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.387 17.697C21.6898 17.5868 20.9739 17.6253 20.2705 17.6191C19.2257 17.6191 18.1809 17.6325 17.1415 17.6128C17.1289 19.5035 17.1289 21.3939 17.1415 23.2839C18.3959 23.2705 19.645 23.2777 20.8968 23.2777C21.5608 23.2777 22.2409 23.3028 22.8771 23.0644C23.3062 22.9108 23.6894 22.6509 23.9908 22.309C24.2921 21.967 24.5018 21.5542 24.6002 21.1092C24.7481 20.361 24.671 19.542 24.2418 18.8968C23.835 18.2517 23.1226 17.8449 22.387 17.697V17.697Z"
        fill="#149F49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM11.6667 23.5036C12.7312 23.5224 13.75 22.845 14.1891 21.8835C14.3932 21.433 14.4819 20.9387 14.4471 20.4453C14.4919 19.4901 14.0278 18.5161 13.2151 17.9937C12.767 17.6711 12.2151 17.5457 11.6729 17.5099C11.6667 16.7545 11.6729 16.0063 11.6667 15.2581C12.4471 15.2903 13.2079 14.9292 13.7276 14.3548C14.23 13.7899 14.4878 13.0487 14.4444 12.2939C14.3548 11.3781 13.8172 10.4937 12.9866 10.0744C12.586 9.84229 12.1281 9.75806 11.6703 9.73925C11.6568 8.91308 11.6703 8.09409 11.664 7.27419C15.2186 7.26792 18.7733 7.26165 22.3342 7.27419C23.7473 7.3638 25.1801 7.86201 26.2312 8.83602C26.9387 9.49886 27.4138 10.3721 27.586 11.3262C27.741 12.4677 27.638 13.7133 26.9471 14.6747C26.509 15.3584 25.8378 15.8548 25.1219 16.2294C26.2312 16.655 27.2249 17.4355 27.7796 18.5C28.5412 19.9588 28.5215 21.8038 27.7025 23.2294C27.1407 24.2294 26.1989 24.9839 25.1478 25.4229C24.0968 25.862 22.9283 26.0161 21.793 26.0036H11.6676C11.6649 25.1676 11.6711 24.3351 11.6649 23.5036H11.6667ZM28.3333 32.7222C26.069 32.7482 23.7984 32.7294 21.5233 32.7357C18.2392 32.7294 14.9561 32.7482 11.672 32.7222C11.6523 31.8324 11.6523 30.9355 11.672 30.0385C15.1039 30.0125 18.5367 30.0385 21.9767 30.0323C24.0932 30.0385 26.2159 30.0125 28.3315 30.0385C28.3486 30.9355 28.3486 31.8324 28.3289 32.7222H28.3333Z"
        fill="#149F49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.536 15.1612C21.0969 15.1486 21.665 15.2131 22.2063 15.0384C23.0969 14.7866 23.8971 14.0447 24.0387 13.1029C24.1677 12.3287 24.0199 11.4712 23.4778 10.8771C22.9034 10.2382 22.0127 9.92911 21.1677 9.96136H17.1292C17.1355 11.697 17.1355 13.4255 17.1292 15.1585L20.536 15.1612Z"
        fill="#149F49"
      />
      <path
        d="M21.794 26.0002C22.9293 26.0128 24.0969 25.8712 25.1489 25.4196C26.2008 24.968 27.1417 24.226 27.7035 23.226C28.5225 21.8004 28.5423 19.9554 27.7806 18.4966C27.2259 17.4321 26.2322 16.6516 25.1229 16.226C25.8397 15.8515 26.51 15.355 26.9482 14.6713C27.639 13.7099 27.7421 12.4643 27.5871 11.3228C27.4148 10.3687 26.9397 9.49546 26.2322 8.83262C25.1793 7.85861 23.7456 7.36129 22.3352 7.27079C18.7743 7.25825 15.2196 7.26452 11.665 7.27079C11.6713 8.09069 11.6578 8.90968 11.6713 9.73585C12.1291 9.75467 12.587 9.8389 12.9876 10.071C13.8191 10.4903 14.3549 11.3747 14.4455 12.2905C14.4888 13.0453 14.231 13.7865 13.7286 14.3514C13.2053 14.9258 12.4446 15.2869 11.6677 15.2547C11.6739 16.0029 11.6677 16.7511 11.6739 17.5065C12.2161 17.545 12.7707 17.6678 13.2161 17.9903C14.0288 18.5127 14.4929 19.4868 14.4481 20.442C14.4829 20.9353 14.3942 21.4296 14.1901 21.8801C13.751 22.8416 12.7322 23.519 11.6677 23.5002C11.6739 24.3318 11.6677 25.1642 11.6677 25.9966L21.794 26.0002ZM24.6005 21.1095C24.5021 21.5546 24.2924 21.9674 23.991 22.3093C23.6897 22.6513 23.3065 22.9112 22.8774 23.0647C22.2456 23.3031 21.5611 23.278 20.8971 23.278C19.6426 23.278 18.3935 23.2708 17.1417 23.2843C17.1291 21.3936 17.1291 19.5032 17.1417 17.6131C18.1811 17.6328 19.2259 17.6194 20.2707 17.6194C20.9741 17.6256 21.6901 17.5871 22.3872 17.6973C23.1229 17.8452 23.8326 18.252 24.2385 18.8972C24.6713 19.5423 24.7483 20.3613 24.6005 21.1095V21.1095ZM17.1291 9.96166H21.1677C22.0127 9.9294 22.9034 10.2385 23.4777 10.8774C24.0198 11.4715 24.1677 12.329 24.0387 13.1032C23.8971 14.045 23.0969 14.7869 22.2062 15.0387C21.6686 15.2135 21.0969 15.1489 20.536 15.1615H17.1309C17.1354 13.4258 17.1354 11.6973 17.1291 9.96166V9.96166Z"
        fill="white"
      />
      <path
        d="M21.9741 30.0327C18.536 30.039 15.1032 30.013 11.6695 30.039C11.6498 30.935 11.6498 31.8311 11.6695 32.7227C14.9535 32.7486 18.2367 32.7298 21.5207 32.7361C23.7922 32.7298 26.0629 32.7486 28.3308 32.7227C28.3505 31.8329 28.3505 30.9359 28.3308 30.039C26.2134 30.013 24.0906 30.039 21.9741 30.0327V30.0327Z"
        fill="#73C692"
      />
    </svg>
  );
};

export default BitStamp;
