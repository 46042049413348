import useStyleRewriter from "../../../zui/functions/use-style-rewriter";

export default function H4({ children, className = "" }) {
  const srClasses = useStyleRewriter(baseClasses, className);

  return <p className={srClasses}>{children}</p>;
}

const baseClasses = `
    @fts text-base md:text-lg
    @tta text-left
    @vla align-top
    @leh leading-22px md:leading-6`;
