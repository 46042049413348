import { ethers } from "ethers";

import erc20TokenAbi from "~abi/ERC20.json";
import managerAbi from "~abi/SubscriptionManager.json";
import pancakeRouterAbi from "~abi/PancakeRouter.json";
import { contracts, DEFAULT } from "~web3/configs/constants";
import { defaultProvider } from "~web3/configs/networks";

export const getContract = (address, abi, signerOrProvider) => {
  return new ethers.Contract(address, abi, signerOrProvider || defaultProvider);
};

export const getErc20Contract = (address, signerOrProvider) => {
  return getContract(address, erc20TokenAbi, signerOrProvider);
};

export const getPaymentTokenContract = (
  chainId = DEFAULT,
  signerOrProvider,
) => {
  return getErc20Contract(
    contracts.tokens.BUSD.address[chainId],
    signerOrProvider,
  );
};

export const getManagerContract = (chainId = DEFAULT, signerOrProvider) => {
  return getContract(
    contracts.manager.address[chainId],
    managerAbi,
    signerOrProvider,
  );
};

export const getPancakeRouterContract = (
  chainId = DEFAULT,
  signerOrProvider,
) => {
  return getContract(
    contracts.pancakeRouter.address[chainId],
    pancakeRouterAbi,
    signerOrProvider,
  );
};
