export default function MetamaskLines({ className }) {
  return (
    <svg
      width="45"
      height="15"
      viewBox="0 0 45 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <line
        y1="-0.5"
        x2="11.4275"
        y2="-0.5"
        transform="matrix(-0.651785 -0.758404 0.934801 -0.355172 8.44824 13.6666)"
        stroke="black"
      />
      <line x1="24.5" y1="11" x2="24.5" stroke="black" />
      <line
        x1="39.6877"
        y1="12.6096"
        x2="44.6877"
        y2="8.60957"
        stroke="black"
      />
    </svg>
  );
}
