import CardBox from "~components/ui/card-box";

import React, { Fragment, useEffect, useState } from "react";

import SettingsLabel from "../settings-label";

import AddNewBox from "~ui/AddNewBox";

import {
  useCreatePermissionConfigMutation,
  useGetPermissionConfigQuery,
  useUpdatePermissionConfigMutation,
} from "~services/backend/permission-configs";
import useProfile from "~services/backend/profiles/useProfile";

import SettingsNavbar from "~components/layout/SettingsNavbar";
import PermissionSetCard from "./PermissionSetCard";
import Modal from "~components/popups/modal";
import AllIconsLocal from "~components/ui/all-icons";
import AllIcons from "~components/zui/components/all-icons";
import DeleteConfigCard from "./DeleteConfigCard";
import { permissionsButtons } from "~components/chat/PermissionConfigPreview";

const MessagesSettings = ({}) => {
  const [chatConfigs, setChatConfigs] = useState([]);
  const [currentChatConfig, setCurrentChatConfig] = useState();
  const [configToDelete, setConfigToDelete] = useState();

  const { profile } = useProfile();

  const { data: permissionConfig, refetch: refetchGetPermissionConfig } =
    useGetPermissionConfigQuery(
      { userId: profile?.id },
      { skip: !profile?.id },
    );

  useEffect(() => {
    let newChatConfigs = permissionConfig?.chat || [];

    if (newChatConfigs.length) {
      newChatConfigs = newChatConfigs.map((config) => {
        const newConfig = { ...config };
        if (newConfig.levels?.length) {
          newConfig.levels = config.levels.map((level) => +level || level.id);
        }

        return newConfig;
      });
    }
    setChatConfigs(newChatConfigs);
  }, [permissionConfig?.chat]);

  const [createPermissionConfig, createPermissionConfigResult] =
    useCreatePermissionConfigMutation();
  const [updatePermissionConfig, updatePermissionConfigResult] =
    useUpdatePermissionConfigMutation();

  const onSubmit = async ({ configs = chatConfigs }) => {
    const transformedConfigs = configs.filter(Boolean).map((config) => ({
      ...config,
      followed_by_user: config.followedByUser,
    }));
    if (permissionConfig) {
      await updatePermissionConfig({
        id: permissionConfig.id,
        data: { data: { chat: transformedConfigs, chat_enabled: true } },
      });
    } else {
      await createPermissionConfig({
        data: { chat: transformedConfigs, chat_enabled: true },
      });
    }
    refetchGetPermissionConfig();
  };

  useEffect(() => {
    setCurrentChatConfig(undefined);
  }, [
    createPermissionConfigResult.isSuccess,
    updatePermissionConfigResult.isSuccess,
  ]);

  return (
    <>
      <CardBox className="@brw border-0 lg:border-[#F5F5F5] lg:shadow-base">
        <div className="flex-col items-start">
          <SettingsNavbar />
          <div className="flex flex-col mb-6 md:mb-20 w-full">
            <SettingsLabel
              label="DM permissions"
              description="Сhoose the conditions under which users can write messages to you."
            />
            <div className="flex flex-col mb-5">
              {permissionConfig?.chat?.length
                ? permissionConfig?.chat.map((config, index) => {
                    return (
                      <div
                        className="flex items-start lg:items-center justify-between p-4 lg:p-5 mb-4 w-full bg-white rounded-2xl border shadow-md"
                        key={`config-${config.id}`}
                      >
                        <PermissionsList config={config} />
                        <div className="flex items-center">
                          <div onClick={() => setCurrentChatConfig(index)}>
                            <AllIconsLocal
                              name="Edit"
                              className="min-w-5 w-5 h-5 mr-3 cursor-pointer text-zinc-base hover:text-zinc-900 "
                            />
                          </div>
                          <div onClick={() => setConfigToDelete(index)}>
                            <AllIcons
                              name="Close"
                              className="min-w-7 w-7 h-7 cursor-pointer text-zinc-base hover:text-zinc-900 "
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <AddNewBox
              onClick={() => setCurrentChatConfig(chatConfigs.length)}
              title="Add new permission"
              className="!min-h-20"
            />
          </div>
        </div>
      </CardBox>
      <Modal
        show={typeof currentChatConfig === "number"}
        setShow={() => setCurrentChatConfig(undefined)}
        RenderCard={PermissionSetCard}
        renderCardProps={{
          permissionsConfig: chatConfigs[currentChatConfig],
          setPermissionsConfig: (config) => {
            const newConfigs = [...chatConfigs];
            newConfigs[currentChatConfig] = config;
            setChatConfigs(newConfigs);
          },
          levels: profile?.subscriptionLevels,
          onSubmit,
          loading:
            createPermissionConfigResult.isLoading ||
            updatePermissionConfigResult.isLoading,
        }}
        childContainerClasses={"@wh w-full lg:w-fit"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />
      <Modal
        show={typeof configToDelete === "number"}
        setShow={() => setConfigToDelete(undefined)}
        RenderCard={DeleteConfigCard}
        renderCardProps={{
          onSubmit: () => {
            const newChatConfigs = [...chatConfigs];
            newChatConfigs[configToDelete] = null;
            onSubmit({ configs: newChatConfigs });
            setConfigToDelete(undefined);
          },
          loading: updatePermissionConfigResult.isLoading,
        }}
        childContainerClasses={"@wh w-full lg:w-fit"}
        containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
      />
    </>
  );
};

export default MessagesSettings;

export const PermissionsList = ({
  config,
  containerClassName = "",
  handlers = {},
  author,
  conditionState = {},
}) => {
  const configKeys = Object.keys(config).filter(
    (key) =>
      (Array.isArray(config[key]) ? config[key].length : !!config[key]) &&
      permissionsButtons[key],
  );

  return (
    <div className={`flex flex-wrap gap-2 items-center ${containerClassName}`}>
      {configKeys.map((key, index) => {
        const PermissionButton = permissionsButtons[key];

        const isLast = index === configKeys.length - 1;
        const isActive = conditionState[key];

        return (
          <Fragment key={`config-${config.id}-condition-${key}`}>
            <PermissionButton
              data={config[key]}
              handler={handlers[key] ? handlers[key] : undefined}
              active={isActive}
              author={author}
            />

            {isLast ? null : <span className="font-semibold">{"&"}</span>}
          </Fragment>
        );
      })}
    </div>
  );
};
