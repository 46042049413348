const LikeFilled = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M4.19346 8.05707C4.19346 7.8821 4.04618 7.73535 3.85766 7.73535C3.51007 7.73535 3.16249 7.73535 2.81491 7.73535C1.42457 7.741 0.299348 8.81338 0.293457 10.1454C0.293457 12.1152 0.293457 14.0794 0.293457 16.0492C0.293457 16.4386 0.399499 16.8224 0.599801 17.1554C1.11234 18.0133 1.89587 18.4254 2.92684 18.4254C3.23908 18.4254 3.55131 18.4254 3.85766 18.4254C4.04029 18.4254 4.19346 18.2842 4.19346 18.1036V8.05707Z"
        fill="#0065FF"
      />

      <path
        d="M19.5001 8.08598C19.2516 6.90565 18.2916 6.12065 17.0886 6.115C16.0947 6.10935 15.1007 6.115 14.1067 6.115C14.1067 5.426 14.1067 4.77654 14.1067 4.13272C14.1067 3.79952 14.1293 3.46067 14.0842 3.13311C13.8752 1.59699 12.7457 0.501372 11.2039 0.292414C10.6787 0.218996 10.2834 0.371479 9.99536 0.795043C9.54356 1.44451 9.29506 1.75512 9.15388 2.08268C8.84891 2.77167 7.83236 4.53934 6.17199 7.28404C6.13246 7.34616 6.06469 7.46476 6.00821 7.61724C5.86702 7.98998 5.87267 8.30624 5.87267 8.37965C5.87267 9.04606 5.88961 16.2918 5.88961 17.8392C5.88961 17.9974 6.15505 18.3758 6.53908 18.4774C6.64073 18.5056 6.73674 18.5056 6.79886 18.5C11.7969 18.5056 15.4339 18.4944 15.9761 18.4887C16.106 18.4887 16.4222 18.4774 16.795 18.3419C16.9926 18.2684 17.1733 18.1724 17.3428 18.0595C17.851 17.715 18.2181 17.1841 18.3311 16.5516C18.5457 15.3543 18.7095 14.1514 18.8958 12.9485C19.0879 11.6891 19.2855 10.4241 19.4775 9.16466C19.534 8.80322 19.5792 8.44742 19.5001 8.08598Z"
        fill="#0065FF"
      />
    </svg>
  );
};

export default LikeFilled;
