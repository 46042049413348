import React from "react";

const Coin = ({ className }) => (
  <svg
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M4.5 9C2.01465 9 0 6.98535 0 4.5C0 2.01465 2.01465 0 4.5 0C6.98535 0 9 2.01465 9 4.5C9 6.98535 6.98535 9 4.5 9ZM4.5 8.1C5.45478 8.1 6.37045 7.72072 7.04558 7.04558C7.72072 6.37045 8.1 5.45478 8.1 4.5C8.1 3.54522 7.72072 2.62955 7.04558 1.95442C6.37045 1.27928 5.45478 0.9 4.5 0.9C3.54522 0.9 2.62955 1.27928 1.95442 1.95442C1.27928 2.62955 0.9 3.54522 0.9 4.5C0.9 5.45478 1.27928 6.37045 1.95442 7.04558C2.62955 7.72072 3.54522 8.1 4.5 8.1ZM4.5 2.2725L6.7275 4.5L4.5 6.7275L2.2725 4.5L4.5 2.2725ZM4.5 3.54555L3.54555 4.5L4.5 5.45445L5.45445 4.5L4.5 3.54555Z" />
  </svg>
);

export default Coin;
