import React from "react";

const Telegram = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M1.2885 9.71295L5.05203 11.0958L6.50874 15.7078C6.60195 16.0032 6.96886 16.1124 7.21239 15.9164L9.31024 14.2327C9.53014 14.0563 9.84336 14.0475 10.0732 14.2118L13.857 16.9162C14.1175 17.1026 14.4866 16.9621 14.5519 16.6522L17.3237 3.52631C17.395 3.18778 17.0571 2.90537 16.7297 3.03004L1.28409 8.89594C0.902919 9.04066 0.90624 9.57193 1.2885 9.71295ZM6.27398 10.3597L13.6293 5.89988C13.7615 5.81996 13.8975 5.99593 13.784 6.09959L7.71371 11.6546C7.50034 11.8501 7.36271 12.1118 7.32372 12.3958L7.11694 13.9044C7.08956 14.1058 6.80215 14.1258 6.74569 13.9309L5.95042 11.1799C5.85933 10.8661 5.99207 10.531 6.27398 10.3597Z" />
    </svg>
  );
};

export default Telegram;
