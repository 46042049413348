import React from "react";

const Smile = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.45946C5.83547 2.45946 2.45946 5.83547 2.45946 10C2.45946 14.1645 5.83547 17.5405 10 17.5405C14.1645 17.5405 17.5405 14.1645 17.5405 10C17.5405 5.83547 14.1645 2.45946 10 2.45946ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33832 10.5283C7.66074 10.2865 8.11813 10.3518 8.35994 10.6743C8.59964 10.9939 8.83023 11.1874 9.06882 11.3067C9.30742 11.426 9.60061 11.4943 10.0001 11.4943C10.3996 11.4943 10.6928 11.426 10.9314 11.3067C11.17 11.1874 11.4006 10.9939 11.6403 10.6743C11.8821 10.3518 12.3395 10.2865 12.6619 10.5283C12.9843 10.7701 13.0496 11.2275 12.8078 11.5499C12.4649 12.0072 12.069 12.3696 11.5841 12.6121C11.0991 12.8546 10.5716 12.9538 10.0001 12.9538C9.42858 12.9538 8.9011 12.8546 8.41613 12.6121C7.93116 12.3696 7.53529 12.0072 7.19238 11.5499C6.95056 11.2275 7.01591 10.7701 7.33832 10.5283Z"
    />
    <circle cx="6.38598" cy="8.61034" r="0.833977" />
    <circle cx="13.614" cy="8.61034" r="0.833977" />
  </svg>
);

export default Smile;
