import useStyleRewriter from "../../zui/functions/use-style-rewriter";

const CardBox = ({
  children,
  className,
  maxContentHeight = true,
  onClick = null,
  id = null,
}) => {
  const srClasses = useStyleRewriter(baseClasses, className);

  return (
    <div
      id={id}
      style={maxContentHeight ? { height: "max-content" } : null}
      className={srClasses}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default CardBox;

const baseClasses = `
  @bdc bg-white
  @pn relative 
  @brw border-px
  @pg p-4 md:p-8
  @brr rounded-16px
  @bxsw shadow-400
  @wh w-full
  duration-100
`;
