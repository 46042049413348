export default function Message({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M17.5 9.03125C17.5 11.0472 17.2531 12.285 16.1334 13.7812C15.1537 15.0912 16.065 16.0172 17.3487 17.1494C17.4007 17.1956 17.4374 17.2564 17.4541 17.3238C17.4707 17.3913 17.4665 17.4622 17.4421 17.5273C17.4176 17.5923 17.374 17.6484 17.317 17.6882C17.26 17.7279 17.1923 17.7495 17.1228 17.75H8.75C7.60093 17.75 6.46312 17.5237 5.40152 17.0839C4.33992 16.6442 3.37533 15.9997 2.56282 15.1872C1.7503 14.3747 1.10578 13.4101 0.666054 12.3485C0.226325 11.2869 0 10.1491 0 9C0 7.85093 0.226325 6.71312 0.666054 5.65152C1.10578 4.58992 1.7503 3.62533 2.56282 2.81282C3.37533 2.0003 4.33992 1.35578 5.40152 0.916054C6.46312 0.476325 7.60093 0.25 8.75 0.25C11.0692 0.272029 13.2868 1.20525 14.9238 2.84818C16.5609 4.49112 17.4862 6.71196 17.5 9.03125Z" />
    </svg>
  );
}
