export default function Cog({ className }) {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.2071 8.10066C21.5976 7.71014 21.5976 7.07697 21.2071 6.68645L14.8431 0.322488C14.4526 -0.0680363 13.8195 -0.0680363 13.4289 0.322488C13.0384 0.713012 13.0384 1.34618 13.4289 1.7367L19.0858 7.39356L13.4289 13.0504C13.0384 13.4409 13.0384 14.0741 13.4289 14.4646C13.8195 14.8551 14.4526 14.8551 14.8431 14.4646L21.2071 8.10066ZM0.5 8.39355L20.5 8.39356L20.5 6.39356L0.5 6.39355L0.5 8.39355Z"
        fill="black"
      />
    </svg>
  );
}
