import React from "react";

const Important = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.1217 0 12.1566 0.842854 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462403 11.1126 0.153721 9.56072C-0.15496 8.00887 0.00346628 6.40034 0.608967 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824ZM4.19038 13.7015C5.31803 14.455 6.64379 14.8571 8 14.8571C9.81863 14.8571 11.5628 14.1347 12.8487 12.8487C14.1347 11.5628 14.8571 9.81863 14.8571 8C14.8571 6.64378 14.455 5.31803 13.7015 4.19037C12.948 3.06272 11.8771 2.18383 10.6241 1.66483C9.37114 1.14582 7.9924 1.01003 6.66224 1.27461C5.33209 1.5392 4.11026 2.19228 3.15127 3.15127C2.19228 4.11026 1.5392 5.33208 1.27462 6.66224C1.01003 7.99239 1.14583 9.37113 1.66483 10.6241C2.18383 11.8771 3.06273 12.948 4.19038 13.7015ZM5.0368 3.56545C5.91386 2.97942 6.945 2.66663 7.99984 2.66663C9.41433 2.66663 10.7709 3.22853 11.7711 4.22872C12.7713 5.22892 13.3332 6.58547 13.3332 7.99996C13.3332 9.05479 13.0204 10.0859 12.4343 10.963C11.8483 11.8401 11.0154 12.5236 10.0408 12.9273C9.06628 13.331 7.99392 13.4366 6.95936 13.2308C5.92479 13.025 4.97448 12.5171 4.2286 11.7712C3.48272 11.0253 2.97477 10.075 2.76898 9.04044C2.5632 8.00587 2.66881 6.93352 3.07248 5.95898C3.47615 4.98444 4.15974 4.15149 5.0368 3.56545ZM8.00001 11.5556C8.49093 11.5556 8.8889 11.1576 8.8889 10.6667C8.8889 10.1757 8.49093 9.77778 8.00001 9.77778C7.50909 9.77778 7.11112 10.1757 7.11112 10.6667C7.11112 11.1576 7.50909 11.5556 8.00001 11.5556ZM7.11112 5.33333C7.11112 4.84241 7.50909 4.44444 8.00001 4.44444C8.49093 4.44444 8.8889 4.84241 8.8889 5.33333V8C8.8889 8.49092 8.49093 8.88889 8.00001 8.88889C7.50909 8.88889 7.11112 8.49092 7.11112 8V5.33333Z"
    />
  </svg>
);

export default Important;
