const Share = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4 15.9999L15.7479 4.1606"
        stroke="#1D1D1D"
        strokeWidth="2"
        className="stroke-inherit"
      />
      <path
        d="M4.92383 4H15.9999V15.1622"
        stroke="#1D1D1D"
        strokeWidth="2"
        className="stroke-inherit"
      />
    </svg>
  );
};

export default Share;
