import { useMemo } from "react";
import { useRouter } from "next/router";
import Button from "~components/ui/button";
import useProfile from "~services/backend/profiles/useProfile";
import CardBox from "~components/ui/card-box";

export default function Sidebar({}) {
  const { profile } = useProfile();
  const { asPath } = useRouter();

  const navItems = useMemo(
    () => [
      { id: "home", title: "Feed", href: "/home" },
      {
        id: profile ? profile.username : "profile",
        title: "Profile",
        href: profile ? `/${profile.username}` : "/profile",
      },
      {
        id: "chats",
        title: "Chat",
        href: "/chats",
      },
      {
        id: "settings/subscription",
        title: "Subscription",
        href: "/settings/subscription",
      },
      // { id: `storage`, title: `Storage`, href: `/settings/storage` },
      { id: "referral", title: "Referral", href: "/settings/referral" },
      { id: "settings/account", title: "Settings", href: "/settings/account" },
    ],
    [profile],
  );

  return (
    <CardBox
      className={
        "@pg p-[10px] @brw lg:border @brc lg:border-[#F5F5F5] @pn sticky top-2 @bdc bg-transparent lg:bg-white @dy flex flex-row lg:flex-col @ow overflow-x-scroll gap-1 -mx-4 w-[calc(100%+2rem)] lg:mx-0 lg:w-full lg:shadow-base"
      }
    >
      {navItems.map((item) => (
        <Button
          key={item.id}
          href={item.href}
          className={`lg:leading-[22px] mr-4 flex cursor-pointer justify-start rounded-full px-10 py-3 font-clash text-base font-medium duration-200 lg:mr-0 lg:rounded-[10px] lg:py-5 lg:px-5 lg:text-[18px] last-of-type:mr-0 ${
            asPath === item.href
              ? "text-white lg:text-zinc-900 bg-black lg:bg-stone-base cursor-default"
              : "bg-white text-zinc-base hover:bg-stone-base hover:text-black"
          }`}
        >
          {item.title}
        </Button>
      ))}
    </CardBox>
  );
}
