import React from "react";
import TextInput from "~components/input/text-input";
import TextArea from "~components/input/textarea";

import P from "~components/ui/typography/p";
import getFormattedPrice from "~utils/vanilla/formatters/get-formatted-price";

const DonateForm = ({
  balance,
  tokensValue,
  setTokensValue,
  message,
  disabled,
  handleMessageChange,
}) => {
  return (
    <>
      <div className="relative mt-[60px] w-full mb-10">
        <div className="absolute top-0 right-0 leading-none text-14px text-zinc-base">
          Balance: {getFormattedPrice(balance)} BUSD
        </div>
        <TextInput
          label={"Amount"}
          onChange={(e) => {
            setTokensValue(e.target.value);
          }}
          value={tokensValue}
          incrementButtonVariant="white"
          inputsClassName={
            "@bdc bg-transparent @brc border-gray-base focus:border-black @bro border-opacity-100 @ttc text-black @ftf font-montserrat @fts text-base"
          }
          type="number"
          inputContainerClasses="@mn mb-2"
        />
        <P className="@pn absolute @it right-10 bottom-[18px] @ttc text-zinc-base">
          BUSD
        </P>
      </div>
      <div className="w-full mb-10">
        <div className="flex pb-2 justify-start @ftf font-clash">
          <P className="leading-none @fts text-16px @ttc text-zinc-base">
            Message
          </P>
        </div>
        <div className="w-full h-[116px]">
          <textarea
            disabled={disabled}
            maxLength={350}
            className={
              "w-full min-h-[116px] p-3 rounded-[10px] text-zinc-900 border border-gray-base hover:border-black focus:border-black text-16px focus:outline-4 focus:outline focus:outline-offset-0 focus:outline-gray-100 transition-all max-h-[116px]"
            }
            onChange={handleMessageChange}
            value={message || ""}
          />
        </div>
      </div>
    </>
  );
};

export default DonateForm;
