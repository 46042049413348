export default function User({ className }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M11.169 11.5521C13.4995 11.5521 15.3888 9.70481 15.3888 7.42605C15.3888 5.14729 13.4995 3.29999 11.169 3.29999C8.83844 3.29999 6.94916 5.14729 6.94916 7.42605C6.94916 9.70481 8.83844 11.5521 11.169 11.5521Z" />
      <path d="M17.8188 17.4163C18.0139 17.3406 18.1891 17.2229 18.3312 17.0719C18.4733 16.9209 18.5788 16.7405 18.6398 16.5441C18.7008 16.3478 18.7157 16.1405 18.6834 15.9377C18.6511 15.7349 18.5724 15.5419 18.4533 15.3729C17.6191 14.1946 16.5053 13.2316 15.2071 12.5665C13.9089 11.9014 12.4652 11.5539 10.9996 11.5539C9.53399 11.5539 8.09024 11.9014 6.79207 12.5665C5.49391 13.2316 4.38003 14.1946 3.54591 15.3729C3.42693 15.5421 3.34849 15.7354 3.3164 15.9383C3.28431 16.1413 3.29941 16.3487 3.36056 16.5451C3.42172 16.7416 3.52736 16.9221 3.66964 17.0731C3.81193 17.2242 3.98719 17.3419 4.18242 17.4176C6.37999 18.274 8.72546 18.7092 11.0911 18.6997C13.396 18.6867 15.678 18.2514 17.8188 17.4163Z" />
    </svg>
  );
}
