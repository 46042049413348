import React from "react";

const ArrowCircled = ({ className }) => {
  return (
    <svg
      className={`${className} fill-current`}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.98002 4.8261C4.98148 4.45021 5.28738 4.14668 5.66327 4.14814L9.65837 4.16368C10.0322 4.16514 10.3349 4.46782 10.3363 4.84164L10.3519 8.83674C10.3533 9.21262 10.0498 9.51852 9.67391 9.51999C9.29802 9.52145 8.99212 9.21792 8.99066 8.84203L8.98147 6.48106L5.82554 9.63699C5.55975 9.90278 5.12881 9.90278 4.86302 9.63699C4.59723 9.37119 4.59723 8.94026 4.86302 8.67446L8.01895 5.51853L5.65797 5.50935C5.28209 5.50788 4.97856 5.20198 4.98002 4.8261ZM11.4877 3.01226C9.28634 0.810866 5.71366 0.810866 3.51226 3.01226C1.31087 5.21366 1.31087 8.78634 3.51226 10.9877C5.71366 13.1891 9.28634 13.1891 11.4877 10.9877C13.6891 8.78634 13.6891 5.21366 11.4877 3.01226ZM2.54974 2.04974C5.28272 -0.683246 9.71728 -0.683246 12.4503 2.04974C15.1832 4.78272 15.1832 9.21728 12.4503 11.9503C9.71728 14.6832 5.28272 14.6832 2.54974 11.9503C-0.183246 9.21728 -0.183246 4.78272 2.54974 2.04974Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowCircled;
