export default function Smile({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76468 11.5588C7.00834 11.4289 7.3112 11.521 7.44115 11.7647C7.72767 12.3019 8.07774 12.6784 8.48598 12.9233C8.89422 13.1683 9.39113 13.3 9.99998 13.3C10.6088 13.3 11.1057 13.1683 11.514 12.9233C11.9222 12.6784 12.2723 12.3019 12.5588 11.7647C12.6887 11.521 12.9916 11.4289 13.2353 11.5588C13.4789 11.6887 13.5711 11.9916 13.4412 12.2353C13.0847 12.9036 12.6179 13.4271 12.0285 13.7808C11.439 14.1345 10.7574 14.3 9.99998 14.3C9.24254 14.3 8.56094 14.1345 7.97148 13.7808C7.38203 13.4271 6.91524 12.9036 6.5588 12.2353C6.42885 11.9916 6.52103 11.6887 6.76468 11.5588Z"
      />
      <circle cx="6.75" cy="8.75" r="0.75" />
      <circle cx="13.75" cy="8.75" r="0.75" />
    </svg>
  );
}
