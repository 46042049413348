import React from "react";

const Dashboard = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5436 8C22.5469 8 23.3636 8.78372 23.3636 9.74637V18.0524C23.3636 19.015 22.5469 19.7987 21.5436 19.7987H8.82005C7.81678 19.7987 7 19.015 7 18.0524V9.74637C7 8.78372 7.81678 8 8.82005 8H21.5436ZM21.1876 14.8281H21.1894V11.4087C21.1894 11.1869 21.003 11.0079 20.7717 11.0079H17.2118C16.9806 11.0079 16.7941 11.1869 16.7941 11.4087C16.7941 11.6306 16.9806 11.8095 17.2118 11.8095H19.8151C19.8126 11.8113 19.8101 11.8129 19.8076 11.8145C19.8026 11.8177 19.7977 11.8209 19.7927 11.8257C18.3214 13.1855 16.2011 15.1413 15.4757 15.8015C14.9834 15.3578 13.8347 14.2394 12.7978 13.207C12.7214 13.1319 12.6151 13.0871 12.5051 13.0853C12.3932 13.0817 12.2887 13.1247 12.2085 13.1981L9.12976 16.0466C8.96379 16.2005 8.9582 16.4546 9.11857 16.6139C9.27895 16.7731 9.54375 16.7785 9.70972 16.6246L12.4883 14.0551C12.7699 14.3343 13.2193 14.778 13.6687 15.2146C14.0995 15.6333 14.4482 15.9661 14.7018 16.2023C14.8566 16.3472 14.9759 16.4546 15.0654 16.5298L15.0675 16.5315C15.1628 16.6122 15.3148 16.7409 15.4981 16.7409C15.5708 16.7409 15.6472 16.7212 15.7256 16.6711C15.7648 16.646 15.8435 16.5957 20.3521 12.4251V14.8281C20.3521 15.05 20.5386 15.2289 20.7699 15.2289C21.0011 15.2289 21.1876 15.05 21.1876 14.8281ZM20.2591 21.2358C20.2591 21.0139 20.0708 20.835 19.8414 20.835H10.4428C10.2115 20.835 10.0251 21.0139 10.0251 21.2358C10.0251 21.4577 10.2115 21.6366 10.4428 21.6366H19.8414C20.0726 21.6366 20.2591 21.4577 20.2591 21.2358Z"
      />
    </svg>
  );
};

export default Dashboard;
