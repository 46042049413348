import React, { useEffect, useState } from "react";
import P from "~components/ui/typography/p";
import Button from "~components/ui/button";
import AllIcons from "~components/ui/all-icons";

import Web3Action from "~components/web3/Web3Action";

import PaidModalBase from "./donate-modal/paid-modal-base";
import { usePurchase } from "~hooks/web3/use-contract";
import useToken, { FetchStatus } from "~hooks/web3/use-token";

const PurchaseItemCard = ({ setShow, type, price, itemId }) => {
  const [waiting, setWaiting] = useState(false);
  const [sended, setSended] = useState(false);

  const { purchase } = usePurchase();
  const { allowance, approve, allowanceState } = useToken();

  const handleReset = () => {
    setWaiting(false);
    setSended(false);
  };

  const handleClose = () => {
    setShow(false);
    handleReset();
  };

  const handlePurchase = async () => {
    try {
      setWaiting(true);

      const tx = await purchase({ itemType: type, itemId });
      if (tx.status) {
        setSended(true);
      }

      setWaiting(false);
      setShow(false);
    } catch (e) {
      setWaiting(false);
    }
  };

  useEffect(() => {
    if (allowanceState.fetchStatus === FetchStatus.LOADING) {
      setWaiting(true);
    } else {
      setWaiting(false);
    }
  }, [allowanceState.fetchStatus]);

  const iconPath = sended && "/assets/images/heart.png";
  const title = sended ? "Purchased" : "Confirm purchase";

  const disabled = waiting;

  const buttonsSection = (
    <>
      {!sended && (
        <Button
          onClick={handleClose}
          className="@wh w-36 @jyc flex items-center justify-center @bxsw shadow-none py-3 @ht h-12 @brc border border-black @bdc bg-white @ttc text-black hover:bg-black hover:text-white @mn mr-2"
          variant="rounded"
        >
          <P variant="button" className="@ftf font-family-inter-medium">
            Cancel
          </P>
        </Button>
      )}
      <Web3Action>
        <Button
          onClick={
            allowance >= price
              ? handlePurchase
              : () => approve({ amount: price })
          }
          className="@wh w-36 @jyc @bxsw shadow-none hover:opacity-70 transition duration-30 text-white disabled:text-gray-450 py-3 flex justify-center items-center @ht h-12 "
          variant="rounded"
          disabled={disabled}
        >
          {waiting ? (
            <AllIcons
              name="SmallLoader"
              className="h-5 w-5 animate-spin flex justify-center items-center"
            />
          ) : (
            <P variant="button" className="@ftf font-family-inter-medium @ttc">
              {allowance >= price ? "Purchase" : "Approve"}
            </P>
          )}
        </Button>
      </Web3Action>
    </>
  );

  return (
    <PaidModalBase iconPath={iconPath} title={title} buttons={buttonsSection}>
      <span className="text-lg">
        Purchase {type} for <strong>{price}$</strong>
      </span>
    </PaidModalBase>
  );
};

export default PurchaseItemCard;
