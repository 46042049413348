import { isProd } from "~configs";

export const tokens = {};

export const contracts = {
  tokens: {
    BUSD: {
      address: {
        56: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        97: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
      },
    },
  },
  manager: {
    address: {
      56: "0xFa7B367e1E0DDE7252Ff7e291214D5E3EAB1edF7",
      97: "0xdae7a5559b8466dd8653e0bf1d350215114842be",
      80001: "0x3b8aE56D64fCFd382a346D023308E8B57414B5bd",
    },
    name: "SubscriptionManager",
  },
  pancakeRouter: {
    address: {
      56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      97: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    },
  },
};

export const adminAddress =
  process.env.NEXT_PUBLIC_ADMIN_ADDRESS ||
  (isProd
    ? "0xDb807b3148DaC406AA3f375E89e0318A96D0e0ba"
    : "0x93caccab9af3f9dc1a923aa41ff1753b9669f46d");

export const nullAddress = "0x0000000000000000000000000000000000000000";

export const minPrice = 1;
export const maxUint256 =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const maxSubscriptionLevels =
  process.env.NEXT_PUBLIC_MAX_SUBSCRIPTION_LEVELS || 3;

// CHAIN_IDS
const LOCAL = 31337;
const ETH = 1;
const ROPSTEN = 3;
const BSC = 56;
const BSC_T = 97;
const MUMBAI = 80001;
const MATIC = 137;

const DEFAULT =
  +process.env.NEXT_PUBLIC_CHAIN_ID ||
  (process.env.NODE_ENV === "production" ? BSC : BSC_T);

export { ETH, ROPSTEN, BSC, BSC_T, LOCAL, MATIC, MUMBAI, DEFAULT };
