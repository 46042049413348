export const selectIsConnectedWithWeb3 = (state) =>
  state.auth.isConnectedWithWeb3;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export const selectAccountId = (state) => state.auth.accountId;

export const selectAccountAddress = (state) => state.auth.accountAddress;

export const selectJwt = (state) => state.auth.jwt;

export const selectSignModalOpened = (state) => state.auth.signModalOpened;

export const selectIsAccountBindingInProgress = (state) =>
  state.auth.isAccountBindingInProgress;
