import { memo, useMemo } from "react";
import SmartButton from "../../zui/components/SmartButton";

const Button = memo((props) => {
  const passClasses = useMemo(() => {
    const classes = [];

    if (props.variant) {
      if (withBaseVariants.includes(props.variant)) {
        classes.push(variants.base);
      }

      if (variants[props.variant]) {
        classes.push(variants[props.variant]);
      }
    }

    if (props.className) {
      classes.push(props.className);
    }

    return classes
      .join(" ")
      .replace(/\n\s\s+/g, " ")
      .trim();
  }, [props.variant, props.className]);

  return (
    <SmartButton {...props} className={passClasses + baseClasses}>
      {props.children}
    </SmartButton>
  );
});

export default Button;

const baseClasses = " transition text-center";

const withBaseVariants = [
  "outline",
  "outlineWide",
  "swap",
  "arrow",
  "connect",
  "circle",
  "text",
  "terminal",
  "dropdown",
  "follow",
  "followed",
  "redOutline",
];

const variants = {
  border: `@cr cursor-pointer
    @dy flex
    @ani items-center
    @ttc text-gray-900 lg:hover:text-purple-base
    @tndn duration-200
    @brw border
    @brc border-zinc-900 lg:hover:border-purple-base
    @pg px-4 py-2
    @brr rounded-md
    @bxsw lg:hover:drop-shadow-lg`,
  purple: `@wh w-fit
    @fts text-16px @leh leading-22px
    @cr cursor-pointer
    @ftf font-montserrat
    @ftw font-semibold
    @dy flex
    @ani items-center
    @jyi justify-center
    @ttc text-white
    @tndn duration-200
    @bdc purple-gradient
    @pg px-10 py-3
    @brr rounded-md
    @bxsw lg:hover:drop-shadow-lg`,
  dark: `@wh w-fit
    @fts text-16px @leh leading-22px
    @cr cursor-pointer
    @ftf font-montserrat
    @ftw font-semibold
    @dy flex
    @ani items-center
    @jyi justify-center
    @ttc text-white
    @tndn duration-200
    @bdc bg-zinc-900 lg:hover:bg-purple-base
    @pg px-10 py-3
    @brr rounded-md
    @bxsw lg:hover:drop-shadow-lg`,
  gray: `@bdc bg-gray-100 lg:hover:bg-zinc-900 
    @ttc lg:hover:text-white text-zinc-900 
    @pg px-5 py-2 
    @fts text-14px
    @ftf font-clash
    @ftw font-semibold
    @brr rounded-full`,
  text: `@dy flex
    @ani items-center
    @ttc text-zinc-base lg:hover:text-zinc-900
    @fts text-14px
    @tndn duration-200
    @ftf font-montserrat
    @ftw font-normal`,
  header: `@ftf font-clash
    @ftw font-medium
    @fts text-[18px]
    @ttc text-zinc-900 lg:hover:text-pink-base
    @tndn duration-200
    @mn mx-[20px]`,
  lime: `@bdc bg-lime-base lg:hover:bg-zinc-900 
    @ttc text-zinc-900 lg:hover:text-white
    @tndn duration-200 
    @pg px-5 py-2 
    @brr rounded-full
    @fts text-14px
    @ftf font-clash
    @ftw font-semibold`,
  rounded: `@ftf font-clash
    @ftw font-semibold
    @fts text-md
    @ttc text-white lg:hover:text-zinc-900
    @pg px-5 py-2.5
    @bdc bg-zinc-900 lg:hover:bg-lime-base
    @tndn duration-200
    @brr rounded-full
    @cr cursor-pointer`,
};
