import { useRef, useState } from "react";
import { ChangeNumberButtons } from "~components/input/text-input";
import getFormattedPrice from "~utils/vanilla/formatters/get-formatted-price";

const AmountInput = ({
  isActive,
  value,
  setValue,
  setIsActive,
  inputAvailable,
  step = 1,
  className = "",
}) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;

    setValue(value && +(+value).toFixed(2));
  };

  return (
    <>
      {!isActive && (
        <div
          className={`z-10 relative text-zinc-base py-3 font-medium min-w-[152px] w-min flex justify-center items-center rounded-full text-16px leading-[22px] cursor-pointer border-2 border-[#DCDCDC] max-h-[50px] ${className}`}
          onClick={() => {
            setIsActive(true);
            if (!inputAvailable) return;
            inputRef.current.focus();
            setIsInputActive(true);
          }}
        >
          Own Amount
        </div>
      )}
      <div
        className={`${
          !isActive && "opacity-0 absolute -z-1 pointer-events-none"
        }`}
      >
        <div
          className={` max-h-[50px] relative min-w-32 flex justify-center items-center py-3 rounded-full text-16px leading-[22px] px-1.5 ${
            !inputAvailable && "cursor-pointer"
          }  border-2 ${
            isActive ? "border-zinc-900" : "border-[#DCDCDC]"
          } ${className}`}
        >
          {!isInputActive && (
            <span className={"p-0 font-semibold text-center "}>
              {getFormattedPrice(value)}
            </span>
          )}

          {inputAvailable && (
            <input
              type="number"
              ref={inputRef}
              className={`p-0 w-full font-semibold ${
                isInputActive ? "text-center " : "text-right opacity-0 absolute"
              }`}
              onChange={handleChange}
              onFocus={() => setIsInputActive(true)}
              onBlur={() => setIsInputActive(false)}
              value={value}
              min={"1"}
              max={"99999"}
            />
          )}
          {!isInputActive && (
            <span
              onClick={() => inputAvailable && inputRef.current.focus()}
              className={"w-fit ml-1 text-right"}
            >
              $
            </span>
          )}
          {!inputAvailable && (
            <ChangeNumberButtons
              value={value}
              onChange={handleChange}
              incrementButtonVariant={"gray"}
              stepValue={step}
              className="!static h-9 ml-2 !-my-2"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AmountInput;
