import { transformResponseItem } from "~api";

import { backendApi } from "..";

export const followingsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createFollowing: build.mutation({
      query: ({ authorId, level = null, txHash, tokenId }) => ({
        url: "followings",
        method: "POST",
        params: { populate: "*" },
        body: {
          data: {
            author: authorId,
            subscription_level: level,
            tx_hash: txHash,
            token_id: tokenId,
          },
        },
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (result, _error, { authorId }) => {
        const userId = result?.user?.id;

        const tags = [];
        if (result?.id) tags.push({ type: "Following", id: result?.id });
        if (userId) tags.push({ type: "Profile", id: userId });
        if (authorId) tags.push({ type: "Profile", id: authorId });

        return tags;
      },
    }),

    updateFollowing: build.mutation({
      query: ({ authorId, level, txHash, followingId, tokenId }) => ({
        url: `followings/${followingId}`,
        method: "PUT",
        params: { populate: "*" },
        body: {
          data: {
            author: authorId,
            subscription_level: level,
            tx_hash: txHash,
            token_id: tokenId,
          },
        },
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result, _error, { followingId, authorId }) => {
        const tags = [];
        if (followingId) tags.push({ type: "Following", id: followingId });
        if (authorId) tags.push({ type: "Profile", id: authorId });
        if (_result.user?.id)
          tags.push({ type: "Profile", id: _result.user.id });

        return tags;
      },
    }),

    deleteFollowing: build.mutation({
      query: (id) => ({
        url: `followings/${id}`,
        method: "DELETE",
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result, _error, id) => [{ type: "Following", id }],
    }),
  }),
});

export const {
  useCreateFollowingMutation,
  useUpdateFollowingMutation,
  useDeleteFollowingMutation,
} = followingsApi;
