import React from "react";

const Huobi = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.121 12.3033C24.121 6.5642 21.3249 1.6216 19.1954 0.0173669C19.1954 0.0173669 19.0316 -0.0730122 19.0485 0.158584C18.8734 11.247 13.2078 14.2521 10.0953 18.2965C2.91021 27.6395 9.58696 37.8805 16.3993 39.7729C20.1952 40.8348 15.5181 37.8975 14.9137 31.7009C14.1737 24.222 24.121 18.5055 24.121 12.3033V12.3033Z"
        fill="#1B2143"
      />
      <path
        d="M27.386 16.0825C27.3408 16.0543 27.2787 16.0317 27.2392 16.1051C27.1205 17.5003 25.6971 20.4829 23.8895 23.2281C17.7607 32.5259 21.2516 37.0109 23.2173 39.4229C24.3583 40.8181 23.2173 39.4229 26.0642 37.9938C29.5834 35.8586 31.8654 32.1643 32.2044 28.0634C32.7636 21.4544 28.7982 17.2913 27.386 16.0825V16.0825Z"
        fill="#2CA6E0"
      />
    </svg>
  );
};

export default Huobi;
