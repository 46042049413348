import React from "react";

const Star = ({ className }) => (
  <svg
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M0.203394 6.62696C0.361781 6.17513 0.668225 5.91499 1.15716 5.88076C1.288 5.87049 1.41884 5.86022 1.54968 5.84653C2.55165 5.75753 3.55362 5.66511 4.55559 5.57612C4.99288 5.53846 5.4336 5.49396 5.87089 5.46658C6.05682 5.45631 6.15323 5.39128 6.22898 5.20986C6.83843 3.76538 7.46164 2.32775 8.07798 0.886692C8.19504 0.61628 8.34999 0.390367 8.63577 0.273987C9.13159 0.0720336 9.65496 0.294524 9.87877 0.807965C10.3539 1.9033 10.8222 2.99864 11.2939 4.09741C11.4661 4.49447 11.6417 4.88811 11.8035 5.28859C11.8483 5.39812 11.9102 5.44262 12.0239 5.45289C12.592 5.50081 13.1636 5.55216 13.7317 5.6035C14.4307 5.66511 15.1331 5.73015 15.832 5.79518C16.2142 5.82941 16.5964 5.86022 16.9752 5.90472C17.3712 5.95264 17.6397 6.18198 17.7533 6.55508C17.8739 6.94529 17.7568 7.28416 17.4469 7.55457C16.803 8.11936 16.1557 8.68072 15.5118 9.24208C14.923 9.75552 14.3343 10.2724 13.7386 10.779C13.6525 10.8509 13.6491 10.9193 13.6697 11.0117C13.9727 12.333 14.2757 13.6542 14.5787 14.9789C14.6373 15.2391 14.6992 15.4992 14.7578 15.7594C14.8542 16.1701 14.7543 16.5227 14.4134 16.776C14.0657 17.0361 13.6938 17.0258 13.322 16.8033C12.2615 16.1735 11.201 15.5437 10.1439 14.9139C9.82367 14.7256 9.50001 14.5408 9.19013 14.3423C9.0524 14.2533 8.95599 14.2498 8.81138 14.3388C7.44787 15.1603 6.07404 15.9682 4.70709 16.7828C4.45574 16.9334 4.20094 17.0121 3.90827 16.9403C3.44 16.8239 3.11289 16.3447 3.21963 15.8723C3.52952 14.4723 3.85318 13.0723 4.16995 11.6724C4.2216 11.4465 4.2698 11.2171 4.32834 10.9912C4.349 10.9056 4.33867 10.8509 4.26636 10.7858C3.12322 9.79317 1.98352 8.80052 0.840385 7.80787C0.582146 7.58538 0.310133 7.37658 0.199951 7.03429C0.203394 6.90079 0.203394 6.76387 0.203394 6.62696Z" />
  </svg>
);

export default Star;
