import { transformResponseItem } from "~api";

import { postsPopulate, profilePopulate } from "~api/utils";
import { backendApi } from "~services/index";

export const profilesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    loginWithWeb3: build.query({
      query: ({ signature, expires, address }) => ({
        url: "auth/web3/callback",
        params: { signature, expires, address },
      }),

      transformResponse: transformResponseItem,
    }),

    loginWithEmail: build.query({
      query: ({ confirmation, email }) => ({
        url: "auth/email-confirmation",
        params: { confirmation, email },
      }),

      transformResponse: transformResponseItem,
    }),

    sendEmailCode: build.mutation({
      query: ({ email }) => ({
        url: "auth/send-email-confirmation",
        method: "POST",
        body: { email },
      }),

      transformResponse: transformResponseItem,
    }),

    getMe: build.query({
      query: () => ({
        url: "users/me",
      }),

      transformResponse: transformResponseItem,

      providesTags: (result) =>
        result ? [{ type: "Profile", id: result.id }] : [],
    }),

    getRecommendedProfiles: build.query({
      query: () => ({
        url: "users",
        params: {
          filters: { is_verified: true },
          start: 0,
          limit: 4,
          populate: profilePopulate,
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Profile", id })) : [],
    }),

    getProfileById: build.query({
      query: (id) => ({
        url: `users/${id}`,
        params: {
          populate: profilePopulate,
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result, _error, id) =>
        result
          ? [
              ...(result.followers?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              ...(result.followings?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              { type: "Profile", id },
            ]
          : [],
    }),

    getPreviewChatProfile: build.query({
      query: (id) => ({
        url: `users/${id}`,
        params: {
          populate: {
            ...profilePopulate,
            permission_config: {
              populate: ["chat", "chat.levels"],
            },
          },
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result, _error, id) =>
        result
          ? [
              ...(result.followings?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              { type: "Profile", id },
            ]
          : [],
    }),

    getProfileByName: build.query({
      query: (username) => ({
        url: "users",
        params: {
          filters: { username },
          populate: profilePopulate,
        },
      }),

      transformResponse: (response) =>
        response.length ? transformResponseItem(response?.[0]) : null,

      providesTags: (result) =>
        result
          ? [
              ...(result.followers?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              ...(result.followings?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              { type: "Profile", id: result?.id },
            ]
          : [],
    }),

    getProfileByAddress: build.query({
      query: (address) => ({
        url: "users",
        params: {
          filters: { address },
          populate: profilePopulate,
        },
      }),

      transformResponse: (response) =>
        response.length ? transformResponseItem(response?.[0]) : null,

      providesTags: (result) =>
        result
          ? [
              ...(result.followers?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              ...(result.followings?.map((follow) => ({
                type: "Following",
                id: follow.id,
              })) || []),
              { type: "Profile", id: result?.id },
            ]
          : [],
    }),

    updateUser: build.mutation({
      query: ({ id, data }) => ({
        url: `users/${id}`,
        method: "PUT",
        body: data,
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result, _error, { id }) => [{ type: "Profile", id }],
    }),

    getAllProfiles: build.query({
      query: () => ({
        url: "users",
      }),

      transformResponse: transformResponseItem,
    }),

    getVerifiedProfiles: build.query({
      query: () => ({
        url: "users",
        params: {
          filters: { is_verified: true },
          populate: profilePopulate,
        },
      }),

      transformResponse: transformResponseItem,
    }),

    createReport: build.mutation({
      query: ({ postId, title, text }) => ({
        url: "reports",
        method: "POST",
        body: {
          data: {
            title,
            text,
            post: postId,
          },
        },
      }),

      transformResponse: transformResponseItem,
    }),

    getWeb3Params: build.query({
      query: ({
        levelId,
        period,
        type = "subscribe",
        tokenId,
        itemType,
        itemId,
      }) => ({
        url: "users/web3-params",
        params: {
          level: levelId,
          type,
          period,
          token_id: tokenId,
          item_type: itemType,
          item_id: itemId,
        },
      }),

      transformResponse: transformResponseItem,
    }),

    createPurchase: build.mutation({
      query: (data) => ({
        url: "purchases",
        method: "POST",
        params: {
          populate: {
            message: {
              populate: {
                media: "*",
                user: {
                  fields: ["id"],
                },
                chat: {
                  fields: ["id"],
                },
                permissions: {
                  populate: ["levels"],
                },
              },
            },
            post: { populate: postsPopulate },
          },
        },
        body: data,
      }),
      transformResponse: transformResponseItem,
      invalidatesTags: (result) => {
        const res = ["Purchase"];
        if (result?.user?.id) {
          res.push({ type: "Profile", id: result.user.id });
        }

        return res;
      },
    }),

    getPurchases: build.query({
      query: ({ userId, authorId, filters = {} }) => ({
        url: "purchases",
        params: {
          filters: {
            user: userId,
            author: authorId,
            ...filters,
          },
          populate: "*",
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result) =>
        result?.length
          ? [...result.map(({ id }) => ({ type: "Purchase", id }))]
          : [],
    }),

    deleteFile: build.mutation({
      query: (id) => ({
        url: `upload/files/${id}`,
        method: "DELETE",
      }),

      transformResponse: transformResponseItem,
    }),
  }),
});

export const {
  useLazyLoginWithWeb3Query,
  useLazyLoginWithEmailQuery,
  useSendEmailCodeMutation,

  useGetMeQuery,
  useLazyGetMeQuery,

  useGetRecommendedProfilesQuery,

  useLazyGetProfileByIdQuery,
  useGetProfileByIdQuery,

  useGetPreviewChatProfileQuery,

  useGetProfileByNameQuery,
  useGetProfileByAddressQuery,

  useUpdateUserMutation,

  useCreateReportMutation,

  useDeleteFileMutation,

  useCreatePurchaseMutation,
  useGetPurchasesQuery,

  useLazyGetWeb3ParamsQuery,
} = profilesApi;

export const {
  getProfileByName,
  getRecommendedProfiles,
  getAllProfiles,
  getVerifiedProfiles,
} = profilesApi.endpoints;
