import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscribe: {
    show: false,
    profile: null,
    level: null,
  },
  donate: {
    show: false,
    profile: null,
    amount: 0,
  },
  signMessage: {
    show: false,
  },
  selectLoginMethod: {
    show: false,
  },
  mediaGallery: {
    show: false,
    media: [],
    mediaIndex: null,
  },
  unlockAccess: {
    show: false,
    permissions: null,
    author: null,
    itemId: null,
    isLocked: true,
  },
  purchaseItem: {
    show: false,
    price: null,
    type: null,
    itemId: null,
  },
};

const slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setShow: (state, { payload }) => {
      const { uid, show, ...props } = payload;

      state[uid] = { show, ...props };
    },
  },
});

const { setShow } = slice.actions;

export const setSubscribeModal = ({ show, profile, level }) =>
  setShow({ show, profile, level, uid: "subscribe" });

export const setMediaGalleryModal = ({ show, media, mediaIndex }) =>
  setShow({ show, media, mediaIndex, uid: "mediaGallery" });

export const setDonateModal = ({ show, address, amount }) =>
  setShow({ show, address, amount, uid: "donate" });

export const setUnlockAccessModal = ({
  show,
  permissions,
  author,
  itemId,
  isLocked = true,
}) =>
  setShow({ show, permissions, author, itemId, isLocked, uid: "unlockAccess" });

export const setPurchaseItemModal = ({ show, price, type }) =>
  setShow({ show, price, type, uid: "purchaseItem" });

export default slice.reducer;
