import LogoCircle from "./icons/LogoCircle";
import LogoText from "./icons/LogoText";
import Home from "./icons/Home";
import User from "./icons/User";
import Dollar from "./icons/Dollar";
import Cog from "./icons/Cog";
import Info from "./icons/Info";
import Search from "./icons/Search";
import Picture from "./icons/Picture";
import Lightning from "./icons/Lightning";
import Lock from "./icons/Lock";
import Nft from "./icons/Nft";
import Message from "./icons/Message";
import Heart from "./icons/Heart";
import ArrowDown from "./icons/ArrowDown";
import Smile from "./icons/Smile";
import File from "./icons/File";
import Plane from "./icons/Plane";
import Video from "./icons/Video";
import LogoFull from "./icons/LogoFull";
import MetamaskLines from "./icons/MetamaskLines";
import SmallLoader from "./icons/SmallLoader";
import ColouredBusd from "./icons/ColouredBusd";
import ColouredCursor from "./icons/ColouredCursor";
import ColouredDollar from "./icons/ColouredDollar";
import ColouredLevels from "./icons/ColouredLevels";
import ColouredLike from "./icons/ColouredLike";
import ColouredPercent from "./icons/ColouredPercent";
import ColouredRockets from "./icons/ColouredRockets";
import ColouredVip from "./icons/ColouredVip";
import ButtonGraphics from "./icons/ButtonGraphics";
import ButtonGraphicsStar from "./icons/ButtonGraphicsStar";
import MessageTail from "./icons/MessageTail";
import ArrowRight from "./icons/ArrowRight";
import Instagram from "./icons/Instagram";
import Telegram from "./icons/Telegram";
import Share from "./icons/Share";
import ArrowRightTop from "./icons/ArrowRightTop";
import ChevronDown from "./icons/ChevronDown";
import Disconnect from "./icons/Disconnect";
import Mail from "./icons/Mail";
import Chevron from "./icons/Chevron";
import Verified from "./icons/Verified";
import More from "./icons/More";
import Write from "./icons/Write";
import DollarThin from "./icons/DollarThin";
import MetaMask from "./icons/Metamask";
import WalletConnect from "./icons/WalletConnect";
import SuccessCircle from "./icons/SuccessCircle";
import Edit from "./icons/Edit";
import PaidMessage from "./icons/PaidMessage";
import PhotoCamera from "./icons/PhotoCamera";

export const icons = {
  LogoCircle,
  LogoText,
  Home,
  User,
  Dollar,
  Cog,
  PhotoCamera,
  Info,
  Search,
  Picture,
  PaidMessage,
  Lightning,
  Lock,
  Nft,
  Message,
  Heart,
  ArrowDown,
  Smile,
  File,
  Plane,
  Video,
  LogoFull,
  MetamaskLines,
  SmallLoader,
  ColouredLevels,
  ColouredLike,
  ColouredBusd,
  ColouredVip,
  ColouredCursor,
  ColouredDollar,
  ColouredPercent,
  ColouredRockets,
  Chevron,
  ArrowRight,
  Instagram,
  Telegram,
  Mail,
  ButtonGraphics,
  ButtonGraphicsStar,
  MessageTail,
  Share,
  SuccessCircle,
  ArrowRightTop,
  ChevronDown,
  Disconnect,
  Verified,
  More,
  Write,
  Edit,
  DollarThin,
  MetaMask,
  WalletConnect,
};

const AllIcons = ({ name = "", className = "", ...props }) => {
  const Icon = icons[name] ? icons[name] : icons["LogoCircle"];

  return <Icon className={className} {...props} />;
};

export default AllIcons;
