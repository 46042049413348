import { createSlice } from "@reduxjs/toolkit";
import { profilesApi } from "~services/backend/profiles";
import { authTokensManager } from "~utils/localStorage/authTokensManager";
import { parseJwt } from "~utils/vanilla";

const initialState = {
  accountId: null,
  jwt: null,
  accountAddress: null,

  isConnectedWithWeb3: false,
  isAuthenticated: false,
  isAccountBindingInProgress: false,

  signModalOpened: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    web3AccountUpdated: (state, { payload }) => {
      const { account } = payload;

      if (account) {
        state.isConnectedWithWeb3 = true;

        const jwt = authTokensManager.getJwt(account) || null;
        const accountId = parseJwt(jwt)?.id || null;

        state.jwt = jwt;
        state.accountId = accountId;
        state.accountAddress = account?.toLowerCase();
      }
    },
    forcedLoginWithJwt: (state) => {
      const jwt = authTokensManager.getJwt() || null;
      const accountId = parseJwt(jwt)?.id || null;

      if (jwt) state.jwt = jwt;
      if (accountId) state.accountId = accountId;

      state.isAuthenticated = true;
    },
    startSigning: (state) => {
      state.signModalOpened = true;
    },
    finishSigning: (state) => {
      state.signModalOpened = false;
    },
    startBinding: (state) => {
      state.isAccountBindingInProgress = true;
    },
    finishBinding: (state) => {
      state.isAccountBindingInProgress = false;
    },

    logout: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        profilesApi.endpoints.loginWithWeb3.matchFulfilled,
        (state, action) => {
          const { user, jwt } = action.payload;

          state.jwt = jwt;
          state.accountId = user.id;
          state.accountAddress = user.address?.toLowerCase();
          if (state.accountAddress) {
            authTokensManager.setJwt(state.accountAddress, jwt);
          }
          state.isAuthenticated = true;
          state.signModalOpened = false;
        },
      )
      .addMatcher(
        profilesApi.endpoints.loginWithEmail.matchFulfilled,
        (state, action) => {
          const { user, jwt } = action.payload;

          state.jwt = jwt;
          state.accountId = user.id;
          if (user.address) state.accountAddress = user.address.toLowerCase();
          state.isAuthenticated = true;
          state.signModalOpened = false;

          authTokensManager.setCurrentAccount();
          authTokensManager.setJwt("default", jwt);
        },
      )
      .addMatcher(
        profilesApi.endpoints.getProfileById.matchFulfilled,
        (state, action) => {
          const user = action.payload;

          if (user.address) state.accountAddress = user.address.toLowerCase();
        },
      );
  },
});

export const {
  logout,
  web3AccountUpdated,
  startSigning,
  finishSigning,
  forcedLoginWithJwt,
  startBinding,
  finishBinding,
} = slice.actions;

export default slice.reducer;
