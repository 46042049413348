import Button from "~components/ui/button";
import LogoButton from "~components/ui/logo-button";
import Avatar from "~components/ui/avatar";

import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";
import ConnectWalletButton from "~components/web3/connect-wallet-button";
import AllIcons from "~components/ui/all-icons";
import useProfile from "~services/backend/profiles/useProfile";
import useToken from "~hooks/web3/use-token";

export default function Navbar() {
  const { profile, logout } = useProfile();

  const preparedImage = getSocialImagePath(profile?.image);

  const { balance } = useToken();

  return (
    <div
      className={
        "t-0 relative z-30 flex w-screen items-center justify-between bg-stone-base py-2 px-4 md:h-[78px] md:px-5"
      }
    >
      <div className="w-2/12 shrink-0">
        <div className="w-[140px] md:w-[150px]">
          <LogoButton href="/" />
        </div>
      </div>
      <div className="flex w-10/12 items-center justify-end">
        <div className="hidden md:block">
          <Button variant="header" href="/">
            About
          </Button>
        </div>
        <div className="ml-5 flex items-center">
          {profile ? (
            <>
              <Button
                href={`/${profile.username}`}
                className="@pn relative @cr cursor-pointer"
              >
                <div className="flex items-center text-lg rounded-full bg-zinc-light p-1 pl-4">
                  <span className="mr-2 font-medium">
                    ${balance ? balance.toFixed(2) : 0}
                  </span>
                  <Avatar
                    className="!h-10 !w-10 !bg-white hidden lg:block"
                    src={preparedImage}
                  />
                </div>
              </Button>
              <Button
                onClick={logout}
                className="@pn relative @cr cursor-pointer @ht h-[40px] @wh w-[40px] @bdc bg-[#f0f0f0] md:h-[50px] md:w-[50px] @brr rounded-full flex justify-center items-center @brc  border-[2px] border-transparent ml-4 hover:bg-pink-base stroke-zinc-900 hover:stroke-white duration-200"
              >
                <AllIcons name="Disconnect" />
              </Button>
            </>
          ) : (
            <ConnectWalletButton />
          )}
        </div>
      </div>
    </div>
  );
}
