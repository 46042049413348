import React, { useEffect, useRef } from "react";

import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { getSocialFilePath } from "~utils/vanilla/formatters/get-social-image-path";
import { isMobile } from "react-device-detect";
import AllIcons from "~components/ui/all-icons";

let width;
if (typeof window !== "undefined") {
  width = isMobile ? window.innerWidth : window.innerWidth * 0.8;
}

function ReactSpringGallery({
  media,
  activeSlide = 0,
  setActiveSlide,
  children,
  setShow,
}) {
  const ref = useRef(activeSlide);

  const [props, api] = useSprings(media.length, (i) => ({
    x: i === activeSlide ? 0 : width * (i - activeSlide),
    display: i === activeSlide ? "block" : "none",
  }));

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (active && Math.abs(mx) > width / 10) {
        ref.current = clamp(
          ref.current + (xDir > 0 ? -1 : 1),
          0,
          media.length - 1,
        );
        cancel();
      }
      api.start((i) => {
        if (i < ref.current - 1 || i > ref.current + 1)
          return { display: "none" };
        const x = (i - ref.current) * width + (active ? mx : 0);
        setActiveSlide(ref.current);
        return { x, display: "block" };
      });
    },
  );

  const setSlidePosition = () =>
    api.start((i) => {
      if (i < ref.current - 1 || i > ref.current + 1)
        return { display: "none" };

      const x = i === ref.current ? 0 : width * (i - ref.current);
      return { x, display: "block" };
    });

  const mediaLength = media.length;
  useEffect(() => {
    if (ref.current !== activeSlide) {
      const next = activeSlide > ref.current;
      const dirMultiplier = next ? 1 : -1;
      for (
        let index = ref.current;
        next ? index <= activeSlide : index >= activeSlide;
        index += dirMultiplier
      ) {
        if (index >= 0 && index < mediaLength) {
          ref.current = index;
          setSlidePosition();
        }
      }
    }
  }, [activeSlide]);

  return (
    <div className="h-full max-h-[85vh] md:max-h-[95vh] w-screen lg:w-[80vw] relative overflow-hidden border-0 md:rounded-10px flex justify-center items-center">
      <div
        onClick={() => setShow(false)}
        className="w-full h-full absolute inset-0"
      />
      {mediaLength > 1 && (
        <NavItem
          next={false}
          onClick={() => {
            ref.current = ref.current ? ref.current - 1 : 0;
            setActiveSlide(ref.current);
            setSlidePosition();
          }}
        />
      )}
      {props.map((item, index) => {
        const { x, display } = item;

        const mediaObj = media[index];

        const url = getSocialFilePath(mediaObj);

        const sizes = mediaObj.isHidden ? "w-auto h-full" : "w-auto h-auto";
        const isHidden = display.get() === "none" && activeSlide !== index;

        if (isHidden) return null;
        return (
          <animated.div
            {...bind()}
            key={`${mediaObj.id}-${index}`}
            className={`absolute ${sizes} overflow-hidden touch-none border-0 md:rounded-10px`}
            style={{ display, x }}
          >
            {mediaObj.renderType === "image" || mediaObj.isHidden ? (
              <>
                <img
                  src={url || "/assets/images/blur.jpg"}
                  className={`${sizes} max-h-[85vh] md:max-h-[95vh] pointer-events-none touch-none select-none object-contain`}
                />
                <div
                  style={{ cursor: "grab" }}
                  className="absolute w-full h-full inset-0"
                >
                  {children && children(mediaObj)}
                </div>
              </>
            ) : (
              <video src={url} controls className={"h-full w-full"} />
            )}

            {isHidden ? null : <></>}
          </animated.div>
        );
      })}
      {mediaLength > 1 && (
        <NavItem
          next={true}
          onClick={() => {
            const lastItem = media.length - 1;
            ref.current = ref.current < lastItem ? ref.current + 1 : lastItem;
            setActiveSlide(ref.current);
            setSlidePosition();
          }}
        />
      )}
    </div>
  );
}

export default ReactSpringGallery;

const clamp = (number, lower, upper) => {
  number = +number;
  lower = +lower;
  upper = +upper;
  lower = lower === lower ? lower : 0;
  upper = upper === upper ? upper : 0;
  if (number === number) {
    number = number <= upper ? number : upper;
    number = number >= lower ? number : lower;
  }
  return number;
};

// true = next, false = prev
const NavItem = ({ next, onClick }) => (
  <div
    className={`z-10 absolute  ${
      next ? "right-2" : "left-2"
    }  flex items-center`}
  >
    <div
      onClick={onClick}
      className="flex justify-center items-center w-[30px] h-[30px] rounded-full bg-black bg-opacity-40 cursor-pointer"
    >
      <AllIcons
        name="Chevron"
        className={`text-white stroke-2  w-[18px] ${
          next ? "ml-1 " : "mr-1 rotate-180"
        }`}
      />
    </div>
  </div>
);
