import React from "react";

const FilledWallet = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.84615 4.53516H14.1538C15.1734 4.53516 16 5.35334 16 6.36263V13.6725C16 14.6818 15.1734 15.5 14.1538 15.5H1.84615C0.826551 15.5 0 14.6818 0 13.6725V6.36263C0 5.35334 0.826551 4.53516 1.84615 4.53516ZM11.5216 10.6123C11.686 10.721 11.8794 10.7791 12.0771 10.7791C12.3424 10.7791 12.5967 10.6748 12.7843 10.4891C12.9718 10.3035 13.0771 10.0517 13.0771 9.7892C13.0771 9.59342 13.0185 9.40203 12.9086 9.23925C12.7987 9.07646 12.6426 8.94959 12.4598 8.87467C12.2771 8.79975 12.076 8.78014 11.8821 8.81834C11.6881 8.85653 11.5099 8.95081 11.37 9.08925C11.2302 9.22768 11.1349 9.40406 11.0964 9.59608C11.0578 9.7881 11.0776 9.98713 11.1533 10.168C11.229 10.3489 11.3571 10.5035 11.5216 10.6123Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41278e-07 5.37264V4.11626C-0.00030278 3.673 0.15567 3.24353 0.441019 2.90192C0.726367 2.56031 1.1232 2.32798 1.56308 2.245L11.6923 0.533653C11.9703 0.481211 12.2566 0.490151 12.5307 0.559833C12.8048 0.629515 13.06 0.758223 13.278 0.936772C13.4961 1.11532 13.6717 1.33931 13.7922 1.59276C13.9127 1.84621 13.9753 2.12287 13.9754 2.403V3.54517H1.84615C0.826552 3.54517 4.41278e-07 4.36335 4.41278e-07 5.37264Z"
    />
  </svg>
);

export default FilledWallet;
