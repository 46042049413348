import React from "react";

const TikTok = ({ className }) => (
  <svg
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M9.12724 0.0168759C10.2179 0 11.3024 0.0100005 12.3855 0C12.4512 1.27569 12.9099 2.57514 13.8437 3.47706C14.7757 4.40148 16.0939 4.82463 17.3764 4.96776V8.32357C16.1745 8.28419 14.9669 8.03418 13.8762 7.51665C13.4012 7.30164 12.9587 7.02475 12.5255 6.74161C12.5199 9.17674 12.5355 11.6087 12.5099 14.0339C12.4449 15.1989 12.0605 16.3584 11.383 17.3184C10.2929 18.9166 8.40095 19.9586 6.45772 19.9911C5.26578 20.0592 4.07509 19.7342 3.05941 19.1354C1.3762 18.1428 0.191761 16.3259 0.0192517 14.3758C-0.00247774 13.9628 -0.00581457 13.549 0.0092511 13.1357C0.159259 11.55 0.943676 10.033 2.16124 9.00111C3.54131 7.79917 5.47454 7.22664 7.28464 7.5654C7.30152 8.79984 7.25214 10.033 7.25214 11.2675C6.42522 11 5.45892 11.075 4.73638 11.5769C4.20775 11.9251 3.80827 12.4375 3.59944 13.0351C3.42693 13.4576 3.47631 13.927 3.48631 14.3758C3.68445 15.7433 4.99952 16.8928 6.40334 16.7684C7.33402 16.7584 8.22594 16.2184 8.71097 15.4277C8.86785 15.1508 9.04348 14.8677 9.05286 14.542C9.13474 13.0513 9.10224 11.5669 9.11224 10.0762C9.11911 6.71661 9.10224 3.36643 9.12786 0.0175009L9.12724 0.0168759Z" />
  </svg>
);

export default TikTok;
