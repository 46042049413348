import { useRouter } from "next/router";
import Navbar from "~components/layout/navbar";
import Footer from "../../page-blocks/footer";
import Sidebar from "../sidebar";
import useAutoLogin from "~services/backend/profiles/useAutoLogin";
import useAuthorization from "~services/backend/profiles/useAuthorization";

import ModalsWrapper from "~components/wrappers/ModalsWrapper";

const headerRoutes = [
  "/[username]",
  "/profile",
  "/help-center",
  "/help-center/[key]",
  "/chats",
  "/chats/[id]",
];

const footerRoutes = ["/chats", "/chats/[id]"];

const withoutSidebarRoutes = ["/donate"];

const MainLayout = ({ children }) => {
  const { route } = useRouter();

  useAutoLogin();
  useAuthorization();

  if (withoutSidebarRoutes.includes(route)) {
    return <ModalsWrapper>{children}</ModalsWrapper>;
  }

  return (
    <ModalsWrapper>
      <main
        className={
          "flex min-h-[100vh] w-full flex-col items-center justify-between bg-stone-base"
        }
      >
        <div className="flex flex-col w-full items-center">
          <Navbar />
          <div className={"w-full max-w-[1292px] px-4 md:pt-[32px]"}>
            {headerRoutes.includes(route) ? (
              children
            ) : (
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:min-w-60 lg:w-60 mb-5 lg:mb-0">
                  <Sidebar />
                </div>
                <div className="w-full flex flex-col items-start lg:flex-row relative">
                  {children}
                </div>
              </div>
            )}
          </div>
        </div>
        {!footerRoutes.includes(route) ? <Footer /> : null}
      </main>
    </ModalsWrapper>
  );
};

export default MainLayout;
