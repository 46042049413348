export default function LogoFull({ className }) {
  return (
    <svg
      width="151"
      height="24"
      viewBox="0 0 151 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M29 14.3287C29 18.7535 32.7457 19.8268 39.0324 19.8268C44.7057 19.8268 48.5829 18.9945 48.5829 14.8983C48.5829 11.0211 44.8371 10.6926 38.7914 10.364C34.3229 10.1011 33.4029 9.88208 33.4029 8.69922C33.4029 7.56017 34.3229 7.18779 38.2 7.18779C42.2962 7.18779 43.9829 7.60398 43.9829 9.29065H47.9257C47.9257 5.50113 44.421 4.05541 38.6381 4.05541C32.899 4.05541 29.46 5.12875 29.46 8.50208C29.46 11.9411 32.1105 12.9926 38.7914 13.2992C43.7638 13.5402 44.64 13.7592 44.64 15.0297C44.64 16.2345 43.3914 16.6945 39.2514 16.6945C34.2571 16.6945 32.9429 15.9497 32.9429 14.3287H29Z"
        fill="black"
      />
      <path
        d="M64.3127 6.70589V13.9345C63.1517 15.2926 60.4356 16.5849 57.9165 16.5849C54.8279 16.5849 53.9079 15.1611 53.9079 12.423V6.70589H50.1403L50.0965 13.8468C50.1403 18.6002 53.3603 19.8268 56.7994 19.8268C59.9317 19.8268 62.4727 18.7973 64.3127 17.3516V19.6078H68.0584V6.70589H64.3127Z"
        fill="black"
      />
      <path
        d="M79.8986 6.48684C77.1167 6.48684 74.8605 7.25351 73.4586 8.72113V2.08398H69.7129V19.6078H73.4586V17.5926C74.8605 19.0602 77.1167 19.8268 79.8986 19.8268C84.0386 19.8268 87.5872 18.0964 87.5872 13.1678C87.5872 8.23922 84.0386 6.48684 79.8986 6.48684ZM78.6501 16.6945C75.2986 16.6945 73.4586 15.7087 73.4586 13.1678C73.4586 10.6049 75.2986 9.61922 78.6501 9.61922C82.0015 9.61922 83.8196 10.6049 83.8196 13.1678C83.8196 15.7087 82.0015 16.6945 78.6501 16.6945Z"
        fill="black"
      />
      <path
        d="M88.6605 13.1678C88.6605 17.8992 92.2529 19.8268 97.5977 19.8268C102.417 19.8268 105.812 18.2497 106.425 14.4602H102.592C102.088 16.1249 100.314 16.6945 97.5977 16.6945C94.3777 16.6945 92.4062 15.8402 92.4062 13.1678C92.4062 10.4735 94.3777 9.61922 97.5977 9.61922C100.314 9.61922 102.088 10.1887 102.592 11.8535H106.425C105.812 8.06398 102.417 6.48684 97.5977 6.48684C92.2529 6.48684 88.6605 8.41446 88.6605 13.1678Z"
        fill="black"
      />
      <path
        d="M107.854 2.08398V19.6078H111.599V2.08398H107.854Z"
        fill="black"
      />
      <path
        d="M127.546 6.70589V13.9345C126.385 15.2926 123.668 16.5849 121.149 16.5849C118.061 16.5849 117.141 15.1611 117.141 12.423V6.70589H113.373L113.329 13.8468C113.373 18.6002 116.593 19.8268 120.032 19.8268C123.165 19.8268 125.706 18.7973 127.546 17.3516V19.6078H131.291V6.70589H127.546Z"
        fill="black"
      />
      <path
        d="M143.132 6.48684C140.35 6.48684 138.093 7.25351 136.692 8.72113V2.08398H132.946V19.6078H136.692V17.5926C138.093 19.0602 140.35 19.8268 143.132 19.8268C147.271 19.8268 150.82 18.0964 150.82 13.1678C150.82 8.23922 147.271 6.48684 143.132 6.48684ZM141.883 16.6945C138.532 16.6945 136.692 15.7087 136.692 13.1678C136.692 10.6049 138.532 9.61922 141.883 9.61922C145.234 9.61922 147.052 10.6049 147.052 13.1678C147.052 15.7087 145.234 16.6945 141.883 16.6945Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6251 20.1713C12.8871 20.1713 16.3422 16.7162 16.3422 12.4542C16.3422 8.19216 12.8871 4.7371 8.6251 4.7371C4.36306 4.7371 0.907998 8.19216 0.907998 12.4542C0.907998 16.7162 4.36306 20.1713 8.6251 20.1713ZM8.6251 20.7766C13.2214 20.7766 16.9475 17.0505 16.9475 12.4542C16.9475 7.85789 13.2214 4.13184 8.6251 4.13184C4.02879 4.13184 0.302734 7.85789 0.302734 12.4542C0.302734 17.0505 4.02879 20.7766 8.6251 20.7766Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43207 20.2442C12.4499 20.2442 15.7251 16.8366 15.7251 12.6103C15.7251 8.38407 12.4499 4.97648 8.43207 4.97648C4.41425 4.97648 1.13908 8.38407 1.13908 12.6103C1.13908 16.8366 4.41425 20.2442 8.43207 20.2442ZM8.43207 20.4863C12.5936 20.4863 15.9672 16.9601 15.9672 12.6103C15.9672 8.26057 12.5936 4.73438 8.43207 4.73438C4.27055 4.73438 0.896973 8.26057 0.896973 12.6103C0.896973 16.9601 4.27055 20.4863 8.43207 20.4863Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96562 19.8381C11.7613 19.8381 14.8527 16.6102 14.8527 12.6102C14.8527 8.61017 11.7613 5.38226 7.96562 5.38226C4.16996 5.38226 1.07855 8.61017 1.07855 12.6102C1.07855 16.6102 4.16996 19.8381 7.96562 19.8381ZM7.96562 20.0197C11.8695 20.0197 15.0343 16.7024 15.0343 12.6102C15.0343 8.51804 11.8695 5.20068 7.96562 5.20068C4.06171 5.20068 0.896973 8.51804 0.896973 12.6102C0.896973 16.7024 4.06171 20.0197 7.96562 20.0197Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76827 19.3603C11.4963 19.3603 14.5185 16.3381 14.5185 12.6101C14.5185 8.88201 11.4963 5.85982 7.76827 5.85982C4.04021 5.85982 1.01803 8.88201 1.01803 12.6101C1.01803 16.3381 4.04021 19.3603 7.76827 19.3603ZM7.76827 19.4814C11.5632 19.4814 14.6396 16.405 14.6396 12.6101C14.6396 8.81515 11.5632 5.73877 7.76827 5.73877C3.97336 5.73877 0.896973 8.81515 0.896973 12.6101C0.896973 16.405 3.97336 19.4814 7.76827 19.4814Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53504 19.3491C11.1663 19.3491 14.1126 16.3333 14.1126 12.6101C14.1126 8.88685 11.1663 5.87107 7.53504 5.87107C3.90373 5.87107 0.957499 8.88685 0.957499 12.6101C0.957499 16.3333 3.90373 19.3491 7.53504 19.3491ZM7.53504 19.4096C11.2011 19.4096 14.1731 16.3654 14.1731 12.6101C14.1731 8.8548 11.2011 5.81055 7.53504 5.81055C3.86894 5.81055 0.896973 8.8548 0.896973 12.6101C0.896973 16.3654 3.86894 19.4096 7.53504 19.4096Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5ZM9.07905 21.079C13.9261 21.079 17.8554 17.1497 17.8554 12.3027C17.8554 7.45566 13.9261 3.52637 9.07905 3.52637C4.23202 3.52637 0.302734 7.45566 0.302734 12.3027C0.302734 17.1497 4.23202 21.079 9.07905 21.079Z"
        fill="black"
      />
    </svg>
  );
}
