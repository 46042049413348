import axios from "axios";

const instance = axios.create();

instance.interceptors.response.use(
  (res) =>
    res?.data?.data ||
    res?.data || {
      error: res?.message || res?.error || res?.toString() || null,
      ok: false,
    },
  (err) => {
    const { response } = err;

    let message;

    if (response?.status >= 400) {
      message =
        response.data?.error?.message ||
        response.data?.message ||
        response.data.toString();
    }

    throw new Error(message || err.message);
  },
);

export default instance;
