import React from "react";

const AddNewBox = ({
  onClick = () => {},
  title = "Add new",
  className = "",
}) => {
  return (
    <div>
      <div
        onClick={onClick}
        className={`relative cursor-pointer flex justify-center items-center min-h-[450px] h-full hover:border-black rounded-[10px] transition duration-200 group hover:scale-[101%] ${className}`}
        style={{
          background: `url(
          "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='gray' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"
        )`,
          borderRadius: "18px",
        }}
      >
        <span className="font-medium text-rainbow-gradient">{title}</span>
      </div>
    </div>
  );
};

export default AddNewBox;
