export default function Home({ className }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79287 7.1088C3.85723 7.65866 3.29999 8.5572 3.29999 9.51604V16.15C3.29999 17.2546 4.19542 18.15 5.29999 18.15H7.43332C7.98561 18.15 8.43332 17.7023 8.43332 17.15V12.3491H13.5668V17.15C13.5668 17.7023 14.0145 18.15 14.5668 18.15H16.7002C17.8047 18.15 18.7002 17.2546 18.7002 16.15V9.51604L18.7 9.51604C18.7 8.5572 18.1427 7.65866 17.2071 7.1088L12.2043 4.16871C11.4812 3.74377 10.5188 3.74377 9.79572 4.16871L4.79287 7.1088Z"
      />
    </svg>
  );
}
