export const backBaseUrl =
  process.env.NEXT_PUBLIC_BACKEND_URL || "https://api.subclub.me";

export const backWsUrl =
  process.env.NEXT_PUBLIC_BACKEND_WS_URL || "wss://api.subclub.me";

export const backUploaderUrl =
  process.env.NEXT_PUBLIC_BACKEND_UPLOADER_URL || "https://subclub.space";

export const isProd = process.env.NODE_ENV === "production";

export const allowedImageExtensions = ["png", "jpeg", "webp", "tiff"];
