import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

const SettingsNavbar = () => {
  const { asPath } = useRouter();

  return (
    <div className="flex mb-20">
      {routes.map(({ title, href }) => (
        <Link key={href} href={href}>
          <a
            className={`px-4 mx-0.5 py-2 bg-white rounded-full ${
              href === asPath ? "border shadow-md font-semibold" : ""
            }`}
          >
            {title}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default SettingsNavbar;

const routes = [
  {
    href: "/settings/account",
    title: "Account",
  },
  //   {
  //     href: `/settings/personal`,
  //     title: `Personal`,
  //   },
  //   {
  //     href: `/settings/subcard`,
  //     title: `Subcard`,
  //   },
  {
    href: "/settings/messages",
    title: "Messages",
  },
];
