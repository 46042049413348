export default function Cog({ className }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M6.09544 6.38876C6.039 6.12775 5.99668 5.85967 5.91202 5.60571C5.73566 5.06956 5.85558 4.64629 6.30002 4.30062C6.96315 3.78564 7.68977 3.38353 8.47282 3.08724C8.95959 2.90382 9.38286 3.00964 9.72148 3.40469C10.6809 4.51931 12.2117 4.51931 13.1712 3.40469C13.5098 3.00964 13.9401 2.90382 14.4198 3.08724C15.2805 3.41174 16.0706 3.87029 16.7831 4.45582C17.1922 4.78738 17.3122 5.2036 17.1429 5.69742C16.6843 7.04484 17.4603 8.37815 18.8642 8.63917C19.3862 8.73793 19.6754 9.03422 19.7742 9.5492C19.9576 10.5086 19.9435 11.461 19.7601 12.4204C19.6754 12.879 19.3227 13.2105 18.8501 13.2811C17.4109 13.4998 16.5785 14.9036 17.0935 16.2651C17.284 16.7801 17.1711 17.2034 16.7478 17.5491C16.0988 18.0782 15.3863 18.5014 14.6103 18.8259C14.1235 19.0235 13.665 18.9106 13.3334 18.5014C12.3599 17.2951 10.7162 17.3163 9.77086 18.5508C9.44635 18.9741 8.9878 19.1081 8.48693 18.9106C7.64744 18.5861 6.87849 18.1487 6.18009 17.5843C5.72155 17.2104 5.60868 16.7942 5.84148 16.244C6.3494 15.0094 5.70038 13.6338 4.42351 13.267C4.27537 13.2246 4.12722 13.2035 3.97202 13.1823C3.52053 13.1047 3.17486 12.759 3.10431 12.3075C2.94911 11.3622 2.97027 10.4169 3.14664 9.4716C3.23835 8.95662 3.58402 8.63211 4.12722 8.56862C5.23478 8.43458 6.06017 7.50338 6.09544 6.38876ZM14.2717 10.9954C14.2787 9.45749 13.016 8.18767 11.4781 8.18062C9.91195 8.17357 8.63508 9.42928 8.63508 10.9742C8.63508 12.5403 9.88373 13.8031 11.4428 13.8102C13.0018 13.8172 14.2646 12.5544 14.2717 10.9954Z" />
    </svg>
  );
}
