import { useEffect, useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import Button from "~components/ui/button";
import P from "~components/ui/typography/p";

const AcceptCookiesMessage = ({
  message = "We use cookies",
  containerClasses = "",
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setShow(!+localStorage.getItem("acceptCookies"));
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("acceptCookies", "1");
    setShow(false);
  };

  const messageAnimation = useSpring({
    opacity: show ? "1" : "0",
    transform: show ? "scale(1)" : "scale(0.75)",
    config: { ...config.default },
    delay: 200,
  });

  return show ? (
    <animated.div
      style={messageAnimation}
      className={`${containerBaseClasses} ${containerClasses}`}
    >
      <span className="mr-2 text-base sm:text-lg">🍪</span>
      <P className="@ftf font-clash @fts text-lg @ftw font-medium">{message}</P>
      <Button
        onClick={handleAcceptCookies}
        variant="rounded"
        className="@mn ml-4 @pg px-8 py-2 @bdc bg-white hover:bg-black @ttc text-pink-base hover:text-white"
      >
        Ok
      </Button>
    </animated.div>
  ) : null;
};

export default AcceptCookiesMessage;

const containerBaseClasses =
  "flex flex-row items-center z-50 p-1 pl-3 rounded-full bg-stone-base font-family-rubik-medium text-14px leading-20px sm:text-12px md:leading-14px";
