import RollerLoader from "~components/ui/loaders/roller-loader";
import ImageUploader from "./image-uploader/index";
import { twMerge } from "tailwind-merge";

export default function Avatar({
  src,
  uniqInputId,
  withFileInput,
  setImageFile,
  className,
}) {
  const loading = false;

  return (
    <div
      className={twMerge(
        "relative mx-auto shadow-xl rounded-full w-[120px] h-[120px] md:w-[160px] md:h-[160px] border-[2px] border-transparent flex flex-shrink-0 items-center justify-center overflow-hidden group",
        className,
      )}
    >
      {withFileInput ? (
        <ImageUploader
          withFileInput={withFileInput}
          setImageFile={setImageFile}
          uniqInputId={uniqInputId}
        />
      ) : null}
      {!loading ? (
        <img
          src={src ? src : "/assets/images/avatar.jpg"}
          className={"object-cover w-full h-full"}
        />
      ) : (
        <RollerLoader />
      )}
    </div>
  );
}
