import { useCallback, useEffect } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
// import { NoBscProviderError } from "@binance-chain/bsc-connector";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from "@web3-react/walletconnect-connector";

import { setupNetwork } from "~web3/utils/wallet";
import { connectorLocalStorageKey } from "~web3/configs/wallets";
import { connectorsByName } from "~web3/utils/web3-react";
import { DEFAULT } from "~web3/configs/constants";
import createEventNotification from "~utils/create-event-notification";
import { authTokensManager } from "~utils/localStorage/authTokensManager";

const useWeb3Auth = () => {
  const { chainId, activate, deactivate, account } = useWeb3React();

  const logout = useCallback(
    (options = {}) => {
      console.log("web3 logout");
      deactivate();

      localStorage.removeItem(connectorLocalStorageKey);

      if (options.forced) {
        authTokensManager.clearCurrentAccount();
      }

      // This localStorage key is set by @web3-react/walletconnect-connector
      if (localStorage.walletConnect || localStorage.walletconnect) {
        connectorsByName.walletConnect.close();
        connectorsByName.walletConnect.walletConnectProvider = null;
      }
    },
    [deactivate, chainId],
  );

  const login = useCallback(
    async (connectorId, connectorUid) => {
      const connector = connectorsByName[connectorId];
      connector.connectorUid = connectorUid;

      if (connector) {
        window.localStorage.setItem(connectorLocalStorageKey, connectorId);
        window.localStorage.setItem("connectorUid", connectorUid);
        console.log("web3 login");

        return new Promise((resolve, reject) => {
          activate(connector, async (error) => {
            console.log("activate", error);
            if (
              connectorUid === "metamask" &&
              error?.message ===
                "Already processing eth_requestAccounts. Please wait."
            ) {
              createEventNotification({
                event: "warning",
                title: "Please open your MetaMask and follow the instructions",
              });

              return;
            }

            if (error instanceof UnsupportedChainIdError) {
              const provider =
                connectorUid === "metamask"
                  ? window.ethereum
                  : connector.walletConnectProvider;

              await setupNetwork(provider, DEFAULT);
              activate(connector, (err) => console.log("activate 2", err));

              return;
            } else {
              window.localStorage.removeItem(connectorLocalStorageKey);
              if (error instanceof NoEthereumProviderError) {
                createEventNotification({
                  event: "error",
                  title: "Provider Error, no provider was found",
                });
                reject(error);
              } else if (
                error instanceof UserRejectedRequestErrorInjected ||
                error instanceof UserRejectedRequestErrorWalletConnect
              ) {
                if (connector instanceof WalletConnectConnector) {
                  const walletConnector = connector;
                  walletConnector.walletConnectProvider = null;
                }

                createEventNotification({
                  event: "error",
                  title: error?.message,
                });
                reject(error);

                return;
              }
            }

            createEventNotification({
              event: "error",
              title: error?.message,
            });
            logout();
            reject(error);
          }).then(resolve);
        });
      }

      createEventNotification({
        event: "error",
        title: "Unable to find connector, the connector config is wrong",
      });
      throw new Error(
        "Unable to find connector, the connector config is wrong",
      );
    },
    [activate],
  );

  return { login, logout, account };
};

export default useWeb3Auth;
