import React from "react";

const Landscape = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.06284 1.54285C3.3945 1.54285 2.75353 1.80835 2.28094 2.28094C1.80835 2.75353 1.54285 3.3945 1.54285 4.06284V13.9371C1.54285 14.6054 1.80835 15.2464 2.28094 15.719C2.75353 16.1916 3.3945 16.4571 4.06284 16.4571H13.9371C14.6054 16.4571 15.2464 16.1916 15.719 15.719C16.1916 15.2464 16.4571 14.6054 16.4571 13.9371V4.06284C16.4571 3.3945 16.1916 2.75353 15.719 2.28094C15.2464 1.80835 14.6054 1.54285 13.9371 1.54285H4.06284ZM1.18998 1.18998C1.95191 0.428048 2.98531 0 4.06284 0H13.9371C15.0146 0 16.048 0.428049 16.81 1.18998C17.5719 1.95191 17.9999 2.98531 17.9999 4.06284V13.9371C17.9999 15.0146 17.5719 16.048 16.81 16.81C16.048 17.5719 15.0146 17.9999 13.9371 17.9999H4.06284C2.98531 17.9999 1.95191 17.5719 1.18998 16.81C0.428049 16.048 0 15.0146 0 13.9371V4.06284C0 2.98531 0.428048 1.95191 1.18998 1.18998Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1197 4.83436C5.40962 4.83436 4.83399 5.40999 4.83399 6.12007C4.83399 6.83014 5.40962 7.40578 6.1197 7.40578C6.82978 7.40578 7.40541 6.83014 7.40541 6.12007C7.40541 5.40999 6.82978 4.83436 6.1197 4.83436ZM3.29114 6.12007C3.29114 4.55789 4.55753 3.2915 6.1197 3.2915C7.68187 3.2915 8.94826 4.55789 8.94826 6.12007C8.94826 7.68224 7.68187 8.94863 6.1197 8.94863C4.55753 8.94863 3.29114 7.68224 3.29114 6.12007Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2909 8.20093C12.6269 8.20025 12.9592 8.26959 13.2668 8.40454C13.5744 8.53947 13.8505 8.73703 14.0775 8.98461L17.3947 12.6023C17.4042 12.6126 17.4142 12.6235 17.4246 12.6348C17.5314 12.7511 17.6842 12.9173 17.7944 13.111C17.9391 13.3653 17.9997 13.6337 17.9997 13.937V14.0465C17.9997 15.095 17.5832 16.1006 16.8418 16.842C16.1003 17.5834 15.0948 17.9999 14.0463 17.9999H4.06258C3.75724 17.9999 3.48063 17.8198 3.3571 17.5406C3.23357 17.2613 3.28637 16.9355 3.49176 16.7096L10.5074 8.99201C10.7334 8.74347 11.0088 8.54469 11.3159 8.4085C11.6229 8.27231 11.955 8.20162 12.2909 8.20093ZM11.649 10.0299C11.649 10.0299 11.649 10.0299 11.649 10.0299L5.80638 16.457H14.0463C14.6856 16.457 15.2987 16.2031 15.7508 15.751C16.2029 15.2989 16.4568 14.6858 16.4568 14.0465V13.937C16.4568 13.8984 16.4539 13.8791 16.4526 13.8727C16.4488 13.8661 16.4371 13.8481 16.4057 13.8105C16.3724 13.7707 16.3335 13.7281 16.2733 13.6622L16.2554 13.6426L12.9404 10.0273C12.8583 9.93777 12.7583 9.86624 12.6471 9.81743C12.5358 9.76862 12.4156 9.74353 12.2941 9.74378C12.1726 9.74403 12.0524 9.7696 11.9414 9.81886C11.8303 9.86811 11.7307 9.94003 11.649 10.0299Z"
    />
  </svg>
);

export default Landscape;
