import React from "react";

const ZeroBlance = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4062 12.1506C16.2748 10.2819 16.2748 7.25224 14.4062 5.38358C12.5375 3.51492 9.5078 3.51492 7.63914 5.38358C7.09896 5.92375 6.71494 6.56095 6.48706 7.23907C8.15481 6.67863 10.0701 7.06266 11.3986 8.39114C12.7271 9.71963 13.1111 11.6349 12.5507 13.3027C13.2288 13.0748 13.866 12.6908 14.4062 12.1506Z"
        opacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3988 8.39108C9.94537 6.93768 7.58894 6.93768 6.13554 8.39108C4.68213 9.84449 4.68213 12.2009 6.13554 13.6543C7.58894 15.1077 9.94537 15.1077 11.3988 13.6543C12.8522 12.2009 12.8522 9.84449 11.3988 8.39108ZM12.1507 14.4062C14.0193 12.5376 14.0193 9.50786 12.1507 7.63919C10.282 5.77053 7.25231 5.77053 5.38365 7.63919C3.51498 9.50785 3.51498 12.5376 5.38365 14.4062C7.25231 16.2749 10.282 16.2749 12.1507 14.4062Z"
      />
    </svg>
  );
};

export default ZeroBlance;
