import { transformResponseItem } from "~api";
import { backendApi } from "..";

export const likesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createLike: build.mutation({
      query: (postId) => ({
        url: "likes",
        method: "POST",
        body: {
          data: {
            post: postId,
          },
        },
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (result, _error, postId) => {
        const tags = [];
        if (result?.id) tags.push({ type: "Like", id: result?.id });
        if (postId) tags.push({ type: "Post", id: postId });

        return tags;
      },
    }),

    deleteLike: build.mutation({
      query: (id) => ({
        url: `likes/${id}`,
        method: "DELETE",
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result, _error, id) => [{ type: "Like", id: id }],
    }),
  }),
});

export const { useCreateLikeMutation, useDeleteLikeMutation } = likesApi;
