import React from "react";

const Wallet = ({ className }) => (
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current  ${className}`}
  >
    <path
      d="M15.1538 4.07617H2.84615C1.82655 4.07617 1 4.90272 1 5.92233V13.3069C1 14.3265 1.82655 15.1531 2.84615 15.1531H15.1538C16.1734 15.1531 17 14.3265 17 13.3069V5.92233C17 4.90272 16.1734 4.07617 15.1538 4.07617Z"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M14.9754 4.0763V2.92246C14.9753 2.63946 14.9127 2.35997 14.7922 2.10393C14.6717 1.84789 14.4961 1.62161 14.278 1.44124C14.06 1.26086 13.8048 1.13084 13.5307 1.06044C13.2566 0.99005 12.9703 0.981019 12.6923 1.034L2.56308 2.76284C2.1232 2.84667 1.72637 3.08137 1.44102 3.42647C1.15567 3.77157 0.999697 4.20543 1 4.65323V6.53784"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M13.077 10.3838C12.8792 10.3838 12.6859 10.3251 12.5214 10.2153C12.357 10.1054 12.2288 9.9492 12.1531 9.76647C12.0774 9.58375 12.0576 9.38268 12.0962 9.1887C12.1348 8.99472 12.23 8.81654 12.3699 8.67668C12.5097 8.53683 12.6879 8.44159 12.8819 8.403C13.0759 8.36442 13.277 8.38422 13.4597 8.45991C13.6424 8.5356 13.7986 8.66377 13.9085 8.82822C14.0183 8.99267 14.077 9.18601 14.077 9.38379C14.077 9.64901 13.9716 9.90336 13.7841 10.0909C13.5966 10.2784 13.3422 10.3838 13.077 10.3838Z" />
  </svg>
);

export default Wallet;
