const NotificationError = ({ className }) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9435 13.3006C11.5944 13.9515 12.6497 13.9515 13.3005 13.3006C13.9514 12.6497 13.9514 11.5945 13.3005 10.9436L9.41804 7.06106L13.3005 3.17857C13.9514 2.5277 13.9514 1.47242 13.3006 0.821549C12.6497 0.170673 11.5944 0.17067 10.9435 0.821542L7.06102 4.70403L3.17855 0.821533C2.52767 0.170657 1.4724 0.170655 0.821525 0.821526C0.170649 1.4724 0.170647 2.52767 0.821518 3.17855L4.70399 7.06104L0.821472 10.9435C0.170596 11.5944 0.170593 12.6497 0.821465 13.3006C1.47234 13.9514 2.52761 13.9514 3.17849 13.3006L7.06101 9.41807L10.9435 13.3006Z"
      fill="#E94D3D"
    />
  </svg>
);

export default NotificationError;
