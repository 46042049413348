import React from "react";

const FilledCheck = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M10.571 9.62885L8.33916 11.8652C8.31221 11.8921 8.28527 11.9191 8.25832 11.946C8.20444 11.9999 8.15953 12.0493 8.10564 12.0987C7.8901 12.2963 7.59821 12.2918 7.39613 12.0942C6.23755 10.9401 4.89037 9.59742 3.57912 8.27718C3.24232 7.9359 3.24232 7.43295 3.58361 7.0737C3.68689 6.96144 3.79916 6.85366 3.90693 6.74589L4.01022 6.64709C4.21678 6.44053 4.44131 6.33724 4.67033 6.33724C4.89486 6.33724 5.11939 6.44053 5.32147 6.6426C5.79298 7.11412 6.2645 7.58563 6.73152 8.05714C6.99197 8.3176 7.24794 8.57356 7.50839 8.83402C7.53983 8.86545 7.57126 8.89239 7.6027 8.92383L7.76436 9.07651L7.87213 8.96873C7.93051 8.91036 7.9844 8.85198 8.04278 8.79809C8.16402 8.67684 8.28078 8.5556 8.39753 8.43884C10.1713 6.66506 11.9406 4.89576 13.7144 3.12198C13.912 2.92439 13.912 2.60556 13.7189 2.40348C12.228 0.890148 10.1534 -0.0349151 7.86315 0.00100968C3.46236 0.0773498 -0.0223451 3.64738 0.000107894 8.04367C0.0225609 12.3726 3.48482 15.8887 7.79579 15.9965C12.2729 16.1088 15.9956 12.4804 16.0001 8.00325C16.0001 7.168 15.8744 6.36419 15.6364 5.60977C15.5241 5.24603 15.0616 5.12927 14.7877 5.4032C13.3821 6.81325 11.9765 8.2188 10.571 9.62885Z" />
  </svg>
);

export default FilledCheck;
