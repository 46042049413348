import React from "react";

const Bin = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.07715 4.65381L5.75023 15.423C5.7822 16.0453 6.23484 16.5 6.82715 16.5H13.0195C13.6141 16.5 14.0583 16.0453 14.0964 15.423L14.7695 4.65381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4.65381H15.8462"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.3462 6.8077L12.0769 14.3462M7.76923 4.65385V3.3077C7.76892 3.20154 7.7896 3.09637 7.83008 2.99824C7.87056 2.9001 7.93004 2.81094 8.0051 2.73588C8.08017 2.66081 8.16933 2.60133 8.26746 2.56085C8.3656 2.52037 8.47077 2.49969 8.57692 2.5H11.2692C11.3754 2.49969 11.4806 2.52037 11.5787 2.56085C11.6768 2.60133 11.766 2.66081 11.841 2.73588C11.9161 2.81094 11.9756 2.9001 12.0161 2.99824C12.0566 3.09637 12.0772 3.20154 12.0769 3.3077V4.65385H7.76923ZM9.92308 6.8077V14.3462V6.8077ZM7.5 6.8077L7.76923 14.3462L7.5 6.8077Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bin;
