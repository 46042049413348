import React from "react";

const Notification = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8309 3.50653C5.60028 4.38996 4.08074 6.57225 4.08074 9.05546L4.08132 12.3988C3.11191 12.4758 2.34998 13.2932 2.34998 14.2864C2.34998 15.3319 3.19258 16.18 4.2336 16.18H7.26931C7.41566 17.5676 8.58147 18.6501 9.99998 18.6501C11.4185 18.6501 12.5843 17.5676 12.7306 16.18H15.7664C16.8068 16.18 17.65 15.3308 17.65 14.2864C17.65 13.2929 16.8868 12.4759 15.9192 12.3984V9.05544C15.9192 6.57273 14.3996 4.39014 12.1691 3.50659C12.155 2.31389 11.1901 1.3501 9.99998 1.3501C8.81108 1.3501 7.84496 2.31436 7.8309 3.50653ZM10.7154 3.53291C10.7154 3.13424 10.3944 2.81249 9.99998 2.81249C9.60653 2.81249 9.28459 3.135 9.28459 3.53291V4.5791L8.75941 4.7317C6.86489 5.28218 5.53459 7.03632 5.53459 9.05544V12.5423C5.53459 13.2658 4.95164 13.8552 4.23135 13.8552C3.99666 13.8552 3.80382 14.0481 3.80382 14.2864C3.80382 14.5249 3.99618 14.7176 4.2336 14.7176H15.7664C16.003 14.7176 16.1961 14.524 16.1961 14.2864C16.1961 14.0485 16.0025 13.8552 15.7686 13.8552C15.0471 13.8552 14.4654 13.2677 14.4654 12.5423V9.05544C14.4654 7.03683 13.1349 5.28226 11.2405 4.73172L10.7154 4.57911V3.53291ZM8.74096 16.18C8.8737 16.758 9.38777 17.1877 9.99998 17.1877C10.6122 17.1877 11.1262 16.758 11.259 16.18H8.74096Z"
      />
    </svg>
  );
};

export default Notification;
