import React from "react";
import Button from "~components/ui/button";
import CardBox from "~components/ui/card-box";
import P from "~components/ui/typography/p";

const ApproveInfo = ({ onApprove, setShow }) => {
  const handleClick = () => {
    onApprove();
    setShow(false);
  };
  return (
    <CardBox
      className={"p-10 @brw border-0 lg:max-w-[450px]"}
      maxContentHeight={false}
    >
      <P className="@ftf font-clash @ftw font-medium @fts text-18px text-zinc-900 mb-4 text-center lg:text-left">
        Approve tokens spending in wallet
      </P>

      <P className="@ftf font-montserrat @ftw font-normal @fts text-14px text-zinc-base text-center lg:text-left mb-4">
        A confirmation window will be open in your wallet. <br />
        This is not a subscription yet, you need to give access to your BUSD
        balance
      </P>

      <img
        alt=""
        src="/assets/images/confirm.png"
        className="mx-auto w-[300px]"
      />

      <div className="flex items-center justify-center mt-4">
        <Button
          onClick={handleClick}
          variant="rounded"
          className="@bdc bg-white hover:bg-black @ttc text-black hover:text-white @brw border @brc border-zinc-900 hover:border-white @pg py-[11px] px-[30px] @wh w-[140px] flex justify-center items-center"
        >
          Ok
        </Button>
      </div>
    </CardBox>
  );
};

export default ApproveInfo;
