import { nullAddress } from "~web3/configs/constants";
import { localStorage, readJson } from "./common";

const referralStorageKey = "referralStorage";

export const referralManager = {
  // {0x1: 0x2, 0x3: 0x4}
  getStorage() {
    return readJson(localStorage[referralStorageKey], {});
  },

  // 0x5, 0x7 => {0x5, 0x7}
  setReferralToStorage(authorAddress, referrerAddress = nullAddress) {
    const storage = this.getStorage() || {};

    if (storage[authorAddress]) return;

    storage[authorAddress] = referrerAddress;

    localStorage.setItem(referralStorageKey, JSON.stringify(storage));
  },

  // 0x5 => 0x7
  getReferralFromStorage(authorAddress) {
    const referrerAddress = this.getStorage()[authorAddress] || nullAddress;

    return referrerAddress;
  },
};
