import React from "react";

const Bitfinex = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M1.51583 34.3341C3.35814 36.3069 13.4889 45.7593 29.3889 34.7884C40.8889 25.7477 40.6004 6.14766 39.4966 0.225333C39.112 1.08747 25.7697 30.6176 1.51583 34.3341V34.3341ZM39.4966 0.22145C39.3543 0.163197 24.562 -1.87175 9.93891 7.63893C0.858139 13.5418 -0.345707 22.1865 0.0696779 27.9613C21.3274 25.5457 39.012 0.901062 39.4966 0.22145V0.22145Z"
          fill="#97C554"
        />
        <path
          d="M39.4966 0.22145C39.3543 0.163197 24.562 -1.87175 9.93891 7.63893C0.858139 13.5418 -0.345707 22.1865 0.0696779 27.9613C21.3274 25.5457 39.012 0.901062 39.4966 0.22145V0.22145Z"
          fill="#709B30"
        />
      </g>
      <defs></defs>
    </svg>
  );
};

export default Bitfinex;
