import { isAddress } from "ethers/lib/utils";

// есть shortenAddress in vanilla/utils
export const formatAddress = (address, slice = 0) => {
  if (slice) {
    return address.length > slice ? `${address.slice(0, slice)}...` : address;
  }

  if (!isAddress(address)) {
    return address;
  }

  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};
