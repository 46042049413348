import React from "react";

const Tractor = ({ className }) => (
  <svg
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M4.58297 17.0552C2.12157 17.0552 0.0945403 14.9743 0.100109 12.4488C0.105678 9.85485 2.10487 7.7854 4.59411 7.7854C7.07221 7.7911 9.08254 9.87195 9.07697 12.4374C9.0714 14.9914 7.06108 17.0609 4.58297 17.0552Z" />
    <path d="M18.6274 8.78873C18.6218 8.54359 18.594 8.29275 18.5327 8.05901C18.232 6.90171 17.2352 6.12638 16.0657 6.12638C14.6123 6.12638 13.1644 6.12068 11.711 6.12638C11.6163 6.12638 11.5662 6.10927 11.5328 6.00096C11.0316 4.31347 10.5248 2.62598 10.0181 0.944197C9.87884 0.48812 9.64495 0.305689 9.1716 0.305689C8.26946 0.305689 7.36175 0.305689 6.45961 0.305689C5.52963 0.299988 4.59964 0.299988 3.66409 0.299988C3.47475 0.299988 3.28541 0.362698 3.14062 0.48812C2.94015 0.66485 2.87889 0.887188 2.87889 1.13233C2.87889 2.47776 2.87889 3.82889 2.88446 5.17432C2.88446 5.32254 2.84548 5.38525 2.70626 5.43086C2.05471 5.6418 1.43658 5.93254 0.862994 6.32021C0.484317 6.57675 0.384079 7.02713 0.612399 7.39769C0.840718 7.76256 1.29736 7.85377 1.67603 7.60293C2.32201 7.18106 3.01254 6.89031 3.75876 6.71928C5.07856 6.41713 6.34824 6.54825 7.48983 7.30648C9.56142 8.68041 10.3578 10.7214 10.2464 13.1842C10.2241 13.7201 10.5192 14.0963 11.0483 14.0963C11.3713 14.0963 11.6943 14.0963 12.0172 14.0963C12.0284 14.0963 12.1342 14.102 12.201 14.0165C12.24 13.9709 12.2456 13.9196 12.2456 13.8968C12.3458 11.8216 14.022 10.1684 16.0769 10.1684C16.5948 10.1684 17.1071 10.3109 17.1962 10.3394C17.6361 10.4705 17.9981 10.6586 18.271 10.8354C18.4269 10.9323 18.6274 10.8183 18.6274 10.6358V10.6301C18.6385 9.63817 18.6385 9.35312 18.6274 8.78873Z" />
    <path d="M16.0825 17.1349C17.5834 17.1349 18.8001 15.8893 18.8001 14.3528C18.8001 12.8163 17.5834 11.5708 16.0825 11.5708C14.5817 11.5708 13.365 12.8163 13.365 14.3528C13.365 15.8893 14.5817 17.1349 16.0825 17.1349Z" />
  </svg>
);

export default Tractor;
