import React from "react";

const Okb = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 18.1132C25.0018 18.1132 29.0566 14.0584 29.0566 9.0566C29.0566 4.05478 25.0018 0 20 0C14.9982 0 10.9434 4.05478 10.9434 9.0566C10.9434 14.0584 14.9982 18.1132 20 18.1132Z"
        fill="#88BDF3"
      />
      <path
        d="M20 40C25.0018 40 29.0566 35.9452 29.0566 30.9434C29.0566 25.9416 25.0018 21.8868 20 21.8868C14.9982 21.8868 10.9434 25.9416 10.9434 30.9434C10.9434 35.9452 14.9982 40 20 40Z"
        fill="#3075EE"
      />
      <path
        d="M30.9434 29.0566C35.9452 29.0566 40 25.0018 40 20C40 14.9982 35.9452 10.9434 30.9434 10.9434C25.9416 10.9434 21.8868 14.9982 21.8868 20C21.8868 25.0018 25.9416 29.0566 30.9434 29.0566Z"
        fill="#5795F1"
      />
      <path
        d="M9.0566 29.0566C14.0584 29.0566 18.1132 25.0018 18.1132 20C18.1132 14.9982 14.0584 10.9434 9.0566 10.9434C4.05478 10.9434 0 14.9982 0 20C0 25.0018 4.05478 29.0566 9.0566 29.0566Z"
        fill="#205FEC"
      />
      <path
        d="M17.8679 17.868C16.2537 17.4755 14.7785 16.6465 13.6038 15.4718C12.429 14.2971 11.6001 12.8219 11.2076 11.2076C12.819 11.6061 14.291 12.4371 15.4647 13.6108C16.6385 14.7846 17.4694 16.2566 17.8679 17.868V17.868Z"
        fill="#134BEF"
      />
      <path
        d="M11.1887 28.8114C11.5812 27.1971 12.4102 25.7219 13.5849 24.5472C14.7596 23.3725 16.2348 22.5435 17.8491 22.151C17.4506 23.7624 16.6196 25.2344 15.4459 26.4082C14.2721 27.582 12.8001 28.4129 11.1887 28.8114V28.8114Z"
        fill="#0932E8"
      />
      <path
        d="M22.1321 22.1322C23.7463 22.5247 25.2215 23.3537 26.3962 24.5284C27.571 25.7031 28.3999 27.1783 28.7925 28.7926C27.181 28.3941 25.709 27.5632 24.5353 26.3894C23.3615 25.2156 22.5306 23.7436 22.1321 22.1322V22.1322Z"
        fill="#164BEA"
      />
      <path
        d="M28.8113 11.1888C28.4188 12.8031 27.5898 14.2783 26.4151 15.453C25.2404 16.6277 23.7652 17.4567 22.1509 17.8492C22.5494 16.2378 23.3804 14.7658 24.5541 13.592C25.7279 12.4183 27.1999 11.5873 28.8113 11.1888Z"
        fill="#3170F1"
      />
    </svg>
  );
};

export default Okb;
