import React from "react";

const Medium = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M18.25 5.91581H17.6073C17.3687 5.91581 17.0312 6.26366 17.0312 6.48631V14.5574C17.0312 14.7803 17.3687 15.0842 17.6073 15.0842H18.25V17H12.4271V15.0842H13.6459V6.59997H13.5861L10.7401 17H8.53669L5.72734 6.59997H5.65625V15.0842H6.875V17H2V15.0842H2.62429C2.88131 15.0842 3.21875 14.7803 3.21875 14.5574V6.48625C3.21875 6.2636 2.88131 5.91574 2.62429 5.91574H2V4H8.09591L10.0973 11.5263H10.1525L12.1723 4H18.25V5.91581Z" />
    </svg>
  );
};

export default Medium;
