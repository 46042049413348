export default function Mail({ className }) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M10.5898 12.7768C10.4021 12.8841 10.1877 12.9377 10 12.9377C9.81233 12.9377 9.59786 12.8841 9.41019 12.7768L0 7.03955V14.6803C0 16.3157 1.31367 17.6294 2.94906 17.6294H17.0509C18.6863 17.6294 20 16.3157 20 14.6803V7.03955L10.5898 12.7768Z" />
      <path d="M17.0508 2.88428H2.94896C1.55485 2.88428 0.375229 3.87623 0.0803223 5.18991L9.9999 11.2489L19.9195 5.18991C19.6246 3.87623 18.4449 2.88428 17.0508 2.88428Z" />
    </svg>
  );
}
