export default function Dollar({ className, fill = "current" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      fill="none"
      className={`fill-${fill} ${className}`}
    >
      <path d="M8.5 0.1875C4.18528 0.1875 0.6875 3.68528 0.6875 8C0.6875 12.3147 4.18528 15.8125 8.5 15.8125C12.8147 15.8125 16.3125 12.3147 16.3125 8C16.3125 3.68528 12.8147 0.1875 8.5 0.1875ZM9.40337 11.9928V12.8143H7.652V11.9976C6.22406 11.7372 5.3395 10.7978 5.3395 9.37575H7.58063C7.58063 9.90628 8.01422 10.1713 8.49937 10.1713C8.95434 10.1713 9.38791 9.93969 9.38791 9.53525C9.38791 9.04412 8.77956 8.90175 8.03375 8.71741C6.89494 8.43441 5.44494 8.08281 5.44494 6.29553C5.44494 5.02287 6.23375 4.19712 7.57325 3.95759V3.18572H9.32463V3.96563C10.6559 4.22531 11.4063 5.10259 11.4063 6.40844L9.23019 6.41334C9.23019 5.95316 8.86194 5.72837 8.41197 5.72837C8.02103 5.72837 7.64778 5.88928 7.64778 6.22837C7.64778 6.67769 8.23769 6.82497 8.98478 7.01438C10.1453 7.314 11.6607 7.69406 11.6607 9.49197C11.6606 10.8957 10.7541 11.7425 9.40337 11.9928Z" />
    </svg>
  );
}
