import React from "react";

const Case = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C17.882 9.43571 17.5697 8.92867 17.1159 8.56436C16.662 8.20005 16.0943 8.00073 15.5085 8H14.4915C13.9057 8.00073 13.338 8.20005 12.8841 8.56436C12.4303 8.92867 12.118 9.43571 12 10H11C9.34315 10 8 11.3431 8 13V15H22V13C22 11.3431 20.6569 10 19 10H18ZM16.9403 10H13.0597C13.1644 9.70855 13.3583 9.45605 13.6146 9.27704C13.8709 9.09804 14.1772 9.00128 14.4915 9H15.5085C15.8228 9.00128 16.1291 9.09804 16.3854 9.27704C16.6417 9.45605 16.8356 9.70855 16.9403 10ZM8 19V16H14V17C14 17.5523 14.4477 18 15 18C15.5523 18 16 17.5523 16 17V16H22V19C22 20.1046 21.1046 21 20 21H10C8.89543 21 8 20.1046 8 19Z"
      />
    </svg>
  );
};

export default Case;
