import useStyleRewriter from "../../../zui/functions/use-style-rewriter";

export default function P({ children, className = "" }) {
  const srClasses = useStyleRewriter(baseClasses, className);

  return <p className={srClasses}>{children}</p>;
}

const baseClasses = `
    @fts text-16px`;
