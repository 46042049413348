import React from "react";

const BackupCodes = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.8466 19.4196C21.8386 17.4841 19.9031 16.2744 17.6853 16.2744C14.4999 16.2744 11.8789 18.8954 11.8789 22.0809C11.8789 25.2664 14.4999 27.8873 17.6853 27.8873C19.9031 27.8873 21.8386 26.6776 22.8466 24.7422H24.5402C24.6611 24.7422 24.7418 24.7018 24.8628 24.6615L26.1934 24.097L27.6047 24.6615C27.6854 24.7018 27.8063 24.7018 27.8869 24.7018H30.145C30.3466 24.7018 30.5886 24.6212 30.7499 24.4599L33.0079 22.0809C33.2902 21.7986 33.2902 21.3147 33.0483 20.9922L31.9192 19.6615C31.7579 19.4599 31.5563 19.3793 31.3144 19.3793H22.8466V19.4196ZM29.8628 23.1293H28.0886L26.516 22.4841C26.3144 22.4035 26.1128 22.4035 25.9112 22.4841L24.4192 23.1293H22.3628C22.0402 23.1293 21.7176 23.3309 21.5966 23.6535C20.9515 25.2664 19.4192 26.2744 17.6853 26.2744C15.387 26.2744 13.4918 24.3793 13.4918 22.0809C13.4918 19.7825 15.387 17.8873 17.6853 17.8873C19.4192 17.8873 20.9515 18.9357 21.5966 20.5083C21.7176 20.8309 21.9999 21.0325 22.3628 21.0325H30.9918L31.395 21.5163L29.8628 23.1293Z" />
      <path d="M22 7C13.7339 7 7 13.7339 7 22C7 30.2661 13.7339 37 22 37C30.2661 37 37 30.2661 37 22C37 13.7339 30.2661 7 22 7ZM22 35.3871C14.621 35.3871 8.6129 29.379 8.6129 22C8.6129 14.621 14.621 8.6129 22 8.6129C29.379 8.6129 35.3871 14.621 35.3871 22C35.3871 29.379 29.379 35.3871 22 35.3871Z" />
      <path d="M16.9999 23.7337C17.9129 23.7337 18.6531 22.9935 18.6531 22.0805C18.6531 21.1674 17.9129 20.4272 16.9999 20.4272C16.0868 20.4272 15.3467 21.1674 15.3467 22.0805C15.3467 22.9935 16.0868 23.7337 16.9999 23.7337Z" />
    </svg>
  );
};

export default BackupCodes;
