import React from "react";

const AuthentificationApp = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.5914 7.00589C22.4771 7.00758 22.365 7.03717 22.2647 7.09204C22.1645 7.14691 22.0792 7.22542 22.0163 7.32073C20.7401 9.18227 16.6949 10.7648 12.0667 10.7921C12.0575 10.792 12.0484 10.792 12.0393 10.7921C12.0092 10.787 11.9788 10.7838 11.9483 10.7826C11.7865 10.7819 11.6294 10.8371 11.5037 10.9388C11.378 11.0406 11.2914 11.1826 11.2586 11.3408C11.2586 11.3408 10.5591 14.5047 10.2086 18.3314C9.85821 22.1582 9.78603 26.6394 11.3241 29.5328C12.4764 31.7002 15.2026 33.4423 17.6364 34.7684C19.8875 35.9949 21.7441 36.7371 22.0362 36.8527C22.1684 36.9553 22.3331 37.0072 22.5003 36.9991C22.6698 37.0075 22.8365 36.9542 22.9697 36.849C22.9707 36.849 22.9718 36.849 22.9729 36.849C23.2771 36.7289 25.1244 35.9922 27.3672 34.7702C29.801 33.4441 32.5237 31.7021 33.6759 29.5346C35.214 26.6413 35.1418 22.16 34.7913 18.3332C34.4409 14.5065 33.7414 11.3427 33.7414 11.3427C33.708 11.1813 33.6187 11.0369 33.4893 10.9349C33.3598 10.8328 33.1984 10.7795 33.0335 10.7845C33.0141 10.7837 32.9948 10.7837 32.9754 10.7845C28.5284 10.7667 24.6216 9.26816 23.2585 7.45947C23.2086 7.32323 23.1175 7.20587 22.9979 7.12357C22.8782 7.04128 22.7359 6.99811 22.5907 7.00006L22.5914 7.00589ZM22.5787 8.83042C24.5332 10.9038 28.2837 12.0696 32.4719 12.172C32.6122 12.8389 33.0997 15.2241 33.3963 18.4635C33.7377 22.1914 33.6664 26.5678 32.4373 28.8798C31.594 30.4661 29.0401 32.2648 26.6946 33.5427C24.5714 34.6995 22.8459 35.3942 22.5004 35.5319C22.1548 35.3942 20.4294 34.6995 18.3061 33.5427C15.9606 32.2648 13.4066 30.4661 12.5634 28.8798C11.3342 26.5678 11.2631 22.1914 11.6044 18.4635C11.9009 15.2257 12.3881 12.8424 12.5288 12.1738C16.7897 12.0717 20.5978 10.9066 22.5787 8.83042Z" />
      <path d="M22.4957 14.5064C21.4517 14.5158 20.4334 15.111 19.823 16.2569C19.2701 17.2948 18.9653 18.782 18.8689 20.8327C18.2845 21.0023 17.8457 21.5472 17.8457 22.1839V28.0836C17.8457 28.852 18.4818 29.4935 19.2458 29.4935H25.7529C26.5169 29.4935 27.1548 28.852 27.1548 28.0836V22.1839C27.1548 21.5727 26.7487 21.0462 26.199 20.8547C26.1142 18.8759 25.7942 17.3835 25.2267 16.3265C24.5998 15.1588 23.5783 14.5003 22.4957 14.5064ZM22.5032 15.9126C23.0817 15.9126 23.5402 16.1456 23.9961 16.9948C24.3978 17.7428 24.7007 19.0065 24.7936 20.7741H20.2747C20.3772 18.915 20.672 17.6419 21.0557 16.9216C21.4892 16.1077 21.8991 15.9159 22.5032 15.9126ZM19.246 22.1803C21.537 22.1803 23.7658 22.1803 25.7531 22.1803C25.7667 22.1803 25.7568 22.17 25.7568 22.1803V28.08C25.7568 28.094 25.7669 28.08 25.7531 28.08H19.246C19.2324 28.08 19.2441 28.0903 19.2441 28.08V22.1803C19.2441 22.1662 19.2323 22.1803 19.246 22.1803Z" />
    </svg>
  );
};

export default AuthentificationApp;
