import Button from "~components/ui/button";
import P from "~components/ui/typography/p";
import useStyleRewriter from "../../zui/functions/use-style-rewriter";

const InputHeading = ({
  title = "",
  onButtonClick = () => console.log("InputHeading -> onClick"),
  buttonTitle,
  className,
}) => {
  const srClasses = useStyleRewriter(
    "@fx flex @wh w-full @it items-center justify-between",
    className,
  );

  return (
    <div className={srClasses}>
      <div className="w-fit pb-2">
        <P className="@leh leading-none @fts text-[14px] @ttc text-zinc-base">
          {title}
        </P>
      </div>
      {buttonTitle && (
        <div className="flex justify-end w-fit pb-2">
          <Button variant="text" onClick={onButtonClick}>
            <P className="text-gray-dark hover:text-blue-primary duration-200">
              {buttonTitle}
            </P>
          </Button>
        </div>
      )}
    </div>
  );
};

export default InputHeading;
