const walletConfigs = [
  {
    title: "Metamask",
    icon: "MetaMask",
    connectorId: "injected",
    uid: "metamask",
    background: "linear-gradient(268.56deg, #FFC632 17.64%, #FF9900 91.11%)",
  },
  // {
  //   title: `TrustWallet`,
  //   icon: `TrustWallet`,
  //   connectorId: `injected`,
  //   uid: `trustwallet`,
  // },
  // {
  //   title: `TokenPocket`,
  //   icon: `TokenPocket`,
  //   connectorId: `injected`,
  //   uid: `tokenpocket`,
  // },
  {
    title: "WalletConnect",
    icon: "WalletConnect",
    connectorId: "walletConnect",
    uid: "walletConnect",
    background: "linear-gradient(268.56deg, #C070FF 17.64%, #9C4FFF 91.11%)",
  },
  // {
  //   title: `Coinbase Wallet`,
  //   icon: `/assets/images/coinbase-wallet.png`,
  //   connectorId: `walletLink`,
  //   uid: `walletLink`,
  // },
  // {
  //   title: `Binance Chain`,
  //   icon: `Binance`,
  //   connectorId: `bsc`,
  //   uid: `bsc`,
  // },
];

export default walletConfigs;

export const connectorLocalStorageKey = "connectorId";
