import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWeb3Auth from "src/hooks/web3/use-web3-auth";
import { forcedLoginWithJwt } from "~store/authSlice";
import {
  selectIsAuthenticated,
  selectIsConnectedWithWeb3,
} from "~store/authSlice/selectors";
import { authTokensManager } from "~utils/localStorage/authTokensManager";
import { connectorLocalStorageKey } from "~web3/configs/wallets";

/**
 * Web3 Autologin
 */
const useAutoLogin = () => {
  const { login } = useWeb3Auth();
  const { query } = useRouter();

  const dispatch = useDispatch();
  const isConnectedWithWeb3 = useSelector(selectIsConnectedWithWeb3);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) return;

    const connectorKey = localStorage.getItem(connectorLocalStorageKey);
    const connectorUid = localStorage.getItem("connectorUid");

    if (!isConnectedWithWeb3 && connectorKey && connectorUid) {
      login(connectorKey, connectorUid).catch((err) =>
        console.error("useAutoLogin", err),
      );

      return;
    }

    const emailJwt = authTokensManager.getJwt() ?? null;

    if (!isAuthenticated && emailJwt) {
      dispatch(forcedLoginWithJwt());
    }
  }, [query]);
};

export default useAutoLogin;
