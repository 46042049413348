import { ethers } from "ethers";
import createEventNotification from "~utils/create-event-notification";

// TODO: можно не плодить кучу функций, которые делают примерно одно и то же, а создать одну вида call(contractInfo, args, etc..) и использовать ее для вызова любого метода контракта. Refactoring
export const approve = async ([spender, amount], tokenContract) => {
  try {
    const tx = await tokenContract.approve(spender, amount);

    createEventNotification({
      event: "warning",
      title: "Approve transaction sent to blockchain",
      message: "Please, wait for confirmation notification",
    });

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Tokens approved successfully",
      });

    return status;
  } catch (error) {
    console.error("approve", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, // error?.message,
    });
  }
};

export const transfer = async (
  [spender, amount],
  tokenContract,
  onConfirm = () => {},
) => {
  try {
    const parsedAmount = ethers.utils.parseUnits(String(amount), 18);

    const gasPrice = await tokenContract.provider.getGasPrice();

    const tx = await tokenContract.transfer(spender, parsedAmount, {
      gasPrice: gasPrice.mul(13000).div(10000),
    });

    onConfirm(tx);
    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    const receipt = await tx.wait();

    return receipt;
  } catch (error) {
    console.error("transfer", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, //
    });
  }
};

export const upgradeSubscription = async (args, managerContract, onConfirm) => {
  try {
    const tx = await managerContract.upgradeSubscription(...args);

    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm(tx);
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Subscription upgraded!",
      });

    return receipt;
  } catch (error) {
    console.error("upgradeSubscription", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, // error?.message
    });
  }
};

export const subscribe = async (args, managerContract, onConfirm) => {
  try {
    const tx = await managerContract.subscribe(...args);

    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm(tx);
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Subscribed!",
      });

    return receipt;
  } catch (error) {
    console.error("subscribe", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, // error?.message
    });
  }
};

export const purchaseItem = async (args, managerContract, onConfirm) => {
  try {
    const tx = await managerContract.purchaseItem(...args);

    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm(tx);
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Purchased!",
      });

    return receipt;
  } catch (error) {
    console.error("purchaseItem", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      title: message, // error?.message
    });
  }
};

export const setSubscriptionPrice = async (
  args,
  managerContract,
  onConfirm,
) => {
  try {
    const tx = await managerContract.setSubscriptionLevel(...args);
    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm();
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Blockchain data updated (Subscription)",
      });

    return status;
  } catch (error) {
    console.error("setSubscriptionPrice", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, // error?.message
    });
  }
};

export const setReferrerShare = async (fee, managerContract, onConfirm) => {
  try {
    const tx = await managerContract.setReferrerShare(fee);

    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm();
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Referrer bonus is setted on blockchain",
      });

    return status;
  } catch (error) {
    console.error("setReferrerShare", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      title: message, // error?.message,
    });
  }
};

export const setReferralShareOfReferrerFeeByAuthor = async (
  args,
  managerContract,
  onConfirm,
) => {
  try {
    const tx = await managerContract.setReferralShareOfReferrerFeeByAuthor(
      ...args,
    );
    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm();
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Referral discount is setted on blockchain",
      });

    return status;
  } catch (error) {
    console.error("setReferralShareOfReferrerFeeByAuthor", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      // message,
      title: message, // error?.message,
    });
  }
};

export const updateAutoRenewal = async (args, managerContract, onConfirm) => {
  try {
    const tx = await managerContract.updateAutoRenewal(...args);
    createEventNotification({
      event: "warning",
      title: "Transaction has been sent to the blockchain",
      message: "Please, wait for confirmation notification",
    });
    if (onConfirm) {
      onConfirm(tx);
    }

    const receipt = await tx.wait();

    const status = receipt.status;

    if (status)
      createEventNotification({
        event: "success",
        title: "Blockchain data updated (Subscription auto renewing)",
      });

    return status;
  } catch (error) {
    console.error("updateAutoRenewal", error);
    const message =
      typeof error === "string"
        ? error
        : error?.error?.message || error?.data?.message || error?.message;

    createEventNotification({
      event: "error",
      message, //
      title: message, // error?.message,
    });
  }
};
