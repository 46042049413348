import React, { useState } from "react";

import AllIconsLocal from "~components/ui/all-icons";
import AllIcons from "~components/zui/components/all-icons";
import Button from "~components/ui/button";

import H2 from "~components/ui/typography/h2";

import CardBox from "~components/ui/card-box";

import P from "~components/ui/typography/p";
import AmountInput from "~components/donation/AmountInput";

const PermissionSetCard = ({
  permissionsConfig = {},
  setPermissionsConfig,
  onSubmit = () => {},
  loading,
  levels,
  setShow,
  title = "Add new permission",
  subtitle = "Сhoose one or several conditions",
}) => {
  const [priceInputActive, setPriceInputActive] = useState(
    permissionsConfig.price ? true : false,
  );

  return (
    <CardBox
      maxContentHeight={false}
      className="@ht max-h-[91vh] @dy flex @ani flex-col  @ow overflow-y-scroll @brw border-0 @wh min-w-fit w-full lg:max-w-[480px]"
    >
      <div className="w-full flex flex-col justify-between">
        <div className="flex flex-col mb-6">
          <H2 className="@ftf font-clash @ftw font-semibold @fts text-[26px] @tta text-center @mn mb-3">
            {title}
          </H2>
          <P className="@ftf font-montserrat @mn mx-auto @fts text-14px @leh leading-20px @ttc text-zinc-700">
            {subtitle}
          </P>
        </div>
        <div className="flex flex-col">
          <H2 className="@ftf font-clash @ftw font-semibold @fts text-xl @mn mb-3">
            Following
          </H2>
          <div className="flex items-center gap-1 mb-6">
            <PermissionCaseButton
              isActive={permissionsConfig.following}
              setIsActive={(following) =>
                setPermissionsConfig({ ...permissionsConfig, following })
              }
              title={"Following"}
            />
            <PermissionCaseButton
              isActive={permissionsConfig.followedByUser}
              setIsActive={(followedByUser) =>
                setPermissionsConfig({ ...permissionsConfig, followedByUser })
              }
              title={"Followed by me"}
            />
          </div>
        </div>
        {levels?.length ? (
          <div className="flex flex-col">
            <H2 className="@ftf font-clash @ftw font-semibold @fts text-xl @mn mb-3">
              Subscription
            </H2>
            <div className="flex flex-wrap gap-1 mb-6">
              <PermissionCaseButton
                isActive={permissionsConfig.subscription}
                setIsActive={(subscription) =>
                  setPermissionsConfig({
                    ...permissionsConfig,
                    subscription,
                    levels: null,
                  })
                }
                title={"Any"}
              />

              {levels.map((level) => (
                <PermissionCaseButton
                  key={level.id}
                  isActive={
                    permissionsConfig?.levels?.find(
                      (_level) => _level.id === level.id,
                    ) || permissionsConfig?.levels?.includes(level.id)
                  }
                  setIsActive={(isActive) => {
                    let subscription = false;
                    let currentLevels = permissionsConfig.levels?.length
                      ? [...permissionsConfig.levels]
                      : [];
                    if (isActive) {
                      currentLevels.push(level.id);
                    } else {
                      currentLevels = currentLevels.filter(
                        (currentLevel) => currentLevel !== level.id,
                      );
                    }
                    if (currentLevels.length === levels.length) {
                      currentLevels = null;
                      subscription = true;
                    }
                    setPermissionsConfig({
                      ...permissionsConfig,
                      levels: currentLevels,
                      subscription,
                    });
                  }}
                  colorTitle={`Tier ${level.index + 1} `}
                  title={"subscription"}
                />
              ))}
            </div>
          </div>
        ) : null}

        <div className="flex flex-col">
          <H2 className="@ftf font-clash @ftw font-semibold @fts text-xl @mn mb-3">
            Price
          </H2>
          <div className="flex flex-wrap gap-1 mb-6">
            <PermissionCaseButton
              isActive={permissionsConfig.price === 1 && !priceInputActive}
              setIsActive={(price) => {
                setPermissionsConfig({
                  ...permissionsConfig,
                  price: price ? 1 : null,
                });
                setPriceInputActive(false);
              }}
              title={"1$"}
            />

            <AmountInput
              isActive={priceInputActive}
              setIsActive={setPriceInputActive}
              step={1}
              value={priceInputActive ? permissionsConfig.price : undefined}
              setValue={(price) =>
                setPermissionsConfig({ ...permissionsConfig, price })
              }
              inputAvailable={true}
              className={`!py-2 !text-sm !max-h-10   ${
                priceInputActive
                  ? "border-zinc-900 !border-0.5"
                  : "!border-zinc-base !text-zinc-base !border"
              }`}
            />
          </div>
        </div>
        <div className="flex justify-center items-center ">
          <Button
            onClick={() => {
              setShow(false);
            }}
            variant="rounded"
            className="@bdc bg-white hover:bg-zinc-900 @ttc text-zinc-900 hover:text-white @brw border @brc border-zinc-900 hover:border-white @pg py-[11px] px-[30px] @wh w-[140px] flex justify-center items-center @ht h-[46px] mr-5"
          >
            Cancel
          </Button>

          <Button
            onClick={() => onSubmit({ setShow })}
            variant="rounded"
            className="@wh w-[140px]  @ht h-[46px] @pg py-2.5 px-8 flex justify-center items-center"
          >
            {loading ? (
              <AllIconsLocal
                name="SmallLoader"
                className="h-5 w-5 animate-spin"
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </CardBox>
  );
};

export default PermissionSetCard;

const PermissionCaseButton = ({ colorTitle, title, isActive, setIsActive }) => {
  return (
    <Button
      variant={isActive ? "rounded" : "gray"}
      onClick={() => setIsActive(!isActive)}
      className={`@wh w-fit @dy flex items-center ${
        isActive ? "@pg py-2.5 pl-3 lg:pl-5 pr-3" : "@pg px-5 py-2.5"
      } @mn mb-2 @cr cursor-pointer ${
        !isActive && "@ttc md:hover:text-white text-zinc-base"
      } `}
    >
      {colorTitle && <span className="text-pink-base pr-1">{colorTitle}</span>}
      {title}
      {isActive && <AllIcons name="Close" className="ml-2 w-6 h-6" />}
    </Button>
  );
};
