export const selectSubscribeModalState = (state) => state.modals.subscribe;

export const selectMediaGalleryModalState = (state) =>
  state.modals.mediaGallery;

export const selectDonateModalState = (state) => state.modals.donate;

export const selectUnlockAccessModalState = (state) =>
  state.modals.unlockAccess;

export const selectPurchaseItemModalState = (state) =>
  state.modals.purchaseItem;
