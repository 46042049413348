const DislikeFilled = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      className={`full-current ${className}`}
    >
      <path
        d="M15.6 11.1175C15.6 11.2925 15.7473 11.4392 15.9358 11.4392C16.2834 11.4392 16.631 11.4392 16.9785 11.4392C18.3689 11.4336 19.4941 10.3612 19.5 9.02916C19.5 7.05936 19.5 5.0952 19.5 3.12539C19.5 2.73595 19.394 2.35215 19.1937 2.01914C18.6811 1.16123 17.8976 0.749209 16.8666 0.749209C16.5544 0.749209 16.2421 0.749209 15.9358 0.749209C15.7532 0.749209 15.6 0.890313 15.6 1.07093V11.1175Z"
        fill="#FF429D"
      />
      <path
        d="M0.293335 11.0886C0.541825 12.2689 1.5019 13.0539 2.70482 13.0596C3.69879 13.0652 4.69275 13.0596 5.68671 13.0596C5.68671 13.7486 5.68671 14.398 5.68671 15.0418C5.68671 15.375 5.66412 15.7139 5.7093 16.0414C5.91826 17.5776 7.04776 18.6732 8.58953 18.8821C9.11475 18.9556 9.51008 18.8031 9.7981 18.3795C10.2499 17.7301 10.4984 17.4194 10.6396 17.0919C10.9445 16.4029 11.9611 14.6352 13.6215 11.8905C13.661 11.8284 13.7288 11.7098 13.7852 11.5573C13.9264 11.1846 13.9208 10.8683 13.9208 10.7949C13.9208 10.1285 13.9038 2.88274 13.9038 1.33532C13.9038 1.17719 13.6384 0.798809 13.2544 0.697153C13.1527 0.668915 13.0567 0.668913 12.9946 0.674561C7.99654 0.668913 4.35954 0.680208 3.81738 0.685856C3.68749 0.685856 3.37123 0.697153 2.99849 0.832693C2.80083 0.906111 2.62011 1.00212 2.45069 1.11507C1.94241 1.45957 1.57532 1.99043 1.46237 2.62295C1.24776 3.82023 1.08399 5.02315 0.897618 6.22607C0.705603 7.48546 0.507941 8.75051 0.315926 10.0099C0.259451 10.3713 0.21427 10.7271 0.293335 11.0886Z"
        fill="#FF429D"
      />
    </svg>
  );
};

export default DislikeFilled;
