import React from "react";

const TwitterColored = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2896 18C13.8368 18 17.9648 11.8438 17.9648 6.50515C17.9648 6.3303 17.9648 6.15623 17.9528 5.98295C18.7559 5.41105 19.4491 4.70293 20 3.89176C19.2512 4.21863 18.4567 4.4329 17.6432 4.52738C18.4998 4.02255 19.141 3.22842 19.4472 2.29285C18.6417 2.76347 17.7605 3.09511 16.8416 3.27346C16.2229 2.62577 15.4047 2.19689 14.5135 2.05318C13.6223 1.90947 12.7078 2.05894 11.9116 2.47848C11.1154 2.89801 10.4819 3.56421 10.109 4.37398C9.73605 5.18376 9.64462 6.09196 9.8488 6.95805C8.21741 6.87748 6.62146 6.46005 5.16455 5.73288C3.70763 5.0057 2.4223 3.98501 1.392 2.73708C0.867274 3.62645 0.70656 4.67929 0.942583 5.68123C1.17861 6.68318 1.79362 7.55888 2.6624 8.13006C2.00936 8.11122 1.37054 7.93777 0.8 7.62439V7.67559C0.800259 8.60833 1.12821 9.51227 1.72823 10.2341C2.32824 10.9559 3.16338 11.4512 4.092 11.6359C3.4879 11.7981 2.85406 11.8218 2.2392 11.7052C2.50151 12.5078 3.01202 13.2098 3.69937 13.7128C4.38671 14.2158 5.21652 14.4948 6.0728 14.5107C5.22203 15.1691 4.24776 15.6559 3.20573 15.9433C2.16369 16.2306 1.07435 16.3128 0 16.1853C1.87653 17.3709 4.05994 17.9997 6.2896 17.9969"
      fill="#1DA1F2"
    />
  </svg>
);

export default TwitterColored;
