import qs from "qs";

// TODO to env
const backBaseUrl = "https://website.subclub.space";

export const getLayoutQuery = (locale) => {
  const currentLocale = locale === "ua" ? "uk" : locale;

  const query = qs.stringify(
    {
      locale: currentLocale,
      populate: { header: { populate: "*" }, footer: { populate: "*" } },
    },
    { encode: false },
  );

  return query;
};

export const getMainQuery = (locale) => {
  const currentLocale = locale === "ua" ? "uk" : locale;

  const keys = [
    "comment",
    "extra",
    "image",
    "images",
    "videos",
    "items",
    "plans",
    "card",
    "card_image",
    "screen_image",
    "title_image",
    "our_items",
    "our_title_image",
    "their_items",
    "their_title_image",
  ];

  const query = qs.stringify(
    {
      locale: currentLocale,
      populate: {
        page_blocks: {
          populate: {
            ...keys.reduce((a, b) => ({ ...a, [b]: { populate: "*" } }), {}),
            videos: { populate: { video: "*", poster: "*" } },
          },
        },
        header: { populate: "*" },
        footer: { populate: "*" },
      },
    },
    { encode: false },
  );

  return query;
};

export const getAmbassadorQuery = (locale) => {
  const currentLocale = locale === "ua" ? "uk" : locale;

  const keys = ["image", "images", "buttons", "items", "title_image"];

  const query = qs.stringify(
    {
      locale: currentLocale,
      populate: {
        page_blocks: {
          populate: {
            ...keys.reduce((a, b) => ({ ...a, [b]: { populate: "*" } }), {}),
          },
        },
      },
    },
    { encode: false },
  );

  return query;
};

const getImageUrl = (obj, size = "medium") => {
  if (!obj) {
    return null;
  }

  const url = size ? obj.formats?.[size]?.url || obj.url : obj.url;

  const httpsExists = url.match(/^https?:\/\//);

  if (httpsExists) {
    return url;
  }

  return `${backBaseUrl || ""}${url}`;
};

const getTitleImageUrl = (obj) => {
  return getImageUrl(obj, "full");
};

const getVideoUrl = (obj) => {
  if (!obj) {
    return null;
  }

  return {
    poster: getImageUrl(obj.poster?.data),
    video: `${backBaseUrl}${obj.video?.data?.url}`,
  };
};

const transformListItems = (items) => {
  return items.reduce(
    (a, b) => [
      ...a,
      {
        ...b,
        image: getImageUrl(b.image?.data),
        icon: getImageUrl(b.icon?.data),
      },
    ],
    [],
  );
};

export const transformPageBlock = (block, transformers) => {
  const key = block?.__component;

  if (!transformers?.[key]) {
    return null;
  }

  return transformers[key](block);
};

export const layoutTransformers = {
  ["header"]: (data = {}) => ({
    ...data,
  }),
  ["footer"]: (data = {}) => ({
    ...data,
    socials: data?.socials || [],
    policies: data?.policies || [],
    platform_items: {
      title: data?.platform_title || "",
      links: data?.platform_items || [],
    },
    help_items: {
      title: data?.help_title || "",
      links: data?.help_items || [],
    },
    resources_items: {
      title: data?.resources_title || "",
      links: data?.resources_items || [],
    },
  }),
};

export const mainTransformers = {
  ["page-blocks.main-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    images: block?.images?.data?.map(getImageUrl) || null,
    videos: block?.videos?.map(getVideoUrl),
  }),
  ["page-blocks.intro-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    comment: {
      ...block.comment,
      image: getImageUrl(block?.comment?.image?.data),
    },
    image: {
      desktop: getImageUrl(block?.screen_image?.desktop?.data),
      mobile: getImageUrl(block?.screen_image?.mobile?.data),
    },
  }),
  ["page-blocks.verification-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    image: getImageUrl(block?.image?.data),
    items: transformListItems(block.items),
  }),
  ["page-blocks.better-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    items: transformListItems(block.items),
  }),
  ["page-blocks.referral-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    image: {
      desktop: getImageUrl(block?.screen_image?.desktop?.data),
      mobile: getImageUrl(block?.screen_image?.mobile?.data),
    },
    items: transformListItems(block.items),
    card_image: getImageUrl(block?.card_image?.data),
  }),
  ["page-blocks.comparsion-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    image: getImageUrl(block?.image?.data),
    images: block?.images?.data?.map(getImageUrl) || null,
    cards: [
      {
        title: block.our_title,
        items: transformListItems(block.our_items),
      },
      {
        title: block.their_title,
        items: transformListItems(block.their_items),
      },
    ],
  }),
  ["page-blocks.storage-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
  }),
  ["page-blocks.storage-block-2"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    image: {
      desktop: getImageUrl(block?.screen_image?.desktop?.data),
      mobile: getImageUrl(block?.screen_image?.mobile?.data),
    },
  }),
  ["page-blocks.email-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    image: getImageUrl(block?.image?.data),
  }),
};

export const ambassadorTransformers = {
  ["page-blocks.ambassador-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    images: block?.images?.data?.map(getImageUrl) || null,
  }),
  ["page-blocks.start-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    items: transformListItems(block.items),
  }),
  ["page-blocks.better-block"]: (block) => ({
    ...block,
    title_image: {
      desktop: getTitleImageUrl(block?.title_image?.desktop?.data),
      mobile: getTitleImageUrl(block?.title_image?.mobile?.data),
    },
    items: transformListItems(block.items),
  }),
  ["page-blocks.form-block"]: (block) => ({
    ...block,
  }),
};
