import React from "react";

const Apps = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 8H12.0833C13.372 8 14.4167 9.04467 14.4167 10.3333V12.0833C14.4167 13.372 13.372 14.4167 12.0833 14.4167H10.3333C9.04467 14.4167 8 13.372 8 12.0833V10.3333C8 9.04467 9.04467 8 10.3333 8ZM17.9168 8H19.6668C20.9555 8 22.0002 9.04467 22.0002 10.3333V12.0833C22.0002 13.372 20.9555 14.4167 19.6668 14.4167H17.9168C16.6282 14.4167 15.5835 13.372 15.5835 12.0833V10.3333C15.5835 9.04467 16.6282 8 17.9168 8ZM12.0833 15.5834H10.3333C9.04467 15.5834 8 16.628 8 17.9167V19.6667C8 20.9554 9.04467 22 10.3333 22H12.0833C13.372 22 14.4167 20.9554 14.4167 19.6667V17.9167C14.4167 16.628 13.372 15.5834 12.0833 15.5834ZM17.9168 15.5834H19.6668C20.9555 15.5834 22.0002 16.628 22.0002 17.9167V19.6667C22.0002 20.9554 20.9555 22 19.6668 22H17.9168C16.6282 22 15.5835 20.9554 15.5835 19.6667V17.9167C15.5835 16.628 16.6282 15.5834 17.9168 15.5834Z"
      />
    </svg>
  );
};

export default Apps;
