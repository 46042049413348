import React from "react";

const Blog = ({ className }) => (
  <svg
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M6.14617 0.5C9.25784 0.5 11.7923 3.18142 11.7923 6.5C11.7923 9.81858 9.25784 12.5 6.14617 12.5C3.0345 12.5 0.5 9.84513 0.5 6.5C0.5 3.15487 3.0345 0.5 6.14617 0.5ZM15.18 0.845133C16.7108 0.845133 17.9906 3.39381 17.9906 6.5C17.9906 9.63274 16.7359 12.1549 15.18 12.1549C13.6242 12.1549 12.3695 9.63274 12.3695 6.5C12.3695 3.36726 13.6242 0.845133 15.18 0.845133ZM19.4962 1.4292C20.0734 1.4292 20.5 3.71239 20.5 6.5C20.5 9.28761 20.0483 11.5708 19.4962 11.5708C18.9497 11.5708 18.5015 9.33305 18.4926 6.58348L18.4925 6.5C18.4925 3.71239 18.9442 1.4292 19.4962 1.4292Z" />
  </svg>
);

export default Blog;
