import { ethers } from "ethers";
import { hexValue } from "ethers/lib/utils";

import { compose, mapObjIndexed, filter } from "ramda";

import {
  BSC,
  BSC_T,
  ETH,
  LOCAL,
  ROPSTEN,
  MATIC,
  MUMBAI,
  DEFAULT,
} from "./constants";

const networkConfigs = {
  [ETH]: {
    chainId: hexValue(ETH),
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  [ROPSTEN]: {
    chainId: hexValue(ROPSTEN),
    chainName: "Ropsten Test Network",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: [
      "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88raea12eaa4456161",
    ],
    blockExplorerUrls: ["https://ropsten.etherscan.io"],
  },
  [MATIC]: {
    chainId: hexValue(MATIC),
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "matic",
      decimals: 18,
    },
    rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  [MUMBAI]: {
    chainId: hexValue(MUMBAI),
    chainName: "Polygon Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "matic",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  [BSC]: {
    chainId: hexValue(BSC),
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "bnb",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.ninicoin.io"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [BSC_T]: {
    chainId: hexValue(BSC_T),
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "bnb",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [LOCAL]: {
    chainId: hexValue(LOCAL),
    chainName: "Hardhat Network",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: ["http://127.0.0.1:8545/"],
  },
};

Object.defineProperty(networkConfigs[DEFAULT], "isActive", {
  value: true,
  enumerable: false,
});

export const supportedChains = Object.keys(networkConfigs)
  .filter((key) => networkConfigs[key].isActive)
  .map(Number);

export const defaultProvider = new ethers.providers.JsonRpcProvider(
  networkConfigs[DEFAULT].rpcUrls[0],
);

export const rpcUrlByChains = compose(
  mapObjIndexed((config) => config.rpcUrls[0]),
  filter((config) => config.isActive),
)(networkConfigs);

export default networkConfigs;
