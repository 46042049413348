import React from "react";

const TokenPocket = ({ className }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M13.4911 0.663128C13.9778 0.780611 15.2573 1.10588 16.3411 2.22876C16.8187 2.7282 17.1983 3.31297 17.4599 3.95263C17.6797 4.46092 18.2216 5.76442 17.9011 7.43394C17.6729 8.58365 17.0991 9.63625 16.2563 10.4509C15.6098 11.0826 14.8314 11.5632 13.977 11.8583C12.8938 12.2513 11.7298 12.3683 10.59 12.1988C10.59 13.4327 10.59 14.6395 10.59 15.8455C10.59 16.1868 10.4566 16.3186 10.1105 16.3194C9.36804 16.3194 8.62559 16.3194 7.88233 16.3194C7.30051 16.3194 6.7179 16.3154 6.13528 16.3194C5.67254 16.3234 5.65576 16.3466 5.65576 15.8551C5.65896 11.1041 5.65896 6.35289 5.65576 1.60139C5.65576 1.31607 5.65576 1.03156 5.65576 0.747044C5.65576 0.62157 5.67974 0.523269 5.8156 0.487305H12.0406C12.5293 0.489244 13.0161 0.548251 13.4911 0.663128Z"
        fill="#2761E6"
      />
      <path
        d="M7.88071 16.3202C7.88071 16.6516 7.88071 16.983 7.88071 17.3144C7.8809 17.3537 7.86919 17.3921 7.84715 17.4247C7.82791 17.4508 7.80306 17.4722 7.77443 17.4874C7.74579 17.5026 7.71411 17.5112 7.68171 17.5126H3.14306C3.08745 17.5103 3.03493 17.4864 2.99671 17.4459C2.95848 17.4054 2.93759 17.3516 2.93847 17.296V7.48982C2.1049 7.48982 1.28572 7.48423 0.465744 7.48982C-0.0273619 7.49701 0.000610031 7.47144 0.000610031 7.0079C0.00433963 5.27311 0.00433963 3.53858 0.000610031 1.80432C-0.00314384 1.75765 0.0105224 1.71124 0.0389716 1.67405C0.0583956 1.65081 0.082928 1.63236 0.110654 1.62016C0.138381 1.60797 0.168551 1.60234 0.198811 1.60372H5.65255C5.65255 6.35523 5.65255 11.1065 5.65255 15.8574C5.65255 16.3489 5.67253 16.3258 6.13207 16.3218C6.71388 16.3138 7.2989 16.3202 7.88071 16.3202Z"
        fill="#29ADFE"
      />
    </svg>
  );
};

export default TokenPocket;
