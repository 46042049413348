import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";

import { isMobile } from "react-device-detect";
import { DEFAULT } from "~web3/configs/constants";
import { rpcUrlByChains, supportedChains } from "~web3/configs/networks";

const POLLING_INTERVAL = 12000;

const injected = new InjectedConnector({
  supportedChainIds: supportedChains,
});

const walletConnect = new WalletConnectConnector({
  rpc: rpcUrlByChains,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
  qrcodeModalOptions: {
    mobileLinks: ["metamask", "1inch", "trust"],
  },
});

export const connectorsByName = {
  injected: injected,
  walletConnect: walletConnect,
};

export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async ({ provider, account, expires }) => {
  const signatureElements = getSignatureElements();
  const { domain, types, message } = signatureElements;
  message.expires = expires;
  message.account = account.toLowerCase();

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const signTypedMessage = () =>
      provider.send("eth_signTypedData", [
        account.toLowerCase(),
        JSON.stringify(signatureElements),
      ]);

    const signature = isMobile
      ? Promise.any([signTypedMessage(), signTypedMessage()])
      : signTypedMessage();

    return signature;
  }
  delete types.EIP712Domain;
  return provider.getSigner(account)._signTypedData(domain, types, message);
};

const getSignatureElements = () => ({
  types: {
    EIP712Domain: [
      {
        name: "name",
        type: "string",
      },
      {
        name: "version",
        type: "string",
      },
      {
        name: "chainId",
        type: "uint256",
      },
    ],
    LoginToken: [
      { name: "account", type: "address" },
      { name: "expires", type: "uint256" },
    ],
  },
  primaryType: "LoginToken",
  domain: {
    name: "subclub.me",
    version: "1",
    chainId: DEFAULT,
  },
  message: {
    account: "0x0000000000000000000000000000000000000000",
    expires: 0,
  },
});
