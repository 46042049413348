import { useEffect, useMemo, useState } from "react";

import { useWeb3React } from "@web3-react/core";

import { formatEtherValue } from "~web3/utils/big-number";

import { getPaymentTokenContract } from "~web3/utils/contract-heplers";
import { contracts, DEFAULT, maxUint256 } from "~web3/configs/constants";
import { approve, transfer } from "~web3/utils/calls";
import { parseEther } from "ethers/lib/utils";
import { useSelector } from "react-redux";
import { selectAccountAddress } from "~store/authSlice/selectors";

export const FetchStatus = {
  NOT_FETCHED: "not-fetched",
  SUCCESS: "success",
  FAILED: "failed",
  LOADING: "loading",
};

const useTokenContract = () => {
  const { library, chainId } = useWeb3React();
  return useMemo(
    () => getPaymentTokenContract(chainId, library?.getSigner()),
    [library],
  );
};

const { NOT_FETCHED, SUCCESS, FAILED, LOADING } = FetchStatus;

const useToken = () => {
  const [allowanceState, setAllowanceState] = useState({
    allowance: 0,
    fetchStatus: NOT_FETCHED,
  });
  const [balanceState, setBalanceState] = useState({
    balance: 0,
    fetchStatus: NOT_FETCHED,
  });
  const tokenContract = useTokenContract();

  const accountAddress = useSelector(selectAccountAddress);

  const { account, library: provider, chainId } = useWeb3React();

  const updateBalance = async () => {
    try {
      const res = await tokenContract.balanceOf(accountAddress);

      setBalanceState({
        balance: formatEtherValue(res),
        fetchStatus: SUCCESS,
      });
    } catch (e) {
      console.error("useToken updateBalance", e);
      setBalanceState((prev) => ({
        ...prev,
        fetchStatus: FAILED,
      }));
    }
  };

  const updateAllowance = async (
    spender = contracts.manager.address[DEFAULT],
  ) => {
    try {
      const allowance = await tokenContract.allowance(account, spender);

      setAllowanceState({
        allowance: formatEtherValue(allowance),
        fetchStatus: SUCCESS,
      });
    } catch (e) {
      console.error("useToken updateAllowance", e);
      setAllowanceState((prev) => ({
        ...prev,
        fetchStatus: FAILED,
      }));
    }
  };

  const approveHandler = async ({
    spender = contracts.manager.address[chainId],
    amount = maxUint256,
  }) => {
    if (!(provider && tokenContract && amount)) return;

    if (amount !== maxUint256) {
      amount = parseEther(String(amount));
    }
    setAllowanceState((prev) => ({
      ...prev,
      fetchStatus: LOADING,
    }));

    const success = await approve([spender, amount], tokenContract);

    await updateAllowance();

    return success;
  };

  const transferHandler = async ({ address, amount, onConfirm }) => {
    if (!(provider && tokenContract && amount)) return;

    const txReceipt = await transfer(
      [address, amount],
      tokenContract,
      onConfirm,
    );
    updateBalance();
    return txReceipt;
  };

  useEffect(() => {
    if (tokenContract && account && provider && accountAddress) {
      updateBalance();
      updateAllowance();
    }
  }, [account, provider, accountAddress]);

  useEffect(() => {
    if (!tokenContract || !accountAddress) return;
    updateBalance();
    const lowerAcc = accountAddress.toLowerCase();
    tokenContract.on("Transfer", (from, to) => {
      if (to.toLowerCase() === lowerAcc || from.toLowerCase() === lowerAcc) {
        console.log("Transfer", { from, to });
        updateBalance();
      }
    });
  }, [tokenContract, accountAddress]);

  return {
    balance: balanceState.balance,
    allowance: allowanceState.allowance,
    allowanceState,
    updateAllowance,
    transfer: transferHandler,
    approve: approveHandler,
  };
};

export const useGetEthBalance = () => {
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.NOT_FETCHED);
  const [balance, setBalance] = useState(0);
  const { account, library: provider } = useWeb3React();

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const walletBalance = await provider.getBalance(account);

        setBalance(formatEtherValue(walletBalance));
        setFetchStatus(FetchStatus.SUCCESS);
      } catch {
        setFetchStatus(FetchStatus.FAILED);
      }
    };

    if (account) {
      fetchBalance();
    }
  }, [account, setBalance, setFetchStatus]);

  return { balance, fetchStatus };
};

export default useToken;
