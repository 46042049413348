import React from "react";

const Profile = ({ className }) => (
  <svg
    className={`fill-current ${className}`}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.16904 8.73963C10.4996 8.73963 12.3889 6.85035 12.3889 4.51981C12.3889 2.18927 10.4996 0.299988 8.16904 0.299988C5.8385 0.299988 3.94922 2.18927 3.94922 4.51981C3.94922 6.85035 5.8385 8.73963 8.16904 8.73963Z" />
    <path d="M14.8189 14.7372C15.014 14.6598 15.1891 14.5394 15.3313 14.385C15.4734 14.2305 15.5789 14.046 15.6399 13.8452C15.7008 13.6444 15.7157 13.4324 15.6834 13.225C15.6511 13.0176 15.5725 12.8202 15.4533 12.6474C14.6192 11.4423 13.5053 10.4574 12.2072 9.77718C10.909 9.09693 9.46524 8.74158 7.99964 8.74158C6.53405 8.74158 5.0903 9.09693 3.79214 9.77718C2.49397 10.4574 1.38009 11.4423 0.545968 12.6474C0.426994 12.8204 0.348548 13.0181 0.31646 13.2257C0.284372 13.4332 0.299467 13.6453 0.360622 13.8463C0.421777 14.0472 0.527422 14.2317 0.669705 14.3862C0.811989 14.5407 0.987254 14.6611 1.18248 14.7386C3.38005 15.6144 5.72552 16.0595 8.09117 16.0497C10.3961 16.0364 12.678 15.5912 14.8189 14.7372Z" />
  </svg>
);

export default Profile;
