const BurgerThin = ({ className }) => (
  <svg
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} fill-current`}
  >
    <path d="M19.8989 10C20.5049 10 20.9961 10.4912 20.9961 11.0972C20.9961 11.7032 20.5049 12.1944 19.8989 12.1944H1.0972C0.491233 12.1944 4.21469e-08 11.7032 0 11.0972C-6.32203e-08 10.4912 0.491233 10 1.0972 10H19.8989Z" />
    <path d="M19.8989 5.64853e-07C20.5049 1.91355e-06 20.9961 0.491235 20.9961 1.0972C20.9961 1.70317 20.5049 2.1944 19.8989 2.1944H1.0972C0.491233 2.1944 4.21469e-08 1.70317 0 1.0972C-6.32203e-08 0.491233 0.491233 -1.47514e-07 1.0972 0L19.8989 5.64853e-07Z" />
  </svg>
);

export default BurgerThin;
