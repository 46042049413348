import { authTokensManager } from "~utils/localStorage/authTokensManager";
import axios from "./axios";

export const backBaseUrl =
  process.env.NEXT_PUBLIC_BACKEND_URL || "http://localhost:1337";

/**
 * @deprecated Не использовать, актуальный апи-клиент в ~/src/api
 */
export const api = {
  /**
   * @deprecated Не использовать, актуальный апи-клиент в ~/src/api
   */
  get: (url, options = { isAuth: false }) => {
    const headers = combineHeaders({ options });
    return axios({
      method: "get",
      url: url,
      ...options,
      headers,
    });
  },

  /**
   * @deprecated Не использовать, актуальный апи-клиент в ~/src/api
   */
  post: (url, body, options = { isAuth: false }) => {
    const headers = combineHeaders({ options });
    return axios({
      method: "post",
      url: url,
      data: body,
      ...options,
      headers,
    });
  },

  /**
   * @deprecated Не использовать, актуальный апи-клиент в ~/src/api
   */
  delete: (url, options = {}) => {
    const headers = combineHeaders({ options });
    return axios({
      method: "delete",
      url: url,
      ...options,
      headers,
    });
  },

  /**
   * @deprecated Не использовать, актуальный апи-клиент в ~/src/api
   */
  put: (url, body, options = {}) => {
    const headers = combineHeaders({ options });
    return axios({
      method: "put",
      data: body,
      url: url,
      ...options,
      headers,
    });
  },
};

const combineHeaders = ({ options }) => {
  const headers = options.headers || {};

  if (options.isAuth) {
    const token = authTokensManager.getJwt();
    token && (headers.Authorization = `Bearer ${token}`);
  }

  return headers;
};
