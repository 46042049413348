import React from "react";

const Edit = ({ className }) => (
  <svg
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${className}`}
  >
    <path d="M7.30762 13H13.6155" />
    <path d="M10.4619 1.43547C10.7407 1.15664 11.1189 1 11.5132 1C11.7085 1 11.9018 1.03846 12.0822 1.11318C12.2626 1.18789 12.4265 1.29741 12.5645 1.43547C12.7026 1.57353 12.8121 1.73744 12.8868 1.91782C12.9615 2.09821 13 2.29154 13 2.48679C13 2.68204 12.9615 2.87538 12.8868 3.05576C12.8121 3.23615 12.7026 3.40005 12.5645 3.53811L3.80352 12.2991L1 13L1.70088 10.1965L10.4619 1.43547Z" />
  </svg>
);

export default Edit;
