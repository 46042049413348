import React from "react";
import P from "~ui/typography/p";
import H3 from "~ui/typography/h3";

const Heading = ({ title, subtitle, iconPath }) => {
  return (
    <>
      {iconPath && (
        <img
          className="w-20 h-20 mb-10 object-cover"
          src={iconPath}
          alt="Very nice icon"
        />
      )}
      {title && (
        <H3 className="@ftf font-clash font-semibold @fts text-26px @leh leading-30px @tta text-center @mn mb-3">
          {title}
        </H3>
      )}
      {subtitle && (
        <P className="@ftf font-montserrat mx-auto @fts text-base @leh leading-20px @ttc text-true-gray-850 dark:text-true-gray-450">
          {subtitle}
        </P>
      )}
    </>
  );
};

export default Heading;
