import H3 from "~components/ui/typography/h3";
import P from "~components/ui/typography/p";
import Button from "~components/ui/button";
import Web3Action from "~components/web3/Web3Action";
import AllIcons from "~components/ui/all-icons";

const BluredContent = ({ setShowSubscribeModal, title = "Subscribe" }) => {
  return (
    <div className="w-full h-full relative ">
      <div
        className={
          "absolute top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center text-zinc-900 "
        }
      >
        <div className="mb-5 bg-white bg-opacity-50 px-2 py-2 rounded-10px">
          <H3 className="@tta text-center @ftf font-clash @ftw font-semibold @fts text-base md:text-22px ">
            Become a subscriber
          </H3>
          <P className="@ftf font-montserrat @ftw font-medium @tta text-center @fts text-xs  md:text-base">
            Сontent is available only for subscribers
          </P>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Web3Action>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowSubscribeModal(true);
              }}
              variant="rounded"
              className={
                "@wh w-full lg:w-fit @mn mr-0 @bdc bg-lime-base hover:bg-zinc-900 @ttc text-zinc-900 hover:text-white @pg @pg px-5 py-2 md:py-4 "
              }
            >
              <div className="flex items-center justify-center ">
                <AllIcons className="w-4 h-4 mr-1" name="Dollar" />

                {title}
              </div>
            </Button>
          </Web3Action>
        </div>
      </div>
    </div>
  );
};

export default BluredContent;
