import React from "react";
import Heading from "./heading";

const PaidModalBase = ({ children, title, subtitle, iconPath, buttons }) => {
  return (
    <div className="text-center flex flex-col items-center @pg p-4 sm:p-10 bg-white rounded-2xl dark:bg-true-gray-850 lg:w-[500px]">
      <Heading title={title} subtitle={subtitle} iconPath={iconPath} />

      {children}

      <div className="w-full flex justify-evenly mt-10">{buttons}</div>
    </div>
  );
};

export default PaidModalBase;
