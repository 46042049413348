import React from "react";

const Key = ({ className }) => (
  <svg
    viewBox="0 0 358 358"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M316.345 41.472C268.376 -6.495 198.05 -13.942 159.267 24.839C129.888 54.22 127.046 101.7 148.246 143.851L5.73097 286.363C1.93597 290.158 -0.0850307 295.376 0.160969 300.734L0.156969 339.761C0.383969 344.689 2.46797 349.125 5.72697 352.386C8.98897 355.647 13.426 357.731 18.352 357.957L57.079 357.653C62.438 357.899 67.655 355.878 71.452 352.082L85.972 337.561C89.552 333.982 91.564 329.125 91.564 324.061V304.43H111.195C116.259 304.43 121.115 302.418 124.695 298.838L141.716 281.817C145.296 278.237 147.304 273.381 147.304 268.319V248.687L166.937 248.686C172.001 248.686 176.859 246.675 180.437 243.094L213.966 209.568C256.115 230.768 303.595 227.926 332.976 198.547C371.759 159.766 364.312 89.441 316.345 41.472ZM293.448 121.314C283.036 131.726 261.846 127.419 246.122 111.694C230.398 95.969 226.088 74.781 236.502 64.369C246.916 53.955 268.106 58.262 283.829 73.986C299.552 89.712 303.862 110.901 293.448 121.314Z" />
  </svg>
);

export default Key;
