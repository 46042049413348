import { transformResponseItem } from "~api";
import { postsPopulate } from "~api/utils";
import { backUploaderUrl } from "~configs";
import { backendApi } from "..";

export const postsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getPostsFeed: build.query({
      query: ({ pagination } = {}) => ({
        url: "posts/feed",
        params: {
          populate: postsPopulate,
          sort: ["createdAt:desc"],
          pagination,
        },
      }),

      transformResponse: (response) =>
        response?.data?.length ? transformResponseItem(response?.data) : [],

      providesTags: (result) =>
        result?.length
          ? [
              ...result.map(({ id }) => ({ type: "Post", id })),
              ...result
                .filter(({ userLike }) => userLike)
                .map(({ userLike }) => ({ type: "Like", id: userLike })),
            ]
          : [],
    }),

    deletePost: build.mutation({
      query: (id) => ({
        url: `posts/${id}`,
        method: "DELETE",
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result) => [{ type: "Post", id: _result.id }],
    }),

    addPost: build.mutation({
      query: (data) => ({
        url: `${backUploaderUrl}/api/posts`,
        method: "POST",
        params: {
          populate: postsPopulate,
        },
        body: data,
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (result) =>
        result ? [{ type: "Post", id: result.id }] : [],
    }),

    editPost: build.mutation({
      query: ({ id, data }) => ({
        url: `${backUploaderUrl}/api/posts/${id}`,
        method: "PUT",
        params: {
          populate: postsPopulate,
        },
        body: data,
      }),

      transformResponse: transformResponseItem,
    }),

    getPosts: build.query({
      query: ({ filters, pagination } = {}) => ({
        url: "posts",
        params: {
          filters,
          populate: postsPopulate,
          sort: ["createdAt:desc"],
          pagination,
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result = []) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Post", id: id })),
              ...result
                .filter(({ userLike }) => userLike)
                .map(({ userLike }) => ({ type: "Like", id: userLike })),
            ]
          : [],
    }),

    getProfilePosts: build.query({
      query: ({ userId, pagination }) => ({
        url: "posts",
        params: {
          filters: { user: userId },
          populate: postsPopulate,
          sort: ["createdAt:desc"],
          pagination,
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result = []) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Post", id: id })),
              ...result
                .filter(({ userLike }) => userLike)
                .map(({ userLike }) => ({ type: "Like", id: userLike })),
            ]
          : [],
    }),
  }),
});

export const {
  useGetProfilePostsQuery,
  useLazyGetProfilePostsQuery,

  useGetPostsFeedQuery,
  useLazyGetPostsFeedQuery,

  useLazyGetPostsQuery,

  useDeletePostMutation,
  useEditPostMutation,
  useAddPostMutation,
} = postsApi;

export const { getProfilePosts } = postsApi.endpoints;
