import React from "react";

const PieChart = ({ className }) => (
  <svg
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} fill-current`}
  >
    <path d="M10.0659 9.56219C9.89146 9.47018 9.67792 9.521 9.54762 9.66946C8.81851 10.5002 7.7574 11.0319 6.57123 11.0527C4.32382 11.0922 2.46883 9.28925 2.42949 7.02414C2.39605 5.09832 3.68403 3.45974 5.4535 2.98726C5.64355 2.93652 5.7843 2.76781 5.78087 2.5702L5.76052 1.39865C5.75637 1.15972 5.54633 0.976089 5.31324 1.02323C2.52599 1.58688 0.44942 4.09157 0.500938 7.05797C0.55891 10.396 3.29306 13.0572 6.60503 12.9991C8.4874 12.966 10.1513 12.062 11.2286 10.6754C11.3748 10.4871 11.3058 10.2164 11.0954 10.1053L10.0659 9.56219Z" />
    <path d="M7.5004 5.98998C7.5004 6.13711 7.58036 6.27248 7.70888 6.34293L12.6028 9.0254C12.8115 9.13979 13.0738 9.04731 13.1499 8.82096C13.3781 8.14152 13.5004 7.41464 13.5004 6.66002C13.5004 3.32778 11.0996 0.559111 7.94129 0.00567046C7.70711 -0.0353662 7.5004 0.151783 7.5004 0.390702V5.98998Z" />
  </svg>
);

export default PieChart;
