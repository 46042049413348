import React from "react";

const Discord = ({ className }) => (
  <svg
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M12.36 0H1.64008C0.73604 0 0 0.735985 0 1.64801V12.464C0 13.376 0.735985 14.112 1.64003 14.112H10.712L10.288 12.632L11.312 13.584L12.28 14.48L14 16V1.64801C14 0.735985 13.264 0 12.36 0ZM9.27201 10.4481C9.27201 10.4481 8.98402 10.104 8.744 9.80001C9.79203 9.50399 10.192 8.84801 10.192 8.84801C9.864 9.06403 9.552 9.21606 9.27201 9.31997C8.87202 9.48802 8.488 9.59997 8.11203 9.66401C7.34399 9.808 6.64 9.76802 6.04002 9.65602C5.58398 9.56798 5.19198 9.44001 4.86402 9.31204C4.68 9.24001 4.48001 9.15202 4.27996 9.04002C4.25601 9.024 4.232 9.01601 4.20799 8.99999C4.19202 8.99201 4.18398 8.98402 4.176 8.97604C4.03201 8.89603 3.952 8.84003 3.952 8.84003C3.952 8.84003 4.33601 9.48004 5.352 9.78399C5.11203 10.0881 4.81601 10.448 4.81601 10.448C3.04796 10.392 2.37601 9.23203 2.37601 9.23203C2.37601 6.65602 3.528 4.568 3.528 4.568C4.68 3.70399 5.77599 3.728 5.77599 3.728L5.856 3.82403C4.41602 4.23993 3.75201 4.87201 3.75201 4.87201C3.75201 4.87201 3.92799 4.77603 4.22396 4.64002C5.07998 4.26399 5.76002 4.16003 6.04002 4.13602C6.08799 4.12798 6.12802 4.12 6.17603 4.12C6.71213 4.05016 7.25467 4.0448 7.79205 4.10403C8.55199 4.19202 9.36804 4.41602 10.2 4.87201C10.2 4.87201 9.56803 4.27203 8.208 3.85602L8.32 3.72805C8.32 3.72805 9.416 3.70399 10.568 4.56805C10.568 4.56805 11.72 6.65602 11.72 9.23203C11.72 9.23203 11.04 10.392 9.27201 10.448V10.4481ZM5.55199 6.71203C5.09601 6.71203 4.73605 7.11201 4.73605 7.59999C4.73605 8.08802 5.10399 8.488 5.55199 8.488C6.00803 8.488 6.36799 8.08802 6.36799 7.59999C6.37602 7.11201 6.00803 6.71203 5.55205 6.71203H5.55199ZM8.47204 6.71203C8.016 6.71203 7.65599 7.11201 7.65599 7.59999C7.65599 8.08802 8.02403 8.488 8.47204 8.488C8.92802 8.488 9.28797 8.08802 9.28797 7.59999C9.28797 7.11201 8.92802 6.71203 8.47204 6.71203Z" />
  </svg>
);

export default Discord;
