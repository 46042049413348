import React from "react";

const Portfolio = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48519 12.4025C6.48519 11.5717 7.15869 10.8982 7.98949 10.8982C8.8203 10.8982 9.4938 11.5717 9.4938 12.4025V15.2921C9.4938 16.1229 8.8203 16.7964 7.98949 16.7964C7.15869 16.7964 6.48519 16.1229 6.48519 15.2921V12.4025ZM10.9766 6.50431C10.9766 5.6735 11.6501 5 12.4809 5C13.3117 5 13.9852 5.6735 13.9852 6.50431V15.2921C13.9852 16.1229 13.3117 16.7964 12.4809 16.7964C11.6501 16.7964 10.9766 16.1229 10.9766 15.2921V6.50431ZM15.4679 8.58907C15.4679 7.75826 16.1414 7.08476 16.9722 7.08476C17.803 7.08476 18.4765 7.75826 18.4765 8.58906V15.2922C18.4765 16.123 17.803 16.7965 16.9722 16.7965C16.1414 16.7965 15.4679 16.123 15.4679 15.2922V8.58907ZM5.73991 17.8383C5.33127 17.8383 5 18.1696 5 18.5782C5 18.9869 5.33127 19.3182 5.73991 19.3182H19.2601C19.6687 19.3182 20 18.9869 20 18.5782C20 18.1696 19.6687 17.8383 19.2601 17.8383H5.73991Z"
      />
    </svg>
  );
};

export default Portfolio;
