export default function More({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-icon ${className}`}
    >
      <circle cx="4.5" cy="9.5" r="1.5" fill="#000000" />
      <circle cx="10.5" cy="9.5" r="1.5" fill="#000000" />
      <circle cx="16.5" cy="9.5" r="1.5" fill="#000000" />
    </svg>
  );
}
