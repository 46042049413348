import { useWeb3React } from "@web3-react/core";

import { useEffect, useMemo, useState } from "react";

import {
  purchaseItem,
  subscribe,
  updateAutoRenewal,
  upgradeSubscription,
} from "~web3/utils/calls";
import { getManagerContract } from "~web3/utils/contract-heplers";
import createEventNotification, {
  handleApiError,
} from "~utils/create-event-notification";

import useProfile from "~services/backend/profiles/useProfile";

import { defaultProvider } from "~web3/configs/networks";
import { useDispatch } from "react-redux";

import {
  profilesApi,
  useCreatePurchaseMutation,
  useLazyGetWeb3ParamsQuery,
} from "~services/backend/profiles";
import { useUpdateFollowingMutation } from "~services/backend/followings";
import { chatsApi } from "~services/backend/chats";
import { postsApi } from "~services/backend/posts";

const useManagerContract = () => {
  const { library, chainId } = useWeb3React();
  return useMemo(
    () => getManagerContract(chainId, library?.getSigner() || defaultProvider),
    [library],
  );
};

export const useReferralSettings = () => {
  return {
    handleSetReferralShareOfReferrerFeeByAuthor: () => {},
    handleSetReferrerShare: () => {},
    referrerShare: 0,
    referralShare: 0,
  };
};

export const useSubscriptions = (author) => {
  const { library: provider } = useWeb3React();

  const { profile } = useProfile();

  const [getWeb3Params] = useLazyGetWeb3ParamsQuery();
  const [updateFollowing] = useUpdateFollowingMutation();

  const managerContract = useManagerContract();

  const [currentSubscriptions, setCurrentSubscriptions] = useState(null);

  const handleSubscribe = async ({
    author,
    period = 1,
    level,
    amount = 1,
    onConfirm,
    followingId,
  }) => {
    if (!(provider && managerContract && author)) return;

    const { data: params, error } = await getWeb3Params({
      levelId: level,
      period,
      type: "subscribe",
      amount,
      followingId,
    });

    if (error) {
      createEventNotification(handleApiError(error));
      return;
    }

    const onConfirmCallback = (tx) => {
      const { hash } = tx;

      onConfirm(tx);
      if (followingId && hash) {
        updateFollowing({
          authorId: author,
          level,
          followingId,
          txHash: hash,
        });
      }
    };

    const receipt = await subscribe(
      [
        params.author,
        params.level,
        params.period,
        params.token,
        params.beneficiaries,
        params.fees,
        params.signature,
      ],
      managerContract,
      onConfirmCallback,
    );

    if (!receipt) return;

    const mintLog = receipt.logs.find(
      (log) =>
        log.address.toLowerCase() === managerContract.address.toLowerCase(),
    );

    if (mintLog) {
      const parsedMintLog = managerContract.interface.parseLog(mintLog);

      const { tokenId } = parsedMintLog.args;

      updateFollowing({
        authorId: author,
        level,
        followingId,
        tokenId: +tokenId,
      });
    }

    return receipt;
  };

  const handleUpgrade = async ({
    author,
    tokenId,
    followingId,
    level,
    period = 1,
    amount = 1,
    onConfirm,
  }) => {
    if (!(provider && managerContract && author)) return;

    const { data: params, error } = await getWeb3Params({
      authorId: author,
      levelId: level,
      period,
      type: "upgrade",
      amount,
      tokenId,
    });

    if (error) {
      createEventNotification(handleApiError(error));
      return;
    }

    const onConfirmCallback = (tx) => {
      const { hash } = tx;

      onConfirm(tx);
      if (followingId && hash) {
        updateFollowing({
          authorId: author,
          level,
          followingId,
          txHash: hash,
          tokenId,
        });
      }
    };

    const receipt = await upgradeSubscription(
      [
        params.tokenId,
        params.level,
        params.period,
        params.token,
        params.beneficiaries,
        params.fees,
        params.signature,
      ],
      managerContract,
      onConfirmCallback,
    );

    if (!receipt) return;

    updateFollowing({
      authorId: author,
      level,
      followingId,
      tokenId,
    });

    return receipt;
  };

  const handleUpdateAutorenewal = async ({
    author,
    level,
    tokenId,
    followingId,
    autoRenewal = false,
    onConfirm = () => {},
  }) => {
    if (!(provider && managerContract && author)) return;

    const onConfirmCallback = async (tx) => {
      const { hash } = tx;

      onConfirm(tx);
      if (followingId && hash) {
        updateFollowing({
          authorId: author,
          followingId,
          level,
          txHash: hash,
          tokenId,
        });
      }
    };

    const success = await updateAutoRenewal(
      [tokenId, autoRenewal],
      managerContract,
      onConfirmCallback,
    );

    if (!success) return;

    createEventNotification({
      event: "success",
      title: "Auto renewing canceled",
    });

    return success;
  };

  const getCurrentSubscriptions = () => {
    if (!profile?.followings?.length || !author) return;
    author = author.toLowerCase();
    const subscriptions = [];
    profile.followings.forEach((f) => {
      const {
        autoRenewal,
        cost,
        expiration,
        period,
        subscriptionLevel: level,
        id,
        tokenId,
      } = f;

      if (level && f.author?.address === author && isActual(expiration)) {
        subscriptions.push({
          autoRenewal,
          cost,
          expiration,
          period,
          level: level.index,
          id,
          tokenId,
        });
      }
    });

    setCurrentSubscriptions(subscriptions);
  };

  const followingsHash = useMemo(
    () =>
      profile?.followings ? profile.followings.map(Object.values).join("") : "",
    [profile],
  );

  useEffect(() => {
    getCurrentSubscriptions();
  }, [followingsHash]);

  return {
    handleSubscribe,
    handleUpgrade,
    handleUpdateAutorenewal,
    currentSubscriptions,
  };
};
const isActual = (expiration) => expiration * 1000 > Date.now();

export const useDiscountInfo = () => 0;

export const useSubscriptionListener = ({ onUpdate }) => {
  const { profile } = useProfile();
  const { account } = useWeb3React();
  const managerContract = useManagerContract();

  const handleSubscriptionUpdate = async (from, to, tokenId) => {
    console.log("event Transfer", from, to, tokenId);
    if (to.toLowerCase() === profile.address.toLowerCase()) {
      onUpdate();
    }
  };

  useEffect(() => {
    if (!(managerContract && profile && account)) return;

    managerContract.on("Transfer", handleSubscriptionUpdate);
    console.log("Listening Transfer");

    return () => {
      managerContract.off("Transfer", handleSubscriptionUpdate);
      console.log("Stop listening Transfer");
    };
  }, [managerContract, profile?.id, account]);
};

export const usePurchase = () => {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const { library: provider } = useWeb3React();

  const managerContract = useManagerContract();

  const [getWeb3Params] = useLazyGetWeb3ParamsQuery();

  const [createPurchase, createPurchaseResult] = useCreatePurchaseMutation();

  const purchase = async ({ itemId, itemType, onConfirm = () => {} }) => {
    if (!(provider && managerContract && itemId && itemType)) return;

    const { data: params, error } = await getWeb3Params({
      type: "purchase",
      itemType,
      itemId,
    });

    if (error) {
      createEventNotification(handleApiError(error));
      return;
    }

    const onConfirmCb = (tx) => {
      createPurchase({
        data: {
          tx_hash: tx.hash,
        },
      });
      onConfirm();
    };
    const tx = await purchaseItem(
      [
        params.itemUid,
        params.author,
        params.token,
        params.beneficiaries,
        params.fees,
        params.signature,
      ],
      managerContract,
      onConfirmCb,
    );

    if (tx.status) {
      createEventNotification({
        event: "success",
        title: "Successfully purchased",
      });

      createPurchase({
        data: {
          tx_hash: tx.transactionHash,
        },
      });
    }

    return tx;
  };

  useEffect(() => {
    if (!createPurchaseResult.isSuccess) return;

    const { data } = createPurchaseResult;

    const itemType = data.message ? "message" : "post";
    if (data.message && data.message.chat?.id) {
      dispatch(
        chatsApi.util.updateQueryData(
          "getMessagesByChat",
          {
            chatId: data.message.chat.id,
          },
          (draft) => {
            draft.forEach((msg, index) => {
              if (msg.id === data.message.id) {
                draft[index] = data.message;
              }
            });
          },
        ),
      );
    }
    if (data.post && data.post.user?.id) {
      const cb = (draft) => {
        draft.forEach((post, index) => {
          if (post.id === data.post.id) {
            draft[index] = data.post;
          }
        });
      };
      dispatch(postsApi.util.updateQueryData("getPostsFeed", {}, cb));
      dispatch(
        postsApi.util.updateQueryData(
          "getProfilePosts",
          {
            userId: data.post.user.id,
          },
          cb,
        ),
      );
    }

    dispatch(
      profilesApi.util.updateQueryData(
        "getPurchases",
        {
          userId: profile.id,
          filters: {
            [itemType]: data[itemType].id,
          },
        },
        (draft) => {
          draft.push(data);
        },
      ),
    );
  }, [createPurchaseResult]);

  return { purchase };
};
