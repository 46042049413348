export default function ArrowDown({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path d="M17 8.5L9.75092 15.7491L2.50184 8.5" strokeWidth="2" />
    </svg>
  );
}
