import React from "react";

const Account = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13205_273469)" />
      <path
        d="M16.164 16.7171C18.4239 16.7171 20.2559 14.8851 20.2559 12.6251C20.2559 10.3652 18.4239 8.5332 16.164 8.5332C13.904 8.5332 12.072 10.3652 12.072 12.6251C12.072 14.8851 13.904 16.7171 16.164 16.7171Z"
        fill="white"
      />
      <path
        d="M22.6123 22.5327C22.8015 22.4576 22.9713 22.3409 23.1092 22.1911C23.247 22.0414 23.3493 21.8625 23.4084 21.6677C23.4675 21.473 23.482 21.2674 23.4507 21.0663C23.4193 20.8652 23.3431 20.6737 23.2275 20.5062C22.4187 19.3376 21.3386 18.3826 20.0797 17.723C18.8209 17.0633 17.4209 16.7188 15.9997 16.7188C14.5785 16.7188 13.1785 17.0633 11.9197 17.723C10.6609 18.3826 9.58076 19.3376 8.77191 20.5062C8.65655 20.674 8.58048 20.8657 8.54936 21.0669C8.51825 21.2682 8.53288 21.4739 8.59218 21.6687C8.65149 21.8636 8.75393 22.0425 8.8919 22.1923C9.02987 22.3421 9.19983 22.4589 9.38914 22.534C11.5201 23.3833 13.7945 23.8149 16.0885 23.8054C18.3235 23.7926 20.5363 23.3608 22.6123 22.5327Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13205_273469"
          x1="2.66667"
          y1="32"
          x2="26.6667"
          y2="7.15256e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB5ABB" />
          <stop offset="1" stopColor="#FF86D8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Account;
