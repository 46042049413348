import { transformResponseItem } from "~api";
import { backendApi } from "..";

export const donationsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    sendDonation: build.mutation({
      query: (data) => ({
        url: "donations",
        method: "POST",
        params: {
          populate: "*",
        },
        body: data,
      }),
      transformResponse: transformResponseItem,
      invalidatesTags: (result) => {
        const res = ["Donation"];
        if (result?.user?.id) {
          res.push({ type: "Profile", id: result.user.id });
        }

        return res;
      },
    }),

    getDonations: build.query({
      query: ({ userId, authorId, amount = 0 }) => ({
        url: "donations",
        params: {
          filters: {
            user: userId,
            author: authorId,
            amount: {
              $gt: amount,
            },
          },
          populate: "*",
          sort: ["amount:desc"],
        },
      }),

      transformResponse: transformResponseItem,

      providesTags: (result) =>
        result?.length
          ? [...result.map(({ id }) => ({ type: "Donation", id }))]
          : [],
    }),
  }),
});

export const { useGetDonationsQuery, useSendDonationMutation } = donationsApi;
