import React from "react";

const Fire = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7532 8.00048C14.957 7.9928 15.1534 8.0775 15.2877 8.231C16.6096 9.74169 16.8971 11.3608 16.8497 12.5919C16.8394 12.8593 16.8135 13.1086 16.7791 13.3351C17.3288 13.0435 17.7166 12.693 17.9854 12.3794C18.2013 12.1275 18.343 11.8962 18.4289 11.733C18.4718 11.6516 18.5004 11.5877 18.5171 11.5477C18.5255 11.5277 18.5309 11.5137 18.5336 11.5066L18.5354 11.5015C18.6106 11.2816 18.7936 11.1155 19.0201 11.062C19.2482 11.0082 19.488 11.0763 19.6538 11.2421L19.7599 11.358C19.8175 11.4246 19.895 11.5195 19.9856 11.6433C20.1668 11.891 20.4006 12.2548 20.6317 12.7401C21.0952 13.7135 21.543 15.1653 21.543 17.1318C21.543 19.8493 20.0918 21.7442 18.1687 22.7409C18.2948 22.3969 18.3653 22.0416 18.3653 21.6913C18.3653 18.9855 15.5666 17.1817 14.9832 17.1817C14.3998 17.1817 11.601 18.9855 11.601 21.6913C11.601 22.1104 11.702 22.5366 11.8782 22.9421C11.3047 22.6786 10.7731 22.3309 10.3044 21.8996C9.09821 20.79 8.35254 19.1672 8.35254 17.1318C8.35254 14.8454 9.50585 12.9754 11.3121 11.5304C11.889 11.0689 12.6128 10.2696 13.2139 9.55248C13.5092 9.2002 13.766 8.87858 13.9489 8.64508C14.0403 8.52844 14.113 8.43407 14.1626 8.36919C14.3122 8.17365 14.4918 8.01033 14.7532 8.00048Z"
      />
    </svg>
  );
};

export default Fire;
