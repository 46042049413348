import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import { HYDRATE } from "next-redux-wrapper";
import { transformResponseItem } from "~api";
import { backBaseUrl } from "~configs";
import { authTokensManager } from "~utils/localStorage/authTokensManager";

const baseQuery = fetchBaseQuery({
  baseUrl: `${backBaseUrl}/api/`,
  transformResponse: transformResponseItem,

  paramsSerializer: (object) =>
    qs.stringify(object, {
      encodeValuesOnly: true,
    }),

  prepareHeaders: (headers) => {
    const token = authTokensManager.getJwt();

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const tagTypes = [
  "Profile",
  "Post",
  "Following",
  "Like",
  "ReferralLink",
  "Donation",
  "Purchase",
  "Message",
  "Chat",
];

export const backendApi = createApi({
  baseQuery,
  tagTypes,
  reducerPath: "backend",

  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },

  endpoints: () => ({}),
});
