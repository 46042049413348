import React from "react";

const Compass = ({ className }) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} fill-current`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 8C14.5 11.3137 11.8137 14 8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C10.1355 2 11.6148 2.65175 12.699 3.7142C13.8127 4.80554 14.5 6.32071 14.5 8ZM16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C10.6798 0 12.6559 0.871786 14.0988 2.28571C15.5806 3.73773 16.5 5.7615 16.5 8ZM11.2414 10.0798C11.307 10.2571 11.3727 10.4344 11.4396 10.6116C11.4318 10.6195 11.4239 10.6274 11.4211 10.6351C11.3829 10.8647 11.1826 10.9744 10.9839 10.8912C10.9761 10.888 10.9672 10.8858 10.9581 10.8835C10.9463 10.8805 10.934 10.8774 10.9226 10.8719C9.69566 10.4171 8.46876 9.96231 7.23671 9.50762C7.1092 9.4589 7.03116 9.38086 6.98244 9.25335C6.80016 8.75593 6.61658 8.25854 6.43301 7.76115C6.24943 7.26372 6.06584 6.76629 5.88355 6.26884C5.78381 6.00102 5.68407 5.73321 5.5921 5.46265C5.54349 5.33 5.60257 5.2105 5.7329 5.12549C5.84496 5.05371 5.94466 5.07988 6.04415 5.11634C7.28134 5.57092 8.51338 6.02561 9.74795 6.48281C9.87294 6.52901 9.96619 6.61702 10.0123 6.74715C10.1348 7.07999 10.2574 7.41349 10.38 7.74729C10.6004 8.34724 10.8212 8.94817 11.0431 9.54801C11.11 9.72526 11.1757 9.90254 11.2414 10.0798Z"
    />
  </svg>
);

export default Compass;
