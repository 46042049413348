import React from "react";

import AllIcons from "~components/ui/all-icons";
import Button from "~components/ui/button";
import H2 from "~components/ui/typography/h2";
import P from "~components/ui/typography/p";
import CardBox from "~components/ui/card-box";

const DeleteConfigCard = ({ loading, onSubmit, setShow }) => (
  <CardBox
    maxContentHeight={false}
    className="@ht max-h-[91vh] @dy flex @ani flex-col  @ow overflow-y-scroll @brw border-0 @wh min-w-[33vw] w-full lg:max-w-[480px]"
  >
    <div className="w-full flex flex-col justify-between">
      <div className="flex flex-col mb-6">
        <H2 className="@ftf font-clash @ftw font-semibold @fts text-[26px] @tta text-center @mn mb-3">
          Delete a permission?
        </H2>
        <P className="@ftf font-montserrat @mn mx-auto @fts text-14px @leh leading-20px @ttc text-zinc-700">
          You are you want to delete a permission? This action cannot be undone
          and conditions will be canceled only for new chats.
        </P>
      </div>
      <div className="flex justify-center items-center ">
        <Button
          onClick={() => {
            setShow(false);
          }}
          variant="rounded"
          className="@bdc bg-white hover:bg-zinc-900 @ttc text-zinc-900 hover:text-white @brw border @brc border-zinc-900 hover:border-white @pg py-[11px] px-[30px] @wh w-[140px] flex justify-center items-center @ht h-[46px] mr-5"
        >
          Cancel
        </Button>

        <Button
          onClick={onSubmit}
          variant="rounded"
          className="@wh w-[140px]  @ht h-[46px] @pg py-2.5 px-8 flex justify-center items-center"
        >
          {loading ? (
            <AllIcons name="SmallLoader" className="h-5 w-5 animate-spin" />
          ) : (
            "Delete"
          )}
        </Button>
      </div>
    </div>
  </CardBox>
);

export default DeleteConfigCard;
