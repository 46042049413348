import React from "react";

const ChineseFlag = ({ className }) => (
  <svg
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.7827 16.9231C24.7827 18.6223 23.5887 20 22.116 20H3.44938C1.97671 20 0.782715 18.6223 0.782715 16.9231V3.07692C0.782715 1.37769 1.97671 0 3.44938 0H22.116C23.5887 0 24.7827 1.37769 24.7827 3.07692V16.9231Z"
      fill="#DE2910"
    />
    <path
      d="M9.62829 3.15899L10.2129 3.44177L10.6808 2.99219L10.5926 3.63558L11.1645 3.94219L10.5259 4.05657L10.4115 4.6952L10.1049 4.12329L9.46148 4.21146L9.91106 3.74361L9.62829 3.15899ZM13.3338 5.49507L13.051 6.07889L13.5006 6.54754L12.858 6.45857L12.5514 7.03128L12.437 6.39185L11.7976 6.27747L12.3703 5.97087L12.2813 5.32827L12.7499 5.77785L13.3338 5.49507ZM12.5736 8.49679L12.7865 9.11L13.4354 9.1235L12.9183 9.5151L13.1066 10.137L12.5736 9.7661L12.0406 10.137L12.2281 9.5151L11.711 9.1235L12.3599 9.11L12.5736 8.49679ZM9.62829 11.1021L10.2129 11.3849L10.6808 10.9353L10.5926 11.5787L11.1645 11.8853L10.5259 11.9997L10.4115 12.6383L10.1049 12.0664L9.46148 12.1546L9.91106 11.6868L9.62829 11.1021ZM6.343 4.72697L7.08092 6.84858L9.32565 6.89465L7.53606 8.25134L8.18661 10.4008L6.343 9.11794L4.4994 10.4008L5.14994 8.25134L3.36035 6.89465L5.60508 6.84858L6.343 4.72697Z"
      fill="#FFDE02"
    />
  </svg>
);

export default ChineseFlag;
