import Head from "next/head";

import { useRouter } from "next/router";
import { useGetProfileByNameQuery } from "~services/backend/profiles";
import { getSocialImagePath } from "./vanilla/formatters/get-social-image-path";

const title = "Subclub";
const description = "Crypto subscription platform";
const url = "subclub.me";
const image = "/assets/images/seo.jpg";

const SEO = {
  "/index"() {
    return {
      title,
      description: description,
      url: url,
      image: `https://${url}${image}`,
      ogTitle: `${title}`,
      ogDescription: description,
      ogImage: `https://${url}${image}`,
      twitterCard: "summary_large_image",
      twitterTitle: `${description} | ${title}`,
      twitterDescription: description,
      twitterImage: `https://${url}${image}`,
    };
  },
  "/[username]"(profile) {
    if (!profile) return SEO["/index"]();

    const profileDescription = profile.bio || description;
    const cover = profile?.cover ? getSocialImagePath(profile.cover) : null;

    const previewTitle = `${profile.username} | ${title}`;
    const previewImage = cover || `https://${url}${image}`;
    return {
      title,
      description: profileDescription,
      url: `${url}/${profile.username}`,
      image: previewImage,
      ogTitle: previewTitle,
      ogDescription: profileDescription,
      ogImage: previewImage,
      twitterCard: "summary_large_image",
      twitterTitle: previewTitle,
      twitterDescription: profileDescription,
      twitterImage: previewImage,
    };
  },
};

export const MetaBlock = ({}) => {
  const { route, query } = useRouter();

  const { data: profile } = useGetProfileByNameQuery(query.username, {
    skip: !query.username,
  });

  const seoBlockGenerator = SEO[route] || SEO["/index"];

  const seoBlock = seoBlockGenerator(profile);

  return (
    <Head>
      <title className="text-base text-green-400">
        {seoBlock.title} | {seoBlock.description}
      </title>
      <meta name="description" content={seoBlock.description} />
      <link rel="icon" href="/favicon.svg" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="title" content={seoBlock.title} />
      <meta name="description" content={seoBlock.description} />
      <meta name="image" content={seoBlock.image} />
      <meta name="url" content={seoBlock.url} />
      <meta property="og:site_name" content={seoBlock.title} />
      <meta property="og:title" content={seoBlock.ogTitle} />
      <meta property="og:description" content={seoBlock.ogDescription} />
      <meta property="og:url" content={seoBlock.url} />
      <meta property="og:image" content={seoBlock.ogImage} />
      <meta name="twitter:card" content={seoBlock.twitterCard} />
      <meta name="twitter:title" content={seoBlock.twitterTitle} />
      <meta name="twitter:description" content={seoBlock.twitterDescription} />
      <meta name="twitter:url" content={seoBlock.url} />
      <meta name="twitter:image" content={seoBlock.twitterImage} />
    </Head>
  );
};
