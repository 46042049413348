import React, { useEffect, useMemo, useState } from "react";
import getMainPageProps from "~components/landing/get-main-page-props";
import LandingFooter from "~components/landing/ui/footer";

export default function Footer() {
  const [footer, setFooter] = useState({});

  useEffect(() => {
    getMainPageProps({ locale: "en" }).then((res) =>
      setFooter(res?.props?.footer),
    );
  }, []);

  return (
    <div className="w-full">
      <LandingFooter data={footer} forPlatform={true} />
    </div>
  );
}
