export default function Picture({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8ZM6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H16C17.1046 2 18 2.89543 18 4V13.4449L14.7607 10.7946C13.7442 9.96284 12.2573 10.0596 11.3571 11.0161L4.78401 18H4C2.89543 18 2 17.1046 2 16V4C2 2.89543 2.89543 2 4 2ZM6.15726 18H16C17.1046 18 18 17.1046 18 16V14.7369L14.1275 11.5685C13.5176 11.0695 12.6255 11.1275 12.0853 11.7014L6.15726 18ZM1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z"
      />
    </svg>
  );
}
