import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import useProfile from "~services/backend/profiles/useProfile";
import { selectIsAccountBindingInProgress } from "~store/authSlice/selectors";
import ConnectWalletButton from "./connect-wallet-button";

const Web3Action = ({ children, additionalCondition = true }) => {
  const { account: address } = useWeb3React();
  const { profile } = useProfile();
  const isAccountBindingInProgress = useSelector(
    selectIsAccountBindingInProgress,
  );

  if (
    address &&
    profile &&
    additionalCondition &&
    !isAccountBindingInProgress
  ) {
    return children;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="max-h-[46px] flex justify-center items-center"
    >
      <ConnectWalletButton
        title="Connect wallet"
        loginMethod="wallet"
        autoOpenSign={false}
      />
    </div>
  );
};

export default Web3Action;
