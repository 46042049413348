import { transformResponseItem } from "~api";
import { backUploaderUrl } from "~configs";
import { backendApi } from "..";

export const chatsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getChats: build.query({
      query: () => ({
        url: "chats",
        params: {
          populate: ["users", "users.image", "lastMessage"],
        },
      }),
      transformResponse: (res) => transformResponseItem(res) || [],

      providesTags: (result) =>
        result?.length
          ? result.map(({ id }) => ({ type: "Chat", id }))
          : [{ type: "Chat", id: null }],
    }),

    createChat: build.mutation({
      query: (data) => ({
        url: `${backUploaderUrl}/api/chats`,
        method: "POST",
        params: { populate: ["users", "lastMessage"] },
        body: { data },
      }),

      transformResponse: transformResponseItem,
    }),

    getMessagesByChat: build.query({
      query: ({ chatId, pagination, withMedia: with_media, filters = {} }) => ({
        url: "messages",
        params: {
          populate: {
            media: "*",
            user: {
              fields: ["id"],
            },
            permissions: {
              populate: ["levels"],
            },
          },
          filters: {
            chat: chatId,
            with_media,
            ...filters,
          },
          sort: ["id:desc"],
          pagination,
        },
      }),

      transformResponse: (res, _, arg) => {
        if (!arg.pagination && !arg.withMedia) {
          res.data.sort((a, b) => a.id - b.id);
        }
        return transformResponseItem(res) || [];
      },

      providesTags: (result) =>
        result?.length
          ? result.map(({ id }) => ({ type: "Message", id }))
          : [{ type: "Message", id: null }],
    }),

    sendMessage: build.mutation({
      query: (data) => ({
        url: `${backUploaderUrl}/api/messages`,
        method: "POST",
        params: {
          populate: ["media", "user", "permissions", "permissions.levels"],
        },
        body: data,
      }),

      transformResponse: transformResponseItem,
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetMessagesByChatQuery,
  useLazyGetMessagesByChatQuery,
  useSendMessageMutation,
  useCreateChatMutation,
} = chatsApi;
