const WalletConnect = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3865_56633)">
        <path
          d="M4.59442 6.34459C7.85599 3.21847 13.144 3.21847 16.4056 6.34459L16.7981 6.72082C16.9612 6.87713 16.9612 7.13055 16.7981 7.28686L15.4554 8.57388C15.3738 8.65203 15.2416 8.65203 15.1601 8.57388L14.6199 8.05614C12.3445 5.87528 8.65547 5.87528 6.38012 8.05614L5.80164 8.6106C5.7201 8.68875 5.5879 8.68875 5.50636 8.6106L4.16357 7.32358C4.00049 7.16727 4.00049 6.91385 4.16357 6.75754L4.59442 6.34459ZM19.1826 9.00629L20.3777 10.1517C20.5408 10.308 20.5408 10.5615 20.3777 10.7178L14.989 15.8828C14.8259 16.0391 14.5615 16.0391 14.3984 15.8828C14.3984 15.8828 14.3984 15.8828 14.3984 15.8828L10.5739 12.217C10.5331 12.1779 10.467 12.1779 10.4262 12.217C10.4262 12.217 10.4262 12.217 10.4262 12.217L6.60172 15.8828C6.43865 16.0391 6.17424 16.0391 6.01116 15.8828C6.01116 15.8828 6.01116 15.8828 6.01116 15.8828L0.622309 10.7177C0.45923 10.5614 0.45923 10.308 0.622309 10.1517L1.81739 9.00622C1.98047 8.84991 2.24487 8.84991 2.40795 9.00622L6.23258 12.672C6.27335 12.7111 6.33945 12.7111 6.38022 12.672C6.38022 12.672 6.38022 12.672 6.38022 12.672L10.2047 9.00622C10.3677 8.84991 10.6321 8.8499 10.7952 9.00621C10.7952 9.00621 10.7952 9.00621 10.7952 9.00621L14.6198 12.672C14.6606 12.7111 14.7267 12.7111 14.7675 12.672L18.5921 9.00629C18.7551 8.84998 19.0195 8.84998 19.1826 9.00629Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3865_56633">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WalletConnect;
