import { useState } from "react";

import AllIcons from "~components/zui/components/all-icons";

const ImageUploader = ({
  withFileInput = false,
  uniqInputId = "file-input",
  onClick,
  setImageFile,
  setSizeError,
  maxSize = 5,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  const handleUploadImage = async (e) => {
    const imageFileValue = e.target.files[0];

    const sizeInMB = imageFileValue?.size / (1024 * 1024);

    if (sizeInMB > maxSize) {
      setSizeError(true);
      setTimeout(() => setSizeError(false), 6000);
      return;
    }

    setImageFile(imageFileValue);
  };

  return (
    <div
      onMouseOver={() => setShowLoader(true)}
      onMouseLeave={() => setShowLoader(false)}
      onClick={onClick}
      className={`absolute z-30 w-full h-full inset-0 cursor-pointer duration-100 pointer-events-auto overflow-hidden bg-zinc-900 flex items-center transition-all justify-center duration-300 ${
        showLoader ? "bg-opacity-50" : "bg-opacity-0"
      }`}
    >
      <AllIcons
        name="Pencil"
        className={`w-7 h-7 text-white z-50 ${showLoader ? "block" : "hidden"}`}
      />
      {withFileInput && (
        <label
          htmlFor={uniqInputId}
          className="w-full h-full absolute z-90 cursor-pointer"
        >
          <input
            type="file"
            name="file"
            id={uniqInputId}
            accept="image/*"
            onChange={handleUploadImage}
            className="hidden"
          />
        </label>
      )}
    </div>
  );
};

export default ImageUploader;

const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
