import React from "react";

const Graph = ({ className }) => (
  <svg
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76904 1.73077C5.76904 0.774892 6.54393 0 7.49981 0C8.45569 0 9.23058 0.774892 9.23058 1.73077V12.2692C9.23058 13.2251 8.45569 14 7.49981 14C6.54393 14 5.76904 13.2251 5.76904 12.2692V1.73077ZM11.5386 4.06415C11.5386 3.10827 12.3135 2.33337 13.2693 2.33337C14.2252 2.33337 15.0001 3.10827 15.0001 4.06414V12.2693C15.0001 13.2251 14.2252 14 13.2693 14C12.3135 14 11.5386 13.2251 11.5386 12.2693V4.06415ZM1.73077 7C0.774891 7 0 7.77489 0 8.73077V12.2692C0 13.2251 0.774891 14 1.73077 14C2.68665 14 3.46154 13.2251 3.46154 12.2692V8.73077C3.46154 7.77489 2.68665 7 1.73077 7Z"
    />
  </svg>
);

export default Graph;
