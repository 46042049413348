import P from "~ui/typography/p";
import H2 from "~components/ui/typography/h2";
import CardBox from "~components/ui/card-box";

import { useSubscriptions, useDiscountInfo } from "src/hooks/web3/use-contract";
import SubscriptionLevelCard from "~components/settings/subscription/subscription-types/subscription-level-card";
import { memo, useEffect, useMemo, useState } from "react";
import TextInput from "~components/input/text-input";
import Button from "~components/ui/button";
import getFormattedPrice from "~utils/vanilla/formatters/get-formatted-price";
import useToken from "src/hooks/web3/use-token";
import AllIcons from "~components/ui/all-icons";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";
import { maxUint256 } from "~web3/configs/constants";

import useProfile from "~services/backend/profiles/useProfile";
import Modal from "~components/popups/modal";
import ApproveInfo from "./ApproveInfo";
import {
  useCreateFollowingMutation,
  useDeleteFollowingMutation,
} from "~services/backend/followings";
import { useGetSubscriptionLevelsQuery } from "~services/backend/subscription-levels";
import { useWeb3React } from "@web3-react/core";
import Web3Action from "~components/web3/Web3Action";

const annualDiscount = 0;
// TODO: рефактор реюзабельных кусков в хуки.
// как минимум можно вынести общие вызовы с SubscriptionsPreview
const SubscriptionLevelsModal = memo(({ profile = {}, setShow }) => {
  const [chosenLevel, setChosenLevel] = useState(null);
  const [period, setPeriod] = useState(1);
  const [advancedSettingsOpened, setAdvancedSettingsOpened] = useState(false);

  const [approveModal, setApproveModal] = useState(false);

  const { profile: contextProfile } = useProfile();
  const { account } = useWeb3React();

  const [deleteFollowing] = useDeleteFollowingMutation();

  const [createFollowing] = useCreateFollowingMutation();

  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: levels = [] } = useGetSubscriptionLevelsQuery(profile.address);

  const { handleSubscribe, currentSubscriptions, handleUpgrade } =
    useSubscriptions(profile.address);

  const { approve, allowance: currentAllowance, balance } = useToken();

  const isFollowed = useMemo(() => {
    if (!contextProfile) return false;

    return !!profile.followers?.find((f) => f.user?.id === contextProfile.id);
  }, [profile.followers, contextProfile]);

  const notMaxAllowance = useMemo(
    () => currentAllowance < BigInt(maxUint256.slice(0, 16)),
    [currentAllowance],
  );

  const discount = useDiscountInfo({ author: profile.address });

  // number[]
  const subscriptionsInUse = useMemo(
    () =>
      currentSubscriptions ? currentSubscriptions.map((sub) => sub.level) : [],
    [currentSubscriptions],
  );

  const level = useMemo(() => {
    if (!handleUpgrade) return null;
    if (!subscriptionsInUse.length) return null;

    const onchainLevel =
      Math.max(subscriptionsInUse) >= 0 ? Math.max(subscriptionsInUse) : null;

    return onchainLevel;
  }, [profile, handleUpgrade, contextProfile, subscriptionsInUse]);

  const subscriptionId = useMemo(() => {
    const followers = profile.followers;

    return (
      contextProfile &&
      followers?.find((follow) => follow.user.id === contextProfile.id)?.id
    );
  }, [profile, contextProfile]);

  const amount = useMemo(
    () =>
      calcPriceWithAnnualDiscount({
        price: levels[chosenLevel]?.price,
        discount: annualDiscount,
        period,
      }) * period,
    [annualDiscount, levels[chosenLevel]?.price, period],
  );

  useEffect(() => {
    //если аллованс максимальный всегда ставим тру
    if (!notMaxAllowance) {
      setApproved(true);
    }
  }, [amount, notMaxAllowance]);

  useEffect(() => {
    setChosenLevel(null);
  }, [profile, currentSubscriptions]);

  const isInsufficientBalance =
    chosenLevel === null
      ? false
      : levels[chosenLevel]
      ? balance < levels[chosenLevel].price
      : true;

  const onApprove = async () => {
    setLoading(true);

    const approveStatus = await approve({
      amount: advancedSettingsOpened ? +currentAllowance + amount : maxUint256,
    });

    if (approveStatus) {
      setApproved(true);
    }

    setLoading(false);
  };

  const onSubscribe = async () => {
    setLoading(true);

    let success;
    let subId = subscriptionId;

    if (!isFollowed) {
      const { data: res } = await createFollowing({ authorId: profile.id });

      subId = res?.id;
    }

    if (!subId) {
      return;
    }

    if (level === null) {
      success = await handleSubscribe({
        author: profile.id,
        period: 1,
        level: levels[chosenLevel].id,
        followingId: subId,
        onConfirm: () => {
          setShow(false);
          setLoading(false);
        },
      });
    } else {
      const { tokenId } = profile.followers.find(
        (follow) => follow.user.id === contextProfile.id,
      );
      success = await handleUpgrade({
        author: profile.id,
        period: 1,
        level: levels[chosenLevel].id,
        followingId: subId,
        tokenId,
        onConfirm: () => {
          setShow(false);
          setLoading(false);
        },
      });
    }

    if (!isFollowed && !success && subId) {
      await deleteFollowing(subId);
    }

    setLoading(false);
  };

  const onCancel = () => {
    setShow(false);
  };

  const handlePeriod = (e) => {
    setPeriod(e.target.value);
  };

  return (
    <CardBox
      className={
        "@pg px-0 lg:px-[60px] @ht h-fit max-h-[90vh] @wh lg:min-w-[70vw] lg:max-w-[90vw] @ow overflow-y-scroll @brw border-0 pb-32 lg:pb-20"
      }
      maxContentHeight={true}
    >
      <H2 className="@ftf font-clash @ftw font-semibold @fts text-[26px] @mn mb-2 lg:mt-8 lg:mb-10 @tta text-center">
        Select a subscription
      </H2>
      <div className="flex lg:max-w-[75vw] mx-auto px-4 lg:px-0 lg:justify-center pb-8 lg:pb-0 lg:mb-10 overflow-x-scroll lg:overflow-x-visible">
        <div className="flex gap-4 h-full mx-10 lg:mx-0">
          {levels.length ? (
            levels.map((level, index) =>
              level.isActive ? (
                <div key={index} className={"w-[300px] flex"}>
                  <SubscriptionLevelCard
                    onClick={setChosenLevel}
                    isActive={chosenLevel === index}
                    noChosenCard={chosenLevel === null}
                    isDisabled={level.limited && !level.availableSupply}
                    isAlreadySubscribed={subscriptionsInUse?.includes(index)}
                    buttonTitle="Choose"
                    index={index}
                    title={level.title}
                    image={level.image && getSocialImagePath(level.image)}
                    price={calcPriceWithAnnualDiscount({
                      price: +level.price,
                      discount: annualDiscount,
                      period,
                    })}
                    description={level.description}
                    limited={level.limited}
                    availableSupply={level.availableSupply}
                    discount={discount}
                  />
                </div>
              ) : null,
            )
          ) : (
            <>
              <SubscriptionLevelCard isLoading={true} />
              <SubscriptionLevelCard isLoading={true} />
              <SubscriptionLevelCard isLoading={true} />
            </>
          )}
        </div>
      </div>

      {notMaxAllowance && !isInsufficientBalance && !!account && (
        <div className="px-4 lg:mb-0">
          <Button
            variant="rounded"
            className="@pn relative @wh w-full lg:w-[300px] @bdc bg-stone-base hover:bg-lime-base @ttc text-zink-900 flex justify-center @ftf font-semibold z-55 @ht max-h-11 mb-7 mx-auto"
            onClick={() => setAdvancedSettingsOpened((op) => !op)}
            disabled={chosenLevel === null}
          >
            Advanced settings
            <AllIcons
              name="ArrowDown"
              className={`w-5 ml-2.5 -mt-[2px] duration-200 ${
                advancedSettingsOpened && "rotate-180 mt-[2px]"
              }`}
            />
          </Button>

          {advancedSettingsOpened && (
            <div className="w-full lg:max-w-[300px] mx-auto mt-5 md:mt-3">
              <div className="font-clash text-gray-400 text-16px leading-20px">
                Allowance amount (in months)
              </div>
              <TextInput
                type="number"
                onChange={handlePeriod}
                value={period}
                inputsClassName={
                  "@ttc text-black @ani items-stretch @ht h-12 @bdc bg-white @brc border-gray-base hover:border-black focus:border-black text-16px focus:outline-4 focus:outline focus:outline-offset-0 focus:outline-gray-100 @brr rounded-[10px] transition-all"
                }
                incrementButtonVariant="white"
              />
            </div>
          )}

          {advancedSettingsOpened && (
            <div className="w-full text-center whitespace-nowrap">
              <P className="@ftf font-clash @ftw font-medium @fts text-14px text-zinc-base">
                Total
              </P>
              <P className="@ftf font-clash @ftw font-semibold @fts text-[50px] @leh leading-[62px] text-zinc-900">
                {levels[chosenLevel]
                  ? getFormattedPrice(
                      calcPriceWithAnnualDiscount({
                        price: levels[chosenLevel].price,
                        discount: annualDiscount,
                        period,
                      }) * period,
                    )
                  : 0}{" "}
                <span className="text-zinc-base ml-1">BUSD</span>
              </P>
            </div>
          )}
        </div>
      )}

      {isInsufficientBalance && !!account && (
        <div className="mb-5">
          <P className="@ftf font-clash @ftw font-semibold @fts text-[18px] @mn mb-1 @tta text-center">
            Insufficient balance :(
          </P>
          <P className="@ftf font-clash @ftw font-semibold @fts text-[14px]  @tta text-center">
            Please, top up your wallet
          </P>
        </div>
      )}

      <div className="w-full py-6 lg:pt-0 shadow-custom lg:h-auto lg:shadow-none flex justify-center items-center fixed bottom-0 left-0 rounded-16px bg-white z-60">
        <Button
          onClick={onCancel}
          variant="rounded"
          className="max-h-[46px] mr-3 @bdc bg-white hover:bg-zinc-900 @ttc text-zinc-900 hover:text-white @brw border @brc border-zinc-900 hover:border-white @pg py-[12px] px-[30px] @wh w-[140px] flex justify-center items-center"
        >
          Cancel
        </Button>

        <Modal
          RenderCard={ApproveInfo}
          show={approveModal}
          setShow={setApproveModal}
          popupWindowClasses="@zi !z-190 rounded-lg"
          childContainerClasses={"@wh w-full lg:w-fit"}
          containerClasses={`@pg px-2 py-0 md:px-4 md:py-0 ${
            approveModal && " @zi !z-190"
          }`}
          renderCardProps={{
            onApprove,
          }}
          zIndex={190}
        />

        <Web3Action>
          <Button
            onClick={approved ? onSubscribe : () => setApproveModal(true)}
            variant="rounded"
            className="ml-3 @bdc bg-zinc-900 lg:hover:bg-white @brw border border-zinc-900 @pg py-[12px] px-[30px] max-h-[46px] @wh w-[140px] flex justify-center items-center"
            disabled={isInsufficientBalance || loading || chosenLevel === null}
          >
            {loading ? (
              <AllIcons name="SmallLoader" className="h-5 w-5 animate-spin" />
            ) : approved ? (
              "Subscribe"
            ) : (
              "Approve"
            )}
          </Button>
        </Web3Action>
      </div>
    </CardBox>
  );
});

export default SubscriptionLevelsModal;

const calcPriceWithAnnualDiscount = ({ price, discount = 0, period = 1 }) => {
  if (period > 12) period = 12;
  const discountForPeriod = period > 1 ? period * (discount / 12) : 0;
  return price - price * discountForPeriod;
};
