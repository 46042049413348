import React from "react";

const Orders = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5665 13.0302H11.5557C11.1381 13.0302 10.804 12.6918 10.804 12.2688C10.804 11.8457 11.1381 11.5073 11.5557 11.5073H16.5665C16.9841 11.5073 17.3181 11.8457 17.3181 12.2688C17.3181 12.6918 16.9841 13.0302 16.5665 13.0302ZM8.00659 15.2725C8.00659 15.7378 8.34065 16.0763 8.79997 16.0763C9.23815 16.0763 9.59336 15.7164 9.59336 15.2725C9.59336 14.8286 9.23815 14.4687 8.79997 14.4687C8.34065 14.4687 8.00659 14.8071 8.00659 15.2725ZM8.00659 12.2687C8.00659 12.7341 8.34065 13.0725 8.79997 13.0725C9.23815 13.0725 9.59336 12.7127 9.59336 12.2687C9.59336 11.8248 9.23815 11.465 8.79997 11.465C8.3618 11.465 8.00659 11.8248 8.00659 12.2687ZM16.5665 16.0338H11.5557C11.1381 16.0338 10.804 15.6953 10.804 15.2723C10.804 14.8493 11.1381 14.5108 11.5557 14.5108H16.5665C16.9841 14.5108 17.3181 14.8493 17.3181 15.2723C17.3181 15.6953 16.9841 16.0338 16.5665 16.0338ZM19.9908 18.4877V8.96923C19.9908 7.82702 18.9468 7.91163 18.9468 7.91163H13.0591C12.725 7.91163 12.5163 7.74241 12.5163 7.74241C12.5163 7.74241 12.2657 7.31937 11.8064 6.6425C11.3888 5.88102 10.846 6.00793 10.846 6.00793H6.29447C5 6.00793 5 7.23476 5 7.23476V18.4031C5 19.7991 6.04392 19.6299 6.04392 19.6299H19.0721C20.1578 19.6299 19.9908 18.4877 19.9908 18.4877Z"
      />
    </svg>
  );
};

export default Orders;
