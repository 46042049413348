export default function ArrowRightTop({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path
        d="M4 15.9997L15.8393 4.16035"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.93115 4H16.0934V15.1622"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
