import React from "react";

const AirBaloon = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9751 13.5192L11.8257 16.4772H10.1974L9.04797 13.5192C8.5117 12.1392 8.20421 10.2146 8.20421 8.23875C8.20421 5.94362 8.54779 3.79585 9.17151 2.19073C9.70442 0.818924 10.3924 0 11.0114 0C11.6306 0 12.3185 0.818924 12.8515 2.19073C13.4753 3.79585 13.8188 5.94362 13.8188 8.23859C13.8188 10.2146 13.5114 12.1392 12.9751 13.5192Z" />
      <path d="M8.64845 0.345703C8.40121 0.738968 8.17361 1.1992 7.96985 1.72356C7.28973 3.47403 6.9151 5.78763 6.9151 8.23836C6.9151 10.3992 7.24593 12.4402 7.84631 13.9859L8.81445 16.4769H8.24998C8.07912 16.4769 7.91513 16.409 7.79428 16.2881L4.91403 13.408C3.53333 12.027 2.77298 10.1913 2.77298 8.23836C2.77298 4.51653 5.25375 1.36386 8.64845 0.345703Z" />
      <path d="M13.2085 16.4769L14.1767 13.9859C14.7771 12.4402 15.1079 10.3992 15.1079 8.23853C15.1079 5.78763 14.7332 3.47403 14.0531 1.72356C13.8494 1.1992 13.6216 0.738968 13.3744 0.345703C16.7691 1.36386 19.25 4.51669 19.25 8.23853C19.25 10.1911 18.4897 12.027 17.1089 13.408L14.2287 16.2881C14.1078 16.409 13.9439 16.4769 13.773 16.4769H13.2085Z" />
      <path d="M9.32401 19.2382H12.6987V17.7657H13.8258C13.8804 17.7657 13.9344 17.7647 13.9878 17.7627V21.3551C13.9878 21.7111 13.6991 21.9996 13.3433 21.9996H8.67964C8.32381 21.9996 8.03511 21.7111 8.03511 21.3551L8.03494 17.7659H9.32401V19.2382Z" />
    </svg>
  );
};

export default AirBaloon;
