export default function Info({ className }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M20 10.5C20 15.189 16.1971 18.9933 11.5033 19C6.80955 19 2.99336 15.1823 3.00001 10.4867C3.01331 5.79774 6.8162 1.99336 11.5033 2.00001C16.1971 2.00666 20 5.81104 20 10.5ZM12.5604 13.6858C12.5604 13.606 12.5604 13.5462 12.5604 13.4863C12.5604 12.329 12.5604 11.1718 12.5604 10.0145C12.5604 9.64867 12.3477 9.43584 11.982 9.43584C11.3039 9.43584 10.6191 9.43584 9.94095 9.43584C9.61518 9.43584 9.37584 9.65532 9.37584 9.96127C9.36919 10.2672 9.60853 10.4934 9.9476 10.5C10.1072 10.5 10.2667 10.5 10.4263 10.5C10.4263 11.5708 10.4263 12.6217 10.4263 13.6858C10.2468 13.6858 10.0739 13.6792 9.90106 13.6858C9.60188 13.6991 9.37584 13.9253 9.37584 14.2179C9.37584 14.5106 9.60188 14.7434 9.90106 14.7434C10.9648 14.75 12.0286 14.75 13.0857 14.7434C13.3915 14.7434 13.6175 14.5106 13.6175 14.2179C13.6175 13.9186 13.3915 13.6925 13.079 13.6858C12.9194 13.6858 12.7532 13.6858 12.5604 13.6858ZM12.5604 7.31417C12.5604 6.73553 12.0884 6.25666 11.51 6.25001C10.9316 6.24336 10.4396 6.72888 10.4396 7.30752C10.4396 7.88616 10.9116 8.36503 11.49 8.37168C12.0684 8.37833 12.5604 7.89946 12.5604 7.31417Z" />
    </svg>
  );
}
