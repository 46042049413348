import React from "react";

const Eye = ({ className }) => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00421 0C13.2084 0 15.6038 2.87749 16.6569 4.59257C16.9825 5.11912 17.1549 5.72594 17.1549 6.345C17.1549 6.96406 16.9825 7.57088 16.6569 8.09743C15.6038 9.81251 13.2084 12.69 9.00421 12.69C4.80001 12.69 2.40459 9.81251 1.35151 8.09743C1.02596 7.57088 0.853516 6.96406 0.853516 6.345C0.853516 5.72594 1.02596 5.11912 1.35151 4.59257C2.40459 2.87749 4.80001 0 9.00421 0ZM6.7409 9.73227C7.41084 10.1799 8.19848 10.4188 9.00421 10.4188C10.0843 10.4178 11.1199 9.9882 11.8837 9.22445C12.6474 8.46069 13.077 7.42512 13.078 6.345C13.078 5.53927 12.8391 4.75164 12.3915 4.0817C11.9438 3.41176 11.3076 2.8896 10.5632 2.58127C9.8188 2.27293 8.99969 2.19225 8.20944 2.34944C7.41919 2.50663 6.69331 2.89463 6.12357 3.46436C5.55383 4.0341 5.16584 4.75999 5.00865 5.55023C4.85146 6.34048 4.93213 7.15959 5.24047 7.90399C5.54881 8.64839 6.07097 9.28463 6.7409 9.73227ZM11.7199 6.34516C11.7199 7.84511 10.5039 9.06105 9.00398 9.06105C7.50403 9.06105 6.28809 7.84511 6.28809 6.34516C6.28809 4.84522 7.50403 3.62927 9.00398 3.62927C10.5039 3.62927 11.7199 4.84522 11.7199 6.34516Z"
    />
  </svg>
);

export default Eye;
